import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
`;

export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    color: var(--color-point) !important;
  }
`;

export const RepeatInfoBox = styled.div`
  text-align: center;
  margin: 1rem 0 2rem 0;
`;

export const RepeatTypeBox = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 48%;
  }
`;

export const WeekDayList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
  padding: 2rem 0;

  button {
    width: 4.1rem;
    height: 5.4rem;
    border-radius: 80px;
  }
`;

export const DayBox = styled.div<{ isSelected: boolean }>`
  border-radius: 80px;
  button {
    background-color: ${({ isSelected }) =>
      isSelected ? '#7750E9' : 'var(--color-black-500)'};
  }
`;

export const BottomBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  input {
    height: 3rem;
  }
  div {
    width: 4.1rem;
    height: 100%;
  }
`;
