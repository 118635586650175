import styled from 'styled-components';
import {
  COACH_CATEGORY_ICON_WIDTH,
  COACH_CATEGORY_ICON_IMAGE_WIDTH
} from '#const/layout';

export const CategorySelectorWrap = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  min-height: calc(5rem + ${COACH_CATEGORY_ICON_WIDTH});
  position: relative;

  .swiper {
    z-index: 0;
    .swiper-wrapper .swiper-slide {
      display: flex;
      justify-content: center;
    }
  }
`;

export const CategoryItem = styled.li`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: ${COACH_CATEGORY_ICON_WIDTH}px;
  height: ${COACH_CATEGORY_ICON_WIDTH}px;
  scroll-snap-align: start;
  height: 100%;

  p {
    font-weight: bold;
  }

  img,
  svg {
    width: ${COACH_CATEGORY_ICON_IMAGE_WIDTH}px;
    height: ${COACH_CATEGORY_ICON_IMAGE_WIDTH}px;
  }
`;

export const DimBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
  max-width: var(--max-width);
  width: 100%;
  background: linear-gradient(
    to right,
    #181818 0%,
    rgba(24, 24, 24, 0) 50%,
    #181818 100%
  );
  pointer-events: none;
`;
