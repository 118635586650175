import React from 'react';
import { UserDefaultProfile } from '#assets/svg';
import { Container, ImageArea } from './styles';

/**
 * @description
 * 유저의 프로필 이미지를 보여주는 컴포넌트 입니다.
 *
 * 유저의 프로필 이미지를 저장하거나 받아오는 부분이 백엔드에 구현되지 않았기 때문에
 * 기본 프로필 이미지만 출력되도록 하였습니다.
 */

interface Props {
  src: string;
}

export const ProfileImage = ({ src }: Props) => {
  return (
    <Container>
      <ImageArea>
        {src ? <img src={src} /> : <UserDefaultProfile />}
        {/* <Camera className="camera" /> */}
      </ImageArea>
    </Container>
  );
};
