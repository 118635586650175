import { Route, Routes } from 'react-router';
import { CoachClinicalDataPage, CoachDetailPage } from '#pages';
import { Container } from './styles';

export function CoachView() {
  return (
    <Container>
      <Routes>
        <Route path="/:myUrl" element={<CoachDetailPage />} />
        <Route
          path="/clinical-data/:myUrl"
          element={<CoachClinicalDataPage />}
        />
      </Routes>
    </Container>
  );
}
