import { Container, Item } from './styles';

export interface SelectItem {
  key: number;
  text: string;
}

interface Props {
  items: SelectItem[];
  values: SelectItem['key'][];
  onChange?: (selectedItems: number[]) => void;
}

export const MultiSelectBar = ({ items, values, onChange }: Props) => {
  const toggleItem = (itemKey: number) => {
    const updatedValues = values.includes(itemKey)
      ? values.filter(key => key !== itemKey)
      : [...values, itemKey];

    onChange && onChange(updatedValues);
  };

  return (
    <Container>
      {items.map(item => (
        <Item
          key={item.key}
          data-text-type="bb"
          onClick={() => toggleItem(item.key)}
          selected={values.includes(item.key)}
        >
          {item.text}
        </Item>
      ))}
    </Container>
  );
};
