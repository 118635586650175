import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 0 6.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 3rem 1.65rem;
`;

export const ContentBox = styled.div`
  width: 100%;
  padding: 1.65rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
`;

export const DateTimeBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  column-gap: 0.75rem;
  align-items: center;
`;

export const AddBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  align-items: center;
  padding-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 2rem 0;
  background-color: var(--color-background);
`;
