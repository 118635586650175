import { BoxIcon } from '#atoms';
import { CheckBoxWrapper, Label, CheckBox } from './styles';

interface CheckBoxAtomProps {
  id: string;
  label?: string;
  checked: boolean;
  onClick?: () => void;
}

export default function CheckBoxAtom({
  id,
  label,
  checked,
  onClick,
  ...props
}: CheckBoxAtomProps) {
  return (
    <CheckBoxWrapper>
      <CheckBox
        id={id}
        className={checked ? 'checked' : ''}
        onClick={onClick}
        {...props}
      >
        {checked && (
          <BoxIcon type="check-white" size={10} boxColor="transparent" />
        )}
      </CheckBox>
      {label && <Label>{label}</Label>}
    </CheckBoxWrapper>
  );
}
