import styled from 'styled-components';

export const TextareaWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const TextareaArea = styled.div<{ height: number }>`
  /* position: relative; */
  width: 100%;
  height: ${props => props.height}rem;
  border: 1px solid var(--color-gray-second);
  border-radius: 0.5rem;

  & > textarea:focus {
    outline: none;
  }
`;

export const TextareaTag = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  resize: none;
  color: var(--color-white);
  font-size: 1.6rem;
  background-color: transparent;
  border: none;
`;
