import React from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useCheckValidState } from '#hooks/useCheckValidState';
import { SignUpEmail } from '#organisims';
import { $signupState } from '#states/atoms/recoilSignupState';
import { PrevWithTitleTextTemplate } from '#templates';
import { ButtonContainer } from './styles';

export function SignUpID() {
  const [signupState, setSignupState] = useRecoilState($signupState);
  const navigate = useNavigate();

  useCheckValidState({
    targetState: typeof signupState.isCoach === 'boolean',
    navigateTo: PATH.SIGN_UP_CATEGORY
  });

  const handleEmailChange = (email: string | null) => {
    setSignupState(prev => {
      return { ...prev, email };
    });
  };
  return (
    <PrevWithTitleTextTemplate
      prevRoute={PATH.SIGN_UP_PHONE}
      title={
        <Text textStyle="display">
          아이디를
          <br />
          입력해주세요
        </Text>
      }
    >
      <SignUpEmail value={signupState.email} setUserEmail={handleEmailChange} />
      <ButtonContainer>
        <Button
          disabled={!signupState.email}
          onClick={() => navigate(PATH.SIGN_UP_PASSWORD)}
        >
          다음
        </Button>
      </ButtonContainer>
    </PrevWithTitleTextTemplate>
  );
}
