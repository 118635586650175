import { useContext } from 'react';
import { LoginContext } from '#providers/LoginProvider';
import TokenController from '#utils/tokenController';

export function useAuth() {
  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);

  const login = (accessToken: string, refreshToken: string) => {
    setIsLoggedIn(true);
    TokenController.setAccessToken(accessToken);
    TokenController.setRefreshToken(refreshToken);
    TokenController.setTokenTime();
  };

  const logout = () => {
    setIsLoggedIn(false);
    TokenController.clear();
  };

  return { isLoggedIn, login, logout };
}
