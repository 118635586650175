import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, ItemPicker, Text } from '#atoms';
import { useApplications } from '#providers/Applications';
import { FibudContainerTemplate, useProgress } from '#templates';
import { Id } from '#types/confirmationForms';
import { exerciseCareerFormatter } from '#utils/dateFormatter';
import { APPLICATION_CAREER } from './const';
import { Container, TextContainer } from './styles';

export function ApplicationCarrer() {
  const { next } = useProgress();
  const { updateCurrentApplications, currentApplication } = useApplications();
  const selectedId = currentApplication?.carrer
    ? APPLICATION_CAREER.find(
        item =>
          item.text === exerciseCareerFormatter(currentApplication?.carrer)
      )?.value
    : null;

  const [selectedStartDate, setSelectedStartDate] = useState<Id | null>(null);

  const onChangeCareer = useCallback(
    (carrer: string) => {
      const selectedCareerValue =
        APPLICATION_CAREER.find(item => item.value === carrer)?.value ?? '0';
      const selectedDate = dayjs().subtract(
        parseInt(selectedCareerValue),
        'year'
      );
      const formattedDate = selectedDate.format('YYYY-MM-DD');

      updateCurrentApplications({ carrer: formattedDate });
    },
    [currentApplication, updateCurrentApplications]
  );

  useEffect(() => {
    selectedStartDate && onChangeCareer(selectedStartDate.toString());
  }, [selectedStartDate]);

  return (
    <FibudContainerTemplate type="paddingWithBottomButton">
      <FibudContainerTemplate type="bottomButtonContainer">
        <Container>
          <TextContainer>
            <Text textStyle="t1">
              회원님의 운동경력을
              <br />
              선택해주세요
            </Text>
          </TextContainer>
          {/* <SelectBox
            placeholder="운동경력을 선택해주세요"
            data={APPLICATION_CAREER.map(item => item.text)}
            onChange={onChangeCareer}
          /> */}
          <ItemPicker
            data={APPLICATION_CAREER.map(e => ({
              id: e.value,
              name: e.text
            }))}
            setSelectedItem={setSelectedStartDate}
            initialValue={selectedId}
          />
        </Container>
      </FibudContainerTemplate>
      <FibudContainerTemplate type="bottomButton">
        <Button onClick={() => next()} disabled={!currentApplication?.carrer}>
          다음
        </Button>
      </FibudContainerTemplate>
    </FibudContainerTemplate>
  );
}
