import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Text, Button, BoxIcon } from '#atoms';
import { PATH } from '#const/path';
import { Modal } from '#molecules';
import { DashboardChartSelectPage } from '#pages';
import { useDashboard } from '#providers/Dashboard';
import { IExerciseRecord } from '#types/records';
import { dateDayFormatter } from '#utils/dateFormatter';
import {
  ButtonContainer,
  Container,
  LectureBox,
  LectureTextWrapper,
  LectureWrapper
} from './styles';

export const DashboardChartList = () => {
  const currentDate = new Date();
  const navigate = useNavigate();
  const { customerDetail, setRegisteredLectureId, setLectureRecord } =
    useDashboard();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pageOpen, setPageOpen] = useState<boolean>(false);
  const [onNextCallback, setOnNextCallback] =
    useState<() => void | undefined>();
  const [onLoadCallback, setOnLoadCallback] =
    useState<(selectedRecords: IExerciseRecord[]) => void | undefined>();

  const isChartAvailable = (taughtAt: string) => {
    const taughtAtDate = dayjs(taughtAt);

    return (
      taughtAtDate.isSame(currentDate, 'day') ||
      taughtAtDate.isBefore(currentDate)
    );
  };

  const handleRecordButtonClick = ({
    registeredLectureId
  }: {
    registeredLectureId: string;
  }) => {
    setModalOpen(true);

    const onNext = () => {
      setModalOpen(false);
      setRegisteredLectureId(registeredLectureId);
      navigate(PATH.DASHBOARD_CHART_FORM);
    };

    const onLoad = (selectedRecords: IExerciseRecord[]) => {
      setPageOpen(false);
      setRegisteredLectureId(registeredLectureId);
      setLectureRecord({ exerciseRecord: selectedRecords });
      navigate(PATH.DASHBOARD_CHART_FORM);
    };

    setOnNextCallback(() => onNext);
    setOnLoadCallback(() => onLoad);
  };

  const handleChartLoadButtonClick = () => {
    setModalOpen(false);
    setPageOpen(true);
  };

  return (
    <Container>
      <div>
        {customerDetail.registeredLectures?.map((item, idx) => (
          <LectureWrapper key={idx}>
            <LectureTextWrapper>
              <LectureBox
                onClick={() => {
                  // if (item.exerciseRecords?.length) {
                  setRegisteredLectureId(item.id);
                  navigate(PATH.DASHBOARD_CHART);
                  // }
                }}
              >
                <Text textStyle="t3">{item.round}회차</Text>
                <BoxIcon type="next-arrow" onlyIcon size={16} />
              </LectureBox>
              <LectureBox data-text-color="gray2">
                <Text>{dateDayFormatter(item.taughtAt)}</Text>
                <Text>{item.gym?.name}</Text>
              </LectureBox>
            </LectureTextWrapper>
            <Button
              onClick={() => {
                handleRecordButtonClick({ registeredLectureId: item.id });
              }}
              disabled={
                (item.exerciseRecords && item.exerciseRecords.length > 0) ||
                !isChartAvailable(item.taughtAt)
              }
            >
              {item.exerciseRecords?.length
                ? '운동차트 등록 완료'
                : '운동차트 등록'}
            </Button>
          </LectureWrapper>
        ))}
      </div>
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <Text textStyle="t3">기존 운동차트에서 불러 오시겠습니까?</Text>
          <ButtonContainer>
            <Button background="anti" onClick={onNextCallback}>
              새로 작성하기
            </Button>
            <Button onClick={handleChartLoadButtonClick}>불러오기</Button>
          </ButtonContainer>
        </Modal>
      )}
      {pageOpen && (
        <DashboardChartSelectPage
          onPrevCallback={() => setPageOpen(false)}
          onLoadCallback={selectedRecords =>
            onLoadCallback && onLoadCallback(selectedRecords)
          }
        />
      )}
    </Container>
  );
};
