import {
  SignHomePage,
  SignInAlarmTalk,
  SignInPage,
  SignInPasswordRequest,
  SignInPasswordReset,
  SignUpBirthday,
  SignUpCategory,
  SignUpFinal,
  SignUpGender,
  SignUpID,
  SignUpNickname,
  SignUpPassword,
  SignUpPhone,
  SignUpPolicy
} from '#pages';
import { Navigate, Route, Routes } from 'react-router';
import { Container } from './styles';

export function SignView() {
  return (
    <>
      <Container>
        <Routes>
          <Route path="" element={<SignHomePage />} />
          <Route
            path="in/*"
            element={
              <Routes>
                <Route path="" element={<SignInPage />} />
                <Route
                  path="password/*"
                  element={
                    <Routes>
                      <Route
                        path="request"
                        element={<SignInPasswordRequest />}
                      />
                      <Route
                        path="reset/:token"
                        element={<SignInPasswordReset />}
                      />
                    </Routes>
                  }
                />
              </Routes>
            }
          ></Route>
          <Route
            path="up/*"
            element={
              <Routes>
                <Route path="" element={<Navigate replace to="policy" />} />
                <Route path="category" element={<SignUpCategory />} />
                <Route path="policy" element={<SignUpPolicy />} />
                <Route path="phone" element={<SignUpPhone />} />
                <Route path="id" element={<SignUpID />} />
                <Route path="password" element={<SignUpPassword />} />
                <Route path="nickname" element={<SignUpNickname />} />
                <Route path="gender" element={<SignUpGender />} />
                <Route path="birthday" element={<SignUpBirthday />} />
              </Routes>
            }
          ></Route>
          <Route path="alarm" element={<SignInAlarmTalk />}></Route>
        </Routes>
      </Container>
      <Routes>
        <Route path="up/final" element={<SignUpFinal />} />
      </Routes>
    </>
  );
}
