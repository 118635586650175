import React from 'react';
import { Button, SelectBox, Text } from '#atoms';
import { PATH } from '#const/path';
import { CheckList } from '#molecules';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import {
  ButtonContainer,
  Container,
  PaddingContainer,
  TextContainer
} from './styles';

export const JOB_LIST = [
  '학생',
  '군인',
  '주부',
  '사무직',
  '영업직',
  '서비스직',
  '전문직',
  '자영업자',
  '운동선수'
];

export const JOB_CHECKLIST = [
  '반복적인 동작을 요구하는 직업인가요?',
  '장시간 앉아있는 직업인가요?',
  '8시간을 초과하여 근무하시나요?',
  '근무 시 정신적 스트레스가 있으신가요?'
];

export function MyPageApplicationsJob() {
  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.MYPAGE_APPLICATIONS}
      title="나의 신청서"
    >
      <PaddingContainer>
        <Container>
          <TextContainer>
            <Text textStyle="t1">현재 직업을 알려주세요</Text>
          </TextContainer>
          <SelectBox placeholder="직업을 선택해주세요" data={JOB_LIST} />
          <Text textStyle="t3">해당하는 항목에 체크해주세요</Text>
          <CheckList checkItems={JOB_CHECKLIST} />
          <ButtonContainer>
            <Button>저장</Button>
          </ButtonContainer>
        </Container>
      </PaddingContainer>
    </PrevHeaderBarWithTitleTemplate>
  );
}
