import React, { useEffect, useState } from 'react';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { ModalPortal, Button, Text } from '#atoms';
import { MAX_PRICE, MIN_PRICE } from '#const/coach/settings';
import { $coachConditionState } from '#states/atoms/coachCondition';
import { Background, PriceModalWrap, Input, InputArea } from './styles';

interface Props {
  setShowModal: SetterOrUpdater<boolean>;
}

export const PriceModal = ({ setShowModal }: Props) => {
  const [minPrice, setMinPrice] = useState<number | null>(null);
  const [maxPrice, setMaxPrice] = useState<number | null>(null);
  const [coachConditionState, setCoachConditionState] =
    useRecoilState($coachConditionState);

  const handleBackgroundClick = () => {
    setShowModal(false);
  };

  const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const inputData = new FormData(event.currentTarget);

    setCoachConditionState(prev => {
      return {
        ...prev,
        grade: false,
        reviewCount: false,
        distance: false,
        price: true,
        minPrice: inputData.get('minPrice')
          ? Number(inputData.get('minPrice'))
          : MIN_PRICE,
        maxPrice: inputData.get('maxPrice')
          ? Number(inputData.get('maxPrice'))
          : MAX_PRICE
      };
    });

    setShowModal(false);
  };

  const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setMinPrice(Number(event.target.value));
    } else {
      setMinPrice(null);
    }
  };

  const handleMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setMaxPrice(Number(event.target.value));
    } else {
      setMaxPrice(null);
    }
  };

  useEffect(() => {
    coachConditionState.minPrice && setMinPrice(coachConditionState.minPrice);
    coachConditionState.maxPrice && setMaxPrice(coachConditionState.maxPrice);
  }, [coachConditionState.minPrice, coachConditionState.maxPrice]);

  return (
    <ModalPortal>
      <Background onClick={handleBackgroundClick}>
        <form onSubmit={handleSubmit}>
          <PriceModalWrap onClick={handleModalClick}>
            <Text textStyle="t2">가격</Text>
            <InputArea>
              <Input
                className="text-16-400"
                type="number"
                name="minPrice"
                min={0}
                max={MAX_PRICE}
                value={minPrice ?? ''}
                onChange={handleMinPriceChange}
                placeholder={`${MIN_PRICE.toLocaleString('ko-KR')}원 이상`}
              />
              <Text style={{ flexShrink: 0 }}>부터</Text>
              <Input
                className="text-16-400"
                type="number"
                name="maxPrice"
                min={minPrice ?? 0}
                max={MAX_PRICE}
                value={maxPrice ?? ''}
                onChange={handleMaxPriceChange}
                placeholder={`${MAX_PRICE.toLocaleString('ko-KR')}원 이하`}
              />
              <Text style={{ flexShrink: 0 }}>까지</Text>
            </InputArea>
            <Button>적용하기</Button>
          </PriceModalWrap>
        </form>
      </Background>
    </ModalPortal>
  );
};
