import React, { PropsWithChildren, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { BoxIcon, Text } from '#atoms';
import {
  ChildrenContainer,
  Container,
  HeaderContainer,
  TitleContainer
} from './styles';

interface Props {
  title: string | React.ReactNode;
  caption?: string;
  prevRoute?: string;
  onPrevClick?: () => void;
}

export function PrevWithTitleTextTemplate({
  prevRoute,
  onPrevClick,
  title,
  caption,
  children
}: PropsWithChildren<Props>) {
  const navigate = useNavigate();

  const onClickArrow = useCallback(() => {
    onPrevClick && onPrevClick();
    if (prevRoute) navigate(prevRoute);
    else navigate(-1);
  }, [prevRoute, onPrevClick]);

  return (
    <Container>
      <HeaderContainer>
        <BoxIcon
          type="prev-arrow"
          boxColor="transparent"
          size={18}
          onlyIcon={true}
          onClick={onClickArrow}
        />
      </HeaderContainer>
      <TitleContainer>
        {typeof title === 'string' ? (
          <Text textStyle="display">{title}</Text>
        ) : (
          title
        )}
        {caption && <Text textColor="gray2">{caption}</Text>}
      </TitleContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
}
