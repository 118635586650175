import { BoxIcon, Text } from '#atoms';
import { EmptyContainer, EmptyContentWrap } from './styles';

interface EmptyProps {
  onClick: () => void;
}

export const EmptyItem = ({ onClick }: EmptyProps) => {
  return (
    <EmptyContentWrap onClick={onClick}>
      <EmptyContainer>
        <BoxIcon size={30} type="plus-white" boxColor="transparent" />
        <Text textStyle="c1" textColor="gray2">
          공간 추가
        </Text>
      </EmptyContainer>
    </EmptyContentWrap>
  );
};
