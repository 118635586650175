import React from 'react';
import styled from 'styled-components';

const StarSVG = ({ id, ratingValue }: { id: string; ratingValue: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
  >
    <defs>
      <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset={`${ratingValue * 100}%`} stopColor="var(--color-point)" />
        <stop
          offset={`${ratingValue * 100}%`}
          stopColor="var(--color-point-third)"
        />
      </linearGradient>
    </defs>
    <path
      d="M7.02447 0.463525C7.17415 0.00286961 7.82585 0.00287008 7.97553 0.463525L9.29611 4.52786C9.36305 4.73388 9.55503 4.87336 9.77164 4.87336L14.0451 4.87336C14.5295 4.87336 14.7309 5.49316 14.339 5.77786L10.8817 8.28976C10.7065 8.41709 10.6331 8.64277 10.7001 8.84878L12.0206 12.9131C12.1703 13.3738 11.6431 13.7568 11.2512 13.4721L7.79389 10.9602C7.61865 10.8329 7.38135 10.8329 7.20611 10.9602L3.74877 13.4721C3.35692 13.7568 2.82968 13.3738 2.97935 12.9131L4.29994 8.84878C4.36687 8.64277 4.29355 8.41709 4.1183 8.28976L0.660968 5.77786C0.269111 5.49316 0.470499 4.87336 0.954861 4.87336L5.22836 4.87336C5.44497 4.87336 5.63695 4.73388 5.70389 4.52786L7.02447 0.463525Z"
      fill={`url(#${id})`}
    />
  </svg>
);

const StarRates = ({ value }: { value: number }) => {
  // Generate stars based on rating
  const starsList = Array.from({ length: 5 }).map((_, index) => {
    // Generate a unique ID for each star's gradient
    const id = `star-gradient-${index}`;
    // Determine the fill percentage of the current star
    const fillPercentage =
      index + 1 <= value ? 1 : index < value ? value % 1 : 0;
    return <StarSVG key={id} id={id} ratingValue={fillPercentage} />;
  });

  return <StarRaterWrap>{starsList}</StarRaterWrap>;
};

const StarRaterWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export default StarRates;
