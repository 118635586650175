export const DISEASE_LIST = [
  '심장 질환',
  '근육/관절 부상',
  '당뇨',
  '고혈압',
  '저혈압',
  '소화기 질환'
];

export const PAINPART_LIST = [
  '목',
  '어깨',
  '허리',
  '골반',
  '팔꿈치',
  '손목',
  '발목',
  '무릎'
];
