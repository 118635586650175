/**
 * 현재위치 반환
 * @returns
 */
export default function getGeolocation() {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          resolve(position);
        },
        function (errPosition) {
          reject(errPosition);
        }
      );
    } else {
      reject({
        message: 'Not support geolocation'
      });
    }
  });
}
