import { atom } from 'recoil';
import sessionStorageManager from '#utils/sessionStorageManager';

const ATOM_KEY = 'coachCondition';

const { districtCode } = sessionStorageManager();

export type ICoachConditionState = Partial<{
  tag: string;
  grade: boolean;
  reviewCount: boolean;
  distance: boolean;
  price: boolean;
  minPrice: number;
  maxPrice: number;
  districtCode: string;
  districtSigu: string;
}>;

export const $coachConditionState = atom<ICoachConditionState>({
  key: ATOM_KEY,
  default: {
    grade: false,
    reviewCount: false,
    distance: true,
    price: false,
    districtCode: districtCode || undefined,
    districtSigu: '전체'
  }
});
