export const sleepQualityToText = (qualityValue: number) => {
  switch (qualityValue) {
    case 0:
      return '나쁨';
    case 1:
      return '보통';
    case 2:
      return '좋음';
    default:
      return '보통';
  }
};
