import { AxiosRequestConfig } from 'axios';
import { axios } from '#api/axios';

export async function post<T, Payload, F = unknown>(
  path: string,
  payload?: Payload,
  params?: AxiosRequestConfig<F>
) {
  try {
    const { data } = await axios.post<T>(path, payload, params);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetcher<T, F = unknown>(
  path: string,
  params?: AxiosRequestConfig<F>
) {
  try {
    const { data } = await axios.get<T>(path, params);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function patch<T, Payload, F = unknown>(
  path: string,
  payload?: Payload,
  params?: AxiosRequestConfig<F>
) {
  try {
    const { data } = await axios.patch<T>(path, payload, params);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function put<T, Payload, F = unknown>(
  path: string,
  payload?: Payload,
  params?: AxiosRequestConfig<F>
) {
  try {
    const { data } = await axios.put<T>(path, payload, params);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function del<T, F = unknown>(
  path: string,
  params?: AxiosRequestConfig<F>
) {
  try {
    const { data } = await axios.delete<T>(path, params);
    return data;
  } catch (e) {
    throw e;
  }
}
