import { useGetLecturesIng } from '#api/lectures';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import usePostLectures from '#hooks/quries/schedule/usePostLectures';
import { useSelectedLectures } from '#hooks/useSelectedLectures';
import { ScheduleCalendar, ScheduleHourTable } from '#organisims';
import { useAttendingLectures } from '#providers/AttendLecture';
import { FibudDate } from '#providers/Schedules';
import { useSchedules } from '#providers/Schedules/Schedules';
import { useToast } from '#providers/ToastProvider';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { LectureIngList, SelectedLecture } from '#types/lectures';
import { convertRegisteredDateToScheduleDate } from '#utils/dateFormatter';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  ButtonContainer,
  Container,
  ItemContainer,
  ScheduleContainer,
  SelectedItem,
  TextContainer
} from './styles';

export function MyPageScheduleRegistration() {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { lectureId } = useParams();
  const { data } = useGetLecturesIng(lectureId ?? '');
  const lecture: LectureIngList | null = data
    ? {
        lecture: data.lecture,
        gymName: data.gym.name,
        calculatedRegisteredLecture: data.calculatedRegisteredLecture,
        status: 'NORMAL'
      }
    : null;
  const { selectedLecture, updateSelectedLecture } = useAttendingLectures();
  const { selectedDate, setSelectedDate, setCoachId } = useSchedules();
  const [selectedDates, setSelectedDates] = useState<FibudDate[]>([]);
  const { selectedLectures, setSelectedLectures } = useSelectedLectures();
  const { mutateAsync: postLectures, isLoading } = usePostLectures();

  const removeSelectedDate = useCallback((index: number) => {
    setSelectedDate(prev => {
      return { ...prev, hour: undefined };
    });
    setSelectedDates(prev => {
      const updatedDates = prev.filter((_, i) => i !== index);
      return updatedDates.sort((a, b) => {
        if (a.year !== b.year) return a.year - b.year;
        if (a.month !== b.month) return a.month - b.month;
        if (a.date !== b.date) return a.date - b.date;
        return (a.hour || 0) - (b.hour || 0);
      });
    });
  }, []);

  const convertToSelectedLecture = (
    selectedLecture: LectureIngList,
    gymId: string,
    selectedDates: FibudDate[]
  ): SelectedLecture[] => {
    const makeFibudDate = (date: FibudDate, teachingTime = 0) => {
      if (!date || !date.hour) return null;
      return new Date(
        date.year,
        date.month - 1,
        date.date,
        date.hour + teachingTime,
        0,
        0
      ).toISOString();
    };

    const makeRound = (index: number) => {
      const currentRound =
        selectedLecture.calculatedRegisteredLecture.registeredLectureCount +
        index +
        1;
      const totalRound =
        selectedLecture.calculatedRegisteredLecture.totalRegisteredLectureCount;

      if (currentRound > totalRound) return totalRound;
      return currentRound;
    };

    return selectedDates.map((date, index) => ({
      lectureId: selectedLecture.lecture.id,
      round: makeRound(index),
      taughtAt: makeFibudDate(date) as string,
      endedAt: makeFibudDate(date, 1) as string,
      gymId: gymId
    }));
  };

  const onClickTeachingTime = (
    selectedLecture: LectureIngList,
    selectedDate: FibudDate
  ) => {
    if (!selectedLecture || !selectedDate.hour) return;
    if (
      selectedLecture.calculatedRegisteredLecture.registeredLectureCount +
        selectedDates.length >=
      selectedLecture.calculatedRegisteredLecture.totalRegisteredLectureCount
    ) {
      showToast({
        message: '예약 가능한 횟수를 초과하였습니다.',
        type: 'fail'
      });
    }
  };

  useEffect(() => {
    if (lecture && !selectedLecture) {
      updateSelectedLecture(lecture);
      setCoachId(lecture.lecture.coachId);
      return;
    }
    setCoachId(selectedLecture?.lecture.coachId ?? null);

    return () => {
      setCoachId(null);
    };
  }, [selectedLecture, lecture]);

  useEffect(() => {
    if (
      selectedDate &&
      selectedDate.hour &&
      !selectedDates.find(
        e =>
          e.date === selectedDate.date &&
          e.day == selectedDate.day &&
          e.hour === selectedDate.hour &&
          e.month === selectedDate.month &&
          e.year === selectedDate.year
      )
    ) {
      if (
        selectedLecture &&
        selectedLecture.calculatedRegisteredLecture.registeredLectureCount +
          selectedDates.length <=
          selectedLecture.calculatedRegisteredLecture
            .totalRegisteredLectureCount
      ) {
        setSelectedDates(prev => [...prev, selectedDate]);
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedLecture && data?.gym.id) {
      const newSelectedLectures = convertToSelectedLecture(
        selectedLecture,
        data?.gym.id,
        selectedDates
      );
      setSelectedLectures(newSelectedLectures);
    }
  }, [selectedDates]);

  const onClickReserve = async (selectedLecture: LectureIngList) => {
    if (
      selectedLecture &&
      selectedLecture?.calculatedRegisteredLecture
        .remainingRegisteredLectureCount < selectedDates.length
    ) {
      showToast({
        message: '예약 가능한 횟수를 초과하였습니다.',
        type: 'fail'
      });
      return;
    }

    const data = await postLectures(selectedLectures);
    const successedLectures = data.success;
    const failedLectures = data.failure;

    if (failedLectures.length > 0) {
      navigate(PATH.WELCOME_SCHEDULE_RESERVE_FAIL, {
        state: {
          lecture: selectedLecture?.lecture.title,
          coach: selectedLecture?.lecture.coachName,
          gym: selectedLecture?.gymName,
          successeRounds: successedLectures.map(lecture => lecture.round ?? 1),
          successeSchedules: successedLectures.map(lecture =>
            convertRegisteredDateToScheduleDate(lecture.taughtAt ?? '')
          ),
          failedRounds: failedLectures.map(lecture => lecture.round ?? 1),
          failedSchedules: failedLectures.map(lecture =>
            convertRegisteredDateToScheduleDate(lecture.taughtAt ?? '')
          )
        }
      });
    } else {
      navigate(PATH.WELCOME_SCHEDULE_RESERVE, {
        state: {
          lecture: selectedLecture?.lecture.title,
          coach: selectedLecture?.lecture.coachName,
          gym: selectedLecture?.gymName,
          successeRounds: successedLectures.map(lecture => lecture.round),
          successeSchedules: successedLectures.map(lecture =>
            convertRegisteredDateToScheduleDate(lecture.taughtAt)
          ),
          failedRounds: failedLectures.map(lecture => lecture.round),
          failedSchedules: failedLectures.map(lecture =>
            convertRegisteredDateToScheduleDate(lecture.taughtAt)
          )
        }
      });
    }
  };

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={`${PATH.MYPAGE_SCHEDULES}/${selectedLecture?.lecture.id}`}
      title="수업 일정 관리"
    >
      <Container>
        <TextContainer>
          <Text textStyle="t1">수업일정을 선택해주세요</Text>
          <Text textStyle="c1" textColor="gray2">
            예약 가능 횟수 이내에서 1회부터 선택 가능합니다 <br />
            원하시는 일시가 불가한 경우, 채팅으로 문의해 주세요
          </Text>
        </TextContainer>
        <ScheduleContainer>
          <ItemContainer>
            <Text textStyle="t3">{selectedLecture?.lecture.title}</Text>
            <div className="space-between">
              <div>
                <Text type="inline" textStyle="t3">
                  예약 가능 횟수
                </Text>
                <Text
                  style={{
                    borderRadius: '0.5rem',
                    padding: '0.5rem 1.5rem',
                    backgroundColor: 'var(--color-point)',
                    marginLeft: '1rem'
                  }}
                  type="inline"
                >
                  {
                    selectedLecture?.calculatedRegisteredLecture
                      .remainingRegisteredLectureCount
                  }
                  회
                </Text>
              </div>
              {selectedLecture?.lecture.recommendRoundPerWeek != undefined && (
                <Text textStyle="c1" textColor="gray2">
                  주 {selectedLecture?.lecture.recommendRoundPerWeek}회 권장
                </Text>
              )}
            </div>
          </ItemContainer>
          <ItemContainer>
            <ScheduleCalendar
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
            />
          </ItemContainer>
          {selectedDate.timeList.length > 0 && selectedLecture && (
            <ItemContainer>
              <Text>시간 선택</Text>
              <hr />
              <ScheduleHourTable
                lectureSchedule={{
                  currentRound:
                    selectedLecture.calculatedRegisteredLecture
                      .registeredLectureCount + selectedDates.length,
                  totalRound:
                    selectedLecture.calculatedRegisteredLecture
                      .totalRegisteredLectureCount
                }}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                onClickTime={() => {
                  if (!selectedLecture) return;
                  onClickTeachingTime(selectedLecture, selectedDate);
                }}
              />
            </ItemContainer>
          )}

          {selectedDates.length > 0 && (
            <ItemContainer>
              {selectedDates.map(
                (selectedDate, index) =>
                  selectedDate.hour && (
                    <SelectedItem key={index}>
                      <Text textColor="gray2">{`${selectedDate.month}월 ${
                        selectedDate.date
                      }일 ${dayjs()
                        .day(selectedDate.day)
                        .locale('ko')
                        .format('dddd')} ${dayjs()
                        .hour(selectedDate.hour)
                        .format('HH')}:00 - ${dayjs()
                        .hour(selectedDate.hour + 1)
                        .format('HH')}:00`}</Text>
                      <BoxIcon
                        type="x-gray"
                        onlyIcon={true}
                        size={13}
                        onClick={() => removeSelectedDate(index)}
                      />
                    </SelectedItem>
                  )
              )}
            </ItemContainer>
          )}
        </ScheduleContainer>
        <ButtonContainer>
          <Button
            disabled={!selectedDates.length}
            onClick={() => {
              if (!selectedLecture) return;
              onClickReserve(selectedLecture);
            }}
            isLoading={isLoading}
          >
            예약하기
          </Button>
        </ButtonContainer>
      </Container>
    </PrevHeaderBarWithTitleTemplate>
  );
}
