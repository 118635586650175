import { Divider } from '../styles';
import { InbodyIndicator } from './InbodyIndicator';
import { InbodyChart } from './InobdyChart';
import { PainChart } from './PainChart';

export const HealthRecord = () => {
  return (
    <div>
      <div className="px-20 pb-32">
        <div className="text-[#d7d2de] text-[18px] font-medium font-['Pretendard Variable'] pb-16">
          인바디
        </div>
        <InbodyIndicator />
        <InbodyChart />
      </div>
      <Divider />
      <div className="px-20 py-32">
        <div className="text-[#d7d2de] text-[18px] font-medium font-['Pretendard Variable'] pb-16">
          통증
        </div>
        <PainChart />
      </div>
    </div>
  );
};
