import styled from 'styled-components';

export const CheckListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const CheckListItems = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5.3rem;
  padding: 1rem 1.2rem;
  border-radius: 0.8rem;
  border: 1px solid var(--color-border);
  color: var(--color-gray-text-second);

  & span {
    display: none;
  }

  &.on {
    color: var(--color-white);
    border-color: var(--color-border-light);

    & span {
      display: inline-block;
    }
  }
`;

export const CheckBox = styled.div`
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 0.4rem;
  background-color: var(--color-gray);

  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
