import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 3rem 0;
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
`;

export const LectureContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  border-radius: 0.75rem;

  &.item {
    background-color: var(--color-gray-third);
    row-gap: 0.6rem;
  }

  &.add-item {
    align-items: center;
    row-gap: 1.25rem;
    border: 1px solid var(--color-gray-second);
  }
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;

  > span {
    padding: 0.3rem 1rem;
    background: var(--color-point);
    border-radius: 1.25rem;
  }
`;

export const BodyArea = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
