import { getMyAccount, patchAccount } from '#api/accounts';
import {
  getRegisteredLectures,
  getRegisteredLecturesReviewCount,
  postRegisteredLectureComplete
} from '#api/registered-lectures';
import { Account, Profile, Sex } from '#types/account';
import {
  IRegisteredLectureReviewCount,
  RegisteredLectureInfo
} from '#types/registeredLectures';
import { convertRegisteredDateToScheduleDate } from '#utils/dateFormatter';
import { getRegisteredLectureStatus } from '#utils/timeFormatter';
import TokenController from '#utils/tokenController';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner';

interface Props {
  children?: React.ReactNode;
}

type FibudSession = {
  access: string;
  refresh: string;
  expire: Dayjs;
};

export type UserInfoType = {
  id: string;
  name: string;
  nickname: string;
  email: string;
  phone: string;
  isCoach: boolean;
  sex: Sex;
  birthday: string;
  profile: Profile[];
};

interface UserInfoContext {
  isLogIn: boolean;
  userInfo: Account | null;
  setUserInfo: React.Dispatch<React.SetStateAction<Account | null>>;
  session: FibudSession | null;
  createSession: (access: string, refresh: string, isSignUp?: boolean) => void;
  touchSession: () => void;
  logout: () => void;
  patchProfile: (
    pProfile: Partial<Account>,
    callbacks?: MutationCallbacks
  ) => void;
  postLectureComplete: (
    registeredLectureId: string,
    callbacks?: MutationCallbacks
  ) => void;
  fetchTodayLectures: () => void;
  registeredLectureCounts: IRegisteredLectureReviewCount | null;
  registeredLectureInfo: RegisteredLectureInfo[];
}

interface MutationCallbacks {
  onSuccess?: () => void;
  onError?: () => void;
}

const UserInfoContext = React.createContext<UserInfoContext | null>(null);

export const useUserInfo = () => {
  const context = React.useContext(UserInfoContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <UserInfo> component.'
    );
  }
  return context;
};

function UserInfo({ children }: Props) {
  const queryClient = useQueryClient();
  const [loaded, setLoaded] = useState<boolean>();
  const { mutate } = useMutation(patchAccount);
  const { mutate: completeLecture } = useMutation(
    postRegisteredLectureComplete
  );
  const { accessToken, refreshToken } = TokenController;
  const [session, setSession] = useState<FibudSession | null>(
    accessToken && refreshToken
      ? {
          access: accessToken,
          refresh: refreshToken,
          expire: dayjs().add(30, 'm')
        }
      : null
  );
  const [userInfo, setUserInfo] = useState<Account | null>(null);
  const [registeredLectureCounts, setRegisteredLectureCounts] =
    useState<IRegisteredLectureReviewCount | null>(null);
  const [registeredLectureInfo, setRegisteredLectureInfo] = useState<
    RegisteredLectureInfo[]
  >([]);

  const createSession = useCallback(
    (access: string, refresh: string, isSignUp?: boolean) => {
      setSession({ access, refresh, expire: dayjs().add(30, 'm') });
      TokenController.setAccessToken(access);
      TokenController.setRefreshToken(refresh);
      getMyAccount().then(account => {
        if (account.isCoach) {
          window.location.href = `${process.env.REACT_APP_FIBUD_TRAINER_APP_URL}`;
          return;
        }

        const tagManagerArgs = {
          dataLayer: {
            event: 'signin',
            user_id: account.id,
            method: account.lType,
            type: account.isCoach ? '트레이너' : '고객'
          }
        };
        const tagManagerArgsSignUp = {
          dataLayer: {
            event: 'signup',
            method: account.lType,
            type: account.isCoach ? '트레이너' : '고객',
            // TODO: 카카오 로그인은 전부 true, 추후 선택기능 추가 필요
            terms_marketing: true,
            user_phone: account.phone,
            user_email: account.email,
            user_name: account.name,
            user_nickname: account.nickname,
            user_gender: account.sex,
            user_birthdate: account.birthday,
            user_id: account.id
          }
        };

        if (isSignUp) {
          TagManager.dataLayer(tagManagerArgsSignUp);
          TagManager.dataLayer(tagManagerArgs);
        } else {
          TagManager.dataLayer(tagManagerArgs);
        }
      });
    },
    []
  );

  const touchSession = useCallback(() => {
    setSession(prev => {
      if (!prev) return null;
      return { ...prev, expire: dayjs().add(30, 'm') };
    });
  }, []);

  const patchProfile = useCallback(
    (pProfile: Partial<Account>, callbacks?: MutationCallbacks) => {
      if (!userInfo)
        throw new Error('[UserInfo] << patchProfile >> userInfo is null');
      const nextProfile = { ...pProfile };
      mutate(nextProfile, {
        onSuccess() {
          setUserInfo({ ...userInfo, ...nextProfile });
          callbacks?.onSuccess && callbacks.onSuccess();
        },
        onError() {
          callbacks?.onError && callbacks.onError();
        }
      });
    },
    [userInfo]
  );

  const fetchTodayLectures = () => {
    getRegisteredLecturesReviewCount().then(registeredLecturesReviewCount => {
      if (
        registeredLecturesReviewCount.reservationCount === 0 &&
        registeredLecturesReviewCount.unreviewedCount === 0
      ) {
        setRegisteredLectureCounts(null);
        setRegisteredLectureInfo([]);
        return;
      } else {
        setRegisteredLectureCounts(registeredLecturesReviewCount);
        getRegisteredLectures().then(registeredLectures => {
          const lectureInfos = registeredLectures.data
            // .filter(registeredLecture => registeredLecture.status === 'NORMAL')
            .map(registeredLecture => ({
              registeredLectureId: registeredLecture.id,
              lectureId: registeredLecture.lectureId,
              coachName: registeredLecture.coachName,
              lectureTitle: registeredLecture.title,
              location: registeredLecture.gym?.name ?? '',
              currentCount: registeredLecture.round,
              status: getRegisteredLectureStatus(registeredLecture.taughtAt),
              taughtAt: convertRegisteredDateToScheduleDate(
                registeredLecture.taughtAt,
                true
              ),
              isFinal: registeredLecture.isFinalLectureForPayment,
              hasStar: registeredLecture.hasStar,
              isRequestedReview: registeredLecture.isRequestedReview
            }));
          setRegisteredLectureInfo(lectureInfos);
        });
      }
    });
  };

  const postLectureComplete = useCallback(
    (registeredLectureId: string, callbacks?: MutationCallbacks) => {
      completeLecture(registeredLectureId, {
        onSuccess() {
          fetchTodayLectures();
          callbacks?.onSuccess && callbacks.onSuccess();
        },
        onError() {
          callbacks?.onError && callbacks.onError();
        }
      });
    },
    [registeredLectureInfo]
  );

  const logout = useCallback(() => {
    setSession(null);
    setUserInfo(null);
    setRegisteredLectureCounts(null);
    setRegisteredLectureInfo([]);
    TokenController.clear();
    queryClient.clear();
  }, []);

  useEffect(() => {
    if (session) {
      TokenController.setAccessToken(session.access);
      TokenController.setRefreshToken(session.refresh);
      getMyAccount().then(account => {
        if (account.isCoach) {
          //   window.location.href = `${process.env.REACT_APP_FIBUD_TRAINER_APP_URL}`;
          TokenController.clear();
          queryClient.clear();
          return;
        }

        fetchTodayLectures();
        setUserInfo(account);
        setLoaded(true);
      });
    }
  }, [session]);

  const memorizedValue = useMemo(
    () => ({
      isLogIn: !!session,
      userInfo,
      setUserInfo,
      session,
      createSession,
      touchSession,
      patchProfile,
      postLectureComplete,
      logout,
      registeredLectureCounts,
      registeredLectureInfo,
      fetchTodayLectures
    }),
    [
      session,
      userInfo,
      setUserInfo,
      createSession,
      touchSession,
      logout,
      registeredLectureCounts,
      registeredLectureInfo
    ]
  );

  return (
    <UserInfoContext.Provider value={memorizedValue}>
      {!loaded && session ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '128px 0 0'
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        children
      )}
    </UserInfoContext.Provider>
  );
}

// 로그인 안된 상태일 때
function Logout({ children }: Props) {
  const { userInfo } = useUserInfo();
  return !userInfo ? <>{children}</> : null;
}

// 로그인 안된 상태일 때 또는 회원으로 로그인 된 상태일때
function ConsumerLike({ children }: Props) {
  const { userInfo } = useUserInfo();
  return !userInfo || !userInfo.isCoach ? <>{children}</> : null;
}

// 회원으로 로그인 된 상태
function IsConsumer({ children }: Props) {
  const { userInfo } = useUserInfo();
  return userInfo && !userInfo.isCoach ? <>{children}</> : null;
}

// 코치로 로그인 된 상태일 때
function IsCoach({ children }: Props) {
  const { userInfo } = useUserInfo();
  const isCoach = userInfo && userInfo.isCoach;

  return null;
}

UserInfo.Logout = Logout;
UserInfo.ConsumerLike = ConsumerLike;
UserInfo.IsConsumer = IsConsumer;
UserInfo.IsCoach = IsCoach;

export default UserInfo;
