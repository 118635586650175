import useInfiniteScrollQuery from '#hooks/useInfiniteScrollQuery';
import { CoachAccount } from '#types/account';
import { PER_PAGE_NUMBER } from 'src/shared/constant';
import { fetcher } from '../libs';

export interface Coaches {
  page: number;
  offset: number;
  total: string;
  isMoreDataAvailable: boolean;
  data: CoachAccount[];
}

interface Params {
  associationUrl: string;
  lat: number;
  lng: number;
  offset: number;
}

/**
 * @abstract /associations/{associationId}/coaches
 * @abstract 협회 소속 트레이너 모두 불러오기
 * @link https://api-dev.fibud.kr/api#/%ED%8A%B8%EB%A0%88%EC%9D%B4%EB%8B%9D%20%ED%98%91%ED%9A%8C/AssociationsController_findAllAssociationCoaches
 * @param associationUrl - 협회 url
 * @param lat
 * @param lng
 * @param offset
 * @returns
 */
export const useGetAssociationCoachesWithUrlQuery = ({
  associationUrl,
  lat,
  lng,
  offset
}: Params) => {
  return useInfiniteScrollQuery<CoachAccount>({
    perPageNum: offset || PER_PAGE_NUMBER,
    queryKeyBase: 'getEducationCoach',
    queryKeyParam: [associationUrl, lat, lng, offset],
    filters: {},
    queryFn: async ({ pageParam = 1 }) => {
      const associationId = await fetcher(`/associations/associationId`, {
        params: { associationUrl }
      });

      return fetcher(`/associations/${associationId}/coaches`, {
        params: {
          lat,
          lng,
          offset,
          page: pageParam - 1
        }
      });
    },
    options: {
      enabled: !!associationUrl && lat > 0 && lng > 0
    }
  });
};
