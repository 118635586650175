export const BannerText = {
  diet: {
    top_text: '운동 혼자하기 힘드시죠?',
    title: '다이어트 전문가',
    bottom_text: '미뤘던 운동을 지금 시작하세요!'
  },
  body_profile: {
    top_text: '인생 버킷리스트',
    title: '보디빌딩 전문가',
    bottom_text: '전문가와 함께 목표를 달성하세요!'
  },
  posture_fix: {
    top_text: '바른자세를 원하신다면?',
    title: '체형교정 전문가',
    bottom_text: '전문가와 함께 체형을 교정해보세요!'
  },
  cardio: {
    top_text: '자주 피곤하신가요?',
    title: '체력증진 전문가',
    bottom_text: '전문가와 함께 체력을 늘려보세요!'
  },
  schedule: {
    top_text: '수강중인 수업이 있으시네요!',
    title: '아래 버튼을 클릭해 다음 수업 일정을 예약해주세요.',
    bottom_text: '수업 예약하기'
  }
};
