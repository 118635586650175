import styled from 'styled-components';

export const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 2rem;
  background: var(--color-point-gradient-r180);
  border-radius: 1rem;
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
