import classNames from 'classnames';
import React, { useState, useCallback, useEffect } from 'react';
import { BoxIcon } from '../BoxIcon';
import { Text } from '../Text';
import {
  SelectBoxContainer,
  SelectBoxText,
  SelectBoxUl,
  SelectBoxLi
} from './style';

type ItemType = string | { id: string; name: string };

type SelectBoxProps = {
  placeholder: string;
  data: ItemType[];
  onChange?: (v: string) => void;
};

export const SelectBox = ({ placeholder, data, onChange }: SelectBoxProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [select, setSelect] = useState<ItemType>('');

  const toggleSelectBoxText = useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(() => {
    setIsOpen(prev => !prev);
  }, []);

  useEffect(() => {
    if (onChange && select) {
      typeof select === 'string' ? onChange(select) : onChange(select.id);
    }
  }, [select]);

  return (
    <SelectBoxContainer
      className={classNames({ open: isOpen, close: !isOpen })}
    >
      <SelectBoxText onClick={toggleSelectBoxText}>
        <Text textColor={select === '' ? 'gray2' : undefined}>
          {select === ''
            ? placeholder
            : typeof select === 'string'
            ? select
            : select.name}
        </Text>
        <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
      </SelectBoxText>
      <SelectBoxUl className={classNames({ open: isOpen, close: !isOpen })}>
        {data.map((item, index) => (
          <SelectBoxLi
            onClick={() => {
              setSelect(item);
              setIsOpen(!isOpen);
            }}
            data-text-type="t3"
            key={index}
          >
            {typeof item === 'string' ? item : item.name}
          </SelectBoxLi>
        ))}
      </SelectBoxUl>
    </SelectBoxContainer>
  );
};
