const storeageKeys = {
  accessToken: 'fibud_acc_token',
  refreshToken: 'fibud_rfsh_token',
  session: 'fibud_session',
  welcome: 'fibud_welcome_skip'
} as const;

const localStorageManager = () => {
  const currentSession = {
    accessToken: localStorage.getItem(storeageKeys.accessToken),
    refreshToken: localStorage.getItem(storeageKeys.refreshToken),
    session: localStorage.getItem(storeageKeys.session)
  };
  const welcome = localStorage.getItem(storeageKeys.welcome);

  const accessToken = () => currentSession.accessToken;
  const refreshToken = () => currentSession.refreshToken;

  const setAccessToken = (accessToken: string) => {
    currentSession.accessToken = accessToken;
    localStorage.setItem(storeageKeys.accessToken, accessToken);
  };
  const setRefreshToken = (refreshToken: string) => {
    currentSession.refreshToken = refreshToken;
    localStorage.setItem(storeageKeys.refreshToken, refreshToken);
  };
  const touchToken = () => {
    const currentPlusHalfHour = new Date()
      .setMinutes(new Date().getMinutes() + 30)
      .toString();

    localStorage.setItem(storeageKeys.session, currentPlusHalfHour);
    currentSession.session = currentPlusHalfHour;
  };

  const isTokenExpired = () => {
    return (
      !currentSession.session ||
      currentSession.session < new Date().getTime().toString()
    );
  };

  const clearSeesion = () => {
    localStorage.removeItem(storeageKeys.accessToken);
    localStorage.removeItem(storeageKeys.refreshToken);
    localStorage.removeItem(storeageKeys.session);

    currentSession.accessToken = null;
    currentSession.refreshToken = null;
    currentSession.session = null;
  };

  const readWelcome = () => {
    localStorage.setItem(storeageKeys.welcome, 'true');
  };
  const isAlreadyReadWelcome = () => !!welcome;

  return {
    accessToken,
    refreshToken,
    setAccessToken,
    setRefreshToken,
    touchToken,
    isTokenExpired,
    clearSeesion,
    isAlreadyReadWelcome,
    readWelcome
  };
};

export default localStorageManager();
