import styled from 'styled-components';

export const BottomContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const StatusContentArea = styled.div<{ isButton?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 5.3rem;
  border: 1px solid
    ${props =>
      props.isButton ? 'var(--color-gray-text)' : 'var(--color-border)'};
  border-radius: 0.5rem;
`;
