import { ITag } from '#types/tags';
import { Tag, Tags } from './styles';

interface TagsProps {
  tags: ITag[];
}
export const ExpertTagsBox = ({ tags }: TagsProps) => {
  return (
    <Tags>
      {tags.map((tag, index) =>
        index < 3 ? <Tag key={index}>{tag.name}</Tag> : null
      )}
    </Tags>
  );
};
