import { LecturesLectureList } from '#organisims';
import AttendingLecture from '#providers/AttendLecture';
import { FibudContainerTemplate, FindCoachTemplate } from '#templates';
import MetaHelmet from '#utils/MetaHelmet';
import { Container } from './styles';

export const LectureChartPage = () => {
  return (
    <>
      <MetaHelmet title="운동차트" />
      <Container>
        <AttendingLecture.IsLoading>
          <FibudContainerTemplate type="centered">
            로딩중...
          </FibudContainerTemplate>
        </AttendingLecture.IsLoading>
        {/* <AttendingLecture.IsError>잘못된 접근입니다!</AttendingLecture.IsError> */}
        <AttendingLecture.IsError>
          <FindCoachTemplate />
        </AttendingLecture.IsError>
        <AttendingLecture.EmptyLecture>
          <FindCoachTemplate />
        </AttendingLecture.EmptyLecture>
        <AttendingLecture.HasLecture>
          <LecturesLectureList />
        </AttendingLecture.HasLecture>
      </Container>
    </>
  );
};
