import { useEffect, useState } from 'react';
import { getNicknameDuplicateCheck } from '#api/accounts';
import { Text, Input } from '#atoms';
import { Container, StatusArea } from './styles';

interface Props {
  value?: string | null;
  setUserNickname: (nickname: string | null) => void;
}

export const SignUpNicknameInput = ({ value, setUserNickname }: Props) => {
  const [nickname, setNickname] = useState<string | null>(value ?? null);
  const [stringCount, setStringCount] = useState(0);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    if (nickname) {
      setStringCount(nickname.length);
      (async () => {
        const isDuplicated = await getNicknameDuplicateCheck(nickname);

        if (isDuplicated) {
          setValidationMessage('중복된 닉네임입니다');
          setUserNickname(null);
        } else {
          setValidationMessage('사용 가능한 닉네임입니다');
          setUserNickname(nickname);
        }
      })();
    } else {
      setStringCount(0);
      setUserNickname(null);
      setValidationMessage('');
    }
  }, [nickname]);

  return (
    <Container>
      <div>
        <Input
          type="text"
          labelText="닉네임"
          placeholder="2 ~ 10자 이내로 입력해주세요"
          showDeleteButton={true}
          onChange={nickname =>
            String(nickname) === String(nickname).trim()
              ? setNickname(String(nickname))
              : setValidationMessage('공백은 입력할 수 없습니다')
          }
          value={nickname}
          maxLength={10}
        />
      </div>
      <StatusArea>
        <Text textStyle="c2">{validationMessage}</Text>
        <Text textStyle="c2">{stringCount} / 10</Text>
      </StatusArea>
    </Container>
  );
};
