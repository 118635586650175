import React, { useCallback, useEffect, useState } from 'react';
import { useGetAllTags } from '#api/tags';
import { Button, Text } from '#atoms';
import { GridCategory } from '#molecules';
import { useApplications } from '#providers/Applications';
import { FibudContainerTemplate, useProgress } from '#templates';
import { ITag } from '#types/tags';
import { TextContainer } from './styles';

export function ApplicationPurpose() {
  const { next } = useProgress();
  const { data: tagsData } = useGetAllTags();
  const { updateCurrentApplications, currentApplication } = useApplications();
  const [initialTags, setInitialTags] = useState<ITag[] | undefined>(undefined);

  const onChangeCategory = useCallback(
    (selectedItems: ITag[]) => {
      const selectedIds = tagsData?.data
        .filter(item => selectedItems.some(({ name }) => name === item.name))
        .map(item => item.id);
      updateCurrentApplications({
        purposes: selectedIds
      });
    },
    [currentApplication]
  );

  useEffect(() => {
    currentApplication &&
      currentApplication.purposes &&
      setInitialTags(
        tagsData?.data.filter(
          e => currentApplication?.purposes?.includes(e.id)
        ) ?? []
      );
  }, [currentApplication?.purposes]);

  return (
    <FibudContainerTemplate type="paddingWithBottomButton">
      <FibudContainerTemplate type="bottomButtonContainer">
        <TextContainer>
          <Text textStyle="t1">PT수강 목적을 선택해주세요</Text>
          <Text textStyle="c1" textColor="gray2">
            중복선택 가능
          </Text>
        </TextContainer>
        {currentApplication?.purposes ? (
          initialTags && (
            <GridCategory
              categories={tagsData?.data ?? []}
              onChange={onChangeCategory}
              initialValue={initialTags}
            />
          )
        ) : (
          <GridCategory
            categories={tagsData?.data ?? []}
            onChange={onChangeCategory}
          />
        )}
      </FibudContainerTemplate>
      <FibudContainerTemplate type="bottomButton">
        <Button
          onClick={() => next()}
          disabled={!currentApplication?.purposes?.length}
        >
          다음
        </Button>
      </FibudContainerTemplate>
    </FibudContainerTemplate>
  );
}
