import React, { useEffect, useState } from 'react';
import { getCustomerList } from '#api/confirmation_forms';
import { IResponse } from '#types/api';
import { IGetCustomerList } from '#types/confirmationForms';
import { useUserInfo } from './UserInfo';

interface Props {
  children?: React.ReactNode;
}

interface CustomerListContext {
  isLoading: boolean;
  isError: boolean;
  customerList: IResponse<IGetCustomerList> | Record<string, never>;
}

const CustomerListContext = React.createContext<CustomerListContext | null>(
  null
);

export const useCustomerList = () => {
  const context = React.useContext(CustomerListContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <CustomerList> component.'
    );
  }
  return context;
};

function CustomerList({ children }: Props) {
  const { userInfo } = useUserInfo();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<
    IResponse<IGetCustomerList> | Record<string, never>
  >({});

  useEffect(() => {
    if (userInfo && userInfo.isCoach) {
      getCustomerList()
        .then(data => setCustomerList(data))
        .catch(() => setIsError(true))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      setIsError(false);
      setCustomerList({});
    }
  }, [userInfo]);

  const memorizedValue = React.useMemo(
    () => ({ isLoading, isError, customerList }),
    [isLoading, isError, customerList]
  );

  return (
    <CustomerListContext.Provider value={memorizedValue}>
      {children}
    </CustomerListContext.Provider>
  );
}

function IsLoading({ children }: Props) {
  const { isLoading } = useCustomerList();
  return isLoading ? <>{children}</> : null;
}

function IsError({ children }: Props) {
  const { isLoading, isError } = useCustomerList();
  return !isLoading && isError ? <>{children}</> : null;
}

function EmptyList({ children }: Props) {
  const { isLoading, isError, customerList } = useCustomerList();
  return !isLoading &&
    !isError &&
    (Object.keys(customerList).length === 0 ||
      customerList.data.length === 0) ? (
    <>{children}</>
  ) : null;
}

function HasList({ children }: Props) {
  const { isLoading, isError, customerList } = useCustomerList();
  return !isLoading &&
    !isError &&
    Object.keys(customerList).length > 0 &&
    customerList.data.length > 0 ? (
    <>{children}</>
  ) : null;
}

CustomerList.IsError = IsError;
CustomerList.IsLoading = IsLoading;
CustomerList.EmptyList = EmptyList;
CustomerList.HasList = HasList;

export default CustomerList;
