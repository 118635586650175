import { PATH } from '#const/path';
import { DashboardConfirmation, DashboardCustomerInfo } from '#organisims';
import { Dashboard, useDashboard } from '#providers/Dashboard';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { ChartHeader, Header, ListContainer } from './styles';

export function DashboardConfirmationPage() {
  const { customerDetail } = useDashboard();

  return (
    <>
      <Header>
        <PrevHeaderBarWithTitleTemplate
          prevRoute={PATH.DASHBOARD}
          title="고객관리"
          headerbarType="relative"
        />
        <Dashboard.IsLoading>로딩중...</Dashboard.IsLoading>
        <Dashboard.IsError>잘못된 접근입니다!</Dashboard.IsError>
        <Dashboard.EmptyCustomerDetail>
          회원 정보가 없습니다.
        </Dashboard.EmptyCustomerDetail>
        <Dashboard.HasCustomerDetail>
          <ChartHeader>
            <DashboardCustomerInfo
              customerDetail={customerDetail}
              type="confirmation-form"
            />
          </ChartHeader>
        </Dashboard.HasCustomerDetail>
      </Header>
      <ListContainer>
        <DashboardConfirmation customerDetail={customerDetail} />
      </ListContainer>
    </>
  );
}
