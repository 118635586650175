import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import { Socket, io } from 'socket.io-client';
import { EVENT } from '#const/chat/socketEvent';
import { useUserInfo } from '../UserInfo';
import { Chat, ChatRoom, ChattingRoom } from './types';

interface Props {
  children?: React.ReactNode;
}

interface ChatContext {
  socket: Socket | null;
  rooms: ChattingRoom[];
  curRoom?: ChatRoom;
  currentRoomChat: Chat[];
  reqRoomList: (page: number, offset?: number) => void;
  sendChat: (message: string) => void;
  exitCurrentRoom: () => void;
  openChat: (
    targetId: string,
    onSuccess: () => void,
    onFail: () => void
  ) => void;
  loadMore: (prevCursor: string) => void;
}

interface ChatInfo {
  chats: Chat[];
  prevCursor: string | null;
}

const ChatContext = React.createContext<ChatContext | null>(null);

export const useChat = () => {
  const context = React.useContext(ChatContext);
  if (!context) {
    throw new Error('This component must be used within a <Chat> component.');
  }
  return context;
};

export function Chats({ children }: Props) {
  let curRoomId = '';

  const { userInfo } = useUserInfo();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [rooms, setRooms] = useState<ChattingRoom[]>([]);
  const [curRoom, setCurRoom] = useState<ChatRoom>();
  const [currentRoomChat, setCurrentRoomChat] = useState<ChatInfo>({
    prevCursor: null,
    chats: []
  });

  const reqRoomList = (page: number, offset?: number) => {
    if (!userInfo || !socket) return;

    const handleRoomList = (rooms: ChattingRoom[]) => {
      setRooms(rooms);
      socket.off(EVENT.ROOM_LIST, handleRoomList);
    };
    socket.emit(EVENT.ROOM_LIST, {
      receiver: userInfo.id,
      page,
      offset
    });
    socket.on(EVENT.ROOM_LIST, handleRoomList);
  };

  const loadMore = useCallback(() => {
    if (!socket || !curRoom) return;
    socket.emit(EVENT.CHAT_LIST, {
      room: curRoom._id,
      cursor: currentRoomChat.prevCursor
    });
  }, []);

  const sendChat = (message: string) => {
    if (!userInfo || !socket || !curRoom) return;
    socket.emit(EVENT.CHAT, {
      room: curRoom._id,
      sender: userInfo.id,
      message
    });
  };

  const openChat = (
    targetId: string,
    onSuccess: () => void,
    onFail: () => void
  ) => {
    if (!userInfo || !socket || userInfo.id === targetId) {
      onFail();
      return;
    }

    function openRoomCallback(room: ChatRoom) {
      if (!socket || !userInfo) return onFail();

      if (curRoomId === room._id) return;

      setCurRoom(room);
      curRoomId = room._id;
      socket.off(EVENT.CREATE_ROOM, openRoomCallback);
      return onSuccess();
    }

    socket.emit(EVENT.CREATE_ROOM, {
      receiver: userInfo.id,
      sender: targetId
    });

    socket.on(EVENT.CREATE_ROOM, openRoomCallback);
    socket.emit(EVENT.ROOM, { receiver: userInfo.id });
  };

  const exitCurrentRoom = () => {
    setCurrentRoomChat({
      prevCursor: null,
      chats: []
    });
    setCurRoom(undefined);
    curRoomId = '';
  };

  // 현재 방에 보여지는 채팅 목록에 의존
  const chatListListener = useCallback(
    ({ chatInfo }: { chatInfo: ChatInfo }) => {
      setCurrentRoomChat(prev => ({
        prevCursor: chatInfo.prevCursor,
        chats: [...prev.chats, ...chatInfo.chats]
      }));
    },
    [currentRoomChat]
  );

  const chatListener = useCallback(
    (res: Chat) => {
      if (!userInfo || !curRoom || curRoom._id !== res.room) return;

      setCurrentRoomChat(prev => {
        const updatedChats = prev.chats.map(chat => {
          if (res.sender !== userInfo.id && chat.isRead === false) {
            // 업데이트된 메세지의 sender가 본인이 아닌 경우 isRead를 true로 업데이트하여 표시
            // TODO: 추후 이전 메세지 포함하여 값을 실시간으로 업데이트하도록 로직 변경
            return { ...chat, isRead: true };
          }
          return chat;
        });

        return {
          ...prev,
          chats: [...updatedChats, res]
        };
      });
    },
    [curRoom]
  );

  useEffect(() => {
    if (userInfo) {
      setSocket(
        io(process.env.REACT_APP_CHAT_SERVER_URL, {
          transports: ['websocket']
        })
      );
    } else {
      setSocket(null);
    }
  }, [userInfo]);

  useLayoutEffect(() => {
    if (!socket) return;
    setCurrentRoomChat({
      prevCursor: null,
      chats: []
    });

    if (curRoom) {
      // 현재 방이 변경되면 채팅 목록과 ROOM을 쏴야함
      socket.emit(EVENT.CHAT_LIST, {
        room: curRoom._id,
        cursor: currentRoomChat.prevCursor,
        offset: 100 // TODO: 무한스크롤 적용
      });
      socket.on(EVENT.CHAT, chatListener);
      socket.on(EVENT.CHAT_LIST, chatListListener);
      socket.emit(EVENT.READ_CHATS, {
        receiver: userInfo?.id,
        room: curRoom._id
      });
    } else {
      setCurrentRoomChat({
        prevCursor: null,
        chats: []
      });
    }
    return () => {
      socket.off(EVENT.CHAT, chatListener);
      socket.off(EVENT.CHAT_LIST, chatListListener);
      socket.off(EVENT.ROOM_LIST, reqRoomList);
      socket.off(EVENT.ROOM, reqRoomList);
    };
  }, [socket, curRoom?._id]);

  const memoValue = useMemo(
    () => ({
      socket,
      rooms,
      curRoom,
      currentRoomChat: currentRoomChat.chats,
      reqRoomList,
      sendChat,
      openChat,
      loadMore,
      exitCurrentRoom
    }),
    [
      socket,
      rooms,
      curRoom,
      currentRoomChat.chats,
      reqRoomList,
      sendChat,
      openChat,
      loadMore,
      exitCurrentRoom
    ]
  );

  return (
    <ChatContext.Provider value={memoValue}>{children}</ChatContext.Provider>
  );
}

function HasRoom({ children }: Props) {
  const { rooms } = useChat();
  return rooms.length ? <>{children}</> : null;
}

function EmptyRoom({ children }: Props) {
  const { rooms } = useChat();
  return rooms.length === 0 ? <>{children}</> : null;
}

Chats.HasRoom = HasRoom;
Chats.EmptyRoom = EmptyRoom;
