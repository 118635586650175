import React from 'react';
import { Route, Routes } from 'react-router';
import { KakaoLoginErrorPage } from '#pages';

export function KakaoLoginErrorView() {
  return (
    <Routes>
      <Route path=":errorType" element={<KakaoLoginErrorPage />} />
    </Routes>
  );
}
