import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import {
  IGetClinicalData,
  IGetClinicalDataList,
  IGetClinicalDataProps
} from '#types/clinicalData';

export const getClinicalDataList = async ({
  coachId,
  page,
  offset
}: IGetClinicalDataProps) => {
  const res = await request<IGetClinicalDataList>({
    method: 'get',
    url: `/clinical-data/list/${coachId}`,
    params: {
      page,
      offset
    }
  });

  return res.data;
};

export const useGetClinicalDataList = ({
  coachId,
  page,
  offset
}: IGetClinicalDataProps) => {
  return useQuery(
    queryKeyFactory.GET_CLINICAL_DATA_LIST({ coachId, page, offset }),
    () => getClinicalDataList({ coachId, page, offset }),
    {
      enabled: !!coachId
    }
  );
};

export const getClinicalData = async (id: string) => {
  const res = await request<IGetClinicalData>({
    method: 'get',
    url: `/clinical-data/${id}`
  });

  return res.data;
};

export const useGetClinicalData = (id: string) => {
  return useQuery(queryKeyFactory.GET_CLINICAL_DATA(id), () =>
    getClinicalData(id)
  );
};

export const postClinicalData = async (payload: FormData) => {
  const res = await request<IGetClinicalData>({
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' },
    method: 'post',
    url: '/clinical-data',
    data: payload
  });

  return res.data;
};

export const modifyClinicalData = async ({
  id,
  payload
}: {
  id: string;
  payload: { rounds: number; body: string };
}) => {
  const res = await request({
    method: 'patch',
    url: `/clinical-data/${id}`,
    data: payload
  });

  return res.data;
};

export const deleteClinicalData = async (id: string) => {
  const res = await request({
    method: 'delete',
    url: `/clinical-data/${id}`
  });

  return res.data;
};

export const postClinicalDataImgs = async ({
  id,
  payload
}: {
  id: string;
  payload: FormData;
}) => {
  const res = await request({
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' },
    method: 'post',
    url: `/clinical-data/${id}/images`,
    data: payload
  });

  return res.data;
};

export const deleteClinicalDataBeforeImg = async ({
  id,
  beforeImageId
}: {
  id: string;
  beforeImageId: string;
}) => {
  const res = await request({
    method: 'delete',
    url: `/clinical-data/${id}/images/before/${beforeImageId}`
  });

  return res.data;
};

export const deleteClinicalDataAfterImg = async ({
  id,
  afterImageId
}: {
  id: string;
  afterImageId: string;
}) => {
  const res = await request({
    method: 'delete',
    url: `/clinical-data/${id}/images/after/${afterImageId}`
  });

  return res.data;
};

export const getRecentClinicalData = async () => {
  const res = await request<IGetClinicalData[]>({
    method: 'get',
    url: `/clinical-data/recent`,
    params: {
      page: 0,
      offset: 5
    }
  });

  return res.data;
};

export const useGetRecentClinicalData = () => {
  return useQuery(
    queryKeyFactory.GET_RECENT_CLINICAL_DATA(),
    getRecentClinicalData
  );
};
