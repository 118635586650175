import request from '#api/axios';
import { ICareer } from '#types/account';

export const postCareers = async (payload: ICareer) => {
  const res = await request({
    method: 'post',
    url: '/careers',
    data: payload
  });

  return res.data;
};

export const deleteCareers = async (id: string) => {
  const res = await request({
    method: 'delete',
    url: `/careers/${id}`
  });

  return res.data;
};
