import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Input, Text } from '#atoms';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { ButtonContainer, Container, InputContainer } from './styles';

export function ProfileShortIntroPage() {
  const { showToast } = useToast();

  const { userInfo, patchProfile } = useUserInfo();
  const [shortIntro, setShortIntro] = useState<string>(
    userInfo?.shortIntroduction ?? ''
  );

  const navigate = useNavigate();

  const onButtonClick = () => {
    patchProfile(
      { shortIntroduction: shortIntro },
      {
        onSuccess: () => {
          showToast({
            message: '한 줄 소개 등록에 성공하였습니다.'
          });
          navigate(PATH.PROFILE_UPDATE);
        },
        onError: () => {
          showToast({
            type: 'fail',
            message: '한 줄 소개 등록에 실패하였습니다.'
          });
        }
      }
    );
  };

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.PROFILE_UPDATE}
      title="한 줄 소개 등록"
      headerbarType="relative"
    >
      <Container>
        <Text textStyle="display">한 줄 소개를 작성해주세요</Text>
        <InputContainer>
          <Input
            value={shortIntro}
            type="text"
            onChange={text => setShortIntro(String(text))}
            placeholder="한 줄 소개를 입력해주세요"
            maxLength={15}
          />
          <Text
            textStyle="c1"
            textColor="gray2"
            style={{ textAlign: 'right' }}
          >{`${shortIntro.length} / 15`}</Text>
        </InputContainer>
        <ButtonContainer>
          <Button disabled={!shortIntro.trim().length} onClick={onButtonClick}>
            프로필 등록
          </Button>
        </ButtonContainer>
      </Container>
    </PrevHeaderBarWithTitleTemplate>
  );
}
