import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 3rem 2rem 11.3rem;
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
  height: 100%;
`;

export const BankInfoContainer = styled.ul`
  display: flex;
  flex-direction: column;

  & > li {
    border-bottom: 1px solid var(--color-border);
    padding: 2rem 0;

    & > div {
      display: flex;
      justify-content: space-between;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
`;

export const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;

  &.bank {
    flex-direction: row;
    align-items: center;
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 3rem 2rem;
  height: 11.3rem;
  background-color: var(--color-background);
  max-width: var(--max-width);
  margin: 0 auto;
`;
