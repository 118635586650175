import React, { PropsWithChildren } from 'react';
import {
  CommonContainer,
  EmptyContainer,
  VerticalPaddingContainer,
  HorizontalPaddingContainer,
  WithBottomButtonContainer,
  BottomButtonContainer,
  BottomButtonBody,
  CenteredContainer
} from './styles';

interface Props {
  type?:
    | 'common'
    | 'paddingWithBottomButton'
    | 'verticalPadding'
    | 'horizontalPadding'
    | 'bottomButton'
    | 'bottomButtonContainer'
    | 'centered';
}

export function FibudContainerTemplate({
  type,
  children
}: PropsWithChildren<Props>) {
  if (type === 'common') return <CommonContainer>{children}</CommonContainer>;
  else if (type === 'paddingWithBottomButton')
    return (
      <CommonContainer>
        <WithBottomButtonContainer>{children}</WithBottomButtonContainer>
      </CommonContainer>
    );
  else if (type === 'verticalPadding')
    return <VerticalPaddingContainer>{children}</VerticalPaddingContainer>;
  else if (type === 'horizontalPadding')
    return <HorizontalPaddingContainer>{children}</HorizontalPaddingContainer>;
  else if (type === 'bottomButtonContainer')
    return <BottomButtonBody>{children}</BottomButtonBody>;
  else if (type === 'bottomButton')
    return <BottomButtonContainer>{children}</BottomButtonContainer>;
  else if (type === 'centered')
    return <CenteredContainer>{children}</CenteredContainer>;
  return <EmptyContainer>{children}</EmptyContainer>;
}
