import { useNavigate } from 'react-router';
import { BoxIcon, Text } from '#atoms';
import { PATH } from '#const/path';
import { useChat } from '#providers/Chat';
import { ChatButton, Container, DetailArea } from './styles';

interface Props {
  title: string;
  coachName: string;
  coachId: string;
  gym: string;
}

export const LectureDetailLectureInfo = ({
  title,
  coachName,
  coachId,
  gym
}: Props) => {
  const navigate = useNavigate();
  const { openChat } = useChat();

  const handleChatButtonClick = (receiverId: string) => {
    const onSuccess = () => {
      navigate(PATH.CHAT_ROOM);
    };
    const onFail = () => '';
    openChat(receiverId, onSuccess, onFail);
  };

  return (
    <Container>
      <Text textStyle="t2">{title}</Text>
      <DetailArea>
        <Text textStyle="t3">{coachName} 전문가</Text>
        <Text textStyle="t3">{gym}</Text>
        <ChatButton onClick={() => handleChatButtonClick(coachId)}>
          <BoxIcon type="chat" size={26} />
        </ChatButton>
      </DetailArea>
    </Container>
  );
};
