import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  padding-bottom: 4rem;
`;

export const DarkPattenButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: auto;
`;

export const List = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: decimal;
  padding-left: 1rem;
`;
