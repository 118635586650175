import { useGetCoachReviews } from '#api/reviews';

interface Props {
  coachId: string;
  filter: number;
}

/** 고객후기 데이터 hook */
export const useCoachReviews = ({ coachId, filter }: Props) => {
  const {
    data: coachReviews,
    isLoading,
    isSuccess,
    isError
  } = useGetCoachReviews({ coachId, order: filter });

  return {
    coachReviews: coachReviews?.data ?? [],
    isLoading,
    isSuccess,
    isError
  };
};
