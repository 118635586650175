import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-black-alpha75);
  z-index: 999;
  max-width: var(--max-width);
  margin: 0 auto;
`;

export const ModalContainer = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 1rem;
  width: calc(100% - 2rem);
  transform: translateY(-50%);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  color: var(--color-white);
  background-color: var(--color-gray);
  padding: 3rem 2rem;
  border-radius: 2rem;

  svg {
    stroke: var(--color-gray-text-second);
  }
`;
