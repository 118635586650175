import { useEffect } from 'react';
import { useNativeInterceptor } from '#utils/native-interceptor';

function useNativeInit() {
  const nativeInterceptor = useNativeInterceptor();
  useEffect(() => {
    nativeInterceptor.initialize();
    return () => nativeInterceptor.unload();
  }, []);

  return nativeInterceptor;
}

export default useNativeInit;
