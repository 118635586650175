import classNames from 'classnames';
import { BoxIcon, Text } from '#atoms';
import { useCheckList } from './hooks';
import { CheckBox, CheckListContainer, CheckListItems } from './styles';

interface Props {
  checkItems: string[];
  onChange?: (checkItems: string[]) => void;
}

export function CheckList({ checkItems, onChange }: Props) {
  const { checked, toggleChecked } = useCheckList({ onChange });

  return (
    <CheckListContainer>
      {checkItems.map(item => (
        <CheckListItems
          key={item}
          className={classNames({ on: checked.includes(item) })}
          onClick={() => toggleChecked(item)}
        >
          <Text>{item}</Text>
          <CheckBox>
            <BoxIcon type="purple-check" size={20} onlyIcon={true} />
          </CheckBox>
        </CheckListItems>
      ))}
    </CheckListContainer>
  );
}
