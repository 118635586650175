import React, { useCallback } from 'react';
import { isInputElement } from '#utils/typeguard';
import { Text, TextColorType, TextStyleType } from '../../atoms/Text';
import { InputContainer, LabeledInputContainer } from './styles';

interface Props {
  labelStyle?: TextStyleType;
  labelColor?: TextColorType;
  label: string;
  value?: string;
  placeholder?: string;
  labeledButton?: string;
  suffix?: string;
  onInput?: (value: string) => void;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const LabeledInput = ({
  labelStyle,
  labelColor,
  label,
  value,
  placeholder,
  suffix,
  labeledButton,
  onInput,
  onButtonClick
}: Props) => {
  const onInputHandler = useCallback<React.FormEventHandler<HTMLInputElement>>(
    ({ target }) => {
      if (!onInput || !isInputElement(target)) return;
      onInput(target.value);
    },
    []
  );

  return (
    <LabeledInputContainer>
      <Text textColor={labelColor} textStyle={labelStyle}>
        {label}
      </Text>
      <InputContainer>
        <input
          value={value}
          placeholder={placeholder}
          onInput={onInputHandler}
        />
        {!labeledButton && suffix && <Text textStyle="c1">{suffix}</Text>}
        {labeledButton && (
          <button onClick={onButtonClick}>{labeledButton}</button>
        )}
      </InputContainer>
    </LabeledInputContainer>
  );
};
