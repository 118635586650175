import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import WelcomeFirst from '#assets/img/WelCome1.png';
import WelcomeSecond from '#assets/img/WelCome2.png';
import { PATH } from '#const/path';
import { WelcomeTemplate } from '#templates';
import localStoreageManager from '#utils/localStoreageManager';

export function WelcomeConsumer() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStoreageManager.isAlreadyReadWelcome())
      navigate(PATH.HOME, { replace: true });
  }, []);

  return (
    <WelcomeTemplate
      firstTexts={[
        '모두에게 PT받을 기회를',
        '부담스러웠던 PT를\n30% 저렴한 가격에 받아보세요'
      ]}
      firstBackground={WelcomeFirst}
      secondTexts={['', '필터기능을 통해\n나에게 딱 맞는 전문가를 찾아보세요']}
      secondBackground={WelcomeSecond}
      nextRoute={PATH.HOME}
    />
  );
}
