import { useLocation, useNavigate } from 'react-router';
import { SetterOrUpdater } from 'recoil';
import { ModalPortal, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { Background, ModalContainer } from './styles';

interface Props {
  setShowModal: SetterOrUpdater<boolean>;
}

export const CoachDetailLoginModal = ({ setShowModal }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackgroundClick = () => {
    setShowModal(false);
  };

  const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleLoginButtonClick = () => {
    navigate(PATH.SIGN, {
      state: { blockedPagePath: location.pathname + location.search }
    });
  };

  return (
    <ModalPortal>
      <Background onClick={handleBackgroundClick}>
        <ModalContainer onClick={handleModalClick}>
          <Text textStyle="t3">로그인이 필요한 서비스입니다</Text>
          <Button onClick={handleLoginButtonClick}>로그인하기</Button>
        </ModalContainer>
      </Background>
    </ModalPortal>
  );
};
