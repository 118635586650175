import { useNavigate } from 'react-router';
import { useGetLecturesIngList } from '#api/lectures';
import { PATH } from '#const/path';
import { MyPageButtonList, UserSummary, LectureCarousel } from '#organisims';
import UserInfo from '#providers/UserInfo';
import { IconHeaderBarTemplate } from '#templates';
import { Container, UserInfoContainer } from './styles';

export function MyPageHome() {
  const { data } = useGetLecturesIngList();
  const navigate = useNavigate();

  return (
    <IconHeaderBarTemplate
      leftIcon="prev-arrow"
      rightIcon="setting"
      onLeftIconClick={() => navigate(PATH.HOME)}
      onRightIconClick={() => navigate(PATH.MYPAGE_SETTING)}
    >
      <Container>
        <UserInfoContainer>
          <UserSummary />
          <UserInfo.IsConsumer>
            <LectureCarousel lecturesList={data} />
          </UserInfo.IsConsumer>
        </UserInfoContainer>
        <MyPageButtonList />
      </Container>
    </IconHeaderBarTemplate>
  );
}
