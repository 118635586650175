import classNames from 'classnames';
import React from 'react';
import { Text } from '#atoms';
import { ItemContainer, TextContainer } from './styles';

interface Props {
  title: string;
  children: React.ReactNode;
  type?: 'row' | 'row-option' | 'images';
  isOptional?: boolean;
}

export const TitleItemBox = ({ title, children, type, isOptional }: Props) => {
  return (
    <ItemContainer className={classNames(type)}>
      <TextContainer>
        <Text textStyle="t3">{title}</Text>
        {isOptional && <Text textColor="gray2"> (선택)</Text>}
      </TextContainer>
      {children}
    </ItemContainer>
  );
};
