import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
`;

export const SelectHeader = styled.div<{
  isOpen: boolean;
  selectedOption: string;
}>`
  background-color: var(--color-gray-second);
  width: 7rem;
  height: 2.75rem;
  text-align: center;
  align-items: center;
  border-radius: 0.5rem;
  display: flex;
  justify-content: ${props => (props.selectedOption === '' ? 'end' : 'center')};
  padding: ${props => (props.selectedOption === '' ? '0 0.5rem' : '0')};
`;

export const OptionList = styled.ul`
  position: absolute;
  width: 7rem;
  background-color: var(--color-gray-second);
  max-height: 17rem;
  overflow-y: scroll;
  border-radius: 0.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Option = styled.li`
  padding: 0.5rem 1rem;
  text-align: center;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;
