import { useEffect, useState } from 'react';
import { getEmailDuplicateCheck } from '#api/accounts';
import { Input } from '#atoms';
import { Container, EmailInputArea } from './styles';

// use early return & use react-query every api request!!!
const isValidEmail = (email: string) =>
  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);

interface Props {
  value?: string | null;
  setUserEmail: (param: string | null) => void;
}

export const SignUpEmail = ({ value, setUserEmail }: Props) => {
  const [email, setEmail] = useState<string | null>(value ?? null);
  const [isValidEmailType, setIsValidEmailType] = useState(false);
  const [isDuplicatedEmail, setIsDuplicatedEmail] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!email) {
      setMessage('');
      setUserEmail(null);
    } else {
      if (isValidEmail(email)) {
        setIsValidEmailType(true);
        setMessage('올바른 형식의 이메일입니다');
      } else {
        setIsValidEmailType(false);
        setUserEmail(null);
        setMessage('이메일 형식이 올바르지 않습니다');
      }
    }
  }, [email]);

  useEffect(() => {
    if (isValidEmailType && email) {
      (async () => {
        const result = await getEmailDuplicateCheck(email);
        setIsDuplicatedEmail(result);
      })();
    }
  }, [email, isValidEmailType]);

  useEffect(() => {
    if (email && isValidEmailType) {
      if (!isDuplicatedEmail) {
        setUserEmail(email);
        setMessage('올바른 형식의 이메일입니다');
      } else {
        setUserEmail(null);
        setMessage('이미 가입된 이메일입니다');
      }
    }
  }, [isDuplicatedEmail, isValidEmailType, email]);

  return (
    <Container>
      <EmailInputArea>
        <Input
          type="email"
          placeholder="이메일 주소를 입력해주세요"
          labelText="이메일"
          value={email}
          onChange={email => setEmail(String(email))}
          showDeleteButton={true}
        />
        <p>{message}</p>
      </EmailInputArea>
    </Container>
  );
};
