import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 6.3rem);
  padding: 2rem;
  overflow-y: auto;
`;

export const TextContainer = styled.div`
  width: 100%;
  padding-bottom: 2rem;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  padding: 1rem 2rem 3rem;
  background-color: var(--color-background);
`;
