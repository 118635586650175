import styled from 'styled-components';

export const Container = styled.span`
  position: relative;
  display: inline-block;
  width: 3.5rem;
  height: 2rem;
  background-color: var(--color-border);
  border: 3px solid var(--color-border);
  box-sizing: content-box;
  border-radius: 2rem;
  transition:
    background-color 0.1s ease-in-out,
    border-color 0.1s ease-in-out;

  &.on {
    background-color: var(--color-point);
    border-color: var(--color-point);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: none;
    background-color: var(--color-white);
    transition: left 0.15s ease-in-out;
  }

  &.on::after {
    left: calc(100% - 2rem);
  }
`;
