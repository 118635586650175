import { useState } from 'react';
import { SelectedLecture } from '#types/lectures';

export const useSelectedLectures = () => {
  const [selectedLectures, setSelectedLectures] = useState<SelectedLecture[]>(
    []
  );

  const addLecture = (newLecture: SelectedLecture) => {
    setSelectedLectures(prevLectures => {
      const updatedLectures = [...prevLectures, newLecture];
      return updatedLectures.sort(
        (a, b) =>
          new Date(a.taughtAt).getTime() - new Date(b.taughtAt).getTime()
      );
    });
  };

  const deleteLecture = (round: number) => {
    setSelectedLectures(prevLectures => {
      const updatedLectures = prevLectures.filter(
        lecture => lecture.round !== round
      );
      return updatedLectures.sort(
        (a, b) =>
          new Date(a.taughtAt).getTime() - new Date(b.taughtAt).getTime()
      );
    });
  };

  return {
    selectedLectures,
    addLecture,
    deleteLecture,
    setSelectedLectures
  };
};
