import { Status } from '#types/account';

const WithdrawalStatus = {
  NORMAL: '신청 완료',
  WITHDRAWAL: '삭제된 정산',
  ACCEPT: '승인된 정산',
  TAKEN: '취소된 정산',
  FINISHED: '정산 완료',
  REJECT: '거절된 정산',
  CANCELLED: '' // TODO: 정산 Status 정의
} as const;

export const convertToWithdrawalStatus = (value: Status) => {
  return WithdrawalStatus[value] || '';
};
