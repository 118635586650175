import styled from 'styled-components';

export const KakaoMapContainer = styled.div`
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
  }
`;
