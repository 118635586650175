import { Container } from '../SignInAlarmTalk';
import { ReviewRequest } from './ReviewRequest';
import { useSearchParams } from 'react-router-dom';

const ReviewRequestPage = () => {
  const [searchParams] = useSearchParams();
  const confirmationId = searchParams.get('confirmation-id');
  return (
    <Container>
      <ReviewRequest confirmationId={confirmationId ? confirmationId : ''} />
    </Container>
  );
};

export default ReviewRequestPage;
