import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
`;

export const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5.8rem;
  padding: 0 2rem;
`;

export const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0.4rem;
  background-color: var(--color-point-second);
`;

export const ProgressBar = styled.div`
  position: absolute;
  width: 0;
  height: 0.4rem;
  bottom: 0;
  background-color: var(--color-point);
  transition: width 0.15s;
`;

export const CarouselContainer = styled.article`
  position: relative;
  width: 100%;
  height: calc(100% - 5.8rem);
  overflow: hidden;
`;

export const CarouselSliderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transition: left 0.15s ease-in-out;
`;

export const CarouselItemContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;
