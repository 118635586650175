import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { IGetDiseaseKinds } from '#types/diseaseKinds';

export const getDiseaseKinds = async () => {
  const res = await request<IGetDiseaseKinds>({
    method: 'get',
    url: '/disease-kinds'
  });

  return res.data;
};

export const useGetDiseaseKinds = () => {
  return useQuery(queryKeyFactory.GET_DISEASE_KINDS(), getDiseaseKinds);
};
