import classNames from 'classnames';
import React from 'react';
import { BoxIcon, Text } from '#atoms';
import { FibudDate } from '#providers/Schedules';
import { useSchedules } from '#providers/Schedules/Schedules';
import { useUserInfo } from '#providers/UserInfo';
import { Container, DateContainer, DateTable, DayContainer } from './styles';

interface ScheduleCalendarProps {
  selectedDates?: FibudDate[];
  setSelectedDates?: React.Dispatch<React.SetStateAction<FibudDate[]>>;
}

export function ScheduleCalendar({ selectedDates }: ScheduleCalendarProps) {
  const {
    selectedDate,
    currentMonth,
    currentDateTable,
    prevMonth,
    nextMonth,
    setSelectedDate
  } = useSchedules();
  const { userInfo } = useUserInfo();

  return (
    <Container>
      <DateContainer>
        <BoxIcon
          type="prev-arrow"
          onlyIcon={true}
          size={13}
          onClick={() => prevMonth()}
        />
        <Text textStyle="t3">
          {currentMonth.year}.{currentMonth.month}
        </Text>
        <BoxIcon
          type="next-arrow"
          onlyIcon={true}
          size={13}
          onClick={() => nextMonth()}
        />
      </DateContainer>
      <DayContainer>
        <ul>
          <li>일</li>
          <li>월</li>
          <li>화</li>
          <li>수</li>
          <li>목</li>
          <li>금</li>
          <li>토</li>
        </ul>
      </DayContainer>
      <DateTable>
        {currentDateTable.map(date => {
          const isSelected = selectedDates?.some(selectedDate => {
            return (
              selectedDate.year === date.year &&
              selectedDate.month === date.month &&
              selectedDate.date === date.date
            );
          });

          return (
            <li
              key={date.time}
              className={classNames({
                on: selectedDate.time === date.time,
                selected: isSelected
              })}
              data-disabled={date.disabled}
              onClick={() => {
                !date.disabled && setSelectedDate({ ...date, hour: undefined });
              }}
            >
              <Text textStyle="bb">{date.date}</Text>
              <div>
                <span
                  className={classNames({
                    scheduled: userInfo?.isCoach && date.scheduled
                  })}
                />
              </div>
            </li>
          );
        })}
      </DateTable>
    </Container>
  );
}
