import styled from 'styled-components';

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  &.row {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &.row-option {
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    > :last-child {
      margin-left: auto;
    }
  }

  &.images {
    row-gap: 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
