import styled from 'styled-components';

export const TextContainer = styled.div`
  width: 100%;

  &.padding {
    padding: 3rem 0;
  }

  &.annotation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const SelectBoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > div:last-child {
    z-index: 29;
  }
`;

export const SelectPageContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-background);
  z-index: 999;
  max-width: var(--max-width);
  margin: 0 auto;
`;
