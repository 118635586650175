// 단위: px

export const HEADER_HEIGHT = 108;
export const SIDE_PADDING = 20;
export const COACH_CATEGORY_ICON_WIDTH = 64;
export const COACH_CATEGORY_ICON_IMAGE_WIDTH = 40;
export const COACH_DETAIL_BUTTON_PADDING_TOP = 16;
export const COACH_DETAIL_BUTTON_PADDING_BOTTOM = 16;
export const SLIDE_GAP = 12;
export const IPHONE12PRO_WIDTH = 390;
export const COACH_CATEGORY_ICON_COUNT = 5;
export const COACH_CATEGORY_GAP_COUNT = COACH_CATEGORY_ICON_COUNT - 1;
