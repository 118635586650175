import styled from 'styled-components';

export const SelectBoxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 5.3rem;
  overflow: hidden;
  border: 1px solid var(--color-border-light);
  border-radius: 0.8rem;
  transition: height linear 0.1s;

  &.open {
    background-color: var(--color-gray);
    border-radius: 0.8rem 0.8rem 0 0;
    border: none;
    overflow: visible;

    & span {
      transform: rotate(-90deg);
    }
  }

  &.close {
    & span {
      transform: rotate(90deg);
    }
  }
`;

export const SelectBoxText = styled.div`
  display: flex;
  width: 100%;
  height: 5.2rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem;
`;

export const SelectBoxUl = styled.ul`
  /* position: absolute;
  top: 5.1rem; */
  width: 100%;
  max-height: 21rem;
  overflow: auto;
  border-radius: 0 0 0.8rem 0.8rem;
  background-color: var(--color-gray);
  border-top: 1px solid var(--color-border);
`;

export const SelectBoxLi = styled.li`
  width: 100%;
  text-align: center;
  height: 5.3rem;
  line-height: 5.3rem;
  color: var(--color-white);
`;
