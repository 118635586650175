import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { getCustomerList } from '#api/confirmation_forms';
import { BoxIcon, Button, Text } from '#atoms';
import { SEX } from '#const/confirmationForms/enum';
import { PATH } from '#const/path';
import useInfiniteScrollQuery from '#hooks/useInfiniteScrollQuery';
import { useIntersectionObserver } from '#hooks/useIntersectionObserver';
import { Modal } from '#molecules';
import { DashboardChartSelectPage } from '#pages';
// import { useChat } from '#providers/Chat';
// import { useDashboard } from '#providers/Dashboard';
import {
  IGetCustomerDetailProps,
  IGetCustomerList
} from '#types/confirmationForms';
import { IExerciseRecord } from '#types/records';
import {
  ButtonContainer,
  Container,
  ItemBox,
  LectureBox,
  LectureTextWrapper,
  LectureWrapper
} from './styles';

interface Props {
  isPreview?: boolean;
  onDetailClick?: ({ personId, id }: IGetCustomerDetailProps) => void;
}

const PER_PAGE_NUMBER = 10 as number;

export const CoachCustomerList: React.FC<Props> = ({
  isPreview,
  onDetailClick
}: Props) => {
  const {
    data: customerList,
    total,
    fetchNextPage,
    hasNextPage
  } = useInfiniteScrollQuery<IGetCustomerList>({
    perPageNum: PER_PAGE_NUMBER,
    filters: { tag: 'active' }, // 필터링 경우 추가
    queryKeyBase: 'getCustomerList',
    queryFn: ({ pageParam = 1 }) => getCustomerList(pageParam)
  });
  const totalCount = total;

  const { setTarget } = useIntersectionObserver({
    hasNextPage,
    fetchNextPage
  });

  // const {
  //   setConfirmationFormIdProps,
  //   setRegisteredLectureId,
  //   setLectureRecord
  // } = useDashboard();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pageOpen, setPageOpen] = useState<boolean>(false);
  const [onNextCallback] = useState<() => void | undefined>();
  const [onLoadCallback] =
    useState<(selectedRecords: IExerciseRecord[]) => void | undefined>();

  const navigate = useNavigate();
  // const { openChat } = useChat();

  // const handleChatButtonClick = (receiverId: string) => {
  //   const onSuccess = () => {
  //     navigate(PATH.CHAT_ROOM);
  //   };
  //   const onFail = () => '';
  //   openChat(receiverId, onSuccess, onFail);
  // };

  // const handleRecordButtonClick = ({
  //   registeredLectureId,
  //   personId,
  //   confirmationFormId
  // }: {
  //   registeredLectureId: string;
  //   personId: string;
  //   confirmationFormId: string;
  // }) => {
  //   setModalOpen(true);

  //   const onNext = () => {
  //     setModalOpen(false);
  //     setRegisteredLectureId(registeredLectureId);
  //     setConfirmationFormIdProps({
  //       personId,
  //       id: confirmationFormId
  //     });

  //     navigate(PATH.DASHBOARD_CHART_FORM);
  //   };

  //   const onLoad = (selectedRecords: IExerciseRecord[]) => {
  //     setPageOpen(false);
  //     setRegisteredLectureId(registeredLectureId);
  //     setConfirmationFormIdProps({
  //       personId,
  //       id: confirmationFormId
  //     });
  //     setLectureRecord({ exerciseRecord: selectedRecords });
  //     navigate(PATH.DASHBOARD_CHART_FORM);
  //   };

  //   setOnNextCallback(() => onNext);
  //   setOnLoadCallback(() => onLoad);
  // };

  const handleChartLoadButtonClick = () => {
    setModalOpen(false);
    setPageOpen(true);
  };

  // if (customerList.length === 0) return null;

  return (
    <>
      <Container>
        <LectureBox>
          <ItemBox className="header-text">
            <Text textStyle="t2">수업목록</Text>
            {!isPreview && (
              <Text textStyle="t2">{customerList && totalCount}개</Text>
            )}
          </ItemBox>
          {isPreview && (
            <ItemBox
              className="show-all"
              onClick={() => navigate(PATH.DASHBOARD)}
            >
              <Text textStyle="c1">모두보기</Text>
              <BoxIcon type="next-arrow" onlyIcon size={12} />
            </ItemBox>
          )}
        </LectureBox>
        <div>
          {customerList?.map(
            ({ id, person, lecture, calculatedRegisteredLecture }, idx) => {
              return (
                <>
                  <LectureWrapper key={idx}>
                    <LectureTextWrapper>
                      {!isPreview ? (
                        <LectureBox
                          data-text-color="gray2"
                          onClick={() => {
                            onDetailClick?.({
                              personId: person.id,
                              id: id
                            });
                          }}
                        >
                          <Text textStyle="t3">{lecture.title}</Text>
                          <BoxIcon type="next-arrow" onlyIcon size={16} />
                        </LectureBox>
                      ) : (
                        <Text textStyle="t3" textColor="gray2">
                          {lecture.title}
                        </Text>
                      )}
                      <LectureBox>
                        <Text textStyle="t3">{person.name}</Text>
                        <Text textStyle="t3">
                          {dayjs().diff(dayjs(person.birthday), 'year')}세{' '}
                          {SEX[person.sex]}
                        </Text>
                      </LectureBox>
                      <LectureBox data-text-color="gray2">
                        <Text>
                          {person.regionSigu} {person.regionDong}
                        </Text>
                        <Text>
                          {calculatedRegisteredLecture.registeredLectureCount} /{' '}
                          {
                            calculatedRegisteredLecture.totalRegisteredLectureCount
                          }
                          회
                        </Text>
                      </LectureBox>
                    </LectureTextWrapper>
                  </LectureWrapper>
                </>
              );
            }
          )}
        </div>
        <div ref={setTarget} style={{ height: '10px' }} />
        {modalOpen && (
          <Modal onClose={() => setModalOpen(false)}>
            <Text textStyle="t3">기존 운동차트에서 불러 오시겠습니까?</Text>
            <ButtonContainer>
              <Button background="anti" onClick={onNextCallback}>
                새로 작성하기
              </Button>
              <Button onClick={handleChartLoadButtonClick}>불러오기</Button>
            </ButtonContainer>
          </Modal>
        )}
        {pageOpen && (
          <DashboardChartSelectPage
            onPrevCallback={() => setPageOpen(false)}
            onLoadCallback={selectedRecords =>
              onLoadCallback && onLoadCallback(selectedRecords)
            }
          />
        )}
      </Container>
    </>
  );
};
