import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 5rem 2rem 3rem;
  overflow: auto;
`;

export const ApplicationList = styled.ul`
  width: 100%;
  padding: 1rem 2rem 2rem;

  > :last-child {
    border-bottom: none;
  }
`;

export const ApplicationItem = styled.li`
  padding: 2rem 0;
  border-bottom: 1px solid var(--color-border);

  > :first-child {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
