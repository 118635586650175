import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const EmailInputArea = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    color: var(--color-white);
    text-align: right;
  }
`;
