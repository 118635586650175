import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getMyAccount } from '#api/accounts';
import { deleteLicenses, postLicenses } from '#api/licenses';
import { BoxIcon, Button, Input, Text } from '#atoms';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { dottedDateFormatter } from '#utils/dateFormatter';
import { useCertifications, Certification } from './hooks';
import {
  AppendButtonContainer,
  ButtonContainer,
  CertificationItem,
  CertificationList,
  Container
} from './styles';

export function ProfileCertificatePage() {
  const now = dayjs().format('YYYY-MM-DD');
  const navigate = useNavigate();
  const { showToast } = useToast();

  const { userInfo, setUserInfo } = useUserInfo();
  const currentCertifications: Certification[] =
    userInfo?.licenses
      .filter(license => license.status === 'NORMAL')
      .map(({ id, name, gotAt }) => ({
        id,
        text: name,
        gotAt
      })) ?? [];
  const [inputText, setInputText] = useState<string>('');
  const [acquiredDate, setAcquiredDate] = useState<string>('');

  const {
    certifications,
    setCertifications,
    appendCertification,
    removeCertification
  } = useCertifications({
    currentCertifications
  });

  // 새로고침 등의 이슈로 userInfo를 못받아와질 때, 다시 set 해줌
  useEffect(() => {
    if (!userInfo) return;
    setCertifications(currentCertifications);
  }, [userInfo]);

  const appendItem = () => {
    if (!inputText.trim().length || !acquiredDate) return;
    appendCertification({
      text: inputText,
      id: Date.now().toString(),
      gotAt: acquiredDate
    });
    setInputText('');
    setAcquiredDate('');
  };

  const handleChangeSendDateFormat = (day: string | number) => {
    if (typeof day === 'number') return;

    setAcquiredDate(day);
  };

  const onButtonClick = () => {
    Promise.all([
      certifications
        .filter(
          certification =>
            !currentCertifications.some(e => e.id === certification.id)
        )
        .map(c => postLicenses({ name: c.text, gotAt: c.gotAt })),
      currentCertifications
        .filter(
          currentCertification =>
            !certifications.some(e => e.id === currentCertification.id)
        )
        .map(c => deleteLicenses(c.id))
    ]).then(res => {
      Promise.all(res.map(p => Promise.allSettled(p))).then(results => {
        const hasErrors = results.some(result =>
          result.some(r => r.status === 'rejected')
        );
        if (!hasErrors) {
          showToast({
            message: '자격사항 등록에 성공하였습니다.'
          });
          getMyAccount().then(account => setUserInfo(account));
          navigate(PATH.PROFILE_UPDATE);
        } else {
          showToast({
            type: 'fail',
            message: '자격사항 등록에 실패하였습니다.'
          });
        }
      });
    });
  };

  return (
    <>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.PROFILE_UPDATE}
        title="자격사항 등록"
      >
        <Container>
          <Text textStyle="display">자격사항을 작성해주세요</Text>
          <Input
            type="text"
            placeholder="자격사항을 입력해주세요"
            value={inputText}
            onChange={text => setInputText(String(text))}
          />
          <Input
            type="date"
            data-placeholder="자격 취득일을 선택해주세요"
            value={acquiredDate}
            onChange={day => handleChangeSendDateFormat(day)}
            min="1900"
            max={now}
          />
          <CertificationList>
            {certifications.map(({ id, text, gotAt }) => (
              <CertificationItem key={id}>
                <Text>{`${text}${
                  gotAt ? ` / ${dottedDateFormatter(gotAt)}` : ''
                }`}</Text>
                <BoxIcon
                  type="x-gray"
                  onlyIcon={true}
                  size={12}
                  onClick={() => removeCertification(id)}
                />
              </CertificationItem>
            ))}
          </CertificationList>
          <AppendButtonContainer onClick={() => appendItem()}>
            <BoxIcon type="plus-gray" size={24} onlyIcon={true} />
            <Text textStyle="c1" textColor="gray2">
              자격 추가
            </Text>
          </AppendButtonContainer>
        </Container>
      </PrevHeaderBarWithTitleTemplate>
      <ButtonContainer>
        <Button
          disabled={
            JSON.stringify(certifications) ===
            JSON.stringify(currentCertifications)
          }
          onClick={onButtonClick}
        >
          프로필 등록
        </Button>
      </ButtonContainer>
    </>
  );
}
