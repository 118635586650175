import React, { useState } from 'react';
import { useGetCoachAllRecords } from '#api/records';
import { BoxIcon, Button, ModalPortal, Text } from '#atoms';
import { IExerciseRecord, IGetCoachAllRecords } from '#types/records';
import { ExerciseRecordSelector } from './ExerciseRecordSelector';
import {
  ButtonContainer,
  ChildrenContainer,
  HeaderContainer,
  PageContainer
} from './styles';

export function DashboardChartSelectPage({
  onPrevCallback,
  onLoadCallback
}: {
  onPrevCallback: () => void;
  onLoadCallback: (totalSelectedRecords: IExerciseRecord[]) => void;
}) {
  const { fetchStatus, isError, data: allRecords } = useGetCoachAllRecords();
  const [selectedRecords, setSelectedRecords] = useState<{
    [key: string]: IExerciseRecord[];
  }>({});
  const totalSelectedRecords = Object.values(selectedRecords).flatMap(
    selectedRecords => selectedRecords
  );

  const handleRecordSelect = (key: number, values: IExerciseRecord[]) => {
    setSelectedRecords(prev => ({
      ...prev,
      [key]: values
    }));
  };

  return (
    <ModalPortal>
      <PageContainer>
        <HeaderContainer>
          <BoxIcon
            type="prev-arrow"
            boxColor="transparent"
            size={18}
            onlyIcon={true}
            onClick={onPrevCallback}
          />
          <Text textStyle="t3">운동차트 불러오기</Text>
        </HeaderContainer>
        <ChildrenContainer>
          <>
            <IsLoading isLoading={fetchStatus === 'fetching'}>
              <p>로딩중...</p>
            </IsLoading>
            <IsError isError={isError} isLoading={fetchStatus === 'fetching'}>
              <p>잘못된 접근입니다!</p>
            </IsError>
            <HasRecord
              isError={isError}
              isLoading={fetchStatus === 'fetching'}
              allRecords={allRecords}
            >
              {allRecords?.data.map((lectureRecord, idx) => (
                <ExerciseRecordSelector
                  key={idx}
                  data={lectureRecord}
                  values={selectedRecords[idx] || []}
                  onChange={values => handleRecordSelect(idx, values)}
                />
              ))}
            </HasRecord>
            <EmptyRecord
              isError={isError}
              isLoading={fetchStatus === 'fetching'}
              allRecords={allRecords}
            >
              <p>등록된 운동기록이 없습니다.</p>
            </EmptyRecord>
          </>
        </ChildrenContainer>
        <ButtonContainer>
          <Text textColor="gray2">총 {totalSelectedRecords.length}개 선택</Text>
          <Button
            onClick={() => onLoadCallback(totalSelectedRecords)}
            disabled={totalSelectedRecords.length === 0}
          >
            불러오기
          </Button>
        </ButtonContainer>
      </PageContainer>
    </ModalPortal>
  );
}

function IsLoading({
  isLoading,
  children
}: {
  isLoading: boolean;
  children: React.ReactNode;
}) {
  return isLoading ? <>{children}</> : null;
}

function IsError({
  isLoading,
  isError,
  children
}: {
  isLoading: boolean;
  isError: boolean;
  children: React.ReactNode;
}) {
  return !isLoading && isError ? <>{children}</> : null;
}

function HasRecord({
  isLoading,
  isError,
  allRecords,
  children
}: {
  isLoading: boolean;
  isError: boolean;
  allRecords?: IGetCoachAllRecords;
  children: React.ReactNode;
}) {
  return !isLoading && !isError && allRecords && allRecords.data.length > 0 ? (
    <>{children}</>
  ) : null;
}

function EmptyRecord({
  isLoading,
  isError,
  allRecords,
  children
}: {
  isLoading: boolean;
  isError: boolean;
  allRecords?: IGetCoachAllRecords;
  children: React.ReactNode;
}) {
  return !isLoading &&
    !isError &&
    allRecords &&
    allRecords.data.length === 0 ? (
    <>{children}</>
  ) : null;
}
