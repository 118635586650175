import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2rem 2rem 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 0 5.3rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  padding: 2rem 0;

  > .display {
    font-size: 4rem;
    font-weight: 700;
  }
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  border: 1px solid var(--color-border);
  border-radius: 2rem;
  padding: 2.4rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    > div.word-break {
      word-break: keep-all;
    }
  }

  &.top-attached {
    padding-top: 0;
  }
`;
