import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Button } from '#atoms';
import { PATH } from '#const/path';
import CoachSettlements, {
  useCoachSettlements
} from '#providers/CoachSettlements';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { formatNumberWithCommas } from '#utils/Numbers';
import { convertToWithdrawalStatus } from '#utils/settlements';
import { TextValueBox } from 'src/components/molecules/TextValueBox';
import { ButtonContainer, Container, DetailContainer } from './styles';

export function BillingDetailPage() {
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date');
  const navigate = useNavigate();
  const { settlementDetail } = useCoachSettlements();

  return (
    <Container>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={`${PATH.BILLING}${date ? `?date=${date}` : ''}`}
        title="수입관리"
      >
        <DetailContainer>
          <CoachSettlements.IsDeposit>
            <div>
              <TextValueBox
                text="거래 정보"
                value={`${settlementDetail.lecture.name} / ${settlementDetail.lecture.count}회차`}
              />
              <TextValueBox
                text="거래 시간"
                value={dayjs(settlementDetail.updatedAt).format(
                  'YYYY.MM.DD HH:mm:ss'
                )}
              />
              <TextValueBox
                text="거래 금액"
                value={`${formatNumberWithCommas(
                  settlementDetail.price - (settlementDetail.tax ?? 0)
                )}원`}
              />
            </div>
            <div>
              <TextValueBox
                text="고객 정보"
                value={`${settlementDetail.customerName} 고객님`}
              />
            </div>
            <div>
              <TextValueBox
                text="고객 결제 금액"
                value={`${formatNumberWithCommas(settlementDetail.price)}원`}
              />
              <TextValueBox
                text="공간 대관료 및 수수료"
                value={`-${formatNumberWithCommas(
                  settlementDetail.tax ?? 0
                )}원`}
              />
            </div>
          </CoachSettlements.IsDeposit>
          <CoachSettlements.IsWithdrawal>
            <div>
              <TextValueBox
                text="거래 정보"
                value={`정산 / ${convertToWithdrawalStatus(
                  settlementDetail.status
                )}`}
              />
              <TextValueBox
                text="거래 시간"
                value={dayjs(settlementDetail.updatedAt).format(
                  'YYYY.MM.DD HH:mm:ss'
                )}
              />
              <TextValueBox
                text="거래 금액"
                value={`${formatNumberWithCommas(
                  settlementDetail.price - (settlementDetail.tax ?? 0)
                )}원`}
              />
              <TextValueBox
                text="정산 계좌"
                value={`${settlementDetail.bankName} ${settlementDetail.accountNumber}`}
              />
            </div>
          </CoachSettlements.IsWithdrawal>
        </DetailContainer>
        <ButtonContainer>
          <Button
            onClick={() =>
              navigate(`${PATH.BILLING}${date ? `?date=${date}` : ''}`)
            }
          >
            확인
          </Button>
        </ButtonContainer>
      </PrevHeaderBarWithTitleTemplate>
    </Container>
  );
}
