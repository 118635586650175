import dayjs from 'dayjs';
import { useState } from 'react';
import { Input } from '#atoms';
import { Container, DateSelectorArea, MessageArea } from './styles';

interface Props {
  value?: string | null;
  onDateChange: (birthday: string | null) => void;
}

// TODO: 기본 placeholder나 스타일링 추후 수정 예정
export const DateSelector = ({ value, onDateChange }: Props) => {
  const now = dayjs().format('YYYY-MM-DD');

  const [birthDay, setBirthDay] = useState<string | number>(value ?? '');
  const [isValidDate] = useState(false);
  const [message] = useState('');

  // input 공용 컴포넌트에 맞춰 인자의 타입 추가
  const onSelectDay = (day: string | number) => {
    if (typeof day === 'string') {
      setBirthDay(day);
      onDateChange(day);
    }
  };

  return (
    <Container>
      <DateSelectorArea>
        <Input
          type="date"
          value={birthDay}
          data-placeholder="생년월일을 입력해주세요."
          min="1900-01-01"
          max={now}
          onChange={day => onSelectDay(day)}
        />
      </DateSelectorArea>
      <MessageArea>{!isValidDate && <p>{message}</p>}</MessageArea>
    </Container>
  );
};
