import { useCallback, useState } from 'react';

export interface Certification {
  id: string;
  text: string;
  gotAt?: string;
}

interface Props {
  currentCertifications?: Certification[];
}

export const useCertifications = ({ currentCertifications }: Props) => {
  const [certifications, setCertifications] = useState<Array<Certification>>(
    currentCertifications ?? []
  );

  const appendCertification = useCallback(
    (cert: Certification) => setCertifications(prev => [...prev, cert]),
    [certifications]
  );

  const removeCertification = useCallback(
    (id: string) => setCertifications(prev => prev.filter(c => c.id !== id)),

    [certifications]
  );

  return {
    certifications,
    setCertifications,
    appendCertification,
    removeCertification
  };
};
