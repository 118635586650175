import styled from 'styled-components';

export const Container = styled.ul`
  background-color: var(--color-gray);
  border-radius: 1rem;
  padding: 2rem;

  > p {
    text-align: center;
    padding-bottom: 2rem;
  }
`;

export const CategoryArea = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;

  &.fold {
    height: 5rem;
    overflow: hidden;
  }
`;

export const CategoryItem = styled.li`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 0.5rem;

  img,
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  > p.bold {
    font-weight: 600;
  }
`;

export const ExpandHandle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;

  svg {
    color: var(--color-gray-text-second);
    transform: rotate(-90deg);
  }

  &.fold > svg {
    color: var(--color-gray-text-second);
    transform: rotate(90deg);
  }
`;
