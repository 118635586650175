import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { queryKeyFactory } from '#api/queryKeyFactory';

const kakaoAxiosInstance = axios.create();

kakaoAxiosInstance.interceptors.request.use(
  config => {
    config.headers.Authorization = `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export const getAddress = async (longitude: number, latitude: number) => {
  try {
    const res = await kakaoAxiosInstance.get(
      process.env.REACT_APP_KAKAO_COORD2ADDRESS_API_URL,
      {
        params: {
          x: longitude,
          y: latitude
        }
      }
    );

    return res.data;
  } catch (error) {
    return error;
  }
};

export const getCoordinatesFromAddress = async (query: string) => {
  try {
    const res = await kakaoAxiosInstance.get(
      process.env.REACT_APP_KAKAO_ADDRESS2COORD_API_URL,
      {
        params: {
          query: query
        }
      }
    );

    return await res.data;
  } catch (error) {
    return error;
  }
};

export const useGetCoordinates = (query: string) => {
  return useQuery(
    queryKeyFactory.GET_COORDINATES(query),
    () => getCoordinatesFromAddress(query),
    {
      enabled: !!query
    }
  );
};

export const useGetAddress = (longitude: number, latitude: number) => {
  return useQuery(
    queryKeyFactory.GET_ADDRESS(longitude, latitude),
    () => getAddress(longitude, latitude),
    {
      enabled: longitude != undefined && latitude != undefined
    }
  );
};
