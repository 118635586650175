import styled from 'styled-components';

export const PopularPostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PopularPostsTobBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PostContentWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > li:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-border);
  }
`;

export const PostContentButton = styled.button`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const PostConetntBottomBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PostConetntBottomRightBox = styled.div`
  display: flex;
  gap: 1rem;
`;

export const CountBox = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const BottomBar = styled.div`
  margin-top: 1rem;
  height: 1px;
  background-color: var(--color-border);
`;
