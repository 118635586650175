import React, { useEffect, useState } from 'react';
import { Text, ToggleWithCaptionButton } from '#atoms';
import { RadioButtonBoxWrap, ButtonArea, Bar } from './styles';

interface Props {
  data: string[];
  onChange: (quality: string) => void;
  initialValue?: string;
}

export const SleepQualitySelector = ({
  data,
  onChange,
  initialValue
}: Props) => {
  const [sleepQuality, setSleepQuality] = useState<string>(initialValue ?? '');

  useEffect(() => {
    onChange(sleepQuality);
  }, [sleepQuality]);

  useEffect(() => {
    setSleepQuality(initialValue ?? '');
  }, [initialValue]);

  return (
    <RadioButtonBoxWrap>
      <Text textStyle="t3">평균 수면의 질 선택</Text>
      <ButtonArea>
        <Bar />
        {data.map(item => (
          <ToggleWithCaptionButton
            isActive={sleepQuality === item}
            key={item}
            text={item}
            onClick={() => setSleepQuality(item)}
          />
        ))}
      </ButtonArea>
    </RadioButtonBoxWrap>
  );
};
