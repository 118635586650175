import styled from 'styled-components';
import down_arrow from '#assets/img/down_arrow.svg';

export const InputWrap = styled.div`
  width: 100%;
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translate(50%, -50%);
  }
`;

export const InputTagArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;

  //datepicker 관련 스타일링 추가
  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    background: transparent; // 배경은 투명하게,
    color: transparent; // 글자도 투명하게! 이 두 설정을 통해 캘린더 아이콘을 사라지게 만든다.
    cursor: pointer;
  }

  // type이 date인 input의 placeholder를 커스텀하기 위한 선택자
  // 기본적으로 type date인 input은 placeholder가 먹히지 않기 때문이다!
  input[type='date']::before {
    content: attr(
      data-placeholder
    ); // input 태그의 placeholder라는 속성값을 가져와서 content로 사용한다. 보통은 placeholder보다는 data-placeholder라는 커스텀 속성을 만들어서 사용하시는 것 같다.
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    color: var(--color-border-light);
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
  }
  input[type='date'].checked::before {
    color: var(--color-gray-second);
    content: attr(data-placeholder);
  }

  // input에 어떠한 유효값이 입력된 상태인지 확인하는 선택자
  // 날짜를 선택하면 유효값이 입력된다.
  // 이 속성을 활용하고자 한다면 반드시 태그에 required 속성을 달아줘야한다.
  input[type='date']:valid::before {
    display: none; // 유효값이 입력된 경우 before에 있는 것을 사라지게 한다. 즉, placeholder를 사라지게 한다.
  }
`;

export const InputTag = styled.input`
  width: 100%;
  height: 5.3rem;
  background-color: transparent;
  border: 1px solid var(--color-gray-second);
  border-radius: 0.5rem;
  font-size: 1.6rem;
  padding: 1rem;
  color: var(--color-white);
  ::placeholder {
    color: var(--color-border-light);
  }
`;

export const DateInput = styled.input.attrs({ type: 'date' })`
  position: relative; // 캘린더 아이콘을 클릭해야만 달력이 보이기 때문에 이 영역 자체를 제어하기 위해 설정
  width: 100%;
  height: 5.3rem;
  background-color: transparent;
  border: 1px solid var(--color-gray-second);
  border-radius: 0.5rem;
  font-size: 1.6rem;
  padding: 1rem;
  color: var(--color-white);
  /* background: url(${down_arrow}) no-repeat right 10px center / 15px auto; // 배경에 커스텀 아이콘을 넣어주자! x축은 오른쪽에서부터 10px 떨어지게 하고, y축은 가운데 정렬하고, width 35px에 height auto라는 크기를 부여한다. */

  &.checked {
    &:valid {
      color: black;
    }
  }
`;
