import { useEffect, useState } from 'react';
import { useUserInfo } from '#hooks/useUserInfo';

export type TodayLectureType = {
  tranieeName: string;
  place: string;
  startTime: number;
  endTime: number;
};

// TODO: 관련 api 확인 후 적용
export const useTodayLecture = () => {
  const { isLoggedIn, userInfo } = useUserInfo();
  const [todayLecture, setTodayLecture] = useState<TodayLectureType[]>([]);

  // useEffect(() => {
  //   if (!isSuccess || isFetching) return;
  //   setTodayLecture([
  //     {
  //       tranieeName: '김하온',
  //       place: '한솔 피트니스 센터',
  //       startTime: 780,
  //       endTime: 900
  //     }
  //   ]);
  // }, [isFetching]);

  useEffect(() => {
    if (!userInfo.isCoach || !isLoggedIn) setTodayLecture([]);
    else
      setTodayLecture([
        {
          tranieeName: '김하온',
          place: '한솔 피트니스 센터',
          startTime: 780,
          endTime: 900
        }
      ]);
  }, [isLoggedIn, userInfo]);

  return { todayLecture };
};
