import { useCallback, useEffect, useState } from 'react';
import { Button, ItemPicker, Text } from '#atoms';
import { SLEEPING_HOUR_AVG_LIST } from '#const/confirmationForms/filter';
import { SleepQualitySelector } from '#molecules';
import { useApplications } from '#providers/Applications';
import { FibudContainerTemplate, useProgress } from '#templates';
import { Id } from '#types/confirmationForms';
import { QUALITY_OF_SLEEP_LIST } from './const';
import { Container, TextContainer } from './styles';

export function ApplicationSleep() {
  const { next } = useProgress();
  const { updateCurrentApplications, currentApplication } = useApplications();
  const [selectedSleepTime, setSelectedSleepTime] = useState<Id | null>(null);
  const initialSleepQuality =
    currentApplication &&
    currentApplication.healthInfo &&
    currentApplication.healthInfo.qualityOfSleep !== undefined
      ? QUALITY_OF_SLEEP_LIST[currentApplication.healthInfo.qualityOfSleep]
      : '';

  const updateSleepQuality = useCallback(
    (nextSleepQuality: string) => {
      updateCurrentApplications({
        healthInfo: {
          qualityOfSleep: QUALITY_OF_SLEEP_LIST.indexOf(nextSleepQuality)
        }
      });
    },
    [currentApplication]
  );

  const updateSleepTime = useCallback(
    (nextSleepTime: string) => {
      updateCurrentApplications({
        healthInfo: {
          sleepingHourAvg: Number(nextSleepTime)
        }
      });
    },
    [currentApplication]
  );

  useEffect(() => {
    selectedSleepTime && updateSleepTime(selectedSleepTime.toString());
  }, [selectedSleepTime]);

  return (
    <FibudContainerTemplate type="paddingWithBottomButton">
      <FibudContainerTemplate type="bottomButtonContainer">
        <Container>
          <TextContainer>
            <Text textStyle="t1">
              수면의 질과 수면시간을
              <br />
              알려주세요
            </Text>
          </TextContainer>
          <SleepQualitySelector
            data={QUALITY_OF_SLEEP_LIST}
            onChange={updateSleepQuality}
            initialValue={initialSleepQuality}
          />
          <Container>
            <Text textStyle="t3">평균 수면 시간 선택</Text>
            <ItemPicker
              data={SLEEPING_HOUR_AVG_LIST}
              setSelectedItem={setSelectedSleepTime}
              initialValue={currentApplication?.healthInfo?.sleepingHourAvg}
            />
          </Container>
        </Container>
      </FibudContainerTemplate>
      <FibudContainerTemplate type="bottomButton">
        <Button
          onClick={() => next()}
          disabled={
            currentApplication?.healthInfo?.qualityOfSleep === undefined ||
            currentApplication?.healthInfo?.sleepingHourAvg === undefined
          }
        >
          다음
        </Button>
      </FibudContainerTemplate>
    </FibudContainerTemplate>
  );
}
