import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 9.3rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem 0;
    border-bottom: 1px solid var(--color-border);

    :nth-child(2) > div {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
    > p:nth-child(2) {
      text-align: center;
    }
  }

  > :last-child {
    border-bottom: none;
  }

  .invalid {
    color: var(--color-red);
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 1rem 3rem;
  background-color: var(--color-background);
  max-width: var(--max-width);
  margin: 0 auto;
`;
