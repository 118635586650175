import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useCoachSettlementsInfo } from '#providers/CoachSettlementsInfo';
import { formatNumberWithCommas } from '#utils/Numbers';
import { TextValueBox } from 'src/components/molecules/TextValueBox';
import {
  ButtonContainer,
  Container,
  MessageContainer,
  SettlementInfoContainer
} from './styles';

export function BillingSuccessPage() {
  const navigate = useNavigate();
  const { settlementRequest } = useCoachSettlementsInfo();
  const currentDate = dayjs().format('YYYY.MM.DD');

  return (
    <>
      <Container>
        <MessageContainer>
          <BoxIcon type="purple-check" size={60} onlyIcon />
          <Text textStyle="t2">정산신청이 완료되었습니다!</Text>
          <Text textStyle="t2">2~3영업일 이내에 입금됩니다.</Text>
        </MessageContainer>
        <SettlementInfoContainer>
          <TextValueBox
            text="정산 금액"
            value={`${formatNumberWithCommas(settlementRequest.price ?? 0)}원`}
            valueStyle="t3"
          />
          <TextValueBox
            text="정산일"
            value={`${currentDate}`}
            valueStyle="t3"
          />
        </SettlementInfoContainer>
      </Container>
      <ButtonContainer>
        <Button onClick={() => navigate(PATH.BILLING)}>확인</Button>
      </ButtonContainer>
    </>
  );
}
