import React from 'react';
import { BoxIcon, IconType } from '#atoms';
import { IGym } from '#types/gyms';
import { GymItemLabel, GymItemsContainer } from './styles';

export const GymItems = ({ data }: { data: IGym }) => {
  const { showerRoom, parkingSpace, locker, fittingRoom, sportswear, tower } =
    data;
  const facilityInfo = [
    {
      name: 'showerRoom',
      label:
        showerRoom !== undefined
          ? `샤워실 ${showerRoom ? '있음' : '없음'}`
          : '',
      icon: `bath-${showerRoom ? 'on' : 'off'}-purple` as IconType
    },
    {
      name: 'parkingSpace',
      label:
        parkingSpace !== undefined
          ? `주차장 ${parkingSpace ? '있음' : '없음'}`
          : '',
      icon: `car-${parkingSpace ? 'on' : 'off'}-purple` as IconType
    },
    {
      name: 'locker',
      label:
        locker !== undefined ? `사물함 대여 ${locker ? '가능' : '불가능'}` : '',
      icon: `inbox-${locker ? 'on' : 'off'}-purple` as IconType
    },
    {
      name: 'fittingRoom',
      label:
        fittingRoom !== undefined
          ? `탈의실 ${fittingRoom ? '있음' : '없음'}`
          : '',
      icon: `hanger-${fittingRoom ? 'on' : 'off'}-purple` as IconType
    },
    {
      name: 'sportswear',
      label:
        sportswear !== undefined
          ? `운동복 대여 ${sportswear ? '가능' : '불가능'}`
          : '',
      icon: `shirt-${sportswear ? 'on' : 'off'}-purple` as IconType
    },
    {
      name: 'tower',
      label:
        tower !== undefined ? `수건 대여 ${tower ? '가능' : '불가능'}` : '',
      icon: `wallpaper-${tower ? 'on' : 'off'}-purple` as IconType
    }
  ];

  return (
    <GymItemsContainer>
      {facilityInfo.map(({ name, label, icon }) => (
        <React.Fragment key={name}>
          {label && (
            <div>
              <BoxIcon type={icon} onlyIcon size={28} />

              <GymItemLabel className="label" data-text-type="c2">
                {label}
              </GymItemLabel>
            </div>
          )}
        </React.Fragment>
      ))}
    </GymItemsContainer>
  );
};
