import React from 'react';
import { BoxIcon } from '#atoms';
import { Container } from './styles';

interface Props {
  onLeftButtonClick: () => void;
}

export const GymLocationMapHeader = ({ onLeftButtonClick }: Props) => {
  const handleLeftButtonClick = () => {
    onLeftButtonClick();
  };

  return (
    <Container>
      <BoxIcon
        type="x-white"
        onlyIcon
        size={20}
        onClick={handleLeftButtonClick}
      />
    </Container>
  );
};
