import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 8.2rem;
  max-height: 36rem;
  padding: 0.6rem 2rem 1.8rem;
  background-color: var(--color-white);
  border-radius: 2rem 2rem 0 0;
  justify-content: center;
  max-width: 500px; // 전체 레이아웃 적용
  right: 0; //중간위치
  margin: 0 auto;

  &.fold {
    gap: 0.5rem;
  }

  &.login.trainer {
    height: 13.8rem;
  }

  &.none {
    /* height: 10.8rem; */
    visibility: hidden;
  }
`;

export const ToggleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &.fold > span {
    transform: rotate(-180deg);
  }
`;

export const Bar = styled.div`
  width: 5rem;
  height: 0.5rem;
  border-radius: 3px;
  background-color: var(--color-gray-second);
`;

export const DrawerHandler = styled.div`
  width: 100%;
  height: 15px;
  text-align: center;

  &::after {
    display: inline-block;
    content: ' ';
    width: 52px;
    height: 5px;
    border-radius: 5px;
    margin: auto;
    background-color: var(--color-gray-second);
    transform: translate(0, -50%);
  }

  &.hide {
    visibility: hidden;
  }
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  &.consumer {
    display: flex;
    flex-direction: column;
  }

  > :last-child {
    border-bottom: none;
  }

  &.trainer {
    text-align: justify;
  }

  & > button {
    margin-top: auto;
  }

  &.empty {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 0;
  border-bottom: 1px solid var(--color-border-light);

  div.flex {
    display: flex;
    justify-content: space-between;
  }
`;

export const TodayLecture = styled.article`
  width: 100%;

  & > div {
    display: flex;
    justify-content: space-between;

    & > p:last-child {
      background-color: var(--color-point);
      color: var(--color-white);
      padding: 0 1.4rem;
      border-radius: 1.2rem;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 3.8rem;
  overflow-y: hidden;

  > :first-child {
    color: var(--color-point);
    font-size: 1.6rem;
    font-weight: bold;
  }
`;
