import styled from 'styled-components';

export const RadioButtonBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 2rem;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const Bar = styled.div`
  width: 100%;
  height: 6px;
  background-color: var(--color-border-light);
  position: absolute;
  z-index: 0;
  top: 0.5rem;
  transform: translateY(-50%);
`;
