import styled from 'styled-components';

export const InputBox = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  border-radius: 0.8rem;
  align-items: center;
  background-color: var(--color-gray-third);

  & input {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--color-white);
    padding: 0 1rem;
  }
`;
