import { atom } from 'recoil';

const ATOM_KEY = 'routeHistoryState';

// export interface HistoryRoute {
//   path?: string;
//   scrollY?: number;
//   tab?: number;
// }

export const routeHistoryState = atom<string[]>({
  key: ATOM_KEY,
  default: []
});
