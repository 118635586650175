import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { IGetCoachAllRecords, IPostRecord, IRecord } from '#types/records';

export const getRecords = async (registeredLectureId: string) => {
  const res = await request<IRecord>({
    method: 'get',
    url: `/records/${registeredLectureId}`
  });

  return res.data;
};

export const useGetRecords = (registeredLectureId: string) => {
  return useQuery(queryKeyFactory.GET_RECORDS(registeredLectureId), () =>
    getRecords(registeredLectureId)
  );
};

export const postRecord = async ({
  registeredLectureId,
  payload
}: {
  registeredLectureId: string;
  payload: IPostRecord;
}) => {
  const res = await request({
    method: 'post',
    url: `/records/${registeredLectureId}`,
    data: payload
  });

  return res.data;
};

export const patchRecord = async ({
  registeredLectureId,
  payload
}: {
  registeredLectureId: string;
  payload: IPostRecord;
}) => {
  const res = await request({
    method: 'patch',
    url: `/records/${registeredLectureId}`,
    data: payload
  });

  return res.data;
};

export const getCoachAllRecords = async ({
  page,
  offset
}: {
  page?: number;
  offset?: number;
} = {}) => {
  const res = await request<IGetCoachAllRecords>({
    method: 'get',
    url: '/records',
    params: {
      page,
      offset
    }
  });

  return res.data;
};

export const deleteMediaFiles = async (
  registeredLectureId: string,
  data: string[]
) => {
  const res = await request({
    method: 'delete',
    url: `/records/${registeredLectureId}/all/exercise-images`,
    data: data
  });
  return res.data;
};

export const useGetCoachAllRecords = ({
  page,
  offset
}: {
  page?: number;
  offset?: number;
} = {}) => {
  return useQuery(queryKeyFactory.GET_COACH_ALL_RECORDS({ page, offset }), () =>
    getCoachAllRecords({ page, offset })
  );
};
