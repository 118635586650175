import styled from 'styled-components';

export const TextContainer = styled.div`
  width: 100%;
  padding: 1rem 0;
`;

export const ScheduleList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > li {
    border-bottom: 1px solid var(--color-border);
    padding: 2rem 0;

    & > div {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      padding-bottom: 1rem;
    }

    &:last-child {
      border: none;
    }
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 0.7rem 2rem 3rem;
  max-width: var(--max-width);
  margin: 0 auto;
  background-color: var(--color-background);
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2rem;
  height: calc(100% - 8.3rem);
  overflow-y: auto;
`;
