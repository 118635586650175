import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { patchAccountPassword } from '#api/accounts';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { SignUpPasswordInput } from '#organisims';
import { useToast } from '#providers/ToastProvider';
import { PrevWithTitleTextTemplate } from '#templates';
import { ButtonContainer } from './styles';

export function SignInPasswordReset() {
  const { showToast } = useToast();

  const [password, setPassword] = useState<string | null>('');
  const { mutateAsync: passwordResetMutation } =
    useMutation(patchAccountPassword);
  const { token } = useParams();
  const navigate = useNavigate();

  const handlePasswordChange = (password: string | null) =>
    setPassword(password);

  const handleButtonClick = () => {
    token &&
      password &&
      passwordResetMutation(
        { token, password },
        {
          onSuccess: () => {
            showToast({
              message: '비밀번호 재설정에 성공하였습니다.'
            });
            navigate(PATH.SIGNIN);
          },
          onError: () =>
            showToast({
              type: 'fail',
              message: '비밀번호 재설정에 실패하였습니다.'
            })
        }
      );
  };

  return (
    <PrevWithTitleTextTemplate
      prevRoute={PATH.SIGNIN}
      title={
        <>
          <Text textStyle="display">비밀번호 재설정</Text>
          <Text textColor="gray2">새로 사용할 비밀번호를 재설정해주세요</Text>
        </>
      }
    >
      <SignUpPasswordInput setUserPassword={handlePasswordChange} />
      <ButtonContainer>
        <Button onClick={handleButtonClick} disabled={!password}>
          비밀번호 변경하기
        </Button>
      </ButtonContainer>
    </PrevWithTitleTextTemplate>
  );
}
