import { useEffect, useState } from 'react';
import { Text, Input } from '#atoms';
import { Container, StatusArea } from './styles';

interface Props {
  value?: string | null;
  setUserName: (nickname: string | null) => void;
}

export const SignUpNameInput = ({ value, setUserName }: Props) => {
  const [name, setName] = useState<string | null>(value ?? null);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    if (name) {
      (async () => {
        const isValidName = /^[^0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/]*$/.test(name);

        if (isValidName) {
          setValidationMessage('');
          setUserName(name);
        } else {
          setValidationMessage(
            '숫자, 특수문자, 공백을 제외한 문자만 입력 가능합니다'
          );
          setUserName(null);
        }
      })();
    } else {
      setUserName(null);
      setValidationMessage('');
    }
  }, [name]);

  return (
    <Container>
      <div>
        <Input
          type="text"
          labelText="이름"
          placeholder="이름을 입력해주세요"
          showDeleteButton={true}
          onChange={name =>
            String(name) === String(name).trim()
              ? setName(String(name))
              : setValidationMessage('공백은 입력할 수 없습니다')
          }
          value={name}
        />
      </div>
      <StatusArea>
        <Text textStyle="c2">{validationMessage}</Text>
      </StatusArea>
    </Container>
  );
};
