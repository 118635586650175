import { RoundIconWrap } from './styles';

interface Props {
  text: string;
}

export const RoundIcon = ({ text }: Props) => {
  return (
    <RoundIconWrap>
      <p data-text-type="c2">{text}</p>
    </RoundIconWrap>
  );
};
