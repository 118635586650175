import { Image } from 'src/components/molecules/ImageWithUpload/hooks';

/**Image[] 타입의 파일을 FormData 형식으로 포맷팅 */
export function createImgToFormData(localImgs: Image[]) {
  const formData = new FormData();

  localImgs.forEach(image => {
    //formdata name 필드  formData.append('여기서 변경', image.file);
    formData.append('images', image.file);
  });

  return formData;
}
