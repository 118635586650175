import React, { PropsWithChildren } from 'react';
import { BoxIcon, IconType } from '#atoms';
import { ChildrenContainer, Container, HeaderConatiner } from './styles';

interface Props {
  leftIcon: IconType;
  rightIcon: IconType;
  onLeftIconClick?: () => void;
  onRightIconClick?: () => void;
}

export const IconHeaderBarTemplate = ({
  leftIcon,
  rightIcon,
  onLeftIconClick,
  onRightIconClick,
  children
}: PropsWithChildren<Props>) => {
  return (
    <Container>
      <HeaderConatiner>
        <BoxIcon
          type={leftIcon}
          boxColor="transparent"
          size={18}
          onlyIcon={true}
          onClick={onLeftIconClick}
        />
        <BoxIcon
          type={rightIcon}
          boxColor="transparent"
          size={20}
          onlyIcon={true}
          onClick={onRightIconClick}
        />
      </HeaderConatiner>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};
