import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

export default function useScrollRestoration(key: string, timer = 300) {
  timer;
  const location = useLocation();
  const storage = window.sessionStorage;

  const setScrollRestoration = () => {
    storage.setItem(key, String(window.scrollY));
  };

  useLayoutEffect(() => {
    if (storage.getItem(key)) {
      const scrollY = Number(storage.getItem(key));

      storage.removeItem(key);
      window.scrollTo(0, scrollY);
    }
  }, [location]);

  return { setScrollRestoration };
}
