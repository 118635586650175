export enum EVENT {
  ROOM = 'room',
  // 들어가있는 채팅방 정보
  ROOM_LIST = 'room list',
  // 채팅방 생성
  CREATE_ROOM = 'create room',
  // 특정 채팅방에 있는 메세지 목록
  MESSAGE_LIST = 'message list',
  NEW_ROOM = 'new room',
  CHAT = 'chat',
  CHAT_LIST = 'chat list',
  FIRST_CHAT_LIST = 'first chat list',
  READ_CHATS = 'read chats'
}
