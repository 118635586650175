import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { BoxIcon, Text } from '#atoms';
import { PATH } from '#const/path';
import CoachSettlements, {
  useCoachSettlements
} from '#providers/CoachSettlements';
import CoachSettlementsInfo, {
  useCoachSettlementsInfo
} from '#providers/CoachSettlementsInfo';
import { useToast } from '#providers/ToastProvider';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { monthDayFormatter } from '#utils/dateFormatter';
import { formatNumberWithCommas } from '#utils/Numbers';
import { TextValueBox } from 'src/components/molecules/TextValueBox';
import { useMonthDate } from './hooks';
import { ListItems, MonthShifter, PointFourthButton } from './Items';
import { ChartHeader, Container, ItemBox } from './styles';

export function BillingMainPage() {
  const { showToast } = useToast();

  const [searchParams] = useSearchParams();
  const date = searchParams.get('date');

  const navigate = useNavigate();
  const { startDate, endDate, handleNextMonth, handlePrevMonth } = useMonthDate(
    { date: date ? new Date(date) : undefined }
  );
  const { getCurrentSettlements, settlements, setSettlementDetail } =
    useCoachSettlements();
  const { getTotalSettlementsInfo, settlementsInfo } =
    useCoachSettlementsInfo();

  const totalDepositAmount = useMemo(() => {
    return settlements.reduce((total, item) => {
      if (item.type === 'DEPOSIT') {
        const amountToAdd = item.price - (item.tax || 0);
        return total + amountToAdd;
      }
      return total;
    }, 0);
  }, [settlements]);

  useEffect(() => {
    const nextDayEnd = new Date(endDate); // 하루를 더해서 보내야 해당 날의 24시까지의 데이터를 받을 수 있음
    nextDayEnd.setDate(nextDayEnd.getDate() + 1);

    getCurrentSettlements({ startDate: startDate, endDate: nextDayEnd });
  }, [startDate, endDate]);

  useEffect(() => {
    getTotalSettlementsInfo();
  }, []);

  const handleEmptySettlement = () => {
    showToast({
      type: 'fail',
      message: '정산 내역이 존재하지 않습니다.'
    });
  };

  return (
    <>
      <ChartHeader>
        <PrevHeaderBarWithTitleTemplate
          prevRoute={PATH.MYPAGE}
          title="수입관리"
        >
          <Container className="overview">
            <CoachSettlementsInfo.IsError>
              잘못된 접근입니다!
            </CoachSettlementsInfo.IsError>
            <CoachSettlementsInfo.IsLoading>
              로딩중...
            </CoachSettlementsInfo.IsLoading>
            <CoachSettlementsInfo.IsEmpty>
              <TextValueBox text="총 수입" value="0원" />
              <div className="item-container">
                <TextValueBox
                  text="정산 완료 금액"
                  value="0원"
                  valueStyle="bb"
                />
                <TextValueBox
                  text="정산 가능 금액"
                  value="0원"
                  valueStyle="bb"
                />
              </div>
              <PointFourthButton
                text="정산하기"
                onClick={handleEmptySettlement}
              />
            </CoachSettlementsInfo.IsEmpty>
            <CoachSettlementsInfo.HasData>
              <TextValueBox
                text="총 수입"
                value={`${formatNumberWithCommas(
                  settlementsInfo.totalPrice ?? 0
                )}원`}
              />
              <div className="item-container">
                <TextValueBox
                  text="정산 완료 금액"
                  value={`${formatNumberWithCommas(
                    settlementsInfo.settledPrice ?? 0
                  )}원`}
                  valueStyle="bb"
                />
                <TextValueBox
                  text="정산 가능 금액"
                  value={`${formatNumberWithCommas(
                    settlementsInfo.price ?? 0
                  )}원`}
                  valueStyle="bb"
                />
              </div>
              <PointFourthButton
                text="정산하기"
                onClick={() => navigate(PATH.BILLING_REQUEST)}
              />
            </CoachSettlementsInfo.HasData>
          </Container>
        </PrevHeaderBarWithTitleTemplate>
      </ChartHeader>
      <Container className="content">
        <div className="heading">
          <ItemBox>
            <Text textStyle="t3">수입 내역</Text>
            <BoxIcon type="filter" onlyIcon size={18} />
          </ItemBox>
          <MonthShifter
            startDateStr={monthDayFormatter(startDate.toString())}
            endDateStr={monthDayFormatter(endDate.toString())}
            handlePrevMonth={handlePrevMonth}
            handleNextMonth={handleNextMonth}
          />
          <TextValueBox
            text="전체 수입"
            value={`${formatNumberWithCommas(totalDepositAmount)}원`}
            valueStyle="bb"
          />
        </div>
        <div className="list-container">
          <CoachSettlements.IsError>
            잘못된 접근입니다!
          </CoachSettlements.IsError>
          <CoachSettlements.IsLoading>로딩중...</CoachSettlements.IsLoading>
          <CoachSettlements.IsEmpty />
          <CoachSettlements.HasData>
            {settlements.map((item, index) => (
              <ListItems
                key={index}
                isWithdrawal={item.type === 'WITHDRAWAL'}
                date={
                  item.type === 'WITHDRAWAL'
                    ? item.updatedAt || ''
                    : item.createdAt
                }
                title={item.lecture.name}
                revenue={item.price - (item.tax ?? 0)}
                detail={
                  item.type === 'WITHDRAWAL'
                    ? item.status
                    : `${item.lecture.count}`
                }
                balance={item.balance}
                onItemClick={() => {
                  setSettlementDetail(item);
                  navigate(
                    `${PATH.BILLING_DETAIL}${date ? `?date=${date}` : ''}`
                  );
                }}
              />
            ))}
          </CoachSettlements.HasData>
        </div>
      </Container>
    </>
  );
}
