import React, { useCallback } from 'react';
import { Button, Text } from '#atoms';
import { FibudLabeledNumericInput } from '#molecules';
import { useApplications } from '#providers/Applications';
import { FibudContainerTemplate, useProgress } from '#templates';
import { Container, MessageContainer, TextContainer } from './styles';

const heightRange = {
  min: 100,
  max: 300
};

const weightRange = {
  min: 20,
  max: 200
};

export function ApplicationBodyInfo() {
  const { next } = useProgress();
  const { updateCurrentApplications, currentApplication } = useApplications();
  const isHeightValid =
    currentApplication?.healthInfo?.height !== undefined &&
    currentApplication?.healthInfo?.height >= heightRange.min &&
    currentApplication?.healthInfo?.height <= heightRange.max;
  const isWeightValid =
    currentApplication?.healthInfo?.weight !== undefined &&
    currentApplication?.healthInfo?.weight >= weightRange.min &&
    currentApplication?.healthInfo?.weight <= weightRange.max;

  const heightInputCallback = useCallback(
    (v?: number) => {
      updateCurrentApplications({
        healthInfo: { ...currentApplication?.healthInfo, height: v }
      });
    },
    [currentApplication, updateCurrentApplications]
  );

  const weightInputCallback = useCallback(
    (v?: number) => {
      if (currentApplication && currentApplication.healthInfo)
        updateCurrentApplications({
          healthInfo: { ...currentApplication.healthInfo, weight: v }
        });
    },
    [currentApplication, updateCurrentApplications]
  );

  return (
    <FibudContainerTemplate type="paddingWithBottomButton">
      <FibudContainerTemplate type="bottomButtonContainer">
        <Container>
          <TextContainer>
            <Text textStyle="t1">
              회원님의 신체정보를
              <br />
              입력해주세요
            </Text>
          </TextContainer>
          <div>
            <FibudLabeledNumericInput
              value={currentApplication?.healthInfo?.height}
              onInput={heightInputCallback}
              label="키"
              suffix="cm"
              pureNumber
              max={heightRange.max}
            />
            <MessageContainer>
              {currentApplication?.healthInfo?.height !== undefined &&
                !isHeightValid && (
                  <Text textStyle="c2" textColor="red">
                    100cm 이상 300cm 이하의 값을 입력해주세요
                  </Text>
                )}
            </MessageContainer>
            <FibudLabeledNumericInput
              value={currentApplication?.healthInfo?.weight}
              onInput={weightInputCallback}
              label="몸무게"
              suffix="kg"
              pureNumber
              max={weightRange.max}
            />
            <MessageContainer>
              {currentApplication?.healthInfo?.weight !== undefined &&
                !isWeightValid && (
                  <Text textStyle="c2" textColor="red">
                    20kg 이상 200kg 이하의 값을 입력해주세요
                  </Text>
                )}
            </MessageContainer>
          </div>
        </Container>
      </FibudContainerTemplate>
      <FibudContainerTemplate type="bottomButton">
        <Button
          onClick={() => next()}
          disabled={!isHeightValid || !isWeightValid}
        >
          다음
        </Button>
      </FibudContainerTemplate>
    </FibudContainerTemplate>
  );
}
