import styled from 'styled-components';

export const DateContainer = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: space-evenly;
`;

export const Container = styled.div`
  width: 100%;
`;

export const ModalTitle = styled.div`
  text-align: left;
  margin-right: auto;
`;

export const YearBox = styled.div`
  display: flex;
  width: 33.3%;
  justify-content: center;
  align-items: center;
  height: 10rem;
  border-right: 1px solid #aaa;
  text-align: center;
  gap: 0.1rem;
  span {
    width: 4.5rem;
    text-align: center;
  }
`;

export const MonthBox = styled(YearBox)`
  width: 7rem;
`;

export const WeekBox = styled(YearBox)`
  width: 7rem;
  border-right: none;
`;

export const CurrentBox = styled.div`
  width: 100%;
  height: 100%;
`;

export const ButtonBox = styled.div`
  width: 80%;
  display: flex;
  gap: 1rem;
`;

export const DateBox = styled.div`
  height: 3rem;
  width: 7rem;
  display: flex;
  flex-direction: column;
  height: 4rem;
  justify-content: center;
  item-align: center;
  gap: 1rem;
  input {
    width: 100%;
    height: 3.3rem;
    text-align: center;
    padding: 0;
  }
`;
