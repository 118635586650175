import { CSSProperties, InputHTMLAttributes } from 'react';
import { InputBox } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  textAlign?: CSSProperties['textAlign'];
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
}

export const BorderlessInput = ({ textAlign, inputRef, ...props }: Props) => {
  return (
    <InputBox>
      <input
        {...props}
        autoComplete="off"
        style={{ textAlign: textAlign }}
        ref={inputRef}
      />
    </InputBox>
  );
};
