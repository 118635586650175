import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  text-align: center;

  flex-shrink: 0;
  scroll-snap-align: start;
  position: relative;
`;

export const Header = styled.div<{ isOT: boolean }>`
  padding-top: 12.5px;
  padding-bottom: 10px;
  background: ${props =>
    props.isOT
      ? 'var(--color-point-gradient)'
      : 'linear-gradient(135deg, #3F3D43 0%, #000 100%);'};
  border-radius: 0.5rem 0.5rem 0 0;

  > div {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;

    & :first-child {
      font-weight: normal;
    }
  }
`;

export const Body = styled.div`
  padding: 0.5rem;
  color: var(--color-black);
  background-color: var(--color-white);
  border-radius: 0 0 0.5rem 0.5rem;
`;

export const OTInfoBoxArea = styled.div`
  width: 100%;
  height: 100%;
`;
