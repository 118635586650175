import { useNavigate } from 'react-router';
import { ArrowButton } from '#atoms';
import { PATH } from '#const/path';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { Container } from './styles';

export function MyPagePolicy() {
  const navigate = useNavigate();

  return (
    <PrevHeaderBarWithTitleTemplate prevRoute={PATH.MYPAGE} title="이용약관">
      <Container>
        <ArrowButton
          text="서비스 이용약관"
          onClick={() => navigate(PATH.MYPAGE_POLICY_SERVICE)}
          hasBigfontSize={false}
          arrowColor="gray"
        />
        <ArrowButton
          text="개인정보 처리방침"
          onClick={() => navigate(PATH.MYPAGE_POLICY_PRIVACY)}
          hasBigfontSize={false}
          arrowColor="gray"
        />
        <ArrowButton
          text="마케팅 정보 수신동의"
          onClick={() => navigate(PATH.MYPAGE_POLICY_MARKETING)}
          hasBigfontSize={false}
          arrowColor="gray"
        />
      </Container>
    </PrevHeaderBarWithTitleTemplate>
  );
}
