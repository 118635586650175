import request from '#api/axios';
import {
  IPaymentsPrepare,
  IPaymentsResponse,
  IPostPayments,
  IPostPaymentsPrepare
} from '#types/payments';

export const postPaymentsPrepare = async ({
  lectureId,
  count
}: IPostPaymentsPrepare) => {
  const res = await request<IPaymentsPrepare>({
    method: 'post',
    url: '/payments/prepare',
    data: { lectureId, count }
  });

  return res.data;
};

export const postPayments = async ({
  impUid,
  lectureId,
  count,
  gymId
}: IPostPayments) => {
  const res = await request<IPaymentsResponse>({
    method: 'post',
    url: '/payments',
    data: { imp_uid: impUid, lecture_id: lectureId, count, gym_id: gymId }
  });

  return res.data;
};

export const postPaymentsFree = async (gymId: string, lectureId: string) => {
  const res = await request<IPaymentsResponse>({
    method: 'post',
    params: {
      gymId
    },
    url: `/payments/free/v2/${lectureId}`
  });

  return res.data;
};
