import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: auto;
  padding: 3rem 0;
`;

export const EmailInputArea = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    text-align: right;
  }
`;
