import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { BorderlessInput } from '#molecules';
import { useCoachBankNumber } from '#providers/CoachBankNumber';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { isNumeric } from '#utils/Numbers';
import { ButtonContainer, Container, Hyphen, InputContainer } from './styles';

export function BillingRegRRNPage() {
  const navigate = useNavigate();
  const { regForm, setRegForm } = useCoachBankNumber();
  const [residentNumber, setResidentNumber] = useState<{
    firstDigit: string;
    secondDigit: string;
  }>({
    firstDigit: regForm.residentNumber.slice(0, 6),
    secondDigit: regForm.residentNumber.slice(6, 13)
  });

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (isNumeric(value) || value === '')
      return setResidentNumber(prev => ({ ...prev, [name]: value }));
  };

  const handleNextButtonClick = () => {
    setRegForm(prev => ({
      ...prev,
      residentNumber: residentNumber.firstDigit.concat(
        residentNumber.secondDigit
      )
    }));
    navigate(PATH.BILLING_REGISTRATION_ACCOUNT);
  };

  useEffect(() => {
    if (!inputRef.current) return;
    residentNumber.firstDigit.length === 6 && inputRef.current.focus();
  }, [residentNumber]);

  return (
    <PrevHeaderBarWithTitleTemplate prevRoute={PATH.MYPAGE} title="수입관리">
      <Container>
        <div>
          <Text textStyle="t1">정산을 위해</Text>
          <Text textStyle="t1">주민등록번호를 입력해주세요</Text>
        </div>
        <InputContainer>
          <BorderlessInput
            name="firstDigit"
            value={residentNumber.firstDigit}
            maxLength={6}
            onChange={handleInputChange}
            textAlign="center"
          />
          <Hyphen>
            <hr />
          </Hyphen>
          <BorderlessInput
            name="secondDigit"
            value={residentNumber.secondDigit}
            maxLength={7}
            inputRef={inputRef}
            onChange={handleInputChange}
            textAlign="center"
          />
        </InputContainer>
      </Container>
      <ButtonContainer>
        <Button
          disabled={
            (residentNumber.firstDigit + residentNumber.secondDigit).length < 13
          }
          onClick={handleNextButtonClick}
        >
          다음
        </Button>
      </ButtonContainer>
    </PrevHeaderBarWithTitleTemplate>
  );
}
