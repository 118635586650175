import { useWNInterface } from '../wni';
import { WNInterface } from '../wni/lib/WNInterface';
import {
  clearWatch,
  getCurrentLocation,
  getLocationPermission
} from './modules/web.location';

export function useNativeInterceptor() {
  const nativeInterceptor = new NativeInterceptor();
  nativeInterceptor.initialize();
  return nativeInterceptor;
}
interface CallbackOptions {
  status?: string;
  latitude?: number;
  longitude?: number;
  watch_id?: number;
  message?: string;
}
type CallbackFunction = (options: CallbackOptions) => void;

interface CommandOptions {
  callback: CallbackFunction;
  url?: string;
  text?: string | null;
  type?: string;
  [key: string]:
    | string
    | number
    | boolean
    | object
    | CallbackFunction
    | undefined
    | null;
}

type BindedCommands = {
  [command: string]: (options: CommandOptions) => Promise<void>;
};

type NativeComponents = {
  [component: string]: boolean | null;
};

export class NativeInterceptor {
  private _wni: WNInterface;
  private _bindedCommands: BindedCommands;
  private _loaded: boolean;
  private _nativeComponents: NativeComponents;

  get isNative() {
    return this._wni.isNative;
  }

  constructor() {
    this._wni = useWNInterface();
    this._loaded = false;
    this._nativeComponents = {};
    this._bindedCommands = {};
  }

  unload() {
    this._loaded = true;
  }

  initialize() {
    this._loaded = true;

    this._bindedCommands = {
      wnLocationPermission: this.wnLocationPermission,
      wnLocationCurrent: this.wnLocationCurrent,
      wnLocationUpdatingStop: this.wnLocationUpdatingStop,
      wnOpenURL: this.wnOpenURL,
      wnEmailSend: this.wnEmailSend,
      // wnMediaPhotoUpload: this.wnMediaPhotoUpload,
      wnPopupOpen: this.wnPopupOpen,
      // wnPopupClose: this.wnPopupClose,
      wnOpenAppSetting: this.wnOpenAppSetting,
      wnPermission: this.wnPermission,
      wnAnalyticsAmplitudeSendEvent: this.wnAnalyticsAmplitudeSendEvent
      // wnPaymentModule: this.wnPaymentModule
    };
  }

  canDelegate(command: string) {
    if (this._loaded && this._bindedCommands[command]) {
      return true;
    }

    return false;
  }

  get isShowNativeComponents() {
    return Object.keys(this._nativeComponents).length > 0;
  }

  closeNativeComponents() {
    if (this._nativeComponents['wnQRScanner']) {
      this.execute('wnCloseQRScanner', {
        callback: () => {
          console.log('wnCloseQRScanner');
        }
      });
    } else if (this._nativeComponents['wnPopup']) {
      this.execute('wnPopupClose', {
        callback: () => {
          console.log('wnPopupClose');
        }
      });
    }
  }

  execute(command: string, options: CommandOptions) {
    if (command === 'wnPopupOpen') {
      this._nativeComponents['wnPopup'] = true;
    } else if (command === 'wnPopupClose') {
      this._nativeComponents['wnPopup'] = null;
      delete this._nativeComponents['wnPopup'];
    }

    if (this._bindedCommands[command]) {
      if (this._wni.isNative) {
        this._wni.execute(command, options);
      } else {
        this._bindedCommands[command](options);
      }
    }
  }

  async wnLocationPermission(options: CommandOptions) {
    const status = (await getLocationPermission()) as string;
    options.callback({
      status: status
    });
  }

  async wnLocationCurrent(options: CommandOptions) {
    const { latitude, longitude } =
      (await getCurrentLocation()) as CallbackOptions;
    options.callback({
      status: 'SUCCESS',
      latitude: latitude,
      longitude: longitude
    });
  }

  // async wnPaymentModule(options: CommandOptions) {
  //   console.log('wnPaymentModule options:', options);
  //   try {
  //     const response = {
  //       status: 'SUCCESS',
  //       message: 'Payment processed successfully'
  //     };
  //     options.callback(response);
  //   } catch (error) {
  //     const errorResponse = {
  //       status: 'ERROR',
  //       message: 'Payment failed'
  //     };
  //     options.callback(errorResponse);
  //   }
  // }

  // async wnLocationUpdating(options: CommandOptions) {
  //   const { watch_id, latitude, longitude } = (await watchLocation({
  //     handler: ({ watch_id, latitude, longitude }: CallbackOptions) => {
  //       options.callback({
  //         status: 'UPDATING',
  //         watch_id: watch_id,
  //         latitude: latitude,
  //         longitude: longitude
  //       });
  //     }
  //   })) as CallbackOptions;

  //   options.callback({
  //     status: 'SUCCESS',
  //     watch_id: watch_id,
  //     latitude: latitude,
  //     longitude: longitude
  //   });
  // }

  async wnLocationUpdatingStop(options: CommandOptions) {
    await clearWatch();
    options.callback({
      status: 'SUCCESS'
    });
  }

  async wnOpenURL(options: CommandOptions) {
    window.open(options.url, '__WN_BLANK__');
  }

  async wnPopupOpen(options: CommandOptions) {
    window.open(options.url, '__WN_POPUP__');
  }

  async wnPopupClose() {
    //  @TODO: __WN_POPUP__ 팝업 닫기 필요
    return true;
  }

  async wnMediaPhotoUpload() {
    // @TODO: 웹용으로 구현 필요
    return true;
  }

  // console.log(options.text);
  // if (options.text) {
  //   navigator.clipboard
  //     .writeText(options.text)
  //     .then(() => {
  //       toastModule.show({ type: ToastType.ALERT, message: $t('주소가 클립보드에 복사되었어요') });
  //     })
  //     .catch(() => {
  //       toastModule.show({ type: ToastType.ERROR, message: $t('클립보드 복사 중에 오류가 발생했어요') });
  //     });
  // } else {
  //   toastModule.show({ type: ToastType.ERROR, message: $t('주소를 입력해 주세요') });
  // }

  async wnEmailSend() {
    console.log('wnEmailSend');
  }

  async wnOpenAppSetting() {
    console.log('wnOpenAppSetting');
  }

  async wnPermission(options: CommandOptions) {
    // const type = {
    //   camera: 'camera',
    //   location: 'geolocation'
    // };

    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(result => {
        //prompt인 상태값만 받아오는 permission API이므로 GRANTED로 보내준후 권한 허용 여부 선택 하도록 하기위해 PROMPT또한 GRANTED로 상태값 보냄
        if (
          result.state.toUpperCase() === 'GRANTED' ||
          result.state.toUpperCase() === 'PROMPT'
        ) {
          options.callback({
            status: 'GRANTED'
          });
        } else {
          options.callback({
            status: result.state.toUpperCase()
          });
        }
      });
    } else {
      options.callback({
        status: 'GRANTED'
      });
    }
  }

  async wnAnalyticsAmplitudeSendEvent() {
    console.log('####');
  }
}
