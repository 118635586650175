import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
`;
export const TimeHeader = styled.div`
  text-align: center;
`;
export const TimeBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

export const LabeledInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

export const TextAreaBox = styled.div`
  padding: 1rem;
  p {
    margin-bottom: 1rem;
  }
`;

export const ButtonBox = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-evenly;
  button {
    width: 45%;
  }
`;
