import { useNavigate } from 'react-router';
import { CheckPurple } from '#assets/svg';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import {
  ArticleContainer,
  ButtonContainer,
  Container,
  ContentContainer,
  FieldContainer
} from './styles';

export function ApplicationRegSuccess() {
  const navigator = useNavigate();
  return (
    <Container>
      <ContentContainer>
        <ArticleContainer>
          <ContentContainer className="check">
            <CheckPurple />
            <Text textStyle="display">수강 신청이 완료되었습니다!</Text>
          </ContentContainer>
          <FieldContainer>
            <Text textStyle="t3">
              가능한 수업 시간을 확인하고 <br /> 바로 수업을 예약해 보세요.
            </Text>
          </FieldContainer>
        </ArticleContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={() => navigator(PATH.MYPAGE_SCHEDULES)}>
          수업 예약하기
        </Button>
      </ButtonContainer>
    </Container>
  );
}
