import { useQuery } from '@tanstack/react-query';
import { fetcher } from '../libs';
import queryKeys from '../queryKeys';

interface Params {
  /** 자격증 url */
  licenseUrl: string;
}

/**
 * @link https://api-dev.fibud.kr/api#/%EC%9E%90%EA%B2%A9%EC%A6%9D%20%EB%B0%9C%EA%B8%89%EC%B2%98/LicenseIssuersController_findAssociationIdByAssociationUrl
 * @param licenseUrl - 자격증 url
 * @returns
 */
export const useGetLicenseIssuerId = ({ licenseUrl }: Params) => {
  return useQuery<string>(
    [queryKeys.LICENSEISSURE],
    () => fetcher(`/license-issuers/licenseIssuerId?licenseUrl=${licenseUrl}`),
    {
      enabled: !!licenseUrl
    }
  );
};
