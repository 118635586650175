import React from 'react';
import { Route, Routes } from 'react-router';
import {
  ProfileClassPage,
  ProfileCategoryPage,
  ProfileGymPage,
  ProfileHomePage,
  ProfileUpdate,
  ProfileSchedulePage,
  ProfileCertificatePage,
  ProfileIntroducePage,
  ProfileCarrerPage,
  ProfileOTPage,
  ProfileClassDetailPage,
  ProfileClassFormPage,
  ProfileClinicalDataPage,
  ProfileClinicalDataFormPage,
  ProfileShortIntroPage,
  ProfileDegreePage
} from '#pages';
import ClinicalData from '#providers/ClinicalData';
import CoachLecture from '#providers/CoachLecture';
import ProfileGymRegistrationPage from 'src/components/pages/ProfileGymRegistrationPage';
import { Container } from './styles';

/**
 * @author sky.blue
 * @description 해당 페이지는 프로필 관련 페이지입니다.
 * 초기에는 코치프로필만 존재하므로 해당 페이지는 코치 기준으로 구현됩니다.
 */
export function ProfileView() {
  return (
    <Container>
      <Routes>
        <Route path="" element={<ProfileHomePage />} />
        <Route path="update" element={<ProfileUpdate />} />
        <Route path="schedule" element={<ProfileSchedulePage />} />
        <Route path="gym" element={<ProfileGymPage />} />
        <Route
          path="gym-registration"
          element={<ProfileGymRegistrationPage />}
        />
        <Route
          path="class/*"
          element={
            <CoachLecture>
              <Routes>
                <Route path="" element={<ProfileClassPage />} />
                <Route path="detail" element={<ProfileClassDetailPage />} />
                <Route path="form" element={<ProfileClassFormPage />} />
                {/* <Route path="price" element={<ProfileClassPricePage />} /> */}
              </Routes>
            </CoachLecture>
          }
        />
        <Route path="ot" element={<ProfileOTPage />} />
        <Route path="category" element={<ProfileCategoryPage />} />
        <Route path="certificate" element={<ProfileCertificatePage />} />
        <Route path="carrer" element={<ProfileCarrerPage />} />
        <Route path="introduce" element={<ProfileIntroducePage />} />
        <Route path="short-introduce" element={<ProfileShortIntroPage />} />
        <Route path="degree" element={<ProfileDegreePage />} />
        <Route
          path="clinical-data/*"
          element={
            <ClinicalData>
              <Routes>
                <Route path="" element={<ProfileClinicalDataPage />} />
                <Route path="form" element={<ProfileClinicalDataFormPage />} />
              </Routes>
            </ClinicalData>
          }
        />
      </Routes>
    </Container>
  );
}
