import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3.8rem 2rem;

  & :last-child {
    border-bottom: none;
  }
`;

export const LectureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  padding: 2rem 0;
  border-bottom: 1px solid var(--color-border);

  > button {
    height: 4.5rem;
  }
`;

export const LectureTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LectureBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.button`
  background-color: var(--color-point);
  border-radius: 0.5rem;
  width: 100%;
  padding: 1rem 7rem;
  text-align: center;
`;
