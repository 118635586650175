import styled from 'styled-components';

export const BottomButtonWrap = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
  height: 7.5rem;
  padding: 0.75rem 1.25rem 3.5rem;
  background-color: var(--color-background);
`;

export const ButtonArea = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.25rem;
  border-radius: 0.5rem;

  &.light {
    background-color: var(--color-point);
    color: var(--color-white);
  }

  &.dark {
    background-color: var(--color-gray);
    color: var(--color-gray-text);
  }
`;
