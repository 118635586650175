import styled from 'styled-components';

export const CoachInformationList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  & > li:not(:last-of-type) {
    border-bottom: 1px solid var(--color-gray);
  }
`;

export const CoachInformation = styled.li`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  gap: 1rem;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
