import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
`;

export const NonScrollSidePaddingContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 2rem 1rem;
`;
