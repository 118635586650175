import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-bottom: 1rem solid var(--color-gray);
`;

export const LectureUserNameWrapper = styled.div`
  display: flex;
  gap: 0.5rem;

  > :first-child {
    color: var(--color-point);
  }
  > :last-child {
    color: var(--color-white);
  }
`;

export const LectureInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-gray-text-second);
`;

export const LectureInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ReviewLists = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 3rem 0;
`;

export const StarArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 14.6rem;
`;

export const ReviewCategory = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--color-gray-text-second);
`;
