import { useNavigate } from 'react-router';
import { Button, Text, Textarea } from '#atoms';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { useProfileIntroduce } from './hooks';
import { ButtonContainer, Container } from './styles';

export function ProfileIntroducePage() {
  const { showToast } = useToast();

  const { userInfo, patchProfile } = useUserInfo();
  const { introduce, onChangeIntroduce } = useProfileIntroduce({
    currentIntroduce: userInfo?.introduction
  });
  const navigate = useNavigate();

  const onButtonClick = () => {
    patchProfile(
      { introduction: introduce },
      {
        onSuccess: () => {
          showToast({
            message: '소개글 등록에 성공하였습니다.'
          });
          navigate(PATH.PROFILE_UPDATE);
        },
        onError: () => {
          showToast({
            type: 'fail',
            message: '소개글 등록에 실패하였습니다.'
          });
        }
      }
    );
  };

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.PROFILE_UPDATE}
      title="소개글 등록"
      headerbarType="relative"
    >
      <Container>
        <Text textStyle="display">소개글을 작성해주세요</Text>
        <Textarea
          value={introduce}
          onChange={onChangeIntroduce}
          limit={1000}
          height={26.8}
          placeholder="입력 예시. 글자 수는 공백 포함 1000자 입니다."
        />
        <ButtonContainer>
          <Button disabled={!introduce.trim().length} onClick={onButtonClick}>
            프로필 등록
          </Button>
        </ButtonContainer>
      </Container>
    </PrevHeaderBarWithTitleTemplate>
  );
}
