import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 2rem;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TextInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  padding: 1rem 1rem 3rem;
  background-color: var(--color-background);
  p {
    text-decoration: underline;
    text-align: center;
    margin-top: 1rem;
    cursor: pointer;
  }
`;
export const CheckBoxContainer = styled.div`
  padding: 0 0 1rem;
  > div:first-child {
    padding-top: 0;
  }
`;

export const TextareaContainer = styled.div`
  padding: 1rem 0;
  p {
    margin-bottom: 1rem;
  }
`;
