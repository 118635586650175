import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`;

export const DetailArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const ChatButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 2rem;

  > div {
    background: none;
    transform: scaleX(-1);
  }
`;
