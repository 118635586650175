import classNames from 'classnames';
import React from 'react';
import { BoxIcon, ModalPortal } from '#atoms';
import { ModalBackground, ModalWrapper } from './styles';

interface Props {
  onClose: () => void;
  children?: React.ReactNode;
  transparent?: boolean;
}

export const Modal = ({ onClose, children, transparent }: Props) => {
  return (
    <ModalPortal>
      <ModalBackground onClick={onClose}>
        <ModalWrapper
          className={classNames({ transparent: !!transparent })}
          onClick={e => e.stopPropagation()}
        >
          {transparent ? (
            <>
              <BoxIcon type="x-gray" size={16} onlyIcon onClick={onClose} />
              {children}
            </>
          ) : (
            <>{children}</>
          )}
        </ModalWrapper>
      </ModalBackground>
    </ModalPortal>
  );
};
