import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router';
import { NextArrow } from '#assets/svg';
import { Text } from '#atoms';
import { PATH } from '#const/path';
import {
  MyLectureBoxContainer,
  MyLectureBoxArea,
  LectureInfoArea,
  TextButton
} from './styles';

interface Props {
  hasData: boolean;
  lectureId?: string;
  coachName?: string;
  title?: string;
  gymName?: string;
  currentRound?: number;
  totalRound?: number;
}

export const LectureBox = ({
  hasData,
  lectureId,
  coachName,
  title,
  gymName,
  currentRound,
  totalRound
}: Props) => {
  const navigate = useNavigate();

  return (
    <MyLectureBoxContainer className={classNames({ none: !hasData })}>
      {hasData ? (
        <MyLectureBoxArea
          onClick={() =>
            navigate(`${PATH.CHART_LECTURE}?id=${lectureId}`, {
              state: { prevLocation: location.pathname + location.search }
            })
          }
        >
          <Text textStyle="t3">{title}</Text>
          <LectureInfoArea>
            <div className="names" data-text-type="c1">
              <p>{coachName} 전문가</p>
              <p>{gymName}</p>
            </div>
            <div className="rounds">
              <Text textStyle="t2">
                {currentRound}/{totalRound}회
              </Text>
            </div>
          </LectureInfoArea>
        </MyLectureBoxArea>
      ) : (
        <MyLectureBoxArea>
          <Text className="none" textStyle="c1">
            현재 수강중인 운동이 없습니다
          </Text>
          <TextButton
            onClick={() => {
              navigate(`${PATH.HOME}?tab=2`);
            }}
          >
            <Text textStyle="t3">내 주변 전문가 둘러보기</Text>
            <NextArrow />
          </TextButton>
        </MyLectureBoxArea>
      )}
    </MyLectureBoxContainer>
  );
};
