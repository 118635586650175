import React, { useMemo, useState } from 'react';

interface Props {
  children?: React.ReactNode;
}

interface ProgressContext {
  pos: number;
  prev: () => void;
  next: () => void;
}

const ProgressContext = React.createContext<ProgressContext | null>(null);

export const useProgress = () => {
  const context = React.useContext(ProgressContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <Progress> component.'
    );
  }
  return context;
};

function Progress({ children }: Props) {
  const [pos, setPos] = useState<number>(0);

  const prev = () => setPos(Math.max(0, pos - 1));
  const next = () => setPos(pos + 1);
  const jumpToApplication = () => setPos(2);
  const memorizedValue = useMemo(
    () => ({
      pos,
      prev,
      next,
      jumpToApplication
    }),
    [pos]
  );

  return (
    <ProgressContext.Provider value={memorizedValue}>
      {children}
    </ProgressContext.Provider>
  );
}

function Container({ children }: React.PropsWithChildren) {
  return (
    <Progress>
      <>{children}</>
    </Progress>
  );
}

Progress.Container = Container;

export default Progress;
