import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { IGetPainPartKinds } from '#types/painPartKinds';

export const getPainPartKinds = async () => {
  const res = await request<IGetPainPartKinds>({
    method: 'get',
    url: '/pain-part-kinds'
  });

  return res.data;
};

export const useGetPainPartKinds = () => {
  return useQuery(queryKeyFactory.GET_PAIN_PART_KINDS(), getPainPartKinds);
};
