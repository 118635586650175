import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useResetRecoilState } from 'recoil';
import WelcomeCoach1 from '#assets/img/WelcomeCoach1.png';
import WelcomeCoach2 from '#assets/img/WelcomeCoach2.png';
import { Logo } from '#assets/svg';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { $signupState } from '#states/atoms/recoilSignupState';
import sessionStorageManager from '#utils/sessionStorageManager';
import {
  Container,
  CarouselItem,
  CarouselSlide,
  FirstCenterLine,
  SecondCenterLine,
  AlignContent,
  FirstTabConatiner,
  SecondTabConatiner,
  BottomProgressContainer,
  ProgressDotContainer,
  ProgresDot,
  ButtonContainer
} from './styles';

const CarouselItems = [
  <CarouselItem>
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, #000 100%), url(${WelcomeCoach1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <AlignContent>
        <FirstTabConatiner>
          <Text textStyle="t2" className="color-white">
            내가 정하는 수업 일정, 수업 공간
          </Text>
          <FirstCenterLine />
          <Text className="color-white">
            수업 가능한 요일, 시간을 자유롭게 선택하고
            <br />
            원하는 운동공간을 직접 비교해서 선택해보세요
          </Text>
        </FirstTabConatiner>
      </AlignContent>
    </div>
  </CarouselItem>,
  <CarouselItem>
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.38) 0%, #000 100%), url(${WelcomeCoach2})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <AlignContent>
        <SecondTabConatiner>
          <Text textStyle="t2" className="color-white">
            더 많은 회원 더 많은 수익
          </Text>
          <SecondCenterLine />
          <Text className="color-white">
            기존 60% 수수료를 30%로 줄였습니다.
            <br />
            수익은 동일하게, 고객에겐 저렴하게 다가가세요
          </Text>
        </SecondTabConatiner>
      </AlignContent>
    </div>
  </CarouselItem>,
  <CarouselItem>
    <div
      style={{
        width: '100%',
        height: '100%',
        background: 'var(--color-point-dark-gradient)'
      }}
    >
      <AlignContent>
        <Logo />
        <div>
          <Text textStyle="t2" className="color-white">
            더 많은 수익을 제공하는
          </Text>
          <Text textStyle="t2" className="color-text-point-gradient">
            합리적인 PT 플랫폼
          </Text>
        </div>
      </AlignContent>
    </div>
  </CarouselItem>
];

export const SignUpCoachFinal = () => {
  const resetSignupState = useResetRecoilState($signupState);
  const [idx, setIdx] = useState<number>(0);
  const slideRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { blockedPagePath, setBlockedPagePath } = sessionStorageManager();

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    if (target) {
      const scrollPosition = target.scrollLeft / (target.clientWidth / 3) / 3;
      const newIndex = Math.round(scrollPosition);
      if (newIndex !== idx) {
        setIdx(newIndex);
      }
    }
  };

  const handleButtonClick = () => {
    resetSignupState();
    navigate(blockedPagePath || PATH.HOME);
    setBlockedPagePath('');
  };

  return (
    <Container onScroll={(e: React.UIEvent<HTMLDivElement>) => handleScroll(e)}>
      <CarouselSlide count={CarouselItems.length} ref={slideRef}>
        {CarouselItems.map((item, index) => (
          <div key={index} style={{ width: '100%' }}>
            {item}
          </div>
        ))}
      </CarouselSlide>
      <BottomProgressContainer>
        <ProgressDotContainer>
          {Array.from({ length: CarouselItems.length }, (_, index) => (
            <ProgresDot activate={idx === index} />
          ))}
        </ProgressDotContainer>
        <ButtonContainer>
          <Button onClick={handleButtonClick}>Fibud 시작하기</Button>
        </ButtonContainer>
      </BottomProgressContainer>
    </Container>
  );
};
