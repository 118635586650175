import React, { useState } from 'react';
import { Button, ItemPicker, ModalPortal, Text } from '#atoms';
import { FibudContainerTemplate } from '#templates';
import { Id } from '#types/confirmationForms';
import { IGetDiseaseKinds } from '#types/diseaseKinds';
import { IGetPainPartKinds } from '#types/painPartKinds';
import { SelectPageContainer, TextContainer } from './styles';
import { HealthData } from '.';

interface Props {
  onComplete: (healthdata: HealthData) => void;
  data: IGetDiseaseKinds | IGetPainPartKinds | undefined;
  type: 'disease' | 'pain';
}

export const HealthSelectPage = ({ onComplete, data, type }: Props) => {
  const [selectedValues, setSelectedValues] = useState<Id[]>([]);
  const [etcValue, setEtcValue] = useState<string | null>(null);

  return (
    <ModalPortal>
      <SelectPageContainer>
        <FibudContainerTemplate type="paddingWithBottomButton">
          <FibudContainerTemplate type="bottomButtonContainer">
            <TextContainer className="padding">
              <Text textStyle="t1">
                {type === 'disease'
                  ? '회원님이 앓고 계신'
                  : '운동에 영향을 끼치는'}
              </Text>
              <TextContainer className="annotation">
                <Text textStyle="t1">
                  {type === 'disease'
                    ? '질환을 선택해주세요'
                    : '부위를 선택해주세요'}
                </Text>
                <Text textStyle="c1" textColor="gray2" type="inline">
                  중복선택 가능
                </Text>
              </TextContainer>
            </TextContainer>
            <ItemPicker
              data={data?.data ?? []}
              hasManualInput={true}
              allowDuplicates={true}
              setSelectedItemsList={setSelectedValues}
              setInputItem={setEtcValue}
            />
          </FibudContainerTemplate>
          <FibudContainerTemplate type="bottomButton">
            <Button
              disabled={!selectedValues.length && !etcValue}
              onClick={() =>
                onComplete(
                  type === 'disease'
                    ? {
                        diseases: selectedValues.map(diseaseId => ({
                          diseaseKindId: diseaseId?.toString() ?? ''
                        })),
                        diseaseEtc: etcValue || ''
                      }
                    : {
                        painParts: selectedValues.map(painPartId => ({
                          kindId: painPartId?.toString() ?? ''
                        })),
                        painPartEtc: etcValue || ''
                      }
                )
              }
            >
              선택
            </Button>
          </FibudContainerTemplate>
        </FibudContainerTemplate>
      </SelectPageContainer>
    </ModalPortal>
  );
};
