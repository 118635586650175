import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';

export const getMyUrlFromCoachId = async (coachId: string) => {
  const res = await request<{ myUrl: string }>({
    method: 'get',
    url: `/my-url/${coachId}`
  });

  return res.data;
};

export const useGetMyUrlFromCoachId = (coachId: string) => {
  return useQuery(
    queryKeyFactory.GET_MYURL_FROM_COACHID(coachId),
    () => getMyUrlFromCoachId(coachId),
    { enabled: !!coachId }
  );
};

export const getCoachIdFromMyUrl = async (myUrl: string) => {
  const res = await request<string>({
    method: 'get',
    url: `/my-url/coachId`,
    params: {
      myUrl
    }
  });

  return res.data;
};

export const useGetCoachIdFromMyUrl = (myUrl: string) => {
  return useQuery(
    queryKeyFactory.GET_COACHID_FROM_MYURL(myUrl),
    () => getCoachIdFromMyUrl(myUrl),
    { enabled: !!myUrl }
  );
};

export const postMyUrl = async (myUrl: string) => {
  const res = await request<string>({
    method: 'post',
    url: `/my-url`,
    data: {
      myUrl
    }
  });

  return res.data;
};

export const usePostMyUrl = () => {
  const queryClient = useQueryClient();
  return useMutation(postMyUrl, {
    onSuccess: () => {
      queryClient.invalidateQueries(['MYURLFROMCOACHID', 'COACHIDFROMMYURL']);
    }
  });
};

export const patchMyUrl = async (myUrl: string) => {
  const res = await request<string>({
    method: 'patch',
    url: `/my-url`,
    data: {
      myUrl
    }
  });

  return res.data;
};

export const usePatchMyUrl = () => {
  const queryClient = useQueryClient();
  return useMutation(patchMyUrl, {
    onSuccess: () => {
      queryClient.invalidateQueries(['MYURLFROMCOACHID', 'COACHIDFROMMYURL']);
    }
  });
};

export const deleteMyUrl = async () => {
  const res = await request<string>({
    method: 'delete',
    url: `/my-url`
  });

  return res.data;
};

export const useDeleteMyUrl = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteMyUrl, {
    onSuccess: () => {
      queryClient.invalidateQueries(['MYURLFROMCOACHID', 'COACHIDFROMMYURL']);
    }
  });
};
