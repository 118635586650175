import { useCallback, useState } from 'react';
import { IDegree } from '#types/account';

export interface Degree extends IDegree {
  id: string;
}

interface Props {
  currentDegrees?: Degree[];
}

export const useDegrees = ({ currentDegrees }: Props) => {
  const [degrees, setDegrees] = useState<Array<Degree>>(currentDegrees ?? []);

  const appendDegree = useCallback(
    (d: Degree) => setDegrees(prev => [...prev, d]),
    [degrees]
  );

  const removeDegree = useCallback(
    (id: string) => setDegrees(prev => prev.filter(c => c.id !== id)),

    [degrees]
  );

  return {
    degrees,
    setDegrees,
    appendDegree,
    removeDegree
  };
};
