import styled from 'styled-components';

export const ChatListContainer = styled.ul`
  width: 100%;
  padding: 0 2rem;

  & > li {
    padding: 1.5rem 0;
    border-bottom: 1px solid var(--color-border);

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const UnReadCount = styled.span`
  min-width: 3rem;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  background-color: var(--color-point);
  border-radius: 1.5rem;
  padding: 0 0.5rem;
`;

export const MessageBox = styled.div`
  display: flex;
  gap: 1rem;

  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
