import styled from 'styled-components';

export const Container = styled.ul`
  display: block;
  width: 100%;
  overflow-x: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const TimeStatusTable = styled.ul`
  & > li {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .block {
      display: inline-block;
      width: 3.7rem;
      height: 1.5rem;
      margin-left: 0.5rem;
      border-radius: 0.4rem;
    }

    margin-bottom: 0.4rem;
  }
`;

export const TimeList = styled.ul`
  width: 100%;
  padding: 0.5rem 0 1rem;

  & > li {
    display: inline-block;
    width: calc(25% - 0.4rem);
    border-radius: 0.8rem;
    background-color: var(--color-white);
    color: var(--color-black);
    text-align: center;
    line-height: 3.6rem;
    margin: 0 0.5rem 0.5rem 0;

    &:nth-child(4n) {
      margin-right: 0;
    }

    &.disabled {
      background-color: var(--color-gray-second);
      color: var(--color-gray-text-second);
    }

    &.reserved {
      background-color: var(--color-point);
      color: var(--color-white);
    }

    &.on {
      background-color: var(--color-point-light);
      color: var(--color-white);
    }
  }
`;
