import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { modifyLecturesIndex } from '#api/lectures';
import { BoxIcon, Text } from '#atoms';
import { PATH } from '#const/path';
import useDrag from '#hooks/useDrag';
import { useCoachLecture } from '#providers/CoachLecture';
import {
  BodyArea,
  ContentArea,
  HeaderContainer,
  LectureContainer,
  TitleArea
} from './styles';

interface Props {
  items: {
    lectureId: string;
    title: string;
    body: string;
    isTitle?: boolean;
  }[];
}

export const CoachClassSummary = ({ items }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(modifyLecturesIndex);

  const { setLectureId } = useCoachLecture();
  const { list, setList, dragStart, dragEnter, drop } = useDrag();

  useEffect(() => {
    list.length > 0 &&
      mutateAsync(
        {
          payload: {
            array: [...list]
          }
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['LECTURES']);
          }
        }
      );
  }, [...list]);

  useEffect(() => {
    const initialList = items.map(item => item.lectureId);
    setList(initialList);
  }, [items]);

  return (
    <>
      <HeaderContainer>
        <Text textStyle="t1">정규 수업을 등록해 주세요</Text>
      </HeaderContainer>
      <ContentArea>
        {items.map((item, index) => (
          <LectureContainer
            key={index}
            className="item"
            draggable
            onDragStart={() => dragStart(index)}
            onDragEnter={() => dragEnter(index)}
            onDragEnd={drop}
            onClick={() => {
              navigate(PATH.PROFILE_CLASS_DETAIL);
              setLectureId(item.lectureId);
            }}
          >
            <TitleArea>
              <Text textStyle="t3">{item.title}</Text>
              {item.isTitle && (
                <Text textStyle="c1" type="inline">
                  대표강의
                </Text>
              )}
            </TitleArea>
            <BodyArea>
              <Text>{item.body}</Text>
            </BodyArea>
          </LectureContainer>
        ))}
        <LectureContainer
          className="add-item"
          onClick={() => navigate(PATH.PROFILE_CLASS_FORM)}
        >
          <BoxIcon type="plus-white" onlyIcon={true} size={25} />
          <Text>수업 추가</Text>
        </LectureContainer>
      </ContentArea>
    </>
  );
};
