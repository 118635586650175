import dayjs from 'dayjs';
import 'dayjs/locale/ko'; // If localization is needed
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.locale('ko'); // Set locale if necessary
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

export interface DateDetails {
  year: number;
  month: number;
  weekOfMonth: number;
}

export function getWeekOfMonth(date: dayjs.Dayjs): number {
  const firstDayOfMonth = date.startOf('month');
  const firstDayOfWeek = firstDayOfMonth.isoWeekday(); // 1: Monday, 7: Sunday
  const currentDay = date.date();

  // Calculate current week number
  const weekOfMonth = Math.ceil((currentDay + firstDayOfWeek - 1) / 7);

  return weekOfMonth;
}

export function getSundayOneWeekPrior(
  year: number,
  month: number,
  weekNumber: number
): string {
  const firstDayOfMonth = dayjs(`${year}-${month}-01`);

  // 월의 첫 번째 일요일 찾기
  let firstSunday =
    firstDayOfMonth.day() === 0
      ? firstDayOfMonth
      : firstDayOfMonth.add(7 - firstDayOfMonth.day(), 'day');

  // 첫 번째 일요일이 이전 달에 속하는 경우 다음 주 일요일로 조정
  if (firstSunday.month() + 1 !== month) {
    firstSunday = firstSunday.add(7, 'day');
    weekNumber--; // 주차 번호 조정
  }

  const sundayBeforeGivenWeek = firstSunday.add(
    (weekNumber - 1) * 7 - 7,
    'day'
  );

  // 계산된 일요일이 다음 달에 속하는 경우 처리
  if (sundayBeforeGivenWeek.month() + 1 !== month) {
    if (weekNumber === 1) {
      // 첫 번째 주가 이전 달에 시작되는 경우
      return firstSunday.subtract(7, 'day').format('YYYY-MM-DD');
    } else {
      return '주어진 주차의 일요일이 해당 월에 없습니다.';
    }
  }

  return sundayBeforeGivenWeek.format('YYYY-MM-DD');
}

export function getNextSunday(startDateWeek: string): string {
  const nextSunday = dayjs(startDateWeek).add(7, 'day').format('YYYY-MM-DD');
  return nextSunday;
}
