import React from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useCheckValidState } from '#hooks/useCheckValidState';
import { SignUpPasswordInput } from '#organisims';
import { $signupState } from '#states/atoms/recoilSignupState';
import { PrevWithTitleTextTemplate } from '#templates';
import { ButtonContainer } from './styles';

export function SignUpPassword() {
  const [signupState, setSignupState] = useRecoilState($signupState);
  const navigate = useNavigate();

  useCheckValidState({
    targetState: typeof signupState.isCoach === 'boolean',
    navigateTo: PATH.SIGN_UP_CATEGORY
  });

  const handlePasswordChange = (password: string | null) =>
    setSignupState(prev => ({ ...prev, password }));

  return (
    <PrevWithTitleTextTemplate
      prevRoute={PATH.SIGN_UP_ID}
      title={
        <Text textStyle="display">
          비밀번호를
          <br />
          입력해주세요
        </Text>
      }
    >
      <SignUpPasswordInput
        value={signupState.password}
        setUserPassword={handlePasswordChange}
      />
      <ButtonContainer>
        <Button
          disabled={!signupState.password}
          onClick={() => navigate(PATH.SIGN_UP_NICKNAME)}
        >
          다음
        </Button>
      </ButtonContainer>
    </PrevWithTitleTextTemplate>
  );
}
