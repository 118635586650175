import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { deleteLecture, useGetLecture } from '#api/lectures';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { TitleItemBox } from '#molecules';
import {
  convertToLectureContext,
  useCoachLecture
} from '#providers/CoachLecture';
import { useToast } from '#providers/ToastProvider';
import { formatNumberWithCommas } from '#utils/Numbers';
import {
  ButtonContainer,
  Container,
  ContentArea,
  HeaderContainer
} from './styles';

interface Props {
  lectureId: string;
  onClickModify: () => void;
}

export const CoachOTDetailView = ({ lectureId, onClickModify }: Props) => {
  const { showToast } = useToast();

  const { data } = useGetLecture(lectureId);
  const lecture = data && convertToLectureContext(data);
  const { setLecture } = useCoachLecture();

  const navigate = useNavigate();

  const { mutateAsync } = useMutation(deleteLecture);

  const handleDeleteButtonClick = async () => {
    try {
      await mutateAsync(lectureId);
      showToast({
        message: '체험 수업 삭제에 성공하였습니다.'
      });
      navigate(PATH.PROFILE_UPDATE);
    } catch (error) {
      showToast({
        type: 'fail',
        message: '체험 수업 삭제에 실패하였습니다.'
      });
    }
  };

  if (lecture) {
    return (
      <Container>
        <HeaderContainer>
          <Text textStyle="display">체험 수업입니다</Text>
        </HeaderContainer>
        <ContentArea>
          <TitleItemBox title="수업 소개">
            <Text>{lecture.body}</Text>
          </TitleItemBox>
          <TitleItemBox title="소비자 가격">
            <Text>{formatNumberWithCommas(lecture.price)}원</Text>
          </TitleItemBox>
          <TitleItemBox title="수업 횟수">
            <Text>{lecture.rounds}회</Text>
          </TitleItemBox>
        </ContentArea>
        <ButtonContainer>
          <Button background="anti" onClick={handleDeleteButtonClick}>
            삭제
          </Button>
          <Button
            onClick={() => {
              setLecture(lecture);
              onClickModify();
            }}
          >
            수정
          </Button>
        </ButtonContainer>
      </Container>
    );
  }
  return <></>;
};
