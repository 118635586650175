import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useGetTags } from '#api/tags';
import Dumbbell from '#assets/img/Dumbell_02.png';
import posture_fix from '#assets/img/posture_fix.png';
import Trademill from '#assets/img/Trademill_02.png';
import WeightMachine from '#assets/img/WeightMachine_03.png';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { LocationTitleBox } from '#molecules';
import { useAttendingLectures } from '#providers/AttendLecture';
import { $selectedCoachTagState } from '#states/atoms/tagState';
import { BannerText } from './const';
import {
  BoxContainer,
  Container,
  IntroContainer,
  GridBox,
  TextArea,
  TitleBox
} from './styles';

interface Props {
  type:
    | 'location'
    | 'diet'
    | 'body_profile'
    | 'posture_fix'
    | 'cardio'
    | 'schedule'
    | 'intro';
}

const COACH_TYPE = [
  { type: 'diet', name: '다이어트' },
  { type: 'body_profile', name: '보디빌딩' },
  { type: 'posture_fix', name: '체형교정' },
  { type: 'cardio', name: '체력증진' }
];

export const MainHomeBanner = ({ type }: Props) => {
  const navigate = useNavigate();
  const { data } = useGetTags();

  const { hasTakenStatusIngLecture } = useAttendingLectures();
  const setSelectedCoachTag = useSetRecoilState($selectedCoachTagState);
  const [, setSearchParams] = useSearchParams();

  const handleBannerClick = () => {
    const selectedCoachTagName = COACH_TYPE.find(coach => coach.type === type)
      ?.name;
    const selectedCoachTagId =
      data && data.data.find(e => e.name === selectedCoachTagName)?.id;

    if (selectedCoachTagId && selectedCoachTagName) {
      setSelectedCoachTag({
        id: selectedCoachTagId,
        name: selectedCoachTagName
      });
      setSearchParams({ tab: '2' });
    }
  };

  if (type === 'intro') {
    return (
      <IntroContainer onClick={() => navigate(PATH.HOME_INTRODUCTION)}>
        <div>
          <Text>피벗이 다른 이유</Text>
          <Text textStyle="display">Fibud Exclusive.</Text>
        </div>
        <BoxIcon type="next-arrow" onlyIcon size={16} />
      </IntroContainer>
    );
  }

  if (type === 'location') {
    return (
      <Container type={type}>
        <TitleBox>
          <LocationTitleBox />
        </TitleBox>
      </Container>
    );
  }

  if (type === 'schedule') {
    return (
      <Container type={type}>
        <TitleBox>
          <BoxContainer type={type}>
            <TextArea>
              <Text textStyle="t3" textColor="point">
                {BannerText[type].top_text}
              </Text>
              <Text textColor="black">{BannerText[type].title}</Text>
            </TextArea>
            <Button
              onClick={() =>
                navigate(PATH.MYPAGE_SCHEDULES, {
                  state: { prevLocation: location.pathname + location.search }
                })
              }
            >
              {hasTakenStatusIngLecture
                ? '수강신청서 등록하기'
                : BannerText[type].bottom_text}
            </Button>
          </BoxContainer>
        </TitleBox>
      </Container>
    );
  }

  return (
    <Container type={type}>
      <TitleBox>
        <BoxContainer
          type={type}
          style={{
            backgroundImage:
              type === 'posture_fix'
                ? `linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)
                  , url(${posture_fix})`
                : undefined
          }}
          onClick={handleBannerClick}
        >
          <TextArea>
            <Text
              textColor={
                ['diet', 'body_profile'].includes(type)
                  ? 'dark'
                  : type === 'posture_fix'
                  ? 'gray2'
                  : undefined
              }
            >
              {BannerText[type].top_text}
            </Text>
            <Text
              textStyle="display"
              textColor={
                ['diet', 'body_profile'].includes(type) ? 'black' : undefined
              }
            >
              {BannerText[type].title}
            </Text>
          </TextArea>
          <GridBox>
            <Text
              textColor={
                ['diet', 'body_profile'].includes(type)
                  ? 'dark'
                  : type === 'posture_fix'
                  ? 'gray2'
                  : undefined
              }
              textStyle="c1"
            >
              {BannerText[type].bottom_text}
            </Text>
            <img
              src={
                type === 'diet'
                  ? WeightMachine
                  : type === 'body_profile'
                  ? Dumbbell
                  : type === 'cardio'
                  ? Trademill
                  : undefined
              }
            />
          </GridBox>
        </BoxContainer>
      </TitleBox>
    </Container>
  );
};
