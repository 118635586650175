import styled from 'styled-components';

export const ItemButton = styled.button`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 1rem;
  width: 100%;
  border-bottom: 1px solid var(--color-gray-text);
`;

export const ClinicalDataContainer = styled.div`
  padding: 2rem;
  display: inline-block;
  width: 100%;
  border-top: 1rem solid var(--color-gray-third);
  border-bottom: 1rem solid var(--color-gray-third);

  > div {
    overflow-x: auto;
    display: flex;
    gap: 1rem;
    align-items: center;
    scroll-snap-type: x mandatory;
  }
`;

export const ButtonContainer = styled.div`
  padding: 2rem;
`;
