import React, { useEffect, useState } from 'react';
import { RatingStarBlank, RatingStarFill } from '#assets/svg';
import { StarRaterWrap } from './styles';

interface Props {
  rating: number;
  onInputRating: (value: number) => void;
}

export const StarRater = ({ rating, onInputRating }: Props) => {
  const [starsList, setStarsList] = useState<JSX.Element[]>(
    Array(5).fill(<RatingStarBlank />)
  );

  const handleStarClick = (value: number) => {
    if (rating === value) return;
    onInputRating(value);
  };

  useEffect(() => {
    if (!rating) return;

    setStarsList(
      Array(5)
        .fill(0)
        .map((_, index) =>
          index < rating ? <RatingStarFill /> : <RatingStarBlank />
        )
    );
  }, [rating]);

  return (
    <StarRaterWrap>
      {starsList.map((star, index) => (
        <div key={index} onClick={() => handleStarClick(index + 1)}>
          {star}
        </div>
      ))}
    </StarRaterWrap>
  );
};

export default StarRater;
