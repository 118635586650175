import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  button {
    height: 100%;
    padding: 1rem;
  }
`;
