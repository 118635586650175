import { useNavigate } from 'react-router';
import { Text } from '#atoms';
import { PATH } from '#const/path';
import { DarkPatternButtons } from '#molecules';
import { PrevHeaderBarTemplate } from '#templates';
import { DarkPattenButtonContainer, List, TitleContainer } from './styles';

export function MyPageWithdrawalPrecaution() {
  const navigate = useNavigate();
  return (
    <PrevHeaderBarTemplate prevRoute={PATH.MYPAGE}>
      <TitleContainer>
        <Text textStyle="t1">
          탈퇴하시기 전에
          <br />
          잠시만요!
        </Text>
        <Text textStyle="c1">회원탈퇴 전 아래 사항들을 확인해주세요</Text>
      </TitleContainer>
      <List data-text-type="c1">
        <li>
          탈퇴 후 수강권, 포인트, 구폰 등 모든 회원이 가진 권리는 소멸되어
          복구가 불가능합니다.
        </li>
        <li>
          탈퇴 후에는 구매했던 모든 수강권 및 상품의 환불처리가 불가능합니다.
        </li>
        <li>
          탈퇴 후 커뮤니티 내에 작성한 모든 게시물과 댓글은 삭제 처리가
          불가합니다.
        </li>
      </List>
      <DarkPattenButtonContainer>
        <DarkPatternButtons
          pointButtonText="회원 유지하기"
          darkButtonText="회원 탈퇴"
          onDarkButtonClick={() => navigate(PATH.MYPAGE_WITHDRAWAL_EXPLANATION)}
          onPointButtonClick={() => navigate(PATH.MYPAGE)}
        />
      </DarkPattenButtonContainer>
    </PrevHeaderBarTemplate>
  );
}
