import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CarouselArea = styled.div`
  display: flex;
  gap: 2rem;
  padding: 1.25rem 2rem 0;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const IndexArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem;
  gap: 0.25rem;
  width: 100%;
  height: 2rem;
`;

export const IndexIcon = styled.div`
  width: 0.25rem;
  height: 0.25rem;
  background-color: var(--color-gray-text-second);
  border-radius: 50%;

  &.point {
    width: 1rem;
    border-radius: 0.5rem;
    background-color: var(--color-point);
  }
`;

export const MyLectureBoxContainer = styled.div`
  flex-shrink: 0;
  scroll-snap-align: center;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.5rem;
  background: var(--color-point-gradient);

  &.none {
    background: var(--color-point-gradient-alpha40);

    & > div {
      align-items: center;
      gap: 0.5rem;
      padding: 2rem 0;
    }
  }
`;

export const MyLectureBoxArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  padding: 1rem;
  color: var(--color-white);
`;

export const TextButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const LectureInfoArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
