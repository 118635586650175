import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const PasswordInputArea = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const MessageArea = styled.div`
  color: var(--color-white);
  text-align: right;
`;
