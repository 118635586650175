import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { getSettlementsInfo } from '#api/settlements';
import { IGetSettlementsInfo, IPostSettlements } from '#types/settlements';

interface Props {
  children?: React.ReactNode;
}

interface CoachSettlementsInfoContext {
  getTotalSettlementsInfo: () => void;
  isLoading: boolean;
  isError: boolean;
  settlementsInfo: IGetSettlementsInfo | Record<string, never>;
  settlementRequest: IPostSettlements;
  setSettlementRequest: React.Dispatch<React.SetStateAction<IPostSettlements>>;
}

const CoachSettlementsInfoContext =
  React.createContext<CoachSettlementsInfoContext | null>(null);

export const useCoachSettlementsInfo = () => {
  const context = React.useContext(CoachSettlementsInfoContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <CoachSettlementsInfo> component.'
    );
  }
  return context;
};

function CoachSettlementsInfo({ children }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [settlementsInfo, setSettlementsInfo] = useState<
    IGetSettlementsInfo | Record<string, never>
  >({});
  const [settlementRequest, setSettlementRequest] = useState<IPostSettlements>({
    type: 'WITHDRAWAL'
  });

  const getTotalSettlementsInfo = () => {
    getSettlementsInfo()
      .then(data => {
        setIsError(false);
        setSettlementsInfo(data);
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        if (error.response?.status === 400) return; // 정산 내역이 없는 경우
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const memorizedValue = React.useMemo(
    () => ({
      getTotalSettlementsInfo,
      isLoading,
      isError,
      settlementsInfo,
      settlementRequest,
      setSettlementRequest
    }),
    [isLoading, isError, settlementsInfo, settlementRequest]
  );

  return (
    <CoachSettlementsInfoContext.Provider value={memorizedValue}>
      {children}
    </CoachSettlementsInfoContext.Provider>
  );
}

function IsLoading({ children }: Props) {
  const { isLoading } = useCoachSettlementsInfo();
  return isLoading ? <>{children}</> : null;
}

function IsError({ children }: Props) {
  const { isLoading, isError } = useCoachSettlementsInfo();
  return !isLoading && isError ? <>{children}</> : null;
}

function IsEmpty({ children }: Props) {
  const { isLoading, isError, settlementsInfo } = useCoachSettlementsInfo();
  return !isLoading && !isError && Object.keys(settlementsInfo).length === 0 ? (
    <>{children}</>
  ) : null;
}

function HasData({ children }: Props) {
  const { isLoading, isError, settlementsInfo } = useCoachSettlementsInfo();
  return !isLoading && !isError && Object.keys(settlementsInfo).length > 0 ? (
    <>{children}</>
  ) : null;
}

CoachSettlementsInfo.IsError = IsError;
CoachSettlementsInfo.IsLoading = IsLoading;
CoachSettlementsInfo.IsEmpty = IsEmpty;
CoachSettlementsInfo.HasData = HasData;

export default CoachSettlementsInfo;
