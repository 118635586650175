import { useEffect, useState } from 'react';
import { Button } from '#atoms';
import { Sex } from '#types/account';
import { Container } from './styles';

interface Props {
  value?: Sex | null;
  setUserSex: (sex: Sex | null) => void;
}

export const SignUpGenderSelector = ({ value, setUserSex }: Props) => {
  const [sex, setSex] = useState<Sex | null>(value ?? null);

  useEffect(() => {
    setUserSex(sex);
  }, [sex]);

  return (
    <Container>
      <Button
        background={sex !== 'MALE' ? 'anti' : undefined}
        onClick={() => setSex('MALE')}
      >
        남성
      </Button>
      <Button
        background={sex !== 'FEMALE' ? 'anti' : undefined}
        onClick={() => setSex('FEMALE')}
      >
        여성
      </Button>
    </Container>
  );
};
