import styled from 'styled-components';

export const Container = styled.ul`
  width: 100%;

  & p {
    margin-top: 0.5rem;
  }
`;

export const BABox = styled.li`
  width: 100%;
`;

export const BAImageBox = styled.ul`
  width: 100%;
  overflow-x: auto;
`;

export const BAImageContainer = styled.li`
  position: relative;
  display: inline-block;
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-right: 5px;

  &:last-child {
    margin: 0;
  }
`;

export const BAImageTypeBox = styled.span`
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 4px;
  color: var(--color-white);
  background-color: var(--color-black-alpha50);
  text-align: center;
  line-height: 1.5;
`;
