import dayjs from 'dayjs';
import { useLocation } from 'react-router';
import { Spacing, Text } from '#atoms';
import { SEX } from '#const/confirmationForms/enum';
import { PATH } from '#const/path';
import { TitleItemBox } from '#molecules';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { IGetConfirmationForm } from '#types/confirmationForms';
import { exerciseCareerFormatter } from '#utils/dateFormatter';
import { sleepQualityToText } from '#utils/sleepQuality';
import { Container, ItemContainer } from './styles';

export function MyPageApplicationsDetail() {
  const location = useLocation();
  const details: IGetConfirmationForm = location.state.confirmationForm;

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.MYPAGE_APPLICATIONS}
      title="나의 신청서"
      headerbarType="relative"
    >
      <Container>
        <div>
          <Text textStyle="t2">{details.person.name}</Text>
          <Text textStyle="t3" textColor="gray2">
            {dayjs().diff(dayjs(details.person.birthday), 'year')}세,{' '}
            {SEX[details.person.sex]}
          </Text>
        </div>
        <div>
          <ItemContainer>
            <TitleItemBox title="운동경력">
              <li data-text-color="gray2">
                {exerciseCareerFormatter(details.person.startAthletic)}
              </li>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="신장">
              <li data-text-color="gray2">{details.healthInfo.height}cm</li>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="몸무게">
              <li data-text-color="gray2">{details.healthInfo.weight}kg</li>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="PT 수강 목적">
              {details.purposes.map((item, idx) => (
                <li data-text-color="gray2" key={idx}>
                  {item.name}
                </li>
              ))}
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="수면 정보">
              <div>
                <li data-text-color="gray2">
                  {sleepQualityToText(details?.healthInfo?.qualityOfSleep ?? 0)}
                </li>
                <li data-text-color="gray2">
                  {details?.healthInfo?.sleepingHourAvg}시간
                </li>
              </div>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="직업">
              <li data-text-color="gray2">{details.person.jobName}</li>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="건강 상태">
              <div data-text-color="gray2">
                <ul>질환 정보</ul>
                {details.healthInfo.diseases?.map((value, idx) => (
                  <li key={idx}>- {value.kind.name}</li>
                ))}
                {details.healthInfo.diseaseEtc && (
                  <li>- {details.healthInfo.diseaseEtc}</li>
                )}
                <Spacing size={20} />
                <ul>통증 부위</ul>
                {details.healthInfo.painParts?.map((value, idx) => (
                  <li key={idx}>- {value.kind.name}</li>
                ))}
                {details.healthInfo.painPartEtc && (
                  <li>- {details.healthInfo.painPartEtc}</li>
                )}
              </div>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="추가설명 및 문의사항">
              {details?.etc && (
                <li data-text-color="gray2" style={{ whiteSpace: 'pre-wrap' }}>
                  {details.etc}
                </li>
              )}
            </TitleItemBox>
          </ItemContainer>
        </div>
      </Container>
    </PrevHeaderBarWithTitleTemplate>
  );
}
