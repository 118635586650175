import styled from 'styled-components';

export const FloatingBoxContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  text-align: right;

  &.open {
    z-index: 30;
  }
`;

export const FloatingButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4.4rem;
  height: 4.4rem;
  background-color: var(--color-point);
  border-radius: 50%;

  &.open {
    background-color: var(--color-gray-second);
  }
`;

export const FloatingBackground = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-black-alpha50);
  z-index: 10;
`;

export const FloatingItemList = styled.ul`
  display: flex;
  width: 20rem;
  flex-direction: column;
  text-align: center;
`;

export const FloatingItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0 3rem;
  width: 100%;
  height: 4.4rem;
  border-radius: 4.4rem;
  background-color: var(--color-point);
  margin-bottom: 1rem;

  & > p {
    width: calc(100% - 3rem);
    text-align: center;
  }
`;
