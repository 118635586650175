import styled from 'styled-components';

interface TabProps {
  tabList: string[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

export const Tab = ({ tabList, setSelectedTab, selectedTab }: TabProps) => {
  const onClickTab = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <div className="flex w-full pb-20">
      {tabList.map(currentTab => (
        <StyledTab
          key={currentTab}
          isSelected={currentTab === selectedTab}
          onClick={() => onClickTab(currentTab)}
        >
          {currentTab}
        </StyledTab>
      ))}
    </div>
  );
};

const StyledTab = styled.div<{ isSelected: boolean }>`
  color: ${({ isSelected }) => (isSelected ? '#BCB8C3' : '#756F7E')};
  border-bottom: 1px solid
    ${({ isSelected }) => (isSelected ? '#7750E9' : 'transparent')};

  /* NEW/body_01_medium */
  font-family: 'Pretendard Variable';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
`;
