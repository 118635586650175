import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-black-alpha75);
  z-index: 999;
  max-width: var(--max-width);
  margin: 0 auto;
`;

export const QRModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 19.5rem;
  height: 23.5rem;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 1.75rem;
  background-color: var(--color-white);
  border-radius: 1.25rem;

  & > div {
    text-align: center;
    color: var(--color-gray);
  }
`;

export const QRBox = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 13rem;
  height: 13rem;
  border: 6px solid var(--color-point);
  border-radius: 1.75rem;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
