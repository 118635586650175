import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { BorderlessInput } from '#molecules';
import { useCoachSettlementsInfo } from '#providers/CoachSettlementsInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { formatNumberWithCommas, isNumeric } from '#utils/Numbers';
import { ButtonContainer, Container, InfoContainer } from './styles';

export function BillingRequestPage() {
  const navigate = useNavigate();
  const { settlementsInfo, setSettlementRequest } = useCoachSettlementsInfo();
  const [settlementPrice, setSettlementPrice] = useState<number | undefined>(
    undefined
  );
  const isValid =
    settlementPrice !== undefined &&
    settlementPrice > 0 &&
    settlementPrice <= settlementsInfo.price;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') return setSettlementPrice(undefined);

    const valueRaw = value.replaceAll(',', '');
    if (isNumeric(valueRaw)) setSettlementPrice(Number(valueRaw));
  };

  const handleNextButtonClick = () => {
    setSettlementRequest(prev => ({
      ...prev,
      price: settlementPrice,
      bankName: settlementsInfo.bankName,
      accountNumber: settlementsInfo.accountNumber
    }));
    navigate(PATH.BILLING_REQUEST_INVOICE);
  };

  return (
    <Container>
      <PrevHeaderBarWithTitleTemplate prevRoute={PATH.BILLING} title="정산하기">
        <InfoContainer>
          <div>
            <Text textStyle="t3">정산 가능 금액</Text>
            <Text>{`${formatNumberWithCommas(settlementsInfo.price)}원`}</Text>
          </div>
          <div>
            <Text textStyle="t3">정산하실 금액을 입력해주세요</Text>
            <div>
              <BorderlessInput
                className={
                  settlementPrice !== undefined &&
                  settlementPrice > settlementsInfo.price
                    ? 'invalid'
                    : undefined
                }
                placeholder={`최대 ${formatNumberWithCommas(
                  settlementsInfo.price
                )}원`}
                value={
                  settlementPrice !== undefined
                    ? formatNumberWithCommas(settlementPrice)
                    : ''
                }
                maxLength={11} // 입력 11자리로 제한
                textAlign="center"
                onChange={handleInputChange}
              />
              <Text>원</Text>
            </div>
          </div>
          <div>
            <Text textStyle="t3">세금 공제 이후 정산액(3.3%)</Text>
            <Text>{`${formatNumberWithCommas(
              settlementPrice !== undefined
                ? Math.floor(settlementPrice * (1 - 0.033))
                : 0
            )}원`}</Text>
          </div>
          <div>
            <Text textStyle="t3">정산받을 계좌를 확인해주세요</Text>
            <Text>{`${settlementsInfo.bankName} ${settlementsInfo.accountNumber}`}</Text>
          </div>
        </InfoContainer>
        <ButtonContainer>
          <Button onClick={handleNextButtonClick} disabled={!isValid}>
            다음
          </Button>
        </ButtonContainer>
      </PrevHeaderBarWithTitleTemplate>
    </Container>
  );
}
