import styled from 'styled-components';

export const ArrowBigButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 0;
  gap: 1rem;

  svg {
    stroke: var(--color-gray-text-second);
  }
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Label = styled.p`
  color: var(--color-white);
`;

export const Status = styled.p`
  color: var(--color-gray-text-second);
`;
