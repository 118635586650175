import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 3rem 2rem 11.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  > :nth-child(3) {
    display: flex;
    flex-direction: column;
    row-gap: 1.4rem;
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 3rem 2rem;
  height: 11.3rem;
  background-color: var(--color-background);
`;

export const CheckListContainer = styled.div`
  display: flex;
  column-gap: 1.2rem;
  align-items: center;
`;

export const CheckBox = styled.div`
  background-color: var(--color-gray-second);
  height: 1.6rem;
  width: 1.6rem;
  align-self: center;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;

  &.checked {
    background-color: var(--color-point);
  }
`;
