import dayjs from 'dayjs';
import { RegisteredLectureStatus } from '#types/registeredLectures';

export const timeFormatter = (utcTime: string): string => {
  const timestamp = Date.parse(utcTime);
  const hours = Math.floor(timestamp / 3600000);
  const minutes = Math.floor((timestamp % 3600000) / 60000);

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};

export const getCoachCareer = (date?: string) => {
  return date ? new Date().getFullYear() - new Date(date).getFullYear() : 0;
};

export const dayPeriodTimeFormatter = (time: string) => {
  // 오전/오후 hh:mm
  dayjs.locale('ko');
  const date = dayjs(time);
  const meridiem = date.format('A');
  const hours = date.format('h');
  const minutes = date.format('mm');
  return meridiem + ' ' + hours + ':' + minutes;
};

export const getRegisteredLectureStatus = (
  date: string
): RegisteredLectureStatus => {
  return dayjs(date).isBefore(dayjs(), 'hour')
    ? 'COMPLETED'
    : dayjs(date).isSame(dayjs(), 'hour')
    ? 'ONGOING'
    : 'UPCOMING';
};

export function getCurrentFormattedTime() {
  const now = dayjs();
  const minutes = now.minute();

  if (minutes >= 45 || minutes < 15) {
    return minutes >= 45
      ? now.add(1, 'hour').minute(0).format('HH:mm')
      : now.minute(0).format('HH:mm');
  } else {
    return now.minute(30).format('HH:mm');
  }
}

export function createArrayFromNumber(num: number): string[] {
  return Array.from({ length: num }, (_, index) => String(index + 1));
}
