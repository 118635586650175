import classNames from 'classnames';
import React from 'react';
import { ButtonArea, Circle } from './styles';

interface Props {
  isActive: boolean;
  text: string;
  onClick: () => void;
}

export const ToggleWithCaptionButton = ({ isActive, text, onClick }: Props) => {
  return (
    <div className="z-1" onClick={onClick}>
      <ButtonArea>
        <Circle className={classNames({ on: isActive, off: !isActive })} />
        <p data-text-color={!isActive && 'gray2'}>{text}</p>
      </ButtonArea>
    </div>
  );
};
