import styled from 'styled-components';

export const CategoryList = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const CategoryItem = styled.li`
  display: inline-block;
  text-align: center;
  color: var(--color-gray-text-second);

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-gray);
    aspect-ratio: 1 / 1;
    border-radius: 1rem;
    & > svg {
      width: 49px;
      height: 49px;
    }
  }

  &:nth-of-type(n + 4) {
    padding-top: 1rem;
  }

  &:nth-of-type(3n + 1) {
    margin-right: 10%;
  }

  &:nth-of-type(3n + 2) {
    margin-left: 5%;
    margin-right: 5%;
  }

  &:nth-of-type(3n) {
    margin-left: 10%;
  }

  &.on {
    & > div {
      border: solid 2px var(--color-point);
    }
    color: var(--color-white);
  }
`;
