import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getMyAccount } from '#api/accounts';
import { postCoachGym, useGetNearGyms } from '#api/gyms';
import { useGetCoordinates } from '#api/location';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { AddressModal, LocationModal } from '#molecules';
import { GymInfoItem } from '#organisims';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { IGym } from '#types/gyms';
import { EmptyItem } from 'src/components/molecules/EmptyItem/Index';
import {
  ButtonContainer,
  Container,
  ContentArea,
  HeaderContainer,
  LocationArea,
  LocationAreaWrap
} from './styles';

export function ProfileGymPage() {
  const { showToast } = useToast();

  const { userInfo, setUserInfo } = useUserInfo();
  const myGym = userInfo?.recommendGyms[0] || null;
  const [address, setAddress] = useState<string>('');
  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false);
  const [coord, setCoord] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [gyms, setGyms] = useState<IGym[] | null>(null);
  const [selectedGym, setSelectedGym] = useState<IGym | null>(null);
  const [locationModalOpen, setLocationModalOpen] = useState<boolean>(false);
  const [isMyGym, setIsMyGym] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data: coordinatesData, isSuccess: coordinatesSuccess } =
    useGetCoordinates(address);

  const { data: nearGymsData, isSuccess: nearGymsSuccess } = useGetNearGyms({
    page: 0, // TODO: 페이징 적용
    offset: 20,
    maxDistance: 10000, // TODO: 기획에 따라 변경
    lat: coord.y,
    lng: coord.x
  });

  const onAddressComplete = (addressData: { address: string }) => {
    setIsMyGym(false);
    setAddress(addressData.address);
    setAddressModalOpen(false);
  };

  const handleMapButtonClick = () => {
    setLocationModalOpen(true);
  };

  useEffect(() => {
    if (userInfo) {
      // setAddress(myGym?.address ?? '');
      // setCoord({ x: myGym?.long ?? 0, y: myGym?.lat ?? 0 });
      setIsMyGym(true);
      setGyms(myGym ? [myGym] : null);
      setSelectedGym(myGym);
    }
  }, [userInfo]);

  useEffect(() => {
    if (address && coordinatesSuccess) {
      const { x, y } = coordinatesData.documents[0].road_address;
      setCoord({ x: Number(x), y: Number(y) });
    }
  }, [address, coordinatesSuccess]);

  useEffect(() => {
    if (nearGymsSuccess) {
      setGyms(nearGymsData.data);
    }
  }, [nearGymsSuccess]);

  useEffect(() => {
    document.body.style.overflowY = addressModalOpen ? 'hidden' : 'auto';
  }, [onAddressComplete, addressModalOpen]);

  useEffect(() => {
    document.body.style.overflowY = locationModalOpen ? 'hidden' : 'auto';
  }, [locationModalOpen]);

  const handlePostButtonClick = async () => {
    if (!selectedGym) {
      showToast({
        type: 'fail',
        message: '운동공간을 선택해주세요.'
      });
      return;
    }
    navigate(PATH.PROFILE_GYM_REGISTRATION);
    /** 운동공간 등록 api */
    postCoachGym(selectedGym.id)
      .then(() => {
        showToast({
          message: '운동공간 등록에 성공하였습니다.'
        });
        getMyAccount().then(account => setUserInfo(account));
        navigate(PATH.PROFILE_UPDATE);
      })
      .catch(() => {
        showToast({
          type: 'fail',
          message: '운동공간 등록에 실패하였습니다.'
        });
      });
  };

  return (
    <>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.PROFILE_UPDATE}
        title="운동공간 등록"
        defaultBackground
      >
        <Container>
          <HeaderContainer>
            <Text textStyle="t1">수업 가능한 운동공간을</Text>
            <Text textStyle="t1">선택해주세요</Text>
            <Text textStyle="c1" textColor="gray2">
              설정하신 지역 근처의 사용가능한 공간입니다
            </Text>
          </HeaderContainer>
          <LocationAreaWrap>
            <LocationArea
              onClick={() => {
                setAddressModalOpen(true);
              }}
            >
              <Text>{address || '위치를 설정해주세요'}</Text>
              <BoxIcon type="location-pin-white" onlyIcon={true} size={20} />
            </LocationArea>
          </LocationAreaWrap>
          {addressModalOpen && (
            <AddressModal
              onComplete={onAddressComplete}
              onCancel={() => {
                setAddressModalOpen(false);
              }}
            />
          )}
          <ContentArea>
            {gyms &&
              (gyms.length === 0 ? (
                <EmptyItem
                  onClick={() =>
                    navigate(PATH.PROFILE_GYM_REGISTRATION, {
                      state: {
                        location: address,
                        lat: coord.y,
                        lng: coord.x
                      } as {
                        location: string;
                        lat: number;
                        lng: number;
                      }
                    })
                  }
                />
              ) : (
                gyms.map((gym, idx) => (
                  <>
                    <GymInfoItem
                      name={gym.name}
                      distance={
                        isMyGym
                          ? undefined
                          : Math.round((gym.distance / 1000) * 10) / 10
                      }
                      src={gym?.images[0]?.resizedLink}
                      price={gym.pricePerSession.toLocaleString('ko-KR')}
                      onClick={() => setSelectedGym(gym)}
                      isClicked={gym.id === selectedGym?.id}
                      handleMapButtonClick={handleMapButtonClick}
                    />
                    {address && gyms.length > 0 && idx === gyms.length - 1 && (
                      <EmptyItem
                        onClick={() =>
                          navigate(PATH.PROFILE_GYM_REGISTRATION, {
                            state: {
                              location: address,
                              lat: coord.y,
                              lng: coord.x
                            }
                          })
                        }
                      />
                    )}
                  </>
                ))
              ))}
          </ContentArea>
          {locationModalOpen && selectedGym && (
            <LocationModal
              data={selectedGym}
              onCancel={() => {
                setLocationModalOpen(false);
              }}
            />
          )}
        </Container>
      </PrevHeaderBarWithTitleTemplate>
      <ButtonContainer>
        <Button disabled={!address.length} onClick={handlePostButtonClick}>
          선택하기
        </Button>
      </ButtonContainer>
    </>
  );
}
