import { useCallback, useState } from 'react';

export interface Carrer {
  id: string;
  text: string;
  startAt?: string;
  endAt?: string;
}

interface Props {
  currentCareers?: Carrer[];
}

export const useCarrers = ({ currentCareers }: Props) => {
  const [carrers, setCarrers] = useState<Array<Carrer>>(currentCareers ?? []);

  const appendCarrer = useCallback(
    (cert: Carrer) => setCarrers(prev => [...prev, cert]),
    [carrers]
  );

  const removeCarrer = useCallback(
    (id: string) => setCarrers(prev => prev.filter(c => c.id !== id)),
    [carrers]
  );

  return {
    carrers,
    setCarrers,
    appendCarrer,
    removeCarrer
  };
};
