import { useNavigate } from 'react-router';
import { BoxIcon, Text } from '#atoms';
import { PATH } from '#const/path';
import Applications, {
  useApplications
} from '#providers/Applications/Applications';
import { FindCoachTemplate, PrevHeaderBarWithTitleTemplate } from '#templates';
import {
  dottedDateFormatter,
  exerciseCareerFormatter
} from '#utils/dateFormatter';
import { ApplicationItem, ApplicationList, LoadingContainer } from './styles';

export function MyPageApplicationsHome() {
  const { applications } = useApplications();
  const navigate = useNavigate();

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.MYPAGE}
      title="신청서 목록"
      headerbarType="absolute"
    >
      <Applications.IsLoading>
        <LoadingContainer>Loading ...</LoadingContainer>
      </Applications.IsLoading>
      <Applications.IsError>
        <LoadingContainer>잘못된 접근입니다!</LoadingContainer>
      </Applications.IsError>
      <Applications.EmptyApplications>
        <FindCoachTemplate />
      </Applications.EmptyApplications>
      <Applications.HasApplications>
        <ApplicationList>
          {applications.map(application => (
            <ApplicationItem key={application.id}>
              <div
                onClick={() => {
                  navigate(PATH.MYPAGE_APPLICATIONS_DETAIL, {
                    state: { confirmationForm: application }
                  });
                }}
              >
                <Text textStyle="t3">
                  {dottedDateFormatter(application.createdAt)}
                </Text>
                <BoxIcon type="next-arrow" onlyIcon size={14} />
              </div>
              <Text textColor="gray2">
                {exerciseCareerFormatter(application.person.startAthletic)} /{' '}
                {application.purposes.map(p => p.name).join(', ')}
              </Text>
            </ApplicationItem>
          ))}
        </ApplicationList>
      </Applications.HasApplications>
    </PrevHeaderBarWithTitleTemplate>
  );
}
