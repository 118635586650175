import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 3rem 2rem 11.3rem;
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
  height: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 0.4rem;
`;

export const Hyphen = styled.div`
  width: fit-content;
  background-color: var(--color-border-light);
  align-self: center;

  > hr {
    width: 1.4rem;
    border: none;
    height: 0.2rem;
    margin: 0;
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 3rem 2rem;
  height: 11.3rem;
  background-color: var(--color-background);
  max-width: var(--max-width);
  margin: 0 auto;
`;
