import all from '#assets/svg/all.svg';
import body_profile from '#assets/svg/body_profile.svg';
import body_shape_correction from '#assets/svg/body_shape_correction.svg';
import competition_preparation from '#assets/svg/competition_preparation.svg';
import diet from '#assets/svg/diet.svg';
import muscle_strengthening from '#assets/svg/muscle_strengthening.svg';
import pain_treatement from '#assets/svg/pain_treatement.svg';
import physical_strength_increase from '#assets/svg/physical_strength_increase.svg';
import rehabilitation from '#assets/svg/rehabilitation.svg';

export const ENROLLMENT_CAREER = ['경력없음', '1년', '2년', '3년', '4년'];

export const ENROLLMENT_PTPURPOSE = [
  '다이어트',
  '바디프로필',
  '체중 증가',
  '체력 증진',
  '체형 고정',
  '재활 케어',
  '통증 케어',
  '근력 강화',
  '대회 준비',
  '기타'
];

export const ENROLLMENT_SLEEPTIME = [
  '3시간 미만',
  '3시간',
  '4시간',
  '5시간',
  '6시간'
];

export const ENROLLMENT_DISEASE = [
  '없음',
  '심장 질환',
  '근육/관절 부상',
  '당뇨병'
];

export const ENROLLMENT_SURGICALSITE = ['없음', '목', '어깨', '허리'];

export const ENROLLMENT_JOB = ['학생', '직장인', '주부'];

// 여기부터 신규
export const PURPOSE_LIST = [
  { code: 'NONE_1', name: '', imgSrc: '', index: -1 },
  { code: 'NONE_2', name: '', imgSrc: '', index: -1 },
  { code: 'DIET', name: '다이어트', imgSrc: diet, index: 0 },
  {
    code: 'MUSCLE_STRENGTHENING',
    name: '근력 강화',
    imgSrc: muscle_strengthening,
    index: 1
  },
  { code: 'BODY_PROFILE', name: '바디프로필', imgSrc: body_profile, index: 2 },
  {
    code: 'PHYSICAL_STRENGTH_INCREASE',
    name: '체력 증진',
    imgSrc: physical_strength_increase,
    index: 3
  },
  { code: 'ALL', name: '전체', imgSrc: all, index: 4 },
  {
    code: 'BODY_SHAPE_CORRECTION',
    name: '체형 교정',
    imgSrc: body_shape_correction,
    index: 5
  },
  {
    code: 'COMPETITION_PREPARATION',
    name: '대회 준비',
    imgSrc: competition_preparation,
    index: 6
  },
  {
    code: 'PAIN_TREATMENT',
    name: '통증 관리',
    imgSrc: pain_treatement,
    index: 7
  },
  {
    code: 'REHABILITATION',
    name: '재활 케어',
    imgSrc: rehabilitation,
    index: 8
  },
  { code: 'NONE_3', name: '', imgSrc: '', index: -1 },
  { code: 'NONE_4', name: '', imgSrc: '', index: -1 }
];

export const COACH_FILTER = [
  { code: 'distance', name: '거리 순' },
  { code: 'grade', name: '별점 높은 순' },
  { code: 'reviewCount', name: '후기 많은 순' },
  { code: 'price', name: '가격' }
];
