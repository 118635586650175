import React, { useMemo, useState } from 'react';

interface Props {
  children: React.ReactNode;
}

interface FloatingButtonContext {
  open: boolean;
  toggleOpen: () => void;
}

const FloatingContext = React.createContext<FloatingButtonContext | null>(null);

export const useFloatingContext = () => {
  const context = React.useContext(FloatingContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <Floating> component.'
    );
  }
  return context;
};

function Floating({ children }: Props) {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => setOpen(!open);

  const memorizedValue = useMemo(
    () => ({ open, toggleOpen }),
    [open, toggleOpen]
  );

  return (
    <FloatingContext.Provider value={memorizedValue}>
      {children}
    </FloatingContext.Provider>
  );
}

function Open({ children }: Props) {
  const { open } = useFloatingContext();
  return open ? <>{children}</> : null;
}

function Close({ children }: Props) {
  const { open } = useFloatingContext();
  return open ? null : <>{children}</>;
}

Floating.Open = Open;
Floating.Close = Close;

export default Floating;
