import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import logo from '#assets/svg/logo.svg';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { ButtonContainer, Container, LogoArea, TextContainer } from './styles';

type ErrorType =
  | 'notMatchedSocialId'
  | 'duplicateEmail'
  | 'duplicatePhone'
  | 'invalidAccount';

const errorMessages: Record<ErrorType, string> = {
  notMatchedSocialId: '로그인된 카카오톡 소셜 아이디 정보가 일치하지 않습니다.', // 로그인 오류
  duplicateEmail: '이미 등록된 이메일 주소입니다.', // 회원가입 오류
  duplicatePhone: '이미 등록된 전화번호입니다.', // 회원가입 오류
  invalidAccount: '유효하지 않은 계정입니다.' // 회원가입 오류 - 백엔드에서 계정 생성 실패
};

export function KakaoLoginErrorPage() {
  const { errorType } = useParams<{ errorType: ErrorType | undefined }>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    errorType && setErrorMessage(errorMessages[errorType]);
  }, [errorType]);

  return (
    <>
      <Container>
        <LogoArea>
          <img src={logo} />
        </LogoArea>
        <TextContainer>
          <BoxIcon type="alert" onlyIcon boxColor="transparent" />
          <Text textStyle="t3">
            {errorType === 'notMatchedSocialId' ? '로그인' : '회원가입'}에
            실패했습니다.
          </Text>
          <Text textStyle="c1" textColor="red">
            {errorMessage}
          </Text>
        </TextContainer>
      </Container>
      <ButtonContainer>
        <Button onClick={() => navigate(PATH.HOME)}>홈으로 돌아가기</Button>
      </ButtonContainer>
    </>
  );
}
