import styled from 'styled-components';

export const LectureInfoWrap = styled.div`
  display: flex;
  flex-direction: column;

  & > div:last-child {
    border-bottom: none;
  }
`;

export const LectureInfoBox = styled.div`
  padding: 2rem;
`;

export const ItemWrapper = styled.div`
  border-bottom: 1px solid var(--color-border);
`;

export const ItemList = styled.ul`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  padding-top: 22px;
  padding-bottom: 13px;
`;

export const IndexIconArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  padding-bottom: 12px;
`;

export const IndexIcon = styled.div`
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background-color: var(--color-gray-second);

  &.point {
    width: 1rem;
    border-radius: 0.5rem;
    background-color: #863cff;
  }
`;
