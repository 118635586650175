import { useNavigate } from 'react-router';
import { NextArrow } from '#assets/svg';
import { Text } from '#atoms';
import { PATH } from '#const/path';
import { useLectureChart } from '#providers/LectureChart';
import { IRegisteredLecture } from '#types/registeredLectures';
import { dateDayFormatter } from '#utils/dateFormatter';
import { Container, RoundButton, RoundBox } from './styles';

interface Props {
  registeredLecture: IRegisteredLecture[] | undefined;
}

export const LectureDetailRoundList = ({ registeredLecture }: Props) => {
  const navigate = useNavigate();
  const { setRegisteredLectureId } = useLectureChart();

  if (!registeredLecture)
    return (
      <Container>
        <Text>회차 정보가 없습니다.</Text>
      </Container>
    );

  return (
    <Container>
      {registeredLecture.map((item, index) => (
        <li key={index}>
          <RoundButton
            onClick={() => {
              setRegisteredLectureId(item.id);
              navigate(`${PATH.CHART_RECORD}?id=${item.id}`);
            }}
          >
            <RoundBox>
              <Text textStyle="t3">{item.round + '회차'}</Text>
              <NextArrow />
            </RoundBox>
            {item.taughtAt && (
              <time dateTime={item.taughtAt} data-text-color="gray2">
                {dateDayFormatter(item.taughtAt)}
              </time>
            )}
          </RoundButton>
        </li>
      ))}
    </Container>
  );
};
