import styled from 'styled-components';

export const TextContainer = styled.div`
  width: 100%;
  height: 5rem;
`;

export const ScheduleList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > li {
    border-bottom: 1px solid var(--color-border);
    padding: 2rem 0;

    & > div {
      display: flex;
      justify-content: space-between;
      > :first-child {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      > :last-child {
        flex-shrink: 0;
      }
    }

    &:last-child {
      border: none;
    }
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  padding: 1rem 2rem 3rem;
  background-color: var(--color-background);
`;
