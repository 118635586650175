import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { Sex } from '#types/account';

export interface ISignup {
  name: string;
  nickname: string;
  phone: string;
  email: string;
  password: string;
  agreeNotification: boolean;
  sex: Sex;
  birthday: string;
  isCoach?: boolean;
  isAdmin?: boolean;
}

export interface IPhoneNumber {
  phone: string;
}

export const postSignup = async (payload: ISignup) => {
  const res = await request<string>({
    // res: userId
    method: 'post',
    url: '/auth/sign',
    data: payload
  });

  return res.data;
};

export const postPhoneAuth = async (payload: IPhoneNumber) => {
  const res = await request<string>({
    method: 'post',
    url: '/auth/code',
    data: payload
  });

  return res.data;
};

export const getPhoneAuth = async (phone: string, verificationCode: string) => {
  const res = await request<string>({
    method: 'get',
    url: 'auth/code',
    params: {
      phone,
      verificationCode
    }
  });

  return res.data;
};

export const useGetPhoneAuth = (
  phone: string,
  verificationCode: string,
  enabled: boolean
) => {
  return useQuery(
    queryKeyFactory.GET_PHONE_AUTH(),
    () => getPhoneAuth(phone, verificationCode),
    {
      enabled,
      refetchOnWindowFocus: false
    }
  );
};
