import React from 'react';
import { Route, Routes } from 'react-router';
import {
  MyPageHome,
  MyPageSetting,
  MyPageSettingsAlert,
  MyPageProfile,
  MyPageProfileNickname,
  MyPageWithdrawalPrecaution,
  MyPageWithdrawalExplanation,
  MyPageHelpHome,
  MyPageHelpCS,
  MyPagePolicy,
  MyPageApplicationsHome,
  MyPageApplicationsCareer,
  MyPageApplicationsHealth,
  MyPageApplicationsJob,
  MyPageApplicationsPurpose,
  MyPageApplicationsSleep,
  MyPageScheduleHome,
  MyPageScheduleRegistration,
  MyPageScheduleCoach,
  MyPagePolicyService,
  MyPagePolicyPrivacy,
  MyPagePolicyMarketing,
  MyPageApplicationsDetail,
  MyPageProfileName,
  MyPageScheduleDetail,
  MyPageScheduleCancellation,
  MyPageScheduleCancellationSuccess,
  MyPageMyUrl
} from '#pages';
import Applications from '#providers/Applications/Applications';
import CoachSchedules from '#providers/CoachSchedules';
import { Schedules } from '#providers/Schedules';
import { Container, NonScrollSidePaddingContainer } from './styles';

export function MyPageView() {
  return (
    <Container>
      <Routes>
        <Route path="" element={<MyPageHome />} />
        <Route
          path="profile/*"
          element={
            <NonScrollSidePaddingContainer>
              <Routes>
                <Route path="" element={<MyPageProfile />} />
                <Route path="nickname" element={<MyPageProfileNickname />} />
                <Route path="name" element={<MyPageProfileName />} />
              </Routes>
            </NonScrollSidePaddingContainer>
          }
        />
        <Route
          path="withdrawal/*"
          element={
            <NonScrollSidePaddingContainer>
              <Routes>
                <Route
                  path="precaution"
                  element={<MyPageWithdrawalPrecaution />}
                />
                <Route
                  path="explanation"
                  element={<MyPageWithdrawalExplanation />}
                />
              </Routes>
            </NonScrollSidePaddingContainer>
          }
        />
        <Route
          path="applications/*"
          element={
            <Applications>
              <Routes>
                <Route path="" element={<MyPageApplicationsHome />} />
                <Route path="detail" element={<MyPageApplicationsDetail />} />
                <Route path="carrer" element={<MyPageApplicationsCareer />} />
                <Route path="purpose" element={<MyPageApplicationsPurpose />} />
                <Route path="sleep" element={<MyPageApplicationsSleep />} />
                <Route path="job" element={<MyPageApplicationsJob />} />
                <Route path="health" element={<MyPageApplicationsHealth />} />
              </Routes>
            </Applications>
          }
        />
        <Route
          path="schedules/*"
          element={
            <Applications>
              <Schedules>
                <CoachSchedules>
                  <Routes>
                    <Route path="" element={<MyPageScheduleHome />} />
                    <Route
                      path=":lectureId/*"
                      element={
                        <Routes>
                          <Route path="" element={<MyPageScheduleDetail />} />

                          <Route
                            path="registration"
                            element={<MyPageScheduleRegistration />}
                          />
                          <Route
                            path="cancellation/*"
                            element={
                              <Routes>
                                <Route
                                  path=""
                                  element={<MyPageScheduleCancellation />}
                                />
                                <Route
                                  path="success"
                                  element={
                                    <MyPageScheduleCancellationSuccess />
                                  }
                                />
                              </Routes>
                            }
                          />
                        </Routes>
                      }
                    />
                  </Routes>
                </CoachSchedules>
              </Schedules>
            </Applications>
          }
        />
        <Route
          path="schedules-coach"
          element={
            <Schedules>
              <CoachSchedules>
                <MyPageScheduleCoach />
              </CoachSchedules>
            </Schedules>
          }
        />
        <Route
          path="help/*"
          element={
            <NonScrollSidePaddingContainer>
              <Routes>
                <Route path="" element={<MyPageHelpHome />} />
                <Route path="cs" element={<MyPageHelpCS />} />
              </Routes>
            </NonScrollSidePaddingContainer>
          }
        />
        <Route
          path="policy/*"
          element={
            <Routes>
              <Route path="" element={<MyPagePolicy />} />
              <Route path="service" element={<MyPagePolicyService />} />
              <Route path="privacy" element={<MyPagePolicyPrivacy />} />
              <Route path="marketing" element={<MyPagePolicyMarketing />} />
            </Routes>
          }
        />
        <Route
          path="setting/*"
          element={
            <NonScrollSidePaddingContainer>
              <Routes>
                <Route path="/" element={<MyPageSetting />} />
                <Route path="alert" element={<MyPageSettingsAlert />} />
              </Routes>
            </NonScrollSidePaddingContainer>
          }
        ></Route>
        <Route
          path="my-url"
          element={
            <NonScrollSidePaddingContainer>
              <MyPageMyUrl />
            </NonScrollSidePaddingContainer>
          }
        />
      </Routes>
    </Container>
  );
}
