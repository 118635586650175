import styled from 'styled-components';

export const MessageInputBoxContainer = styled.div`
  display: flex;
  width: 100%;
  height: 4rem;
  border-radius: 2rem;
  align-items: center;
  background-color: var(--color-gray);
`;

export const Input = styled.input`
  display: inline-block;
  width: calc(100% - 3.6rem);
  padding: 0 1rem;
  color: var(--color-white);
  border: none;
  background-color: transparent;
`;

export const ButtonContainer = styled.div`
  display: inline-block;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.6rem;
  margin-right: 0.4rem;
  background-color: var(--color-black);
  border-radius: 50%;

  & > span {
    display: inline-block;
  }
`;
