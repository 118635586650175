import useInfiniteScrollQuery from '#hooks/useInfiniteScrollQuery';
import { CoachAccount } from '#types/account';
import { IResponse } from '#types/api';
import { PER_PAGE_NUMBER } from 'src/shared/constant';
import { fetcher } from '../libs';

interface Params {
  /** 협회 id useGetAssociationQuery 로 id 받아옴 */
  licenseIssureId: string;
  lat: number;
  lng: number;
  offset: number;
}

/**
 * @link https://api-dev.fibud.kr/api#/%EC%9E%90%EA%B2%A9%EC%A6%9D%20%EB%B0%9C%EA%B8%89%EC%B2%98/LicenseIssuersController_findAllCoachesHavingLicense
 * @param licenseIssureId - 자격증 id
 * @returns
 */
export const useGetLicenseCoachList = ({
  licenseIssureId,
  lat,
  lng,
  offset
}: Params) => {
  return useInfiniteScrollQuery<CoachAccount>({
    perPageNum: offset || PER_PAGE_NUMBER,
    queryKeyBase: 'getEducationCoach',
    queryKeyParam: [licenseIssureId, lat, lng, offset],
    filters: {},
    queryFn: ({ pageParam = 1 }) => {
      return fetcher<IResponse<CoachAccount>>(
        `/license-issuers/${licenseIssureId}/coach`,
        {
          params: {
            lat,
            lng,
            offset,
            page: pageParam - 1
          }
        }
      );
    },
    options: {
      enabled: !!licenseIssureId && lat > 0 && lng > 0
    }
  });
};
