import React from 'react';
import { BoxIcon, Text } from '#atoms';
import { useProgress } from './hooks';
import {
  CarouselContainer,
  CarouselItemContainer,
  CarouselSliderContainer,
  Container,
  HeaderBar,
  ProgressBar,
  ProgressBarContainer
} from './styles';

interface Props {
  title: string;
  pages: React.ReactNode[];
  onCancel?: () => void;
}

export function ProgressHeaderbarTemplate({ title, pages, onCancel }: Props) {
  const { pos, prev } = useProgress();
  return (
    <Container>
      <HeaderBar>
        <BoxIcon
          type="prev-arrow"
          hide={pos === 0}
          onlyIcon={true}
          size={18}
          onClick={prev}
        />
        <Text textStyle="t3">{title}</Text>
        <BoxIcon
          type="x-gray"
          hide={pos === pages.length - 1}
          onlyIcon={true}
          size={18}
          onClick={onCancel}
        />
      </HeaderBar>
      <ProgressBarContainer>
        <ProgressBar
          style={{ width: `${((pos + 1) / pages.length) * 100}%` }}
        />
      </ProgressBarContainer>
      <CarouselContainer>
        <CarouselSliderContainer style={{ left: `-${pos * 100}%` }}>
          {pages.map((page, idx) => (
            <CarouselItemContainer style={{ left: `${idx * 100}%` }} key={idx}>
              {page}
            </CarouselItemContainer>
          ))}
        </CarouselSliderContainer>
      </CarouselContainer>
    </Container>
  );
}
