export const APPLICATION_CAREER = [
  { text: '경력 없음', value: '0' },
  { text: '1년', value: '1' },
  { text: '2년', value: '2' },
  { text: '3년', value: '3' },
  { text: '4년', value: '4' },
  { text: '5년 이상', value: '5' }
];

export const STARTDATE_LIST = [
  '경력없음',
  '1년',
  '2년',
  '3년',
  '4년',
  '5년 이상'
];
