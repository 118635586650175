import { useEffect, useMemo, useState } from 'react';

interface Name {
  isValid: boolean;
  value: string;
}

interface Props {
  currentName: string;
}

export const useName = ({ currentName }: Props) => {
  const [name, setName] = useState<Name>({
    isValid: true,
    value: currentName
  });

  const updateName = (nextName: string) => {
    setName(prev => ({ ...prev, value: nextName }));
  };

  useEffect(() => {
    const { value } = name;
    const trimedValue = value.trim();
    if (trimedValue.length < 2 || trimedValue.length > 10) {
      setName(prev => ({ ...prev, isValid: false }));
    } else setName(prev => ({ ...prev, isValid: true }));
  }, [name.value]);

  const memolizedValue = useMemo(
    () => ({ name, updateName }),
    [name, updateName]
  );

  return memolizedValue;
};
