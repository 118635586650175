import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    padding: 2rem 0;
    border-bottom: 1px solid var(--color-border);
    > div > p:first-child {
      color: var(--color-gray-text-second);
    }
  }

  > :last-child {
    border-bottom: none;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 1rem 3rem;
  background-color: var(--color-background);
`;
