import React from 'react';
import { PATH } from '#const/path';
import { PolicyViewer } from '#organisims';
import {
  FibudContainerTemplate,
  PrevHeaderBarWithTitleTemplate
} from '#templates';

export function MyPagePolicyMarketing() {
  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.MYPAGE_POLICY}
      title="이용약관"
      defaultBackground
    >
      <FibudContainerTemplate type="common">
        <PolicyViewer type="policy_marketing" />
      </FibudContainerTemplate>
    </PrevHeaderBarWithTitleTemplate>
  );
}
