import styled from 'styled-components';

export const PageContainer = styled.div`
  height: 100%;
`;

export const Button = styled.button`
  background-color: var(--color-point);
  border-radius: 0.5rem;
  width: 100%;
  padding: 1rem 7rem;
  text-align: center;
`;

export const TextContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 8.8rem);
  text-align: center;
`;
