import dayjs from 'dayjs';
import { Text } from '#atoms';
import { TitleItemBox } from '#molecules';
import { IGetConfirmationForm } from '#types/confirmationForms';
import { formatNumberWithCommas } from '#utils/Numbers';
import { sleepQualityToText } from '#utils/sleepQuality';
import { Container, ItemContainer } from './styles';

interface Props {
  customerDetail: IGetConfirmationForm | Record<string, never>;
}

export const DashboardConfirmation = ({ customerDetail }: Props) => {
  const diseases = [
    ...(customerDetail.healthInfo.diseases?.map(item => item.kind.name) ?? []),
    ...(customerDetail.healthInfo.diseaseEtc
      ? [customerDetail.healthInfo.diseaseEtc]
      : [])
  ];

  const painParts = [
    ...(customerDetail.healthInfo.painParts?.map(item => item.kind.name) ?? []),
    ...(customerDetail.healthInfo.painPartEtc
      ? [customerDetail.healthInfo.painPartEtc]
      : [])
  ];

  return (
    <Container>
      {Object.keys(customerDetail).length > 0 && (
        <>
          <ItemContainer>
            <TitleItemBox title="PT 수강 목적">
              {customerDetail.purposes.map((item, idx) => (
                <li data-text-color="gray2" key={idx}>
                  {item.name}
                </li>
              ))}
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="신청 시간">
              <div>
                {customerDetail.registeredLectures &&
                  customerDetail.registeredLectures.map((item, idx) => (
                    <Text key={idx} textColor="gray2">
                      {dayjs(item.taughtAt).format('YY/MM/DD dddd HH:mm')}
                    </Text>
                  ))}
              </div>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="신청 공간">
              <Text textColor="gray2">{customerDetail.gym.name}</Text>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="결제 금액">
              <Text textColor="gray2">
                {
                  customerDetail.calculatedRegisteredLecture
                    .totalRegisteredLectureCount
                }
                회{' '}
                {formatNumberWithCommas(
                  customerDetail.calculatedRegisteredLecture.totalamount
                )}
                원
              </Text>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="수면 정보">
              <div data-text-color="gray2">
                <li>
                  수면의 질 -{' '}
                  {sleepQualityToText(customerDetail.healthInfo.qualityOfSleep)}
                </li>
                <li>
                  수면 시간 - {customerDetail.healthInfo.sleepingHourAvg}시간
                </li>
              </div>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="직업">
              <Text textColor="gray2">{customerDetail.person.jobName}</Text>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="건강 상태">
              <div data-text-color="gray2">
                <li>
                  앓고 있는 질환 -{' '}
                  {diseases.length > 0 ? diseases.join(', ') : '없음'}
                </li>
                <li>
                  수술 또는 통증 부위 -{' '}
                  {painParts.length > 0 ? painParts.join(', ') : '없음'}
                </li>
              </div>
            </TitleItemBox>
          </ItemContainer>
          <ItemContainer>
            <TitleItemBox title="추가설명 및 문의사항">
              {customerDetail.etc && (
                <Text textColor="gray2" style={{ whiteSpace: 'pre-wrap' }}>
                  {customerDetail.etc}
                </Text>
              )}
            </TitleItemBox>
          </ItemContainer>
        </>
      )}
    </Container>
  );
};
