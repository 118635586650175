import React from 'react';
import { Text } from '#atoms';
import {
  PopularPostsWrapper,
  PopularPostsTobBox,
  PostContentWrapper,
  PostContentButton,
  PostConetntBottomBox,
  PostConetntBottomRightBox,
  CountBox
} from './styles';

interface Post {
  title: string;
  content: string;
  tag: string;
  likeCount: number;
  commentCount: number;
}

interface Props {
  posts: Array<Post>;
}

export const CommunityPopularPosts = ({ posts }: Props) => {
  return (
    <PopularPostsWrapper>
      <PopularPostsTobBox>
        <Text className="color-white" textStyle="t1">
          실시간 인기글
        </Text>
        <button className="color-point-fourth">더보기</button>
      </PopularPostsTobBox>
      <PostContentWrapper>
        {posts.map((item, index) => (
          <li key={index}>
            <PostContentButton>
              <Text className="color-white">{item.title}</Text>
              <Text className="color-gray-text" textStyle="c1">
                {item.content}
              </Text>
              <PostConetntBottomBox>
                <Text type="inline" className="color-gray-text" textStyle="c2">
                  {item.tag}
                </Text>
                <PostConetntBottomRightBox>
                  <CountBox>
                    <span className="color-white">좋아요</span>
                    <span className="color-gray-text">{item.likeCount}</span>
                  </CountBox>
                  <CountBox>
                    <span className="color-white">댓글</span>
                    <span className="color-gray-text">{item.commentCount}</span>
                  </CountBox>
                </PostConetntBottomRightBox>
              </PostConetntBottomBox>
            </PostContentButton>
          </li>
        ))}
      </PostContentWrapper>
    </PopularPostsWrapper>
  );
};
