import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { GymsData, IGetNearGyms } from '#types/api';
import { IGym, IPostGymSpaceRegistry } from '#types/gyms';

export const getGym = async (gymId: string) => {
  const res = await request<IGym>({
    method: 'get',
    url: `/gyms/${gymId}`
  });

  return res.data;
};

export const getNearGyms = async ({
  page,
  offset,
  maxDistance,
  lat,
  lng
}: IGetNearGyms) => {
  const res = await request<GymsData>({
    method: 'get',
    url: `/gyms`,
    params: {
      page,
      offset,
      maxDistance,
      lat,
      lng
    }
  });

  return res.data;
};

export const useGetGym = (gymId: string) => {
  return useQuery(queryKeyFactory.GET_GYM(gymId), () => getGym(gymId), {
    enabled: !!gymId
  });
};

export const useGetNearGyms = ({
  page,
  offset,
  maxDistance,
  lat,
  lng
}: IGetNearGyms) => {
  return useQuery(
    queryKeyFactory.GET_NEAR_GYMS(page, offset, maxDistance, lat, lng),
    () => getNearGyms({ page, offset, maxDistance, lat, lng }),
    {
      enabled: !!lat && !!lng
    }
  );
};

export const postCoachGym = async (gymId: string) => {
  const res = await request({
    method: 'post',
    url: `/gyms/${gymId}`
  });

  return res.data;
};

export const postGymSpaceRegister = async (params: IPostGymSpaceRegistry) => {
  const res = await request<IGym>({
    method: 'post',
    url: `/gyms`,
    data: params
  });
  return res.data;
};

export const postGymSpaceImgRegister = async (
  id: string,
  payload: FormData
) => {
  const res = await request({
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' },
    method: 'post',
    url: `/gyms/${id}/images`,
    data: payload
  });
  return res.data;
};
