import styled from 'styled-components';
import next_arrow from '#assets/svg/next_arrow.svg';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px 20px 0 20px;
  overflow-y: auto;
`;

export const MainArticle = styled.article`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  border-bottom: 1px solid var(--color-border-light);
`;

export const MainOthersPriceContainer = styled.div`
  width: 100%;
  padding: 0 38px;
  text-align: center;
`;

export const MainOtherPriceBox = styled.div`
  width: 100%;
  border: 10%;
  padding: 16px 23px 11px 23px;
  margin-top: 8px;
  background-color: var(--color-gray);
  border-radius: 16px;
`;

export const PriceLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const MainFibudPriceContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 22px;
`;

export const MainFibudPriceBox = styled.div`
  width: 100%;
  border: 10%;
  padding: 16px 26px 20px;
  margin: 14px 0 6px 0;
  background: var(--color-point-gradient);
  border-radius: 18px;
`;

export const ProfileArticle = styled.article`
  padding: 50px 20px;
  text-align: center;

  & hr {
    border-color: var(--color-border);
  }
`;

export const IconTable = styled.table`
  margin: 0 auto;

  // 각 줄에 공통 적용해야 하는 부분
  & > tr {
    display: block;
    color: var(--color-gray-text-second);
    text-align: center;
  }

  & > tr > td:not(:first-child) {
    margin-left: 7px;
  }

  // 텍스트 tr
  & > tr:nth-child(even) {
    margin: 6px 0 8px;
  }

  // 각 아이템에 적용 해야 하는 부분
  & td {
    display: inline-block;
    width: 71px;
  }

  // 이미지 아이템에 적용하는 부분
  & > tr:nth-child(odd) > td {
    height: 71px;
  }
`;

export const PlaceList = styled.ul`
  padding: 0 30px 30px 30px;
`;

export const PlaceContainer = styled.li`
  display: flex;
  position: relative;
  width: 100%;
  height: 100px;
  padding: 14px;
  margin: 11px 0 0 0;
  background-color: var(--color-gray);
  border-radius: 1rem;

  &.on {
    transform: scale(1.15);
    margin: 20px 0;
    border: solid 1px var(--color-point);
  }

  &::after {
    width: 12px;
    height: 12px;
    content: ' ';
    background: url(${next_arrow}) no-repeat;
  }
`;

export const PlacePreview = styled.span`
  display: inline-block;
  width: 71px;
  height: 71px;
  background-repeat: no-repeat;
  background-position: center;
`;

export const PlaceInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding-left: 17px;
  text-align: justify;
`;

export const ArrowBox = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
`;

export const ProfileBorderBox = styled.div`
  width: 100%;
  padding: 11px 18px;
  background-color: var(--color-gray);
  border-radius: 18px;
  text-align: justify;
`;

export const ProfileCalendarList = styled.ul`
  display: flex;
  text-align: center;
  justify-content: space-around;
  padding: 6px 0 12px;

  & > li {
    width: 23px;
    height: 23px;
    line-height: 1.5;
    border-radius: 50%;
    border: none;
    color: var(--color-gray-text);
  }

  & > li.on {
    background-color: var(--color-point-fourth);
    color: var(--color-white);
  }
`;

export const ProfileDue = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 6px;
`;
