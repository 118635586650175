import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 2rem;

  & :last-child {
    border-bottom: none;
  }
`;

export const LectureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.4rem 0;
  border-bottom: 1px solid var(--color-border);

  > button {
    height: 4.5rem;
  }
`;

export const LectureTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
`;

export const LectureBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;

  .chat {
    transform: scaleX(-1);
  }

  > button {
    height: 4.6rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`;
