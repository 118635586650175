import { useLocation } from 'react-router';
import styled from 'styled-components';
import logo from '#assets/svg/logo.svg';
import { Text } from '#atoms';
import { KakaoButton } from '#molecules';
import sessionStorageManager from '#utils/sessionStorageManager';
import { LineHeightWrapper } from './styles';

export function SignInAlarmTalk() {
  const location = useLocation();
  const { setBlockedPagePath } = sessionStorageManager();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const coachName = urlParams.get('coach-name');
  const smallSizeLetter = 16;

  return (
    <Container>
      <div>
        <LogoArea>
          <img
            style={{ width: '79px', height: '3.2rem', marginBottom: '6rem' }}
            src={logo}
          />
          <LineHeightWrapper lineHeight={33.41}>
            <Text textColor="white" textStyle="t0">
              안녕하세요,
            </Text>
            <Text textColor="white" textStyle="t0">
              <Text style={{ color: '#863CFF' }} type="inline" textStyle="t0">
                {coachName + ' '}
              </Text>
              선생님과 함께
            </Text>
            <Text textColor="white" textStyle="t0">
              한 차원 높은 수준의 PT를
            </Text>
            <Text textColor="white" textStyle="t0" className="forPadding">
              제공해 드릴{' '}
              <Text style={{ color: '#863CFF' }} textStyle="t0" type="inline">
                피벗
              </Text>
              입니다.
            </Text>
          </LineHeightWrapper>
          <Text
            textColor="gray2"
            textStyle="bb"
            style={{
              fontSize: smallSizeLetter,
              width: 270,
              fontWeight: 400
            }}
          >
            개인정보 보호를 위해 <br />
            최초 1회 카카오 로그인이 필요합니다.
          </Text>
        </LogoArea>
      </div>
      <ButtonArea>
        <OAuthButtonArea>
          <KakaoButton
            text="카카오로 1초만에 시작하기"
            icon
            onClick={() => {
              setBlockedPagePath(location.state?.blockedPagePath ?? '');
              window.location.href = `${process.env.REACT_APP_API_SERVER_URL}/auth/login/kakao?state=fibud`;
            }}
          />
        </OAuthButtonArea>
      </ButtonArea>
    </Container>
  );
}

export const Container = styled.div`
  width: 375px;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
`;

export const LogoArea = styled.div`
  display: flex;
  flex-direction: column;

  img {
    margin-bottom: 2rem;
    height: 47px;
  }

  .forPadding {
    padding-bottom: 12px;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  padding: 0.75rem 0 5rem;
  background-color: var(--color-background);
`;

export const OAuthButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const EmailAuthButtonArea = styled.div`
  display: flex;
  justify-content: center;

  & > p:first-of-type::after {
    content: '|';
    margin: 0 1rem;
    color: var(--color-border-light);
  }
`;
