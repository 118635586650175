import { useGetClinicalDataList } from '#api/clinical-data';
import { useGetCoachLectures } from '#api/lectures';
import { Button, Spacing, Text } from '#atoms';
import { PATH } from '#const/path';
import { TabListViewer } from '#molecules';
import { ClinicalDataItem } from '#organisims';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { useNavigate } from 'react-router';
import { CoachInfo } from 'src/components/templates/CoachDetailContent/CoachInfo/CoachInfo';
import { CoachIntroduce } from 'src/components/templates/CoachDetailContent/DetailInfo/CoachIntroduce';
import { CustomerReviews } from 'src/components/templates/CoachDetailContent/DetailInfo/CustomerReviews';
import { LectureInfoPage } from 'src/components/templates/CoachDetailContent/DetailInfo/LectureInfo';
import { LecturePlacePage } from 'src/components/templates/CoachDetailContent/DetailInfo/LecturePlace';
import { ProfileViewer } from 'src/components/templates/CoachDetailContent/ProfileViewer';
import { ButtonContainer, ClinicalDataContainer } from './styles';

export function ProfileHomePage() {
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();
  const { data: lecturesData } = useGetCoachLectures(userInfo?.id ?? '');
  const { data: clinicalDataList } = useGetClinicalDataList({
    coachId: userInfo?.id ?? '',
    offset: 100
  });

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.MYPAGE}
      title=""
      defaultBackground
    >
      <ProfileViewer
        imgSrcList={userInfo?.profile.map(e => e.uploadedLink) ?? []}
      />
      {userInfo && (
        <CoachInfo
          name={userInfo.name}
          tags={userInfo.tags}
          gymLocation={userInfo.recommendGyms[0]}
          grade={userInfo.grade}
          reviewCount={userInfo.reviewCount}
          clinicalDataCount={clinicalDataList?.total ?? 0}
          startAthletic={userInfo.startAthletic}
        />
      )}
      <ButtonContainer>
        <Button onClick={() => navigate(PATH.PROFILE_UPDATE)}>
          프로필 수정
        </Button>
      </ButtonContainer>
      {clinicalDataList && clinicalDataList.data.length > 0 && (
        <ClinicalDataContainer>
          <div>
            <Text textStyle="t3">포트폴리오</Text>
            {/* <BoxIcon type="next-arrow-white" onlyIcon size={14} /> */}
          </div>
          <Text textStyle="c1" textColor="gray2">
            전문가의 PT를 통해 변화된 실제 고객의 데이터 입니다
          </Text>
          <Spacing size={20} />
          <div>
            {clinicalDataList.data.map((data, idx) => (
              <ClinicalDataItem isPreview data={data} key={idx} />
            ))}
          </div>
        </ClinicalDataContainer>
      )}
      <TabListViewer>
        <TabListViewer.List
          tabs={['소개', '수업안내', '수업공간', '고객후기']}
        />
        {userInfo && lecturesData && (
          <TabListViewer.Viewer
            tabs={[
              <CoachIntroduce coachData={userInfo} />,
              <LectureInfoPage
                lectureData={lecturesData.data}
                ot={userInfo?.ot ?? null}
                onePoint={userInfo?.onePoint ?? null}
              />,
              <LecturePlacePage recommendGyms={userInfo?.recommendGyms} />,
              <CustomerReviews coachId={userInfo?.id} />
            ]}
          />
        )}
      </TabListViewer>
    </PrevHeaderBarWithTitleTemplate>
  );
}
