import React from 'react';
import { NextArrow } from '#assets/svg';
import { ArrowBigButtonWrap, TextArea, Label, Status } from './styles';

interface Props {
  label: string;
  status: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const ArrowBigButton = ({ label, status, onClick }: Props) => {
  return (
    <ArrowBigButtonWrap onClick={onClick}>
      <TextArea>
        <Label data-text-type="t3">{label}</Label>
        <Status style={{ whiteSpace: 'pre-wrap' }}>{status}</Status>
      </TextArea>
      <NextArrow />
    </ArrowBigButtonWrap>
  );
};
