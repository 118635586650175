import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import alt_image from '#assets/svg/alt_image.svg';
import { BoxIcon, Text } from '#atoms';
import { Profile } from '#types/account';
import {
  AltImageBox,
  Container,
  ExtraInfoArea,
  HandleBar,
  ImageItem,
  ImageList,
  TitleArea
} from './styles';

interface Props {
  isBottomSheetExpand: boolean;
  setIsBottomSheetExpand: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  address: string;
  phone: string;
  images: Profile[];
  introduction: string;
}

export const GymLocationBottomSheet = ({
  isBottomSheetExpand,
  setIsBottomSheetExpand,
  name,
  address,
  phone,
  images,
  introduction
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null);

  const [isIntroductionTextExpand, setIsIntroductionTextExpand] =
    useState(false);
  const [startYpoint, setStartYpoint] = useState(0);
  const [endYpoint, setEndYpoint] = useState(0);

  const handleTouchStart = (event: React.TouchEvent) => {
    setStartYpoint(event.touches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (endYpoint !== 0) {
      startYpoint > endYpoint
        ? setIsBottomSheetExpand(true)
        : setIsBottomSheetExpand(false);
    }
    setStartYpoint(0);
    setEndYpoint(0);
  };

  const handleIntroductionAreaClick = () => {
    setIsIntroductionTextExpand(prev => !prev);
    !isBottomSheetExpand && setIsBottomSheetExpand(true);
  };

  useEffect(() => {
    const bottomSheetRef = divRef.current;
    if (!bottomSheetRef) return;
    const parentElement = divRef.current.parentElement;
    if (!parentElement) return;

    const handleTouchMove = (event: TouchEvent) => {
      event.preventDefault();
      setEndYpoint(event.touches[0].clientY);
    };

    bottomSheetRef.addEventListener('touchmove', handleTouchMove, {
      passive: false
    });

    return () => {
      bottomSheetRef.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  useEffect(() => {
    !isBottomSheetExpand && setIsIntroductionTextExpand(false);
  }, [isBottomSheetExpand]);

  return (
    <Container
      className={classNames({
        expand: isBottomSheetExpand
      })}
    >
      <div
        className="textInfoArea"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        ref={divRef}
      >
        <HandleBar />
        <TitleArea>
          {images.length > 0 && images[0]?.resizedLink ? (
            <img src={images[0].resizedLink} />
          ) : (
            <AltImageBox>
              <img src={alt_image} width={26} height={26} />
            </AltImageBox>
          )}

          {/* <img src={images[0].resizedLink} /> */}
          <div className="titleTextArea">
            <Text textStyle="t3">{name}</Text>
            <div
              className="introductionArea"
              data-text-type="c1"
              onClick={handleIntroductionAreaClick}
            >
              <p
                className={classNames({ fold: !isIntroductionTextExpand })}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {introduction}
              </p>
              <span
                className={classNames({ expand: isIntroductionTextExpand })}
              >
                <BoxIcon type="thin-arrow" onlyIcon={true} size={14} />
              </span>
            </div>
          </div>
        </TitleArea>
        <ExtraInfoArea data-text-type="c1">
          <div>
            <BoxIcon type="location-pin-white" onlyIcon={true} size={20} />
            <p>{address}</p>
          </div>
          <div>
            <BoxIcon type="phone" onlyIcon={true} size={20} />
            <p>{phone}</p>
          </div>
        </ExtraInfoArea>
      </div>
      {images.length > 0 && (
        <ImageList>
          {images.map(img => (
            <ImageItem key={img.id}>
              <img src={img.resizedLink} />
            </ImageItem>
          ))}
        </ImageList>
      )}
    </Container>
  );
};
