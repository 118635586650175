import styled from 'styled-components';

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  border-radius: 0.8rem;
  border: 1px solid var(--color-border);
  padding: 1rem;
  flex: none;
  min-width: 310px;
  width: 310px;
  height: 26.8rem;
  scroll-snap-align: start;

  > :last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const PreviewTitleContainer = styled.div`
  > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  overflow-x: scroll;
  flex: 1;

  &.preview {
    overflow: hidden;
    > div > img {
      width: 12rem;
      height: 12rem;
      object-fit: contain;
    }
  }

  > div {
    position: relative;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    > div > img {
      min-width: 14rem;
      width: 14rem;
      height: 14rem;
      border-radius: 0.5rem;
      object-fit: contain;
    }

    > div > video {
      width: 14rem;
      height: 14rem;
      border-radius: 0.5rem;
      object-fit: contain;
      border: 1px solid gray;
    }

    p {
      position: absolute;
      width: 24px;
      height: 24px;
      text-align: center;

      &.before {
        bottom: 0;
        left: 0;
        background-color: var(--color-gray);
        border-radius: 0 0.4rem 0 0.4rem;
      }

      &.after {
        bottom: 0;
        right: 0;
        background-color: var(--color-point);
        border-radius: 0.4rem 0 0.4rem 0;
      }
    }
  }
`;

export const TitleWithButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :last-child {
    display: flex;
    gap: 0.5rem;
    > span {
      border-radius: 2rem;
      background-color: var(--color-gray);
      padding: 0.3rem 1rem;
      white-space: nowrap;
    }
  }
`;

export const AltImageBox = styled.div`
  position: relative;
  width: 14rem;
  height: 14rem;
  display: flex;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-second);

  &.preview {
    width: 12rem;
    height: 12rem;
  }
`;
