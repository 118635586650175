import { Button } from '#atoms';

interface Props {
  pointButtonText: string;
  darkButtonText: string;
  onPointButtonClick: () => void;
  onDarkButtonClick: () => void;
}

export const DarkPatternButtons = ({
  pointButtonText,
  darkButtonText,
  onPointButtonClick,
  onDarkButtonClick
}: Props) => {
  return (
    <>
      <Button onClick={onPointButtonClick}>{pointButtonText}</Button>
      <Button background="anti" onClick={onDarkButtonClick}>
        {darkButtonText}
      </Button>
    </>
  );
};
