import classNames from 'classnames';
import React from 'react';
import { ReactIcon, Text } from '#atoms';
import { ITag } from '#types/tags';
import { useGridCategory } from './hooks';
import { CategoryItem, CategoryList } from './styles';

interface Props {
  categories: Array<ITag>;
  onChange?: (categories: Array<ITag>) => void;
  initialValue?: Array<ITag>;
  allowMultiple?: boolean;
}

export function GridCategory({
  categories,
  onChange,
  initialValue // allowMultiple
}: Props) {
  const { selectedCategory, toggleCategory } = useGridCategory({
    initialValue,
    onChange
    // allowMultiple
  });

  return (
    <CategoryList>
      {categories.map((category, idx) => (
        <CategoryItem
          className={classNames({
            on: selectedCategory.includes(category)
          })}
          key={idx}
          onClick={() => toggleCategory(category)}
        >
          {/* <BoxIcon type={category.icon} size={49} /> */}
          <div>
            <ReactIcon iconName={category.iconName ?? ''} />
          </div>
          <Text>
            {/\s/.test(category.name)
              ? category.name.split(' ').map((word, index) => (
                  <Text key={index} style={{ textAlign: 'center' }}>
                    {word}
                    <br />
                  </Text>
                ))
              : category.name}
          </Text>
        </CategoryItem>
      ))}
    </CategoryList>
  );
}
