import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const TextContainer = styled.div`
  width: 100%;
`;

export const CarrerList = styled.ul`
  width: 100%;
  color: var(--color-gray-text);

  & > li {
    width: 100%;
    height: 6rem;
    line-height: 6rem;
    text-align: center;
    border-radius: 0.8rem;
    overflow: hidden;
    box-sizing: border-box;

    &.on {
      color: var(--color-white);
      background: var(--color-point-gradient) 0 0 no-repeat;
      border-image-slice: 1;

      & > p {
        border-radius: 0.6rem;
      }
    }

    & > p {
      width: calc(100% - 0.4rem);
      height: calc(100% - 0.4rem);
      line-height: 5.6rem;
      margin: 0.2rem 0 0 0.2rem;
      background-color: var(--color-background);
    }
  }
`;
