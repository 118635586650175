import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import TokenController from '#utils/tokenController';
import { postRefreshAuthToken } from './auth/token';

export const BASE_URL = process.env.REACT_APP_API_SERVER_URL;
export const refreshAxiosInstance = Axios.create({ baseURL: BASE_URL });

export interface ErrorResponse {
  statusCode: number;
  message: string;
  error: string;
}

export const axios = Axios.create({
  baseURL: BASE_URL
});

const handleAxiosError = (error: AxiosError) => {
  if (error.response?.status === 401) {
    TokenController.clear();
    // window.location.href = '/sign';
    return Promise.reject(error);
  }

  return Promise.reject(error);
};

axios.interceptors.request.use(async config => {
  let access = TokenController.accessToken;

  if (
    TokenController.refreshToken &&
    (!access || TokenController.isTokenExpired())
  ) {
    const { accessToken, refreshToken } = await postRefreshAuthToken();

    TokenController.setAccessToken(accessToken);
    TokenController.setRefreshToken(refreshToken);
    TokenController.setTokenTime();

    access = accessToken;
  }

  config.headers.Authorization = access ? `Bearer ${access}` : '';

  return config;
});

refreshAxiosInstance.interceptors.request.use(config => {
  const refreshToken = localStorage.getItem('refresh_token');

  config.headers.Authorization = refreshToken ? `Bearer ${refreshToken}` : '';

  return config;
});

axios.interceptors.response.use(res => {
  return res;
}, handleAxiosError);
refreshAxiosInstance.interceptors.response.use(res => {
  return res;
}, handleAxiosError);

const request = async <T>(param: AxiosRequestConfig) => {
  return axios({
    ...param
  }).then((res: AxiosResponse<T>) => res);
};

export default request;
