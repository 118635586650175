import styled from 'styled-components';

interface DayLiProps {
  isActive: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-right: 0;
  padding: 2rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const WeekLists = styled.ul`
  width: 95%;
  margin-top: 2rem;
  margin-left: auto;
  margin-bottom: 1.2rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  li {
    text-align: center;
  }
`;

export const DateArea = styled.div`
  width: 100%;
  height: 2.2rem;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  span {
    transform: rotate(-90deg);
    margin-top: 0;
  }
`;
export const WeekCalenderContainer = styled.div`
  width: 100%;
`;

export const DayLi = styled.li<DayLiProps>`
  width: 90%;
  height: 63px;
  background-color: ${props =>
    props.isActive ? 'var(--color-point)' : 'transparent'};
  border-radius: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HorizonLineContainer = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--color-point);
  line-height: 0.1em;
  margin: 10px 0 20px;
  z-index: 0;
`;
