import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const HeaderConatiner = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 5.8rem;
  align-items: center;
`;

export const ChildrenContainer = styled.article`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: calc(100% - 5.8rem);
  overflow: auto;
`;
