import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 2rem 3rem;

  > :last-child {
    border-bottom: none;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  border-bottom: 1px solid var(--color-border);

  li {
    padding-left: 1rem;
  }
`;
