import React, { useEffect, useState } from 'react';
import { getLecturesIng } from '#api/lectures';
import { getRecords } from '#api/records';
import { IGetConfirmationForm } from '#types/confirmationForms';
import { IRecord } from '#types/records';

interface Props {
  children?: React.ReactNode;
}

interface LectureChartContext {
  setLectureId: React.Dispatch<React.SetStateAction<string>>;
  lecture: IGetConfirmationForm | Record<string, never>;
  setRegisteredLectureId: React.Dispatch<React.SetStateAction<string>>;
  record: Record<string, never> | IRecord;
  isLoading: boolean;
  isError: boolean;
}

const LectureChartContext = React.createContext<LectureChartContext | null>(
  null
);

export const useLectureChart = () => {
  const context = React.useContext(LectureChartContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <LectureChart> component.'
    );
  }
  return context;
};

function LectureChart({ children }: Props) {
  const [lectureId, setLectureId] = useState<string>('');
  const [lecture, setLecture] = useState<
    IGetConfirmationForm | Record<string, never>
  >({});
  const [registeredLectureId, setRegisteredLectureId] = useState<string>('');
  const [record, setRecord] = useState<IRecord | Record<string, never>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (lectureId) {
      setIsLoading(true);
      getLecturesIng(lectureId)
        .then(result => {
          setLecture(result);
        })
        .catch(() => setIsError(true))
        .finally(() => setIsLoading(false));
    }
  }, [lectureId]);

  useEffect(() => {
    if (registeredLectureId) {
      setIsLoading(true);
      getRecords(registeredLectureId)
        .then(result => {
          setRecord(result);
        })
        .catch(() => setIsError(true))
        .finally(() => setIsLoading(false));
    }
  }, [registeredLectureId]);

  const memorizedValue = React.useMemo(
    () => ({
      setLectureId,
      lecture,
      setRegisteredLectureId,
      record,
      isLoading,
      isError
    }),
    [lectureId, lecture, registeredLectureId, record, isLoading, isError]
  );

  return (
    <LectureChartContext.Provider value={memorizedValue}>
      {children}
    </LectureChartContext.Provider>
  );
}

function IsLoading({ children }: Props) {
  const { isLoading } = useLectureChart();
  return isLoading ? <>{children}</> : null;
}

function IsError({ children }: Props) {
  const { isLoading, isError } = useLectureChart();
  return !isLoading && isError ? <>{children}</> : null;
}

function EmptyLecture({ children }: Props) {
  const { isLoading, isError, lecture } = useLectureChart();
  return !isLoading && !isError && Object.keys(lecture).length === 0 ? (
    <>{children}</>
  ) : null;
}

function HasLecture({ children }: Props) {
  const { isLoading, isError, lecture } = useLectureChart();
  return !isLoading && !isError && lecture && Object.keys(lecture).length ? (
    <>{children}</>
  ) : null;
}

function EmptyRecord({ children }: Props) {
  const { isLoading, isError, record } = useLectureChart();
  return !isLoading && !isError && Object.keys(record).length === 0 ? (
    <>{children}</>
  ) : null;
}

function HasRecord({ children }: Props) {
  const { isLoading, isError, record } = useLectureChart();
  return !isLoading && !isError && record && Object.keys(record).length ? (
    <>{children}</>
  ) : null;
}

LectureChart.IsLoading = IsLoading;
LectureChart.IsError = IsError;
LectureChart.EmptyLecture = EmptyLecture;
LectureChart.HasLecture = HasLecture;
LectureChart.EmptyRecord = EmptyRecord;
LectureChart.HasRecord = HasRecord;

export default LectureChart;
