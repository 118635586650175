import { PATH } from '#const/path';
export const TOTAL_POLICY_LIST = [
  {
    id: 'policy_age',
    text: '만 14세 이상 확인',
    isEssential: true,
    hasMoreDetail: false
  },
  {
    id: 'policy_service',
    text: '서비스 이용약관',
    isEssential: true,
    hasMoreDetail: true,
    detailURL: `${PATH.POLICY_SERVICE}`
  },
  {
    id: 'policy_privacy',
    text: '개인정보 처리방침',
    isEssential: true,
    hasMoreDetail: true,
    detailURL: `${PATH.POLICY_PRIVACY}`
  },
  {
    id: 'policy_marketing',
    text: '맞춤 이벤트 안내 수신 동의',
    isEssential: false,
    hasMoreDetail: true
  }
];

export const BILLING_POLICY_LIST = [
  { label: '서비스 수수료 및 이용 약관', required: true },
  { label: '개인정보 수집·이용 동의 및 처리방침', required: true }
];
