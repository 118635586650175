import request from '#api/axios';
import { UploadInfo } from '#types/gyms';
import { IPostFiles } from '#types/upload';

export const postUploadFiles = async ({ path, type, images }: UploadInfo) => {
  const res = await request<IPostFiles>({
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' },
    method: 'post',
    url: '/files/one',
    params: {
      path,
      type
    },
    data: images
  });

  return res.data;
};
