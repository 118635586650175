import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { IGetJobs } from '#types/jobs';

export const getJobs = async () => {
  const res = await request<IGetJobs>({
    method: 'get',
    url: '/jobs'
  });

  return res.data;
};

export const useGetJobs = () => {
  return useQuery(queryKeyFactory.GET_JOBS(), getJobs);
};
