import styled from 'styled-components';

export const TabContainer = styled.ul`
  /* position: relative; */
  position: sticky;
  /* top: 0; */
  top: -0.1%;
  left: 0;
  display: flex;
  width: 100%;
  /* height: 5rem; */
  color: var(--color-gray-text-second);
  background-color: var(--color-background);
  box-sizing: border-box;
  border-bottom: solid 1px var(--color-point);
  z-index: 10; // 가장 상위 쌓임맥락(z-index)

  & > li {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    padding: 1.2rem 0.8rem;
    text-align: center;
    border: none;
    border-bottom: solid 4px transparent;

    &.on {
      color: var(--color-white);
      border-bottom: solid 4px var(--color-point);
    }
  }
`;
