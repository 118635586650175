import React from 'react';
import { SquareImage } from '#atoms';
import { useCommunityTodayTraining } from './hooks';
import { Container } from './styles';

export const CommunityTodayTraining = () => {
  const images = useCommunityTodayTraining();

  return (
    <Container>
      {images.map(src => (
        <SquareImage key={src} type="li" src={src} />
      ))}
    </Container>
  );
};
