import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.75rem;
`;

export const ImageArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-gray-text-second);
  position: relative;

  img {
    width: 7.6rem;
    height: 7.6rem;
    border-radius: 50%;
  }

  .camera {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
