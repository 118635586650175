import React, { useState } from 'react';
import { Text, VideoRenderer } from '#atoms';
import { Modal } from '#molecules';
import { IExerciseRecord } from '#types/records';
import {
  ImageItem,
  ImageList
} from 'src/components/molecules/ImageWithUpload/styles';
import {
  Container,
  ExerciseRecordWrapper,
  ExerciseSetWrapper,
  ExerciseSetBox,
  PerSetWeight
} from './styles';

interface Props {
  exerciseRecord: IExerciseRecord[] | undefined;
}
type ModalType = 'video' | 'image';

export const RecordDetailExerciseRecordList = ({ exerciseRecord }: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [detailImage, setDetailImage] = useState<string>('');
  const [modalType, setModalType] = useState<ModalType>('image');

  const hanldeModalOpen = (link: string, type: ModalType) => {
    setModalType(type);
    setDetailImage(link);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setDetailImage('');
  };

  return (
    <Container>
      {exerciseRecord?.map(item => (
        <ExerciseRecordWrapper key={item.id}>
          <Text textStyle="t3">{item.exerciseKindName}</Text>
          <ExerciseSetWrapper>
            {item.sets.map((data, index) => (
              <ExerciseSetBox key={index}>
                <PerSetWeight>
                  <Text>{data.round}set</Text>
                  <Text>{data.set.weight}kg</Text>
                </PerSetWeight>
                <Text>{data.set.reps}회</Text>
              </ExerciseSetBox>
            ))}
          </ExerciseSetWrapper>
          <ImageList>
            {item.videos.map(src => (
              <ImageItem
                key={src.id}
                onClick={() => hanldeModalOpen(src.resizedLink, 'video')}
              >
                <VideoRenderer url={src.resizedLink} />
              </ImageItem>
            ))}
            {item.images.map(src => (
              <ImageItem
                key={src.id}
                onClick={() => hanldeModalOpen(src.resizedLink, 'image')}
              >
                <img src={src.resizedLink} />
              </ImageItem>
            ))}
          </ImageList>
        </ExerciseRecordWrapper>
      ))}
      {modalOpen && (
        <Modal onClose={() => handleModalClose()} transparent>
          {detailImage && modalType === 'image' ? (
            <img className="modal-img" src={detailImage} />
          ) : (
            <VideoRenderer
              className="modal-video"
              controls={true}
              url={detailImage}
            />
          )}
        </Modal>
      )}
    </Container>
  );
};
