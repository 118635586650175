import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getMyAccount } from '#api/accounts';
import { deleteCareers, postCareers } from '#api/careers';
import { BoxIcon, Button, Input, Text } from '#atoms';
import { PATH } from '#const/path';
import { Toast } from '#molecules';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import CheckBoxAtom from 'src/components/atoms/Checkbox';
import { Carrer, useCarrers } from './hooks';
import {
  AppendButtonContainer,
  ButtonContainer,
  CarrerItem,
  CarrerList,
  Container,
  FlexBox,
  CarrerCheckBoxContainer
} from './styles';

export function ProfileCarrerPage() {
  const now = dayjs().format('YYYY-MM-DD');
  const { showToast } = useToast();

  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useUserInfo();

  const currentCareersInit: Carrer[] =
    userInfo?.careers
      .filter(career => career.status === 'NORMAL')
      .map(({ id, name, startAt, endAt }) => ({
        id,
        text: name,
        startAt: startAt,
        endAt: endAt
      })) ?? [];

  const [currentCareers, setCurrentCareers] = useState<Carrer[] | undefined>(
    currentCareersInit
  );

  const [inputText, setInputText] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string | undefined>('');
  const { carrers, setCarrers, appendCarrer, removeCarrer } = useCarrers(
    {
      currentCareers
    } || { currentCareersInit }
  );
  const [activeState, setActiveState] = useState(false);

  const appendItem = () => {
    if (!inputText.trim().length) return;
    if (!startDate) return;

    appendCarrer({
      text: inputText,
      id: Date.now().toString(),
      startAt: startDate,
      endAt: endDate
    });
    setInputText('');
    setStartDate('');
    setEndDate('');
    setChecked(false);
  };

  const onButtonClick = () => {
    if (!currentCareers) return;
    Promise.all([
      carrers
        .filter(career => !currentCareers?.some(e => e.id === career.id))
        .map(c =>
          postCareers({
            name: c.text,
            position: 'position',
            startAt: c.startAt,
            endAt: c.endAt
          })
        ),
      currentCareers
        ?.filter(currentCareer => !carrers.some(e => e.id === currentCareer.id))
        .map(c => deleteCareers(c.id))
    ]).then(res => {
      Promise.all(res.map(p => Promise.allSettled(p))).then(results => {
        const hasErrors = results.some(result =>
          result.some(r => r.status === 'rejected')
        );
        if (!hasErrors) {
          showToast({
            message: '경력사항 등록에 성공하였습니다.'
          });
          getMyAccount().then(account => setUserInfo(account));
          navigate(PATH.PROFILE_UPDATE);
        } else
          showToast({
            type: 'fail',
            message: '경력사항 등록에 실패하였습니다.'
          });
      });
    });
  };

  const handleCheckBox = () => {
    setChecked(!checked);
    if (endDate?.length) setEndDate('');
  };

  //경력사항 state변경 function
  const handleChangeSendDateFormat = (day: string | number, type: string) => {
    if (typeof day === 'number') return;

    if (type === 'start') {
      setStartDate(day);
    } else {
      if (startDate.length === 0) return;
      setEndDate(day);
    }
  };

  useEffect(() => {
    if (checked && endDate?.length) {
      setEndDate(undefined);
    }
  }, [checked]);

  // 새로고침 등의 이슈로 userInfo를 못받아와질 때, 다시 set 해줌
  useEffect(() => {
    if (!userInfo) return;

    const currentCareers: Carrer[] =
      userInfo?.careers
        .filter(career => career.status === 'NORMAL')
        .map(({ id, name, startAt, endAt }) => ({
          id,
          text: name,
          startAt: startAt,
          endAt: endAt
        })) ?? [];

    setCurrentCareers(currentCareers);
    setCarrers(currentCareers);
  }, [userInfo]);

  useEffect(() => {
    if (startDate && (endDate || checked) && inputText) {
      setActiveState(true);
    } else {
      setActiveState(false);
    }
  }, [startDate, endDate, inputText, checked]);

  return (
    <>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.PROFILE_UPDATE}
        title="경력사항 등록"
      >
        <Container>
          <Text textStyle="display">경력사항을 작성해주세요</Text>
          <>
            <Input
              type="text"
              placeholder="경력사항을 입력해주세요"
              value={inputText}
              onChange={text => setInputText(String(text))}
            />
            <Input
              type="date"
              data-placeholder="경력시작일을 선택해주세요"
              value={startDate}
              onChange={day => handleChangeSendDateFormat(day, 'start')}
              min="1900"
              max={now}
            />
            <FlexBox>
              <Input
                type="date"
                data-placeholder="경력종료일을 선택해주세요"
                value={endDate}
                checked={checked}
                onChange={day => handleChangeSendDateFormat(day, 'end')}
                onClick={e => {
                  checked && e.preventDefault();
                }}
                min={startDate}
                max={now}
              />
              <CarrerCheckBoxContainer>
                <CheckBoxAtom
                  id="working"
                  label="재직중"
                  onClick={handleCheckBox}
                  checked={checked}
                />
              </CarrerCheckBoxContainer>
            </FlexBox>
          </>
          <CarrerList>
            {carrers.map(({ id, text, startAt, endAt }) => {
              let TEXT = text;
              if (startAt) {
                TEXT = `${TEXT} / ${dayjs(startAt).format('YYYY.MM.DD')}`;
              }
              if (endAt) {
                TEXT = `${TEXT} / ${dayjs(endAt).format('YYYY.MM.DD')}`;
              } else {
                TEXT = `${TEXT} / 재직중`;
              }

              return (
                <CarrerItem key={id}>
                  <Text>{TEXT}</Text>
                  <BoxIcon
                    type="x-gray"
                    onlyIcon={true}
                    size={12}
                    onClick={() => removeCarrer(id)}
                  />
                </CarrerItem>
              );
            })}
          </CarrerList>
          <AppendButtonContainer onClick={() => appendItem()}>
            <BoxIcon type="plus-gray" size={24} onlyIcon={true} />
            <Text textStyle="c1" textColor="gray2">
              경력 추가
            </Text>
          </AppendButtonContainer>
        </Container>
      </PrevHeaderBarWithTitleTemplate>
      <ButtonContainer>
        <Button
          disabled={JSON.stringify(carrers) === JSON.stringify(currentCareers)}
          onClick={onButtonClick}
        >
          프로필 등록
        </Button>
      </ButtonContainer>
      {activeState && (
        <Toast visibility="visible" text="경력추가 버튼을 눌러주세요" />
      )}
    </>
  );
}
