import styled from 'styled-components';

export const CommonContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 3rem 2rem;
`;

export const WithBottomButtonContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const HorizontalPaddingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 3rem 2rem 0 2rem;
`;

export const VerticalPaddingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 3rem 0;
`;

export const BottomButtonBody = styled.div`
  width: 100%;
  height: calc(100% - 7rem);
  overflow: auto;
`;

export const BottomButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7rem;
  padding-top: 1.7rem;
`;

export const EmptyContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
