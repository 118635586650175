import { useGetAccountMyPage } from '#api/accounts';
import { UserDefaultProfile } from '#assets/svg';
import { Text } from '#atoms';
import UserInfo from '#providers/UserInfo';
import { Container, ProfileArea, TextArea } from './styles';

export const UserSummary = () => {
  const { data: userInfo } = useGetAccountMyPage();

  return (
    <Container>
      <ProfileArea>
        {userInfo?.profile[0] ? (
          <img src={userInfo?.profile[0]?.uploadedLink} />
        ) : (
          <UserDefaultProfile width={60} height={60} />
        )}
      </ProfileArea>
      <TextArea>
        <UserInfo.IsConsumer>
          <Text textStyle="t3">{userInfo?.name} 회원님</Text>
          <Text textStyle="c2" textColor="gray2">
            {userInfo?.email}
          </Text>
        </UserInfo.IsConsumer>
        <UserInfo.IsCoach>
          <Text textStyle="t3">{userInfo?.name} 전문가</Text>
          <Text textStyle="c2" textColor="gray2">
            {userInfo?.email}
          </Text>
          <Text textStyle="c2" textColor="gray2">
            프로그램 {userInfo?.lecturesCount} 보유고객{' '}
            {userInfo?.confirmationFormsCount}
          </Text>
        </UserInfo.IsCoach>
      </TextArea>
    </Container>
  );
};
