import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  max-width: var(--max-width);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  background: var(--color-point-dark-gradient);
`;

export const ArticleContainer = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  gap: 1rem;
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 5rem 2rem;
  flex: 1;

  &.check {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex: 0;
    padding: 5rem 0;

    > div {
      text-align: center;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 0 2rem 3rem;
`;
