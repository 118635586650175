import { VideoRenderer } from '#atoms';
import { Modal } from '#molecules';
import { useState } from 'react';

interface FileDto {
  id: string;
  resizedLink: string; // 리사이즈된 파일 링크
  shortLink?: string; // 단축된 파일 링크
  uploadedLink: string; // 업로드된 파일 링크
}

interface ExerciseMediaProps {
  videos?: FileDto[];
  images?: FileDto[];
}

type ModalType = 'image' | 'video';

export const ExerciseMedia = ({ videos, images }: ExerciseMediaProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [detailImage, setDetailImage] = useState<string>('');

  const [modalType, setModalType] = useState<ModalType>('image');

  const handleDetailClick = (image: string, type: ModalType) => {
    setModalType(type);
    setDetailImage(image);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setDetailImage('');
  };

  return (
    <>
      <div className="flex gap-12 mt-20">
        {videos &&
          videos.map(video => {
            return (
              <div
                onClick={() => handleDetailClick(video.resizedLink, 'video')}
                className="w-120 h-120 rounded-lg overflow-hidden border-1 border-solid border-[#373245]"
              >
                <VideoRenderer url={video.resizedLink} className="h-full" />
              </div>
            );
          })}
        {images?.map(image => {
          return (
            <div
              onClick={() => handleDetailClick(image.resizedLink, 'image')}
              className="w-120 h-120 rounded-lg overflow-hidden border-1 border-solid border-[#373245]"
            >
              <img src={image.resizedLink} alt="운동 사진" />
            </div>
          );
        })}
      </div>
      {modalOpen && (
        <Modal onClose={handleModalClose} transparent>
          {detailImage && modalType === 'image' ? (
            <img className="modal-img" src={detailImage} />
          ) : (
            <VideoRenderer
              className="modal-video"
              controls={true}
              url={detailImage}
            />
          )}
        </Modal>
      )}
    </>
  );
};
