import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem 2rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;

  li {
    padding-left: 1rem;
    list-style-type: none;
  }
`;
