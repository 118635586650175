import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  padding: 0 2rem;
`;

export const ProfileArea = styled.div`
  width: 6rem;
  height: 6rem;

  img {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
  }
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
`;
