import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getCustomerDetail } from '#api/confirmation_forms';
import { patchRecord, postRecord } from '#api/records';
import { BoxIcon, Button, Spacing, Text, Textarea } from '#atoms';
import { PATH } from '#const/path';
import { BorderlessInput, ImageWithUpload, TitleItemBox } from '#molecules';
import { RecordDetailLectureInfo } from '#organisims';
import { useDashboard } from '#providers/Dashboard';
import { useToast } from '#providers/ToastProvider';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { imageFileType, videoFileType } from '#types/fileType';
import { IExerciseRecords, ISet } from '#types/records';
import { isNumeric } from '#utils/Numbers';
import { useUploadImages } from 'src/components/molecules/ImageWithUpload/hooks';
import { useGetExerciseNames } from './hooks';
import {
  AddBox,
  ButtonContainer,
  ChartContainer,
  Container,
  SetInfoWrapper,
  ExerciseInfoContainer,
  PageContainer,
  SetInfoContainer,
  ExerciseDropdown
} from './styles';

const initialSet: IExerciseRecords = {
  exerciseKind: '',
  sets: [
    {
      weight: 0,
      reps: 0
    }
  ],
  images: [],
  videos: []
};

export function DashboardChartFormPage() {
  const { showToast } = useToast();

  const navigate = useNavigate();

  const {
    confirmationFormIdProps,
    setCustomerDetail,
    registeredLectureId,
    lectureRecord
  } = useDashboard();

  const [sets, setSets] = useState<IExerciseRecords[]>(
    lectureRecord.exerciseRecord?.map(item => ({
      exerciseKind: item.exerciseKindName,
      images: item.images.map(image => image.id),
      videos: item.videos.map(video => video.id),
      sets: item.sets.map(value => ({
        weight: value.set.weight,
        reps: value.set.reps
      }))
    })) ?? [{ ...initialSet }]
  );
  const [comment, setComment] = useState<string>(
    lectureRecord.registeredLectureRecord?.comment ?? ''
  );

  const isFullfillSet =
    sets.length > 0 && sets.every(set => set.exerciseKind) && !!comment;

  const [isValid, setIsValid] = useState<boolean>(() => isFullfillSet);

  const [exerciseKeyword, setExerciseKeyword] = useState<string>('');
  const [keywordIdx, setKeywordIdx] = useState<number | null>(null);
  const exerciseNames = useGetExerciseNames({ keyword: exerciseKeyword });

  useEffect(() => {
    setIsValid(isFullfillSet);
  }, [sets, comment]);

  const { mutateAsync: mutatePostAsync } = useMutation(postRecord);
  const { mutateAsync: mutatePatchAsync } = useMutation(patchRecord);

  const addSet = (idx: number) => {
    setSets(prev =>
      prev.map((set, index) =>
        index === idx
          ? {
              ...set,
              sets: [...set.sets, { weight: 0, reps: 0 }]
            }
          : set
      )
    );
  };

  const removeSet = (idx: number, itemIdx: number) => {
    if (itemIdx === 0) {
      showToast({
        type: 'fail',
        message: '세트 입력은 필수입니다.'
      });
      return;
    }
    setSets(prev =>
      prev.map((set, setIndex) =>
        setIndex === idx
          ? {
              ...set,
              sets: set.sets.filter(
                (_, setItemIndex) => setItemIndex !== itemIdx
              )
            }
          : set
      )
    );
  };

  const removeExercise = (idx: number) => {
    if (idx === 0) {
      showToast({
        type: 'fail',
        message: '운동 입력은 필수입니다.'
      });
      return;
    }
    setSets(prev => prev.filter((_, index) => index !== idx));
  };

  const setItemChange = (
    idx: number,
    itemIdx: number,
    updates: Partial<ISet>
  ) => {
    setSets(prev =>
      prev.map((set, setIndex) =>
        setIndex === idx
          ? {
              ...set,
              sets: set.sets.map((setItem, setItemIndex) =>
                setItemIndex === itemIdx
                  ? {
                      ...setItem,
                      ...updates
                    }
                  : setItem
              )
            }
          : set
      )
    );
  };

  const submitButtonClick = async () => {
    try {
      await mutatePostAsync({
        registeredLectureId,
        payload: { records: sets, comment }
      });
      showToast({
        message: '운동차트 등록에 성공하였습니다.'
      });
      getCustomerDetail({
        personId: confirmationFormIdProps.personId,
        id: confirmationFormIdProps.id
      }).then(data => {
        setCustomerDetail(data);
      });

      navigate(PATH.DASHBOARD_CUSTOMER);
    } catch (error) {
      showToast({
        type: 'fail',
        message: '운동차트 등록에 실패하였습니다.'
      });
    }
  };

  const modifyButtonClick = async () => {
    try {
      await mutatePatchAsync({
        registeredLectureId,
        payload: { records: sets, comment }
      });
      showToast({
        message: '운동차트 수정에 성공하였습니다.'
      });
      getCustomerDetail({
        personId: confirmationFormIdProps.personId,
        id: confirmationFormIdProps.id
      }).then(data => {
        setCustomerDetail(data);
      });

      navigate(PATH.DASHBOARD_CUSTOMER);
    } catch (error) {
      showToast({
        type: 'fail',
        message: '운동차트 수정에 실패하였습니다.'
      });
    }
  };

  return (
    <PageContainer onClick={() => setExerciseKeyword('')}>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.DASHBOARD_CUSTOMER}
        title="운동차트"
        defaultBackground
      >
        <>
          <RecordDetailLectureInfo
            {...lectureRecord.registeredLectureRecord}
            isCoach
          />
          <Container className="records">
            <ChartContainer>
              {sets.map((set: IExerciseRecords, idx: number) => (
                // <>
                <ExerciseInfoContainer key={idx}>
                  <BorderlessInput
                    placeholder="운동 이름을 입력해주세요"
                    value={set.exerciseKind}
                    onChange={e => {
                      setSets(prev =>
                        prev.map((set, index) => ({
                          ...set,
                          exerciseKind:
                            index === idx ? e.target.value : set.exerciseKind
                        }))
                      );
                      setKeywordIdx(idx);
                      setExerciseKeyword(e.target.value);
                    }}
                  />
                  {exerciseKeyword && keywordIdx === idx && (
                    <ExerciseDropdown>
                      {exerciseNames.map((name, nameIdx) => (
                        <li
                          key={nameIdx}
                          onClick={() => {
                            setSets(prev =>
                              prev.map((item, index) => ({
                                ...item,
                                exerciseKind:
                                  index === idx ? name : item.exerciseKind
                              }))
                            );
                            setExerciseKeyword('');
                          }}
                        >
                          {name}
                        </li>
                      ))}
                    </ExerciseDropdown>
                  )}
                  <SetInfoWrapper>
                    {set.sets.length > 0 && (
                      <SetInfoContainer>
                        {set.sets.map((item: ISet, itemIdx: number) => (
                          <div key={itemIdx}>
                            <Text textStyle="t3">{itemIdx + 1}세트</Text>
                            <div>
                              <BorderlessInput
                                className="input"
                                value={item.weight}
                                onChange={e =>
                                  (isNumeric(e.target.value) ||
                                    e.target.value === '') &&
                                  setItemChange(idx, itemIdx, {
                                    weight: Number(e.target.value)
                                  })
                                }
                              />
                              <Text textStyle="t3">kg</Text>
                            </div>
                            <div>
                              <BorderlessInput
                                className="input"
                                value={item.reps}
                                onChange={e =>
                                  (isNumeric(e.target.value) ||
                                    e.target.value === '') &&
                                  setItemChange(idx, itemIdx, {
                                    reps: Number(e.target.value)
                                  })
                                }
                              />
                              <Text textStyle="t3">회</Text>
                            </div>
                            <BoxIcon
                              type="x-white"
                              onlyIcon
                              size={12}
                              onClick={() => removeSet(idx, itemIdx)}
                            />
                          </div>
                        ))}
                      </SetInfoContainer>
                    )}
                    <div>
                      <Text
                        textStyle="c1"
                        textColor="gray2"
                        onClick={() => addSet(idx)}
                      >
                        세트 추가
                      </Text>
                      <Text
                        textStyle="c1"
                        textColor="point"
                        onClick={() => removeExercise(idx)}
                      >
                        운동 삭제
                      </Text>
                    </div>
                  </SetInfoWrapper>
                  <UploadFiles
                    registeredLectureId={registeredLectureId}
                    idx={idx}
                    sets={sets}
                    setSets={setSets}
                  />
                </ExerciseInfoContainer>
                // </>
              ))}
            </ChartContainer>
            <AddBox>
              <BoxIcon
                type="plus-gray"
                onlyIcon={true}
                size={22}
                onClick={() => setSets([...sets, initialSet])}
              />
              <Text textStyle="c1">운동을 추가해주세요</Text>
            </AddBox>
          </Container>
          <Container>
            <Text textStyle="t2">전문가 코멘트</Text>
            <div className="textArea">
              <Textarea
                value={comment}
                onChange={e => setComment(e)}
                height={10.8}
                limit={200}
                placeholder="식단, 관리법 등 회원님께 도움이 될 수 있는
                전문가 한 마디를 적어주세요!"
              ></Textarea>
            </div>
          </Container>
          <ButtonContainer>
            <Button
              disabled={!isValid}
              onClick={
                !!lectureRecord.exerciseRecord?.length
                  ? modifyButtonClick
                  : submitButtonClick
              }
            >
              {!!lectureRecord.exerciseRecord?.length ? '수정하기' : '등록하기'}
            </Button>
          </ButtonContainer>
        </>
      </PrevHeaderBarWithTitleTemplate>
    </PageContainer>
  );
}

function UploadFiles({
  idx,
  sets,
  setSets,
  registeredLectureId
}: {
  idx: number;
  sets: IExerciseRecords[];
  setSets: React.Dispatch<React.SetStateAction<IExerciseRecords[]>>;
  registeredLectureId: string;
}) {
  const { lectureRecord } = useDashboard();
  const images =
    (lectureRecord &&
      lectureRecord.exerciseRecord &&
      lectureRecord.exerciseRecord[idx]?.images.map(image => ({
        ...image,
        type: 'image'
      }))) ||
    [];
  const videos =
    (lectureRecord &&
      lectureRecord.exerciseRecord &&
      lectureRecord.exerciseRecord[idx]?.videos.map(video => ({
        ...video,
        type: 'video'
      }))) ||
    [];

  const {
    localImgs: localBeforeImgs,
    handleUploadImages: uploadBeforeImages,
    handleDeleteLocalImage: deleteLocalBeforeImage,
    postedImgs: postedBeforeImgs,
    handleDeletePostedImage: deletePostedBeforeImage,
    maxCount,
    getFileInfo,
    handleUploadChartFiles: uploadChartFiles
  } = useUploadImages({
    fileType: 'exercise-image',
    images: [...images, ...videos],
    registeredLectureId
  });

  useEffect(() => {
    const updatedSets = sets.map((item, index) => {
      if (index === idx) {
        const postedVideoIds = postedBeforeImgs
          .filter(file => file.type === 'video' && file.id)
          .map(file => file.id);
        const postedImageIds = postedBeforeImgs
          .filter(file => file.type === 'image' && file.id)
          .map(file => file.id);
        const videoIds = getFileInfo
          .filter(file => file.type === 'video' && file.id)
          .map(file => file.id);
        const imageIds = getFileInfo
          .filter(file => file.type === 'image' && file.id)
          .map(file => file.id);
        return {
          ...item,
          images: [...postedImageIds, ...imageIds],
          videos: [...postedVideoIds, ...videoIds]
        };
      }
      return item;
    });
    setSets(updatedSets);
  }, [getFileInfo, postedBeforeImgs]);
  const imageAndVideoType = imageFileType + videoFileType;
  return (
    <TitleItemBox title="사진 등록" type="images">
      <Spacing size={10} />
      <ImageWithUpload
        postedImages={postedBeforeImgs}
        localImages={localBeforeImgs}
        onUploadImage={uploadBeforeImages}
        onDeleteLocalImage={deleteLocalBeforeImage}
        onDeletePostedImage={deletePostedBeforeImage}
        maxCount={maxCount}
        handleUploadChartFiles={uploadChartFiles}
        type="chart"
        fileType={imageAndVideoType}
      />
      <Spacing size={20} />
    </TitleItemBox>
  );
}
