import styled from 'styled-components';
import { BoxIcon, Text } from '#atoms';
import { REVIEW_LIST } from '#const/review';
import { IAverageLectureRate } from '#types/rates';
import StarRates from './StarRates';

interface Props {
  data: IAverageLectureRate | undefined;
}

export const CoachRate = ({ data }: Props) => {
  return (
    <Container>
      <Text textStyle="t3">평점</Text>
      <div>
        <AverageRateContainer>
          <BoxIcon type="star_purple" onlyIcon={true} size={36} />
          <div>
            <Text type="inline" textStyle="display">
              {data?.averageRate}{' '}
            </Text>
            <Text type="inline" textColor="gray2">
              {' '}
              / 5.0
            </Text>
          </div>
        </AverageRateContainer>
        <PartRateContainer>
          {REVIEW_LIST.map(({ id, title }) => (
            <div key={id} data-text-type="c2">
              <Text>{title}</Text>
              <StarRates value={data?.[id] ?? 0} />
              <Text>{data?.[id] ?? 0}</Text>
            </div>
          ))}
        </PartRateContainer>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  > div {
    display: flex;
    margin: 0 auto;
  }
`;

const AverageRateContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-right: 2rem;
`;

const PartRateContainer = styled.div`
  border-left: 2px solid var(--color-gray-second);
  padding-left: 2rem;
  color: var(--color-gray-text-second);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > div {
    display: grid;
    grid-template-columns: 2fr 4fr 1fr;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
  }
`;
