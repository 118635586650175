import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { deleteMyTags, postMyTags } from '#api/accounts';
import { useGetAllTags } from '#api/tags';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { GridCategory } from '#molecules';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { ITag } from '#types/tags';
import { ButtonContainer, Container, TextContainer } from './styles';

export function ProfileCategoryPage() {
  const { showToast } = useToast();
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState<Array<ITag>>([]);
  const { userInfo, patchProfile } = useUserInfo();
  const { data: tagsData } = useGetAllTags();

  const initialCategories =
    tagsData?.data.filter(
      e => userInfo?.tags.some(tag => tag.name === e.name)
    ) ?? [];

  const onChangeCategory = useCallback(
    (selectedItems: ITag[]) => setSelectedCategory(selectedItems),
    [selectedCategory]
  );

  const handleButtonClick = () => {
    const tags = tagsData ? tagsData.data : [];
    const willPostTagNames = selectedCategory.filter(
      e => !initialCategories.map(tag => tag.name).includes(e.name)
    );
    const willDeleteTagNames = initialCategories.filter(
      e => !selectedCategory.map(tag => tag.name).includes(e.name)
    );
    const willPostTagIds = willPostTagNames
      .map(e => tags.find(tag => tag.name === e.name)?.id)
      .filter((id): id is string => id !== undefined);
    const willDeleteTagIds = willDeleteTagNames
      .map(e => tags.find(tag => tag.name === e.name)?.id)
      .filter((id): id is string => id !== undefined);

    Promise.all([
      willPostTagIds.length && postMyTags(willPostTagIds),
      willDeleteTagIds.length && deleteMyTags(willDeleteTagIds)
    ])
      .then(() => {
        patchProfile(
          // TODO: 강제 프로필 업데이트 로직 변경
          { tags: selectedCategory },
          {
            onSuccess: () => {
              showToast({
                message: '운동 카테고리 등록에 성공하였습니다.'
              });
              navigate(PATH.PROFILE_UPDATE);
            }
          }
        );
      })
      .catch(() => {
        showToast({
          type: 'fail',
          message: '운동 카테고리 등록에 실패하였습니다.'
        });
      });
  };

  return (
    <>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.PROFILE_UPDATE}
        title="운동 카테고리 등록"
        headerbarType="relative"
      >
        <Container>
          <TextContainer>
            <Text textStyle="display">수업 가능한 운동을 선택해주세요</Text>
            <Text textStyle="c1" textColor="red">
              {selectedCategory.length > 3 ? '최대 등록 개수는 3개입니다' : ``}
            </Text>
          </TextContainer>
          {tagsData?.data && userInfo && (
            <GridCategory
              categories={tagsData?.data ?? []}
              onChange={onChangeCategory}
              initialValue={initialCategories}
            />
          )}
        </Container>
      </PrevHeaderBarWithTitleTemplate>
      <ButtonContainer>
        <Button
          disabled={!selectedCategory.length || selectedCategory.length > 3}
          onClick={handleButtonClick}
        >
          프로필 등록
        </Button>
      </ButtonContainer>
    </>
  );
}
