import { useCallback, useEffect, useState } from 'react';
import { useGetJobs } from '#api/jobs';
import { Button, ItemPicker, Text } from '#atoms';
import { useApplications } from '#providers/Applications';
import { FibudContainerTemplate, useProgress } from '#templates';
import { Id } from '#types/confirmationForms';
import { Container, TextContainer } from './styles';

export function ApplicationJob() {
  const { next } = useProgress();
  const { data } = useGetJobs();
  const { updateCurrentApplications, currentApplication } = useApplications();
  const [selectedJob, setSelectedJob] = useState<Id | null>(null);

  const updateJob = useCallback(
    (jobId: string) => {
      updateCurrentApplications({
        // job: data?.data.find(item => item.name === jobName)?.id || ''
        job: jobId
      });
    },
    [currentApplication]
  );

  useEffect(() => {
    selectedJob && updateJob(selectedJob.toString());
  }, [selectedJob]);

  return (
    <FibudContainerTemplate type="paddingWithBottomButton">
      <FibudContainerTemplate type="bottomButtonContainer">
        <Container>
          <TextContainer>
            <Text textStyle="t1">현재 직업을 알려주세요</Text>
          </TextContainer>
          {/* <SelectBox
            placeholder="직업을 선택해주세요"
            data={data?.data.map(item => item.name) ?? []}
            onChange={updateJob}
          /> */}
          <ItemPicker
            data={data?.data ?? []}
            setSelectedItem={setSelectedJob}
            initialValue={currentApplication?.job}
          />
          {/* <Text textStyle="t3">해당하는 항목에 체크해주세요</Text>
          <CheckList checkItems={JOB_CHECKLIST} /> */}
        </Container>
      </FibudContainerTemplate>
      <FibudContainerTemplate type="bottomButton">
        <Button onClick={() => next()} disabled={!currentApplication?.job}>
          다음
        </Button>
      </FibudContainerTemplate>
    </FibudContainerTemplate>
  );
}
