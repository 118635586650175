import styled from 'styled-components';

export const HorizonLineContainer = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #aaa;
  line-height: 0.1em;
  margin: 10px 0 20px;
`;

export const DashedLineContainer = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px dashed #aaa;
  line-height: 0.1em;
  margin: 10px 0 20px;
`;

export const DottedLineContainer = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px dotted #aaa;
  line-height: 0.1em;
  margin: 10px 0 20px;
`;
