import styled from 'styled-components';

export const CoachInfoWrap = styled.div`
  padding: 1rem 2rem;
`;

export const TagArea = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-gray-text-second);

  & > p:not(:last-of-type)::after {
    content: '|';
    padding: 0 0.5rem;
  }
`;

export const NameArea = styled.div`
  margin-top: 0.5rem;
  color: var(--color-white);
`;

export const LocationArea = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-gray-text-second);

  svg {
    width: 1.25rem;
    margin-right: 0.5rem;
  }
`;

export const GradeArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  color: var(--color-gray-text-second);

  svg {
    width: 1.25rem;
    margin-right: 0.5rem;
  }

  & > p:not(:last-of-type)::after {
    content: '|';
    padding: 0 0.5rem;
  }
`;
