import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 5.8rem;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  max-width: var(--max-width);
  margin: 0 auto;
`;
