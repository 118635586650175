import React from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useCheckValidState } from '#hooks/useCheckValidState';
import { SignUpNameInput, SignUpNicknameInput } from '#organisims';
import { $signupState } from '#states/atoms/recoilSignupState';
import { PrevWithTitleTextTemplate } from '#templates';
import { ButtonContainer, Container } from './styles';

export function SignUpNickname() {
  const [signupState, setSignupState] = useRecoilState($signupState);
  const navigate = useNavigate();

  useCheckValidState({
    targetState: typeof signupState.isCoach === 'boolean',
    navigateTo: PATH.SIGN_UP_CATEGORY
  });

  const handleNameChange = (name: string | null) => {
    setSignupState(prev => {
      return { ...prev, name };
    });
  };

  const handleNicknameChange = (nickname: string | null) => {
    setSignupState(prev => {
      return { ...prev, nickname };
    });
  };

  return (
    <PrevWithTitleTextTemplate
      prevRoute={PATH.SIGN_UP_PASSWORD}
      title={
        <Text textStyle="display">
          이름과 닉네임을
          <br />
          입력해주세요
        </Text>
      }
    >
      <Container>
        <SignUpNameInput
          value={signupState.name}
          setUserName={handleNameChange}
        />
        <SignUpNicknameInput
          value={signupState.nickname}
          setUserNickname={handleNicknameChange}
        />
      </Container>
      <ButtonContainer>
        <Button
          disabled={!signupState.nickname || !signupState.name}
          onClick={() => navigate(PATH.SIGN_UP_GENDER)}
        >
          다음
        </Button>
      </ButtonContainer>
    </PrevWithTitleTextTemplate>
  );
}
