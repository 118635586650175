import { useEffect, useState } from 'react';
import { isValidPathString } from '#utils/typeguard';

interface MyUrl {
  isValid: boolean;
  value: string;
}

interface Props {
  currentMyUrl: string;
}

export const useMyUrl = ({ currentMyUrl }: Props) => {
  const [myUrl, setMyUrl] = useState<MyUrl>({
    isValid: true,
    value: currentMyUrl
  });

  const updateMyUrl = (nextMyUrl: string) => {
    setMyUrl(prev => ({ ...prev, value: nextMyUrl }));
  };

  useEffect(() => {
    const { value } = myUrl;
    setMyUrl(prev => ({
      ...prev,
      isValid: isValidPathString(value) ? true : false
    }));
  }, [myUrl.value]);

  return { myUrl, updateMyUrl };
};
