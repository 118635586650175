import classNames from 'classnames';
import React from 'react';
import DumBell from '#assets/img/dumbbell.svg';
import User from '#assets/img/user.svg';
import { Text } from '#atoms';
import {
  Container,
  IconArea,
  MemberTypeSelectorButton,
  TextArea
} from './styles';

interface Props {
  isCoach: boolean | null;
  onChange: (param: boolean) => void;
}

export const SignUpMemberTypeSelector = ({ isCoach, onChange }: Props) => {
  return (
    <Container>
      {/* trainee */}
      <MemberTypeSelectorButton
        onClick={() => onChange(false)}
        className={classNames({ active: isCoach !== null && !isCoach })}
      >
        <IconArea>
          <img src={User} />
        </IconArea>
        <TextArea>
          <Text textStyle="t3">고객으로 가입</Text>
          <Text textColor="gray2">운동 트레이닝을 받고 싶어요</Text>
        </TextArea>
      </MemberTypeSelectorButton>
      {/* trainer */}
      <MemberTypeSelectorButton
        onClick={() => onChange(true)}
        className={classNames({ active: isCoach })}
      >
        <IconArea>
          <img src={DumBell} />
        </IconArea>
        <TextArea>
          <Text textStyle="t3">전문가로 가입</Text>
          <Text textColor="gray2">고객을 만나고 싶어요</Text>
        </TextArea>
      </MemberTypeSelectorButton>
    </Container>
  );
};
