import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  deleteCoachSchedule,
  postCoachSchedules,
  useGetCoachSchedule
} from '#api/schedules';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { DateTimePicker } from '#molecules';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { DateTime, Schedule } from '#types/schedule';
import { dateNameFormatter, yearMonthDayFormatter } from '#utils/dateFormatter';
import { timeFormatter } from '#utils/timeFormatter';
import {
  AddBox,
  ButtonContainer,
  Container,
  Content,
  ContentBox,
  DateTimeBox,
  HeaderContainer
} from './styles';

export function ProfileSchedulePage() {
  const { showToast } = useToast();

  const { userInfo } = useUserInfo();
  const { data } = useGetCoachSchedule({
    coachId: userInfo ? userInfo.id : '',
    startAt: yearMonthDayFormatter()
  });
  const [dateTime, setDateTime] = useState<Schedule[]>([]);
  const { mutateAsync } = useMutation(deleteCoachSchedule);

  useEffect(() => {
    data &&
      setDateTime(
        data.schedules.map(e => ({
          day: e.day,
          startAt: timeFormatter(e.startAt), // Date.parse로 통일
          endAt: timeFormatter(e.endAt)
        }))
      );
  }, [data]);

  const [completed, setCompleted] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleDateTimeComplete = ({ dates, startTime, endTime }: DateTime) => {
    setDateTime(prev => [
      ...prev,
      {
        day: dates.join(', '),
        startAt: startTime,
        endAt: endTime
      }
    ]);
  };

  const handleRemoveDateTime = (indexToRemove: number) => {
    setDateTime(prev => prev.filter((_, index) => index !== indexToRemove));
  };

  const handlePostButtonClick = async () => {
    try {
      await mutateAsync();
      await postCoachSchedules(
        dateTime.map(schedule => ({
          day: schedule.day,
          startAt: schedule.startAt + ':00',
          endAt: schedule.endAt + ':00'
        }))
      );
      showToast({
        message: '스케줄 등록에 성공하였습니다.'
      });
      navigate(PATH.PROFILE_UPDATE);
    } catch (error) {
      showToast({
        type: 'fail',
        message: '스케줄 등록에 실패하였습니다.'
      });
    }
  };

  return (
    <Container>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.PROFILE_UPDATE}
        title="수업시간 등록"
        headerbarType="relative"
      >
        <HeaderContainer>
          <Text textStyle="t1">수업 가능한 시간대를 등록해주세요</Text>
          <Text textStyle="c1" textColor="gray2">
            등록하신 시간에 맞춰 고객의 신청이 들어옵니다
          </Text>
        </HeaderContainer>
        <DateTimePicker
          onComplete={handleDateTimeComplete}
          isCompleted={completed}
        />
        <ContentBox className="color-gray-second">
          {dateTime ? (
            dateTime.map((item, index) => (
              <Content key={index}>
                <DateTimeBox>
                  <Text>{dateNameFormatter(item.day)}</Text>
                  <Text>
                    {item.startAt} - {item.endAt}{' '}
                  </Text>
                </DateTimeBox>
                <BoxIcon
                  type="x-gray"
                  onlyIcon={true}
                  size={13}
                  onClick={() => handleRemoveDateTime(index)}
                />
              </Content>
            ))
          ) : (
            <></>
          )}
        </ContentBox>
        <AddBox>
          <BoxIcon
            type="plus-gray"
            onlyIcon={true}
            size={26}
            onClick={() => setCompleted(prev => !prev)}
          />
          <Text textStyle="c1">시간 추가</Text>
        </AddBox>
      </PrevHeaderBarWithTitleTemplate>
      <ButtonContainer>
        <Button disabled={!dateTime.length} onClick={handlePostButtonClick}>
          등록
        </Button>
      </ButtonContainer>
    </Container>
  );
}
