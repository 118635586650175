import { CoachAccount } from '#types/account';
import { CoachInfoItem } from '../CoachGridSelector/CoachInfoItem';
import { CoachSelectorWrap, Container, GridArea } from './styles';

interface CoacheListProps {
  coachesList: CoachAccount[];
  setScrollRestoration: () => void;
  setTarget: React.Dispatch<
    React.SetStateAction<HTMLDivElement | null | undefined>
  >;
}

export const CoacheList = ({
  coachesList,
  setScrollRestoration,
  setTarget
}: CoacheListProps) => {
  return (
    <Container>
      <CoachSelectorWrap>
        <GridArea>
          {coachesList?.length === 0 ? (
            <div>목록이 없습니다.</div>
          ) : (
            coachesList?.map(coach => (
              <CoachInfoItem
                key={coach.id}
                data={coach}
                onClick={setScrollRestoration}
              />
            ))
          )}
        </GridArea>
        <div ref={setTarget} style={{ height: '5px' }} />
      </CoachSelectorWrap>
    </Container>
  );
};
