import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;
  gap: 2rem;
`;

export const MenuContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const MenuItem = styled.li`
  display: flex;
  gap: 1rem;
  align-items: center;

  & > hr {
    width: 100%;
    border-color: var(--color-border);
  }
`;
