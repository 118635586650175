import classNames from 'classnames';
import React from 'react';
import { BoxIcon, Text } from '#atoms';
import { ToastContainer } from './styles';
interface Props {
  text: string;
  visibility: 'visible' | 'hidden';
  type?: 'success' | 'fail';
}

export const Toast = ({ text, visibility, type }: Props) => {
  return (
    <ToastContainer className={classNames(visibility, type)}>
      <BoxIcon
        type={type === 'fail' ? 'x-circle-white' : 'check-circle-white'}
        onlyIcon
        size={14}
      />
      <Text textStyle="c1">{text}</Text>
    </ToastContainer>
  );
};
