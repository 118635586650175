import React from 'react';
import {
  HeaderBarWrap,
  ContentArea,
  Title,
  RightArea,
  GaugeBar,
  GaugeArea,
  BottomBar
} from './styles';

/**
 * @description
 * 모든 페이지 레이아웃은 좌우 패딩 1rem이 적용되어있기 때문에
 * bottombar는 좌우패딩의 영향을 받지 않고 표시되는 것을 의도하고자
 * width값을 100%에서 2rem 더하였으며, translateX 속성에 -1rem을 부여하였습니다.
 * 추후 페이지 레이아웃 좌우 패딩 값이 바뀐다면, bottombar의 rem 수치값도 직접 조정해야 합니다.
 *
 * progressPercentage 값의 범위는 0이상 100이하 입니다.
 */

interface Props {
  text?: string;
  leftButtonImgSrc?: string;
  onLeftButtonClick?: React.MouseEventHandler<HTMLImageElement>;
  rightButtonImgSrc1?: string;
  onRightButton1Click?: React.MouseEventHandler<HTMLImageElement>;
  rightButtonImgSrc2?: string;
  onRightButton2Click?: React.MouseEventHandler<HTMLImageElement>;
  showGauge?: boolean;
  progressPercentage?: number;
  transparentBackground?: boolean;
}

export const HeaderBar = ({
  text,
  leftButtonImgSrc,
  onLeftButtonClick,
  rightButtonImgSrc1,
  onRightButton1Click,
  rightButtonImgSrc2,
  onRightButton2Click,
  showGauge,
  progressPercentage,
  transparentBackground
}: Props) => {
  return (
    <HeaderBarWrap transparentBackground={transparentBackground ?? false}>
      <ContentArea>
        {leftButtonImgSrc && (
          <img src={leftButtonImgSrc} onClick={onLeftButtonClick} />
        )}
        <Title className="text-18-600">{text}</Title>
        <RightArea
          className={rightButtonImgSrc1 && rightButtonImgSrc2 && 'both'}
        >
          {rightButtonImgSrc1 && (
            <img src={rightButtonImgSrc1} onClick={onRightButton1Click} />
          )}
          {rightButtonImgSrc2 && (
            <img src={rightButtonImgSrc2} onClick={onRightButton2Click} />
          )}
        </RightArea>
      </ContentArea>
      {showGauge && progressPercentage ? (
        <GaugeArea>
          <GaugeBar width={progressPercentage} />
        </GaugeArea>
      ) : text && !transparentBackground ? (
        <BottomBar />
      ) : (
        ''
      )}
    </HeaderBarWrap>
  );
};
