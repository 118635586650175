import { useNavigate } from 'react-router';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import {
  ArticleContainer,
  ButtonContainer,
  Container,
  ContentContainer
} from './styles';

export const FinalRatingSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <ContentContainer>
        <ArticleContainer>
          <ContentContainer className="check">
            <BoxIcon type="purple-check" size={60} onlyIcon />
            <Text textStyle="display">모든 수업이 완료되었습니다</Text>
            <div>
              <Text>피벗이 회원님의</Text>
              <Text>건강한 운동라이프를 응원합니다</Text>
            </div>
          </ContentContainer>
        </ArticleContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={() => navigate(PATH.HOME)}>확인</Button>
      </ButtonContainer>
    </Container>
  );
};
