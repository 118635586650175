import React from 'react';
import { Route, Routes } from 'react-router';
import {
  WelcomeCoach,
  WelcomeConsumer,
  WelcomeScheduleReserve,
  WelcomeScheduleReserveFail
} from '#pages';

export function WelcomeView() {
  return (
    <Routes>
      <Route path="consumer" element={<WelcomeConsumer />} />
      <Route path="coach" element={<WelcomeCoach />} />
      <Route
        path="schedule-reserve/*"
        element={
          <Routes>
            <Route path="" element={<WelcomeScheduleReserve />} />
            <Route path="fail" element={<WelcomeScheduleReserveFail />} />
          </Routes>
        }
      />
    </Routes>
  );
}
