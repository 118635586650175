import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 0 9.3rem;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 3rem 1.65rem;
`;

export const LocationAreaWrap = styled.div`
  padding: 0 1.65rem;
`;

export const LocationArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.65rem;
  border-radius: 0.75rem;
  border: 1px solid var(--color-border-light);
  color: var(--color-white);
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-top: 1.65rem;
  align-items: stretch;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  padding: 1rem 1rem 3rem;
  background-color: var(--color-background);
  p {
    text-decoration: underline;
    text-align: center;
    margin-top: 1rem;
    cursor: pointer;
  }
`;
