import { useRecoilState } from 'recoil';
import { routeHistoryState } from '#states/atoms/routeHistoryState';

export const useRouteHistory = () => {
  const [routeHistory, setRouteHistoryState] =
    useRecoilState(routeHistoryState);

  // 히스토리 설정
  const setRouteHistory = (newHistory: string[]) => {
    setRouteHistoryState(newHistory);
  };

  // 히스토리 초기화
  const clearRouteHistory = () => {
    setRouteHistoryState([]);
  };

  return {
    routeHistory,
    setRouteHistory,
    clearRouteHistory
  };
};
