import { BoxIcon, ModalPortal } from '#atoms';
import { FibudContainerTemplate } from '#templates';
import { PolicyViewer } from '../PolicyViewer';
import { Background, HeaderContainer } from './styles';

interface Props {
  type: string;
  onCancel: () => void;
}

export function PolicyModal({ type, onCancel }: Props) {
  return (
    <ModalPortal>
      <Background>
        <HeaderContainer>
          <BoxIcon type="x-white" size={18} onlyIcon onClick={onCancel} />
        </HeaderContainer>
        <FibudContainerTemplate type="common">
          <PolicyViewer type={type} />
        </FibudContainerTemplate>
      </Background>
    </ModalPortal>
  );
}
