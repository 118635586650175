import { CheckPurple } from '#assets/svg';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import sessionStorageManager from '#utils/sessionStorageManager';
import { useLocation, useNavigate } from 'react-router';
import {
  ArticleContainer,
  ButtonContainer,
  Container,
  ContentContainer,
  FieldContainer
} from './styles';

export function ApplicationSuccess() {
  const navigate = useNavigate();

  // search에서 lectureId를 가져오기
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const lectureId = params.get('lecture-id');

  // 신청서 작성 페이지를 위해 sessionStorage에 confirmationFormId 저장
  const { setConfirmationFormId } = sessionStorageManager();

  return (
    <Container>
      <ContentContainer>
        <ArticleContainer>
          <ContentContainer className="check">
            <CheckPurple />
            <Text textStyle="display">결제가 완료되었습니다!</Text>
          </ContentContainer>
          <FieldContainer>
            <Text textStyle="t3" textColor="white">
              수강 신청서를 작성하고 <br /> 수업 일정을 잡아보세요
            </Text>
          </FieldContainer>
        </ArticleContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          onClick={() => {
            setConfirmationFormId(lectureId || '');
            navigate(PATH.APPLICATION);
          }}
        >
          신청서 작성하기
        </Button>
      </ButtonContainer>
    </Container>
  );
}
