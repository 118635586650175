import { useEffect, useState } from 'react';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from '#const/main/location';
import getGeolocation from '#utils/getGeolocation';

export const useGeolocation = () => {
  const [position, setPosition] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const position = await getGeolocation();
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      } catch (e) {
        setPosition({ lat: DEFAULT_LATITUDE, lng: DEFAULT_LONGITUDE });
      }
    };

    fetchLocation();
  }, []);

  return { position, setPosition };
};
