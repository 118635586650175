import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { IGetBankNumber, IGetBanks, IPostBankNumbers } from '#types/banks';

export const getBanks = async () => {
  const res = await request<IGetBanks>({
    method: 'get',
    url: '/banks'
  });

  return res.data;
};

export const useGetBanks = () => {
  return useQuery(queryKeyFactory.GET_BANKS(), getBanks);
};

export const postBankNumbers = async (payload: IPostBankNumbers) => {
  const res = await request({
    method: 'post',
    url: '/bank-numbers',
    data: payload
  });

  return res.data;
};

export const getBankNumbers = async () => {
  const res = await request<IGetBankNumber>({
    method: 'get',
    url: '/bank-numbers'
  });

  return res.data;
};
