import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useAttendingLectures } from '#providers/AttendLecture';
import { useNavigate } from 'react-router';
import { URLSEARCH_PARAMS } from 'src/shared/constant';
import {
  Container,
  LectureBox,
  LectureTextWrapper,
  LectureWrapper
} from './styles';

export const LecturesLectureList = () => {
  const { lectures } = useAttendingLectures();
  const navigate = useNavigate();

  return (
    <Container>
      <Text textStyle="t2">진행중인 수업</Text>
      <div>
        {lectures.map(
          (
            {
              lecture,
              gymName,
              calculatedRegisteredLecture,
              confirmationFormId,
              status
            },
            idx
          ) => (
            <LectureWrapper key={idx}>
              <LectureTextWrapper>
                <LectureBox>
                  <Text textStyle="t3">{lecture.title}</Text>
                  <Text textStyle="t3">{lecture.coachName} 전문가</Text>
                </LectureBox>
                <LectureBox data-text-color="gray2">
                  <Text>{gymName}</Text>
                  <Text>
                    {calculatedRegisteredLecture.totalRegisteredLectureCount -
                      calculatedRegisteredLecture.remainingRegisteredLectureCount}
                    /{calculatedRegisteredLecture.totalRegisteredLectureCount}회
                  </Text>
                </LectureBox>
              </LectureTextWrapper>
              <Button
                onClick={() => {
                  status === 'TAKEN'
                    ? navigate(
                        `${PATH.APPLICATION}?${URLSEARCH_PARAMS.CONFIRMATION_FORM_ID}=${confirmationFormId}`
                      )
                    : navigate(`${PATH.CHART_LECTURE}?id=${lecture.id}`);
                }}
              >
                {status === 'TAKEN' ? '수강 신청서 작성하기' : '회차 정보'}
              </Button>
            </LectureWrapper>
          )
        )}
      </div>
    </Container>
  );
};
