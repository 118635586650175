import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PATH } from '#const/path';
import useCoachGymLecture from '#hooks/useCoachGymLecture';
import { CoachOTDetailForm, CoachOTDetailView } from '#organisims';
import CoachLecture from '#providers/CoachLecture';
import { useToast } from '#providers/ToastProvider';
import { PrevHeaderBarWithTitleTemplate } from '#templates';

export function ProfileOTPage() {
  const { showToast } = useToast();

  const { userInfo, gymRentalFee, otId } = useCoachGymLecture();
  const [type, setType] = useState<'view' | 'form'>('form');

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo && gymRentalFee === -1) {
      showToast({
        type: 'fail',
        message: '운동공간 등록 후 수업을 개설할 수 있습니다.'
      });
      navigate(PATH.PROFILE_UPDATE);
    }
  }, [userInfo, gymRentalFee]);

  useEffect(() => {
    otId && setType('view');
  }, [otId]);

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.PROFILE_UPDATE}
      title="체험수업 등록"
      headerbarType="relative"
    >
      <CoachLecture>
        {type === 'view' && otId ? (
          <CoachOTDetailView
            lectureId={otId}
            onClickModify={() => {
              setType('form');
            }}
          />
        ) : (
          <CoachOTDetailForm lectureId={otId} gymRentalFee={gymRentalFee} />
        )}
      </CoachLecture>
    </PrevHeaderBarWithTitleTemplate>
  );
}
