import { useState } from 'react';
import X from '#assets/svg/x_header_white.svg';
import { KakaoMap, ModalPortal } from '#atoms';
import { HeaderBar } from '#molecules';
import { GymLocationBottomSheet } from '#organisims';
import { IGym } from '#types/gyms';
import { Background } from './styles';

interface Props {
  data: IGym;
  onCancel: () => void;
}

export const LocationModal = ({ data, onCancel }: Props) => {
  const [isBottomSheetExpand, setIsBottomSheetExpand] =
    useState<boolean>(false);

  return (
    <ModalPortal>
      <Background>
        <HeaderBar leftButtonImgSrc={X} onLeftButtonClick={onCancel} />
        <KakaoMap
          latitude={Number(data.lat)}
          longitude={Number(data.long)}
          setIsBottomSheetExpand={setIsBottomSheetExpand}
        />
        <GymLocationBottomSheet
          name={data.name}
          address={data.address}
          phone={data.phone}
          images={data.images}
          introduction={data.introduction}
          isBottomSheetExpand={isBottomSheetExpand}
          setIsBottomSheetExpand={setIsBottomSheetExpand}
        />
      </Background>
    </ModalPortal>
  );
};
