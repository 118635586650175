import React from 'react';
import { BoxIcon, ModalPortal, Text } from '#atoms';
import BankList, { useBankList } from '#providers/BankList';
import {
  Background,
  HeaderContainer,
  Container,
  BankSelectorWrap,
  BankItem
} from './styles';

interface Props {
  onComplete: (item: { bankId: string; bankName: string }) => void;
  onClose: () => void;
}

export const BankSelectModal = ({ onComplete, onClose }: Props) => {
  const { banks } = useBankList();

  return (
    <ModalPortal>
      <Background>
        <HeaderContainer>
          <BoxIcon
            type="x-white"
            boxColor="transparent"
            size={18}
            onlyIcon={true}
            onClick={onClose}
          />
        </HeaderContainer>
        <Container>
          <Text textStyle="t1">금융기관을 선택해주세요</Text>
          <Text>은행</Text>
          <BankList.EmptyList>
            <div>은행 목록이 없습니다.</div>
          </BankList.EmptyList>
          <BankSelectorWrap>
            <BankList.HasList>
              {banks?.data.map(item => (
                <BankItem
                  key={item.id}
                  onClick={() =>
                    onComplete({ bankId: item.id, bankName: item.name })
                  }
                >
                  {item.logo ? (
                    <img src={item.logo.resizedLink} />
                  ) : (
                    <BoxIcon type="picture" onlyIcon size={30} />
                  )}
                  <Text textStyle="c2">{item.name}</Text>
                </BankItem>
              ))}
            </BankList.HasList>
          </BankSelectorWrap>
        </Container>
      </Background>
    </ModalPortal>
  );
};
