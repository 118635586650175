import styled from 'styled-components';

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const SignInButtonContainer = styled.div`
  margin-top: auto;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const TextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-text-second);
`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.6rem;
  color: var(--color-gray-text-second);
`;
