import { useNavigate } from 'react-router';
import { Button, Input, Text } from '#atoms';
import { PATH } from '#const/path';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarTemplate } from '#templates';
import { useName } from './hooks';
import { BottomContainer, InputContainer, TitleContainer } from './styles';

export function MyPageProfileName() {
  const navigate = useNavigate();
  const { userInfo, patchProfile } = useUserInfo();
  const { name, updateName } = useName({
    currentName: userInfo?.name ?? ''
  });

  const saveName = () => {
    patchProfile(
      { name: name.value },
      {
        onSuccess: () => navigate(PATH.MYPAGE)
      }
    );
  };

  return (
    <PrevHeaderBarTemplate prevRoute={PATH.MYPAGE_PROFILE}>
      <TitleContainer>
        <Text textStyle="t1">
          변경할 이름을
          <br />
          입력해주세요
        </Text>
      </TitleContainer>
      <InputContainer>
        <Input
          type="text"
          labelText="이름"
          placeholder="2 ~ 10자 이내로 입력해주세요"
          value={name.value}
          onChange={v => updateName(v.toString())}
        />
      </InputContainer>
      <BottomContainer>
        <Button onClick={saveName} disabled={!name.isValid}>
          저장하기
        </Button>
      </BottomContainer>
    </PrevHeaderBarTemplate>
  );
}
