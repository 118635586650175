import React from 'react';
import { useNavigate } from 'react-router';
import { BoxIcon, Button, ProfileImage, Text } from '#atoms';
import { PATH } from '#const/path';
import UserInfo, { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarTemplate } from '#templates';
import {
  BottomContainer,
  Container,
  StatusContainer,
  StatusContentArea
} from './styles';

export function MyPageProfile() {
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();

  return (
    <PrevHeaderBarTemplate prevRoute={PATH.MYPAGE}>
      <Text textStyle="display">내 정보 관리</Text>
      <ProfileImage
        src={userInfo?.profile.length ? userInfo?.profile[0].uploadedLink : ''}
      />
      <Container>
        <UserInfo.IsCoach>
          <StatusContainer onClick={() => navigate(PATH.MYPAGE_PROFILE_NAME)}>
            <Text>이름</Text>
            <StatusContentArea>
              <Text>{userInfo?.name}</Text>
              <BoxIcon
                type="next-arrow"
                width={9}
                height={16}
                onlyIcon={true}
              />
            </StatusContentArea>
          </StatusContainer>
        </UserInfo.IsCoach>
        <UserInfo.IsConsumer>
          <StatusContainer
            onClick={() => navigate(PATH.MYPAGE_PROFILE_NICKNAME)}
          >
            <Text>닉네임</Text>
            <StatusContentArea>
              <Text>{userInfo?.nickname}</Text>
              <BoxIcon
                type="next-arrow"
                width={9}
                height={16}
                onlyIcon={true}
              />
            </StatusContentArea>
          </StatusContainer>
        </UserInfo.IsConsumer>
        <StatusContainer>
          <Text>내 계정</Text>
          <StatusContentArea>
            <Text textColor="gray2">{userInfo?.email}</Text>
          </StatusContentArea>
        </StatusContainer>
      </Container>
      <BottomContainer>
        <Button onClick={() => navigate(PATH.MYPAGE_WITHDRAWAL_PRECAUTION)}>
          회원 탈퇴
        </Button>
      </BottomContainer>
    </PrevHeaderBarTemplate>
  );
}
