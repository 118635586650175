import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router';
import { deleteLecture } from '#api/lectures';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { TitleItemBox } from '#molecules';
import CoachLecture, { useCoachLecture } from '#providers/CoachLecture';
import { useToast } from '#providers/ToastProvider';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { formatNumberWithCommas } from '#utils/Numbers';
import {
  ButtonContainer,
  Container,
  CurriculaArea,
  CurriculaContainer,
  ImageItem,
  ImageList
} from './styles';

export function ProfileClassDetailPage() {
  const { showToast } = useToast();

  const { lectureId, lecture, curriculum, images } = useCoachLecture();
  const navigate = useNavigate();

  const { mutateAsync } = useMutation(deleteLecture);

  const handleDeleteButtonClick = async () => {
    try {
      await mutateAsync(lectureId);
      showToast({
        message: '수업 삭제에 성공하였습니다.'
      });
      navigate(PATH.PROFILE_CLASS);
    } catch (error) {
      showToast({
        type: 'fail',
        message: '수업 삭제에 실패하였습니다.'
      });
    }
  };

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.PROFILE_CLASS}
      title="정규 수업 등록"
      defaultBackground
    >
      <Container>
        <CoachLecture.IsLoading>로딩중...</CoachLecture.IsLoading>
        <CoachLecture.IsError>잘못된 접근입니다!</CoachLecture.IsError>
        <CoachLecture.EmptyLecture>
          강의 정보가 없습니다.
        </CoachLecture.EmptyLecture>
        <CoachLecture.HasLecture>
          <TitleItemBox title="가격">
            <Text>{formatNumberWithCommas(lecture.price)}원</Text>
          </TitleItemBox>
          <TitleItemBox title="수업 이름">
            <Text>{lecture.title}</Text>
          </TitleItemBox>
          <TitleItemBox title="수업 소개">
            <Text>{lecture.body}</Text>
          </TitleItemBox>
          <TitleItemBox title="수업 사진">
            <ImageList>
              {images?.map(item => (
                <ImageItem key={item.id}>
                  <img src={item.resizedLink} />
                </ImageItem>
              ))}
            </ImageList>
          </TitleItemBox>
          {/* <TitleItemBox title="회차 선택" type="row">
            <Text textColor="gray2">{`총 ${lecture.rounds}회`}</Text>
          </TitleItemBox> */}
          <div>
            <Text textStyle="t3">주차별 수업 계획</Text>
            <CurriculaArea>
              {curriculum &&
                curriculum.map((item, index) => (
                  <CurriculaContainer key={index}>
                    <Text>{`${item.order}주차`}</Text>
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                      {item.description}
                    </Text>
                  </CurriculaContainer>
                ))}
            </CurriculaArea>
          </div>
          <TitleItemBox title="권장 횟수" type="row">
            <Text textColor="gray2">
              {lecture.recommendRoundPerWeek
                ? `일주일에 ${lecture.recommendRoundPerWeek}회`
                : '없음'}
            </Text>
          </TitleItemBox>
          <ButtonContainer>
            <Button background="anti" onClick={handleDeleteButtonClick}>
              삭제
            </Button>
            <Button onClick={() => navigate(PATH.PROFILE_CLASS_FORM)}>
              수정
            </Button>
          </ButtonContainer>
        </CoachLecture.HasLecture>
      </Container>
    </PrevHeaderBarWithTitleTemplate>
  );
}
