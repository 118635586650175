import { IconType } from '#atoms';
import { PATH } from '#const/path';

/**
 * @description
 *
 * 백엔드에서 특정 enum 값을 요구하는 경우가 있습니다.
 * 이때 백엔드에서 요구하는 enum 값을 code로,
 * 이에 해당하는 프론트에서 사용할 이름 값을 name으로 지정해주시기 바랍니다.
 */

type Menu = { type: IconType; path: string; desc: string };

export const CONSUMER_MENUS: Array<Menu | 'hr'> = [
  { type: 'person', path: PATH.MYPAGE_PROFILE, desc: '내 정보 관리' },
  { type: 'confirm', path: PATH.MYPAGE_APPLICATIONS, desc: '신청서 관리' },
  { type: 'schedule', path: PATH.MYPAGE_SCHEDULES, desc: '수업일정 관리' },
  'hr',
  { type: 'chat', path: PATH.CHAT, desc: '채팅' },
  'hr',
  { type: 'speaker', path: PATH.MYPAGE_HELP, desc: '고객센터' },
  { type: 'policy', path: PATH.MYPAGE_POLICY, desc: '이용약관' }
];

export const TRAINER_MENUS: Array<Menu | string> = [
  '나의 피벗',
  { type: 'person', path: PATH.PROFILE, desc: '프로필' },
  { type: 'link-white', path: PATH.MYPAGE_MYURL, desc: 'MyURL' },
  // { type: 'QR', path: '', desc: 'QR 코드' },
  { type: 'chat', path: PATH.CHAT, desc: '채팅' },
  'hr',
  '비즈니스',
  { type: 'schedule', path: PATH.MYPAGE_SCHEDULES_COACH, desc: '일정 관리' },
  { type: 'id-card', path: PATH.DASHBOARD, desc: '고객 관리' },
  { type: 'coin', path: PATH.BILLING, desc: '수입 관리' },
  'hr',
  '기타',
  { type: 'speaker', path: PATH.MYPAGE_HELP, desc: '고객센터' },
  { type: 'policy', path: PATH.MYPAGE_POLICY, desc: '이용약관' }
];
