import styled from 'styled-components';

export const ChartHeader = styled.div`
  background: var(--color-point-gradient);

  > div > div {
    border: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  &.overview {
    background: transparent;
    height: fit-content;
    padding: 1rem 2rem 2rem;
    row-gap: 1.8rem;

    > :first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      > p:nth-child(2) {
        font-size: 5rem;
        font-weight: 700;
        line-height: 1.2;
      }
    }
    .item-container {
      display: flex;
      flex-direction: column;
      row-gap: 0.8rem;
    }
  }

  &.content {
    background: var(--color-background);
    height: fit-content;

    .heading {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      border-bottom: 1px solid var(--color-border-light);
      row-gap: 2rem;
    }

    .list-container {
      padding: 2rem;

      > :first-child {
        padding-top: 0;
      }

      > :last-child {
        border-bottom: none;
      }
    }

    .list {
      display: grid;
      grid-template-columns: 1fr 3fr;
      padding: 2rem 0;
      border-bottom: 1px solid var(--color-border);
    }
  }
`;

export const ItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :first-child.next-arrow {
    transform: scaleX(-1);
  }

  &.month-shifter {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    > :first-child {
      justify-self: flex-end;
    }
    > :nth-child(2) {
      justify-self: center;
    }
    column-gap: 3rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5.3rem;
  border-radius: 0.8rem;
  background-color: var(--color-point-fourth);
  color: var(--color-white);
`;

export const ItemContainer = styled.div`
  padding: 2rem;
  border-bottom: 1px solid var(--color-gray-second);
`;
