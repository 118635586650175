import { useEffect, useState } from 'react';
import { getAddress } from '#api/location';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from '#const/main/location';
import { useToast } from '#providers/ToastProvider';
import sessionStorageManager from '#utils/sessionStorageManager';
import { IGetAddress } from './../types/api';

type Location = {
  regionSigu: string;
  regionDong: string;
  longitude: number;
  latitude: number;
};

export function useGetCurrentPosition() {
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [regionSigu, setRegionSigu] = useState('');
  const [regionDong, setRegionDong] = useState('');
  const { showToast } = useToast();
  const { setCurrentLocation } = sessionStorageManager();
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, []);

  useEffect(() => {
    if (!longitude || !latitude) return;
    getAddress(longitude, latitude)
      .then((data: IGetAddress) => {
        setRegionSigu(data.documents[0].address.region_2depth_name);
        setRegionDong(data.documents[0].address.region_3depth_name);
      })
      .catch(() => {
        setCurrentLocation({
          address: '직접 위치 설정',
          latitude: DEFAULT_LATITUDE,
          longitude: DEFAULT_LONGITUDE
        });
        //위치정보 실패 토스트
        showToast({
          type: 'fail',
          message:
            '설정에서 위치 정보를 허용해주시거나, 직접 위치를 설정해주세요.'
        });
        setRegionSigu('');
        setRegionDong('');
      });
  }, [longitude, latitude]);

  const updateLocation = (): Promise<Location | undefined> => {
    return new Promise(resolve => {
      if (regionSigu && regionDong && longitude && latitude) {
        resolve({
          regionSigu,
          regionDong,
          longitude,
          latitude
        });
      } else {
        navigator.geolocation.getCurrentPosition(
          position => {
            getAddress(position.coords.longitude, position.coords.latitude)
              .then((data: IGetAddress) => {
                resolve({
                  regionSigu: data.documents[0].address.region_2depth_name,
                  regionDong: data.documents[0].address.region_3depth_name,
                  longitude: position.coords.longitude,
                  latitude: position.coords.latitude
                });
              })
              .catch(() => {
                showToast({
                  type: 'fail',
                  message:
                    '설정에서 위치 정보를 허용해주시거나, 직접 위치를 설정해주세요.'
                });
                resolve(undefined);
              });
          },
          () => {
            setCurrentLocation({
              address: '직접 위치 설정',
              latitude: DEFAULT_LATITUDE,
              longitude: DEFAULT_LONGITUDE
            });
            showToast({
              type: 'fail',
              message:
                '설정에서 위치 정보를 허용해주시거나, 직접 위치를 설정해주세요.'
            });
            resolve(undefined);
          }
        );
      }
    });
  };

  return { updateLocation };
}
