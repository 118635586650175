import React from 'react';
import { BoxIcon, Text } from '#atoms';
import { Status } from '#types/account';
import { monthDayFormatter } from '#utils/dateFormatter';
import { formatNumberWithCommas } from '#utils/Numbers';
import { convertToWithdrawalStatus } from '#utils/settlements';
import { ButtonContainer, ItemBox } from './styles';

interface MonthShifterProps {
  startDateStr: string;
  endDateStr: string;
  handlePrevMonth: () => void;
  handleNextMonth: () => void;
}

interface ListProps {
  isWithdrawal?: boolean;
  date: string;
  title: string;
  revenue: number;
  detail: string;
  balance: number;
  onItemClick: () => void;
}

interface ButtonProps {
  text: string;
  onClick?: () => void;
}

export const MonthShifter = ({
  startDateStr,
  endDateStr,
  handlePrevMonth,
  handleNextMonth
}: MonthShifterProps) => {
  return (
    <ItemBox className="month-shifter">
      <BoxIcon
        type="next-arrow" //TODO: icon 네이밍 규칙 적용
        onlyIcon
        size={18}
        onClick={handlePrevMonth}
      />
      <Text textStyle="t3">
        {startDateStr} ~ {endDateStr}
      </Text>
      <BoxIcon type="next-arrow" onlyIcon size={18} onClick={handleNextMonth} />
    </ItemBox>
  );
};

export const ListItems = ({
  isWithdrawal,
  date,
  title,
  revenue,
  detail,
  balance,
  onItemClick
}: ListProps) => {
  const isIncome = !isWithdrawal || detail === 'TAKEN' || detail === 'REJECT'; // 입금, 정산 취소, 정산 거절

  return (
    <div className="list">
      <Text textStyle="c1" textColor="gray2">
        {monthDayFormatter(date)}
      </Text>
      <div onClick={onItemClick}>
        <ItemBox>
          <Text>{isWithdrawal ? '정산' : title}</Text>
          <Text textColor={isIncome ? 'point4' : undefined}>
            {isIncome ? '' : '-'}
            {`${formatNumberWithCommas(revenue)}원`}
          </Text>
        </ItemBox>
        <ItemBox>
          <Text>
            {isWithdrawal
              ? convertToWithdrawalStatus(detail as Status)
              : `${detail}회차`}
          </Text>
          <Text textColor="gray2">
            {formatNumberWithCommas(balance)}(잔액)원
          </Text>
        </ItemBox>
      </div>
    </div>
  );
};

export const PointFourthButton = ({ text, onClick }: ButtonProps) => {
  return (
    <ButtonContainer onClick={onClick}>
      <button>
        <Text textStyle="t3">{text}</Text>
      </button>
    </ButtonContainer>
  );
};
