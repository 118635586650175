import classNames from 'classnames';
import { useState } from 'react';
import Venture from '#assets/img/venture_enterprise.png';
import { BoxIcon, Text } from '#atoms';
import { InfoContainer } from './styles';

export const BusinessInfo = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <InfoContainer>
      <img src={Venture} width={120} height={36} />
      <div
        className={classNames({ fold: !!isExpanded })}
        onClick={() => setIsExpanded(prev => !prev)}
      >
        <Text textStyle="c1">피벗 주식회사 사업자 정보</Text>
        <BoxIcon type="next-arrow" size={12} onlyIcon />
      </div>
      {isExpanded && (
        <Text textStyle="c2">
          대표이사 송채겸 | 사업자등록번호 813-81-03095
          <br />
          통신판매업신고번호 2024-성남수정-0053호 | 전화번호 0504-0819-8438
          <br />
          경기도 성남시 수정구 성남대로 1342, 지하1층 B101호 공과대학1 내
          가천스타트업팩토리(복정동, 가천대학교)
        </Text>
      )}
    </InfoContainer>
  );
};
