import { Button, Text, Textarea } from '#atoms';
import { REVIEW_LIST } from '#const/review';
import { ImageWithUpload, TitleItemBox } from '#molecules';
import { HeaderBarTemplate } from '#templates';
import { imageFileType } from '#types/fileType';
import { useUploadImages } from 'src/components/molecules/ImageWithUpload/hooks';
import { RatingStars } from 'src/components/molecules/RatingStar';
import { useSubmitFinalReview } from './hooks';
import {
  Container,
  TextArea,
  InfoWrapper,
  BottomArea,
  ReviewCategory,
  ReviewListWrapper,
  StarArea,
  PageContainer
} from './styles';
import { useGetLatestReviewByConfirmationIdQuery } from '#api/reviews';

interface Props {
  confirmationId: string;
}

export const ReviewRequest = ({ confirmationId }: Props) => {
  const { data } = useGetLatestReviewByConfirmationIdQuery(confirmationId);

  const { localImgs, handleUploadImages, handleDeleteLocalImage } =
    useUploadImages({
      fileType: 'image'
    });

  const { handleSubmit, reviewData, setReviewData } = useSubmitFinalReview({
    data
  });

  return (
    <PageContainer>
      <HeaderBarTemplate TitleCenter={<Text textStyle="t3">리뷰 등록</Text>}>
        <Container>
          {!data ? (
            <>Loading...</>
          ) : (
            <>
              <TextArea>
                <Text children="리뷰를 등록해주세요" textStyle="t1" />
                <div>
                  <InfoWrapper>
                    <Text textColor="gray2" textStyle="t3">
                      {data.title}
                    </Text>
                    <Text textColor="gray2" textStyle="t3">
                      {data.coachName} 전문가
                    </Text>
                  </InfoWrapper>
                </div>
              </TextArea>
              <BottomArea>
                <ReviewListWrapper>
                  {REVIEW_LIST.map(({ id, title }) => (
                    <ReviewCategory key={id}>
                      <Text textStyle="bb">{title}</Text>
                      <StarArea>
                        <RatingStars
                          mode="register"
                          rate={reviewData[id] || 0}
                          onClick={value =>
                            setReviewData(prev => ({ ...prev, [id]: value }))
                          }
                        />
                      </StarArea>
                    </ReviewCategory>
                  ))}
                </ReviewListWrapper>
                <Textarea
                  height={19}
                  limit={500}
                  value={reviewData.desc}
                  onChange={value =>
                    setReviewData(prev => ({ ...prev, desc: value }))
                  }
                  placeholder="수업 리뷰를 적어주세요"
                />
                <TitleItemBox title="사진 첨부" type="images" isOptional>
                  <ImageWithUpload
                    localImages={localImgs}
                    onUploadImage={handleUploadImages}
                    onDeleteLocalImage={handleDeleteLocalImage}
                    fileType={imageFileType}
                  />
                </TitleItemBox>
                <Button
                  children="리뷰 등록하기"
                  onClick={() => handleSubmit(localImgs)}
                  disabled={reviewData.desc.trim() === ''}
                />
              </BottomArea>
            </>
          )}
        </Container>
      </HeaderBarTemplate>
    </PageContainer>
  );
};
