import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
`;

export const CarouselSlide = styled.div<{ count: number }>`
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  width: ${({ count }) => `${count * 100}%`};
  transition: left 0.15s ease-in-out;
`;

export const CarouselItem = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const AlignContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const FirstTabConatiner = styled.div`
  width: 100%;
  padding-left: 21px;
`;

export const SecondTabConatiner = styled.div`
  width: 100%;
  padding-right: 21px;
  text-align: right;
`;

export const FirstCenterLine = styled.hr`
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #b1b1b1 0%, #ffffff 0.01%, #863cff 100%);
  border: none;
`;

export const SecondCenterLine = styled.hr`
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #863cff 0%, #ffffff 100%);
  border: none;
`;

export const BottomProgressContainer = styled.article`
  position: fixed;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  margin: 0 auto;
`;

export const ProgressDotContainer = styled.ul`
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const ProgresDot = styled.li<{ activate: boolean }>`
  width: ${({ activate }) => (activate ? '52px' : '5px')};
  height: 5px;
  border-radius: 5px;
  background-color: ${({ activate }) =>
    activate ? 'var(--color-point)' : 'var(--color-white) '};
`;

export const ButtonContainer = styled.div`
  padding: 1rem 2rem 3.75rem;
`;
