import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { IResponse } from '#types/api';
import {
  IGetConfirmationForm,
  IGetCustomerDetailProps,
  IGetCustomerList,
  IPatchConfirmationForm,
  IPostConfirmationForm
} from '#types/confirmationForms';

// 다 작성하고 날라가는 거
export const postConfirmationForms = async (
  id: string,
  payload: IPostConfirmationForm
) => {
  const res = await request<string>({
    method: 'post',
    url: `/confirmation-forms/${id}`,
    data: payload
  });
  //res.data: confirmationFormId
  return res.data;
};

export const patchConfirmationForms = async ({
  confirmationFormId,
  payload
}: {
  confirmationFormId: string;
  payload: IPatchConfirmationForm;
}) => {
  const res = await request({
    method: 'patch',
    url: `/confirmation-forms/${confirmationFormId}`,
    data: payload
  });

  return res.data;
};

// 수강신청서 채워주는 친구
export const getConfirmationForms = async () => {
  const res = await request<IGetConfirmationForm[]>({
    method: 'get',
    url: '/confirmation-forms'
  });

  return res.data;
};

export const useGetConfirmationForms = () => {
  return useQuery(
    queryKeyFactory.GET_CONFIRMATION_FORMS(),
    getConfirmationForms
  );
};

export const getCustomerList = async (pageParam?: number) => {
  const page = pageParam && pageParam - 1;
  const res = await request<IResponse<IGetCustomerList>>({
    method: 'get',
    url: '/confirmation-forms/coach',
    params: {
      page: page,
      offset: 10 // TODO: offset 변경 및 페이징 적용
    }
  });

  return res.data;
};

export const getCustomerDetail = async ({
  personId,
  id
}: IGetCustomerDetailProps) => {
  const res = await request<IGetConfirmationForm>({
    method: 'get',
    url: `/confirmation-forms/${personId}/${id}`
  });

  return res.data;
};
