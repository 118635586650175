import { Route, Routes } from 'react-router';
import { CoachAssociationPage } from 'src/components/pages/CoachAssociation';

export function TeamView() {
  return (
    <Routes>
      <Route path="/:association" element={<CoachAssociationPage />} />
    </Routes>
  );
}
