import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  height: 8rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
`;
