import { useQuery } from '@tanstack/react-query';
import { fetcher } from '../libs';
import queryKeys from '../queryKeys';

export interface ILicenseIntroduce {
  id: string;
  name: string;
  subList: string[];
  licenseUrl: string;
  adminName: string;
  accountId: string | null;
  introductions: { [key: string]: string };
  images: {
    id: string;
    resizedLink: string;
    shortLink: string;
    uploadedLink: string;
  }[];
}

interface Params {
  /** 자격증 id */
  licenseIssureId: string;
}

/**
 * @description 자격증 상세 조회
 * @link https://api-dev.fibud.kr/api#/%EC%9E%90%EA%B2%A9%EC%A6%9D%20%EB%B0%9C%EA%B8%89%EC%B2%98/LicenseIssuersController_findLicenseIssuer
 * @param licenseIssureId
 * @returns
 */
export const useGetLicenseIntroduce = ({ licenseIssureId }: Params) => {
  return useQuery<ILicenseIntroduce>(
    [queryKeys.LICENSEISSURE, licenseIssureId],
    () => fetcher(`/license-issuers/${licenseIssureId}`),
    {
      enabled: !!licenseIssureId
    }
  );
};
