import { createContext, useState } from 'react';
import { Profile, Sex } from '#types/account';

type UserInfoProviderProps = {
  children: React.ReactNode;
};

export type UserInfoType = {
  id: string;
  name: string;
  nickname: string;
  email: string;
  phone: string;
  isCoach: boolean;
  sex: Sex;
  birthday: string;
  profile: Profile[];
};

type ContextType = {
  userInfo: UserInfoType;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfoType>>;
};

const initialValue = {
  id: '',
  name: '',
  nickname: '',
  email: '',
  phone: '',
  isCoach: false,
  sex: '' as Sex,
  birthday: '',
  profile: []
};

export const UserInfoContext = createContext<ContextType>({
  userInfo: initialValue,
  setUserInfo: () => undefined
});

export function UserInfoProvider({ children }: UserInfoProviderProps) {
  const [userInfo, setUserInfo] = useState<UserInfoType>(initialValue);

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
}
