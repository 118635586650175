import styled from 'styled-components';

export const ButtonSetWrap = styled.div<{
  paddingTop: number;
  paddingBottom: number;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between; /* 수정 */
  gap: 1rem;
  width: 100%;
  padding: 2rem;
  background-color: var(--color-background);
  padding-top: ${props => props.paddingTop}px;
  padding-bottom: ${props => props.paddingBottom}px;
  z-index: 1;

  &.bottom {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  > div.chat {
    flex-shrink: 0;
    color: var(--color-kakao);
    border-radius: 0.8rem;
    border: 1px solid var(--color-kakao);
    width: 8.5rem;
    width: calc(50% - 0.5rem); /* 수정 */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.3rem;
  }
  > button {
    width: calc(50% - 0.5rem);
  }
`;
