import { IconType } from '#atoms';

export const POPULAR_POST_CONETNTS = [
  {
    title: '바디프로필에 목숨거는 사람들 필독',
    content:
      '요즘 갑자기 헬스 유행하면서 바디프로필 찍겠다고, 무리하게 운동하는 사람 많은 것 같아서...',
    tag: '자유게시판',
    likeCount: 65,
    commentCount: 12
  },
  {
    title: '내돈내산 남자 짐웨어 구매 후기',
    content:
      '운동을 좋아하는 나로써 운동복 구매는 필수였다. 젝시믹스, 뮬라웨어, 나이키, 아디다스, ...',
    tag: '자유게시판',
    likeCount: 32,
    commentCount: 15
  },
  {
    title: '6개월 바디프로필 도전기 끝.',
    content:
      '지난 8월 제대로 몸 한 번 만들어보고 싶어 시작했던 바디프로필 챌린지를 마치고자 합니다. 정...',
    tag: 'Before&After',
    likeCount: 31,
    commentCount: 26
  }
];

export const COMMUNITY_TABS: Array<{
  iconType: IconType;
  category: string;
  description: string;
}> = [
  {
    iconType: 'board',
    category: '피벗 게시판',
    description: '헬린이부터 전문가까지! 다양하게 소통해요'
  },
  {
    iconType: 'calendar',
    category: '나의 운동 캘린더',
    description: '매일 운동을 기록하고, 운동라이프를 관리하세요!'
  },
  {
    iconType: 'group',
    category: '그룹 운동',
    description: '내 주변 운동친구 만들고 같이 운동해요'
  }
];
