import styled from 'styled-components';

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Label = styled.span``;

export const CheckBox = styled.div`
  background-color: var(--color-gray-second);
  height: 1.6rem;
  width: 1.6rem;
  align-self: center;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;

  &.checked {
    background-color: var(--color-point);
  }
`;
