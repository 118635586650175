import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 3rem 1.65rem 11.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
`;

export const SelectArea = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

export const CheckBox = styled.div<{ isChecked: boolean }>`
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 0.3rem;
  background-color: ${props =>
    props.isChecked ? `var(--color-point)` : `var(--color-gray-second)`};
  border: ${props =>
    props.isChecked ? `1px solid var(--color-white)` : `none`};
`;

export const CurriculaArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 1rem 0.4rem;
`;

export const CurriculaContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const AddBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  padding: 1rem 1rem 3rem;
  background-color: var(--color-background);
`;

export const MultiSesstionPriceContainer = styled.div`
  width: 100%;
`;
