import styled from 'styled-components';

export const PageContainer = styled.div`
  height: 100%;
  padding: 0 0 9.3rem;
`;

export const CoachCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem 2rem;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem 1rem 3rem;
  background-color: var(--color-background);
`;
