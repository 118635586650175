import { useNavigate } from 'react-router';
import { postSettlements } from '#api/settlements';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useCoachSettlementsInfo } from '#providers/CoachSettlementsInfo';
import { useToast } from '#providers/ToastProvider';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { formatNumberWithCommas } from '#utils/Numbers';
import { ButtonContainer, Container, InfoContainer } from './styles';

export function BillingInvoicePage() {
  const { showToast } = useToast();

  const navigate = useNavigate();
  const { settlementsInfo, settlementRequest } = useCoachSettlementsInfo();

  const handlePostButtonClick = async () => {
    try {
      await postSettlements(settlementRequest);
      navigate(PATH.BILLING_REQUEST_SUCCESS);
    } catch (error) {
      showToast({
        type: 'fail',
        message: '정산 신청에 실패하였습니다.'
      });
    }
  };

  return (
    <Container>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.BILLING_REQUEST}
        title="정산서"
        defaultBackground
      >
        <InfoContainer>
          <div>
            <Text>정산 가능 금액</Text>
            <Text>{`${formatNumberWithCommas(settlementsInfo.price)}원`}</Text>
          </div>
          <div>
            <Text>정산 신청 금액</Text>
            <Text>{`${formatNumberWithCommas(
              settlementRequest.price ?? 0
            )}원`}</Text>
          </div>
          <div>
            <Text>세금 공제 이후 정산액(3.3%)</Text>
            <Text>{`${formatNumberWithCommas(
              Math.floor(
                settlementRequest.price
                  ? settlementRequest.price * (1 - 0.033)
                  : 0
              )
            )}원`}</Text>
          </div>
          <div>
            <Text>정산 후 잔액</Text>
            <Text>{`${formatNumberWithCommas(
              settlementsInfo.price - (settlementRequest.price ?? 0)
            )}원`}</Text>
          </div>
          <div>
            <Text>정산 계좌</Text>
            <Text>{`${settlementsInfo.bankName} ${settlementsInfo.accountNumber}`}</Text>
          </div>
        </InfoContainer>
      </PrevHeaderBarWithTitleTemplate>
      <ButtonContainer>
        <Button onClick={handlePostButtonClick}>정산하기</Button>
      </ButtonContainer>
    </Container>
  );
}
