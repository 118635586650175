import styled from 'styled-components';

export const HeaderBarWrap = styled.header<{ transparentBackground: boolean }>`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  height: 5.8rem;
  background-color: ${({ transparentBackground }) =>
    transparentBackground ? 'transparent' : 'var(--color-background)'};
  padding: 2rem 1.5rem;
  z-index: 999;
`;

export const ContentArea = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 2rem;
`;

export const Title = styled.p`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: var(--color-white);
`;

export const RightArea = styled.div`
  display: flex;

  &.both {
    justify-content: space-between;
    gap: 1.5rem;
  }
`;

export const BottomBar = styled.div`
  width: calc(100% + 2.5rem);
  height: 1px;
  transform: translateX(-1.25rem);
  background-color: var(--color-border);
`;

export const GaugeArea = styled.div`
  position: relative;
  max-width: var(--max-width);
  width: calc(100% + 2.5rem);
  height: 5px;
  transform: translateX(-1.25rem);
  background-color: var(--color-point-third);
`;

export const GaugeBar = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  position: absolute;
  height: 5px;
  background-color: var(--color-point);
  border-radius: 0 2.5px 2.5px 0;
`;
