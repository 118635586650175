import React from 'react';
import { SquareImage, Text } from '#atoms';
import { useCommunityBNFPreview } from './hooks';
import {
  BABox,
  BAImageBox,
  BAImageContainer,
  BAImageTypeBox,
  Container
} from './styles';

export const CommunityBNA = () => {
  const bna = useCommunityBNFPreview();

  return (
    <Container>
      {bna.map(({ images, title, description }, idx) => (
        <BABox key={idx}>
          <BAImageBox>
            {images.map(({ src, type }) => (
              <BAImageContainer key={src}>
                <SquareImage src={src}></SquareImage>
                <BAImageTypeBox>
                  {type === 'before' ? '전' : '후'}
                </BAImageTypeBox>
              </BAImageContainer>
            ))}
          </BAImageBox>
          <Text>{title}</Text>
          <Text textColor="gray2" textStyle="c1">
            {description}
          </Text>
        </BABox>
      ))}
    </Container>
  );
};
