import React from 'react';
import { NextArrow } from '#assets/svg';
import { Text } from '../Text';
import { ArrowButtonWrap } from './styles';

interface Props {
  text: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  hasBigfontSize: boolean;
  arrowColor: 'white' | 'gray';
}

export const ArrowButton = ({
  text,
  onClick,
  hasBigfontSize,
  arrowColor
}: Props) => {
  return (
    <ArrowButtonWrap onClick={onClick}>
      <Text textStyle={hasBigfontSize ? 't3' : undefined}>{text}</Text>
      {arrowColor === 'gray' && (
        <NextArrow stroke={'var(--color-gray-text-second)'} />
      )}
      {arrowColor === 'white' && <NextArrow />}
    </ArrowButtonWrap>
  );
};
