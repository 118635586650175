import React from 'react';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { HelpOpinion } from '#organisims';
import { PrevHeaderBarTemplate } from '#templates';
import { ButtonContainer, OpinionContainer, TitleContainer } from './styles';

export function MyPageHelpCS() {
  return (
    <PrevHeaderBarTemplate prevRoute={PATH.MYPAGE_HELP}>
      <TitleContainer>
        <Text textStyle="t1">
          회원님의 의견을
          <br />
          보내주세요
        </Text>
        <Text textStyle="c1">
          개선해야할 점을 알려주시면 더 나은 서비스를
          <br />
          피벗팀이 되도록 노력하겠습니다.
        </Text>
      </TitleContainer>
      <OpinionContainer>
        <HelpOpinion />
      </OpinionContainer>
      <ButtonContainer>
        <Button>보내기</Button>
      </ButtonContainer>
    </PrevHeaderBarTemplate>
  );
}
