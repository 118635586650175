import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import {
  IGetSettlements,
  IGetSettlementsInfo,
  IGetSettlementsProps,
  IPostSettlements
} from '#types/settlements';

export const getSettlements = async ({
  findStartDate,
  findEndDate,
  paymentType,
  sort
}: IGetSettlementsProps) => {
  const res = await request<IGetSettlements[]>({
    method: 'get',
    url: '/settlements',
    params: { findStartDate, findEndDate, paymentType, sort }
  });

  return res.data;
};

export const useGetSettlements = ({
  findStartDate,
  findEndDate,
  paymentType,
  sort
}: IGetSettlementsProps) => {
  return useQuery(
    queryKeyFactory.GET_SETTLEMENTS(
      findStartDate,
      findEndDate,
      paymentType,
      sort
    ),
    () => getSettlements({ findStartDate, findEndDate, paymentType, sort })
  );
};

export const getSettlementsInfo = async () => {
  const res = await request<IGetSettlementsInfo>({
    method: 'get',
    url: '/settlements/info'
  });

  return res.data;
};

export const postSettlements = async (payload: IPostSettlements) => {
  const res = await request({
    method: 'post',
    url: '/settlements',
    data: payload
  });

  return res.data;
};
