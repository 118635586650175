import styled from 'styled-components';

export const RoundIconWrap = styled.div`
  display: flex;
  align-items: center;
  height: 1.8rem;
  width: fit-content;
  padding: 0 0.6rem;
  border: 1px solid;
  border-radius: 1rem;
  flex-shrink: 0;
`;
