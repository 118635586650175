import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
`;

export const OpinionContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`;
