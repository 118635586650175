import React from 'react';
import { useNavigate } from 'react-router';
import { BoxIcon, Text } from '#atoms';
import { PATH } from '#const/path';
import { PrevHeaderBarTemplate, TitleMenuTemplate } from '#templates';
import { AlignBoxWithFlex, Container } from './styles';

export function MyPageSetting() {
  const navigate = useNavigate();

  return (
    <Container>
      <PrevHeaderBarTemplate prevRoute={PATH.MYPAGE}>
        <TitleMenuTemplate
          title="설정"
          menus={[
            {
              text: '알림 설정',
              menuContoller: (
                <BoxIcon
                  type="next-arrow"
                  onlyIcon={true}
                  size={18}
                  onClick={() => navigate(PATH.MYPAGE_SETTING_ALERT)}
                />
              )
            },
            {
              text: '앱 버전',
              textCaption: '1.00',
              menuContoller: (
                <AlignBoxWithFlex>
                  <Text type="inline">업데이트</Text>
                  <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
                </AlignBoxWithFlex>
              )
            }
          ]}
        />
      </PrevHeaderBarTemplate>
    </Container>
  );
}
