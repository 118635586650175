import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getAccount } from '#api/accounts';
import { Text } from '#atoms';
import {
  CoachInformation,
  CoachInformationList
} from 'src/components/templates/CoachDetailContent/DetailInfo/CoachIntroduce/styles';

interface TabIntroduceProps {
  admin?: {
    name: string;
    accountId: string | null;
  };
  introduceContents: {
    title: string;
    body: string;
  }[];
}

export const TabIntroduce = ({
  admin,
  introduceContents
}: TabIntroduceProps) => {
  const navigate = useNavigate();
  const [adminUrl, setAdminUrl] = useState('');

  useEffect(() => {
    if (admin?.accountId) {
      getAccount(admin.accountId).then(response => {
        const { myUrl } = response;
        setAdminUrl(myUrl ?? '');
      });
    }
  }, [admin?.accountId]);

  return (
    <CoachInformationList>
      {admin?.name && (
        <CoachInformation>
          <Text textStyle="t3">대표자</Text>
          <Text
            isLink={!!admin.accountId}
            textColor={!!admin.accountId ? 'point4' : 'gray2'}
            style={{ whiteSpace: 'pre-wrap' }}
            onClick={() => {
              if (admin.accountId) {
                navigate(`/expert/${adminUrl}`);
              }
            }}
          >
            {admin.name}
          </Text>
        </CoachInformation>
      )}
      {introduceContents?.map((content, index) => {
        const { title, body } = content;
        return (
          <CoachInformation key={index}>
            <Text textStyle="t3">{title}</Text>
            <Text textColor="gray2" style={{ whiteSpace: 'pre-wrap' }}>
              {body}
            </Text>
          </CoachInformation>
        );
      })}
    </CoachInformationList>
  );
};
