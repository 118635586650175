import { AxiosError } from 'axios';
import { ErrorResponse } from '#api/axios';

type Target = EventTarget | null;

export const isDivElement = ($el: Target): $el is HTMLDivElement => {
  return $el !== null && ($el as HTMLElement).tagName === 'DIV';
};

export const isULElement = ($el: Target): $el is HTMLUListElement => {
  return $el !== null && ($el as HTMLElement).tagName === 'UL';
};

export const isInputElement = ($el: Target): $el is HTMLInputElement =>
  $el !== null && ($el as HTMLElement).tagName === 'INPUT';

export const getPureText = (text: string) => {
  return text.replace(
    /[\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2B05}-\u{2B07}\u{2B1B}-\u{2B1C}\u{2B50}\u{2B55}\u{2E80}-\u{2EFF}\u{2F00}-\u{2FDF}\u{2FF0}-\u{2FFF}\u{3000}-\u{303F}\u{3200}-\u{33FF}\u{4DC0}-\u{4DFF}\u{A490}-\u{A4CF}\u{F900}-\u{FAFF}\u{FE00}-\u{FE0F}\u{FE30}-\u{FE4F}\u{FE50}-\u{FE6F}\u{FF00}-\u{FFEF}\u{1F000}-\u{1F02F}\u{1F0A0}-\u{1F0FF}\u{1F10D}-\u{1F1AD}\u{1F1E6}-\u{1F1FF}\u{1F201}-\u{1F20F}\u{1F21A}\u{1F22F}\u{1F232}-\u{1F23A}\u{1F250}-\u{1F251}\u{1F300}-\u{1F320}\u{1F32D}-\u{1F335}\u{1F337}-\u{1F37C}\u{1F37E}-\u{1F393}\u{1F3A0}-\u{1F3CA}\u{1F3CF}-\u{1F3D3}\u{1F3E0}-\u{1F3F0}\u{1F3F4}\u{1F3F8}-\u{1F43E}\u{1F440}\u{1F442}-\u{1F4FC}\u{1F4FF}-\u{1F53D}\u{1F54B}-\u{1F54E}\u{1F550}-\u{1F567}\u{1F57A}\u{1F595}-\u{1F596}\u{1F5A4}\u{1F5FB}-\u{1F64F}\u{1F680}-\u{1F6C5}\u{1F6CC}\u{1F6D0}-\u{1F6D2}\u{1F6EB}-\u{1F6EC}\u{1F6F4}-\u{1F6FC}\u{1F7E0}-\u{1F7EB}\u{1F90C}-\u{1F93A}\u{1F93C}-\u{1F945}\u{1F947}-\u{1F978}\u{1F97A}-\u{1F9CB}\u{1F9CD}-\u{1F9FF}\u{1FA70}-\u{1FA74}\u{1FA78}-\u{1FA7A}\u{1FA80}-\u{1FA86}\u{1FA90}-\u{1FAA8}\u{1FAB0}-\u{1FAB6}\u{1FAC0}-\u{1FAC2}\u{1FAD0}-\u{1FAD6}\u{00A9}\u{00AE}\u{2122}\u{3030}]/gu,
    ''
  );
};

export const isValidPathString = (path: string) => {
  // url에 들어갈 수 있는 문자열인지 확인
  return /^[A-Za-z0-9\-._~\/]+$/.test(path);
};

export const isAxiosErrorWithResponse = (
  error: unknown
): error is AxiosError & { response: { data: ErrorResponse } } => {
  return (
    (error as AxiosError).isAxiosError === true &&
    (error as AxiosError).response !== undefined &&
    (error as AxiosError).response?.data !== undefined
  );
};
