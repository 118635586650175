import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { deleteClinicalData } from '#api/clinical-data';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { ClinicalDataItem } from '#organisims';
import ClinicalData, { useClinicalData } from '#providers/ClinicalData';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { ButtonContainer, Container } from './styles';

export function ProfileClinicalDataPage() {
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();
  const { mutateAsync } = useMutation(deleteClinicalData);
  const { showToast } = useToast();

  const {
    coachId,
    setCoachId,
    clinicalDataList,
    setClinicalDataId,
    clinicalData
  } = useClinicalData();

  useEffect(() => {
    userInfo && setCoachId(userInfo.id);
  }, [userInfo]);

  useEffect(() => {
    if (clinicalData) {
      navigate(PATH.PROFILE_CLINICAL_DATA_FORM);
    }
  }, [clinicalData]);

  useEffect(() => {
    //refetch
    !coachId && userInfo && setCoachId(userInfo.id);
  }, [coachId]);

  const handleDeleteClinicalData = (clinicalDataId: string) => {
    mutateAsync(clinicalDataId)
      .then(() => {
        showToast({
          message: '포트폴리오 삭제에 성공하였습니다.'
        });
        setCoachId(null);
      })
      .catch(() =>
        showToast({
          type: 'fail',
          message: '포트폴리오 삭제에 실패하였습니다.'
        })
      );
  };

  return (
    <>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.PROFILE_UPDATE}
        title="포트폴리오"
        defaultBackground
      >
        <Container>
          <ClinicalData.IsError>
            <div>잘못된 접근입니다!</div>
          </ClinicalData.IsError>
          <ClinicalData.IsListLoading>
            <div>로딩중...</div>
          </ClinicalData.IsListLoading>
          <ClinicalData.EmptyClinicalDataList>
            <div>작성된 포트폴리오가 없습니다</div>
          </ClinicalData.EmptyClinicalDataList>
          <ClinicalData.HasClinicalDataList>
            <Text textStyle="t3">
              총 {clinicalDataList.length}개의 포트폴리오
            </Text>
            {clinicalDataList.map((data, idx) => (
              <ClinicalDataItem
                isCoach
                data={data}
                onEdit={() => {
                  setClinicalDataId(data.id);
                }}
                onDelete={() => handleDeleteClinicalData(data.id)}
                key={idx}
              />
            ))}
          </ClinicalData.HasClinicalDataList>
        </Container>
      </PrevHeaderBarWithTitleTemplate>
      <ButtonContainer>
        <Button onClick={() => navigate(PATH.PROFILE_CLINICAL_DATA_FORM)}>
          포트폴리오 작성하기
        </Button>
      </ButtonContainer>
    </>
  );
}
