import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-black-alpha75);
  z-index: 999;
  max-width: var(--max-width);
  margin: 0 auto;
`;

export const PriceModalWrap = styled.div`
  position: absolute;
  top: 50%;
  width: calc(100% - 2rem);
  max-width: var(--max-width);
  margin: 0 auto;
  height: 20rem;
  left: 1rem;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
  padding: 2rem;
  color: var(--color-white);
  background-color: var(--color-gray);
  border-radius: 2rem;
`;

export const InputArea = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 3.25rem;
  color: var(--color-white);
  background-color: var(--color-gray-second);
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
`;
