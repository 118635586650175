import React, { useEffect, useState } from 'react';
import { Id, IdNameObject } from '#hooks/useConvertBoolList';
import { Item, Input, List, SelectBox } from './styles';

interface Props {
  data: IdNameObject[];
  setSelectedItem?: React.Dispatch<React.SetStateAction<Id>>;
  setSelectedItemsList?: React.Dispatch<React.SetStateAction<Id[]>>;
  selectedItemNamesList?: React.Dispatch<React.SetStateAction<string[]>>;
  setInputItem?: React.Dispatch<React.SetStateAction<string | null>>;
  hasNoneButton?: boolean;
  hasManualInput?: boolean;
  allowDuplicates?: boolean;
  returnsName?: boolean;
  initialValue?: Id;
}

export const ItemPicker = ({
  data,
  setSelectedItem,
  setSelectedItemsList,
  selectedItemNamesList,
  setInputItem,
  hasNoneButton,
  hasManualInput,
  allowDuplicates,
  returnsName,
  initialValue
}: Props) => {
  const [selectedItemId, setSelectedItemId] = useState<Id>(
    initialValue ?? null
  );
  const [selectedItemIdsList, setSelectedItemIdsList] = useState<Id[]>([]);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const noneItemId = data.find(e => e.name === '없음')?.id;

  const handleClick = (id: Id) => {
    if (allowDuplicates) {
      if (selectedItemIdsList.includes(id)) {
        setSelectedItemIdsList(prev => prev.filter(itemId => itemId !== id));
      } else {
        setSelectedItemIdsList(prev => [
          ...prev.filter(itemId => itemId !== 'none' && itemId !== noneItemId),
          id
        ]);
      }

      if (id === noneItemId) {
        setSelectedItemIdsList([id]);
        setInputValue(null);
      }
    } else {
      setSelectedItemId(id);
      setInputValue(null);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setSelectedItemId('');
    // setSelectedItemIdsList([]);
    setSelectedItemIdsList(prev => [
      ...prev.filter(itemId => itemId !== 'none' && itemId !== noneItemId)
    ]);
    setInputValue(event.target.value);
  };

  const handleNoneButtonClick = () => {
    if (allowDuplicates) {
      setSelectedItemIdsList(['none']);
    } else {
      handleClick('none');
    }
    setInputValue(null);
  };

  useEffect(() => {
    if (!setSelectedItem) return;
    setSelectedItem(selectedItemId);
  }, [selectedItemId]);

  useEffect(() => {
    if (!setSelectedItemsList) return;
    setSelectedItemsList([...selectedItemIdsList]);

    returnsName &&
      selectedItemNamesList &&
      selectedItemNamesList(
        data
          .filter(item => selectedItemIdsList.includes(item.id))
          .map(item => item.name)
      );
  }, [selectedItemIdsList]);

  useEffect(() => {
    if (!setInputItem) return;
    setInputItem(inputValue ? inputValue : null);
  }, [inputValue]);

  useEffect(() => {
    initialValue && setSelectedItemId(initialValue);
  }, [initialValue]);

  return (
    <SelectBox>
      <List data-text-type="t3">
        {hasNoneButton && (
          <Item
            key={'none'}
            className={
              selectedItemId === 'none' || selectedItemIdsList.includes('none')
                ? 'select'
                : ''
            }
            onClick={handleNoneButtonClick}
          >
            없음
          </Item>
        )}
        {data.map(item => (
          <Item
            key={item.id}
            onClick={() => handleClick(item.id)}
            className={
              selectedItemId === item.id ||
              selectedItemIdsList.includes(item.id)
                ? 'select'
                : ''
            }
          >
            {item.name}
          </Item>
        ))}
        {hasManualInput && (
          <Input
            type="text"
            placeholder="직접 입력"
            value={inputValue ?? ''}
            onChange={handleInputChange}
            className={inputValue ? 'select' : ''}
          />
        )}
      </List>
    </SelectBox>
  );
};
