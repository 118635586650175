import React from 'react';
import { BoxIcon, Text } from '#atoms';
import { ButtonArea, Container } from './styles';

interface Props {
  text: string;
  icon?: boolean;
  annotation?: string;
  onClick?: () => void;
}

export const KakaoButton = ({ text, icon, annotation, onClick }: Props) => {
  return (
    <Container>
      <ButtonArea onClick={onClick}>
        {icon && <BoxIcon type="kakao" onlyIcon size={18} />}
        <Text className="color-kakao" textStyle="login">
          {text}
        </Text>
      </ButtonArea>
      <Text className="color-gray-second" textStyle="c1">
        {annotation}
      </Text>
    </Container>
  );
};
