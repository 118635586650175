import styled from 'styled-components';

export const PurposeSelectorWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
`;

export const PurposeButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  color: var(--color-gray-text-second);

  &.check > div {
    background-image: linear-gradient(var(--color-gray), var(--color-gray)),
      var(--color-point-gradient);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    filter: drop-shadow(0px 0px 4px rgba(144, 69, 238, 0.7));
  }
`;

export const IconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 6.5rem;
  height: 6.5rem;
  padding: 1.75rem;
  border: 2px solid transparent;
  border-radius: 0.75rem;
  background-color: var(--color-gray);
`;
