import { AltImage } from '#assets/svg';
import { Text } from '#atoms';
import {
  GymContentWrap,
  GymItemsContainer,
  GymThumbnailImg,
  ImageArea,
  InfoArea
} from './styles';

interface Props {
  name: string;
  onClick: () => void;
  isClicked: boolean;
  distance?: number;
  price: string;
  handleMapButtonClick: () => void;
  src: string;
}

export const GymInfoItem = ({
  name,
  onClick,
  distance,
  price,
  isClicked,
  handleMapButtonClick,
  src
}: Props) => {
  return (
    <GymContentWrap onClick={onClick} isClicked={isClicked}>
      <GymItemsContainer isClicked={isClicked}>
        <ImageArea>
          {src ? (
            <GymThumbnailImg src={src} alt="수업공간 썸네일 이미지" />
          ) : (
            <AltImage />
          )}
        </ImageArea>
        <InfoArea>
          <Text textStyle="bb">{name}</Text>
          <Text textStyle="c1" textColor="gray2">
            {distance !== undefined ? `${distance}km` : <span>&nbsp;</span>}
          </Text>
          <Text textStyle="bb">{price}원 / 1시간</Text>
        </InfoArea>
        <Text type="inline" textStyle="c1" onClick={handleMapButtonClick}>
          위치보기
        </Text>
      </GymItemsContainer>
    </GymContentWrap>
  );
};
