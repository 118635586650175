import classNames from 'classnames';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { TabContainer } from './styles';

interface TabListViewerProps {
  initTab?: number;
  children?: React.ReactNode;
  onTabChange?: (tab: number) => void;
}

interface TabListProps {
  tabs: Array<string>;
  onClick?: (tab: number) => void;
}

interface ViewerProps {
  tabs: Array<React.ReactNode>;
}

interface TabListViewContext {
  currentTab: number;
  setCurrentTab: React.Dispatch<number>;
}

const TabListViewContext = createContext<TabListViewContext | null>(null);

const useTabListView = () => {
  const context = useContext(TabListViewContext);
  if (!context)
    throw new Error(
      'This component must be used within a <TabListViewer> component.'
    );
  return context;
};

export function TabListViewer({
  initTab,
  children,
  onTabChange
}: TabListViewerProps) {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [currentTab, setCurrentTab] = useState<number>(initTab ?? 0);

  const memorizedValue = useMemo<TabListViewContext>(
    () => ({
      currentTab,
      setCurrentTab
    }),
    [currentTab, setCurrentTab]
  );

  useEffect(() => {
    if (tab) {
      const aTab = Number(tab);
      setCurrentTab(currentTab => (currentTab === aTab ? currentTab : aTab));
    }
  }, [tab]);

  useEffect(() => {
    onTabChange && onTabChange(currentTab);
  }, [currentTab]);

  return (
    <TabListViewContext.Provider value={memorizedValue}>
      {children}
    </TabListViewContext.Provider>
  );
}

function List({ tabs, onClick }: TabListProps) {
  const { currentTab, setCurrentTab } = useTabListView();

  // const handleCurrentTab = (idx: number) => {
  //   history.replaceState({ tab: idx }, '');
  //   setCurrentTab(idx);
  // };

  // useEffect(() => {
  //   setCurrentTab(history.state.tab ? history.state.tab : 0);
  // }, []);

  return (
    <TabContainer>
      {tabs.map((tab, idx) => (
        <li
          className={classNames({ on: idx === currentTab })}
          key={tab}
          onClick={() => {
            onClick ? onClick(idx) : setCurrentTab(idx);
          }}
          data-text-type={idx === currentTab ? 't3-tab' : undefined}
        >
          {tab}
        </li>
      ))}
    </TabContainer>
  );
}

function Viewer({ tabs }: ViewerProps) {
  const { currentTab } = useTabListView();
  return <React.Fragment key={currentTab}>{tabs[currentTab]}</React.Fragment>;
}

TabListViewer.List = List;
TabListViewer.Viewer = Viewer;
