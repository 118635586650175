import React from 'react';
import { IconType, BoxIcon, Text } from '#atoms';
import {
  Container,
  CategoryContainer,
  IconContainer,
  TextContainer
} from './styles';

interface Props {
  categories: Array<{
    iconType: IconType;
    category: string;
    description: string;
  }>;
}

export const CommunityCategory = ({ categories }: Props) => {
  return (
    <Container>
      {categories.map(({ iconType, category, description }) => (
        <CategoryContainer key={category}>
          <IconContainer>
            <BoxIcon type={iconType} size={30} onlyIcon={true} />
          </IconContainer>
          <TextContainer>
            <Text textStyle="t3">{category}</Text>
            <Text className="color-gray-second" textStyle="c2">
              {description}
            </Text>
          </TextContainer>
        </CategoryContainer>
      ))}
    </Container>
  );
};
