import React from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useCheckValidState } from '#hooks/useCheckValidState';
import { SignUpGenderSelector } from '#organisims';
import { $signupState } from '#states/atoms/recoilSignupState';
import { PrevWithTitleTextTemplate } from '#templates';
import { Sex } from '#types/account';
import { ButtonContainer } from './styles';

export function SignUpGender() {
  const [signupState, setSignupState] = useRecoilState($signupState);
  const navigate = useNavigate();

  useCheckValidState({
    targetState: typeof signupState.isCoach === 'boolean',
    navigateTo: PATH.SIGN_UP_CATEGORY
  });

  const handleGenderChange = (sex: Sex | null) => {
    setSignupState(prev => {
      return { ...prev, sex };
    });
  };

  return (
    <PrevWithTitleTextTemplate
      prevRoute={PATH.SIGN_UP_NICKNAME}
      title={
        <Text textStyle="display">
          회원님의 성별을
          <br />
          선택해주세요
        </Text>
      }
    >
      <SignUpGenderSelector
        setUserSex={handleGenderChange}
        value={signupState.sex}
      />
      <ButtonContainer>
        <Button
          disabled={!signupState.sex}
          onClick={() => navigate(PATH.SIGN_UP_BIRTHDAY)}
        >
          다음
        </Button>
      </ButtonContainer>
    </PrevWithTitleTextTemplate>
  );
}
