export const SLEEP_TIME_LIST = [
  '3시간 미만',
  '3시간',
  '4시간',
  '5시간',
  '6시간',
  '7시간',
  '8시간',
  '9시간',
  '10시간 이상'
];

export const QUALITY_OF_SLEEP_LIST = ['나쁨', '보통', '좋음'];
