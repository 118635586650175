import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  z-index: 999;
  max-width: var(--max-width);
  margin: 0 auto;
`;

export const AddressModalContainer = styled.div`
  position: absolute;
  top: 6.75rem;
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding-bottom: 6.75rem;
`;
