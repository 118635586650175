import request from '#api/axios';
import useInfiniteScrollQuery from '#hooks/useInfiniteScrollQuery';
import { CoachAccount } from '#types/account';
import { IResponse } from '#types/api';
import { useQuery } from '@tanstack/react-query';
import { PER_PAGE_NUMBER } from 'src/shared/constant';
import { EducationIssuerId, IEducation } from '../types';

// educationUrl로 educationIssuerId 찾기
export const getEducationIssuerId = async (
  educationUrl: string | undefined
) => {
  const res = await request<string>({
    method: 'get',
    url: `/education-issuers/educationIssuerId?educationUrl=${educationUrl}`
  });
  return res.data;
};

export const useGetEducationIssuerId = (
  educationUrl: string | undefined,
  options?: { enabled?: boolean }
) => {
  return useQuery(
    ['educationIssuerId', educationUrl],
    () => getEducationIssuerId(educationUrl),
    options
  );
};

//교육 단체 상세 조회
export const getEducationDetail = async (
  educationIssuerId: EducationIssuerId
) => {
  const res = await request<IEducation>({
    method: 'get',
    url: `/education-issuers/${educationIssuerId}`
  });

  return res.data;
};

export const useGetEducationDetail = (
  educationIssuerId: EducationIssuerId,
  options?: { enabled?: boolean }
) => {
  return useQuery(
    ['educationDetail', educationIssuerId],
    () => getEducationDetail(educationIssuerId),
    options
  );
};

interface GetEducationCoachParams {
  educationIssuerId: EducationIssuerId;
  lat: number;
  lng: number;
  offset: number;
  pageParam: number;
}
// 교육 단체의 교육을 받은 코치 모두 조회
export const getEducationCoach = async ({
  educationIssuerId,
  lat,
  lng,
  offset,
  pageParam
}: GetEducationCoachParams) => {
  const page = pageParam && pageParam - 1;

  const res = await request<IResponse<CoachAccount>>({
    method: 'get',
    url: `education-issuers/${educationIssuerId}/coach`,
    params: {
      page,
      lat,
      lng,
      offset
    }
  });

  return res.data;
};

// pagination query
export const useGetEducationCoach = (
  {
    educationIssuerId,
    lat,
    lng,
    offset
  }: Omit<GetEducationCoachParams, 'pageParam'>,
  options: { enabled?: boolean }
) => {
  return useInfiniteScrollQuery<CoachAccount>({
    perPageNum: offset || PER_PAGE_NUMBER,
    queryKeyBase: 'getEducationCoach',
    queryKeyParam: [educationIssuerId, lat, lng, offset],
    filters: {},
    queryFn: ({ pageParam = 1 }) => {
      return getEducationCoach({
        educationIssuerId,
        lat,
        lng,
        pageParam,
        offset
      });
    },
    options
  });
};
