import { useNavigate } from 'react-router';
import { useResetRecoilState } from 'recoil';
import { BoxIcon, Text } from '#atoms';
import { PATH } from '#const/path';
import { $signupState } from '#states/atoms/recoilSignupState';
import sessionStorageManager from '#utils/sessionStorageManager';
import { ButtonArea, Container } from './styles';

export const SignUpFinal = () => {
  const resetSignupState = useResetRecoilState($signupState);
  const navigate = useNavigate();
  const { blockedPagePath, setBlockedPagePath } = sessionStorageManager();

  const handleButtonClick = () => {
    resetSignupState();
    navigate(blockedPagePath || PATH.HOME);
    setBlockedPagePath('');
  };

  return (
    <Container>
      <div>
        <Text textStyle="t2">모든 준비가 끝났어요</Text>
        <Text textStyle="t2">피벗과 함께</Text>
        <Text textStyle="t2">가장 합리적인 PT를 받아보세요.</Text>
      </div>
      <ButtonArea>
        <BoxIcon type="purple-arrow" onlyIcon width={200} />
        <Text
          textStyle="t3"
          onClick={handleButtonClick}
          className="color-text-point-gradient"
        >
          Fibud 시작하기
        </Text>
      </ButtonArea>
    </Container>
  );
};
