import { useState } from 'react';
import React from 'react';
import { Container } from './styles';

interface Props {
  type: string;
}

export function PolicyViewer({ type }: Props) {
  const [text, setText] = useState<string>('');

  fetch(`/${type}.txt`)
    .then(response => response.text())
    .then(text => setText(text));

  return (
    <Container>
      {text &&
        text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
    </Container>
  );
}
