import styled from 'styled-components';

export const PaddingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 3rem 2rem;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 11rem;
`;
