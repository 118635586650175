import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { Container } from './styles';

interface Props {
  initState?: boolean;
  onToggle?: (isOn: boolean) => void;
}

export function Switch({ initState, onToggle }: Props) {
  const [on, setOn] = useState<boolean>(initState ?? false);

  const onCLick = useCallback(() => {
    setOn(!on);
    onToggle && onToggle(!on);
  }, [on]);

  return <Container className={classNames({ on })} onClick={onCLick} />;
}
