import React, { useState } from 'react';
import { DownArrow } from '#assets/svg';
import { Text } from '#atoms';
import {
  Backdrop,
  Container,
  Option,
  OptionList,
  SelectHeader
} from './styles';

interface Props {
  sideLabel?: string;
  data: string[];
  value: string;
  onClick: (value: string) => void;
  disabled?: boolean;
}

export const InlineSelect = ({
  sideLabel,
  data,
  value,
  onClick,
  disabled
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOptions = () => {
    !disabled && setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: string) => {
    onClick(value);
    setIsOpen(false);
  };

  return (
    <Container>
      <div>
        <SelectHeader
          isOpen={isOpen}
          onClick={toggleOptions}
          selectedOption={value}
        >
          {value || disabled || <DownArrow />}
        </SelectHeader>
        {isOpen && (
          <Backdrop onClick={toggleOptions}>
            <OptionList>
              {data.map(option => (
                <Option key={option} onClick={() => handleOptionClick(option)}>
                  {option}
                </Option>
              ))}
            </OptionList>
          </Backdrop>
        )}
      </div>
      {sideLabel && (
        <Text textStyle="c1" textColor="gray2">
          {sideLabel}
        </Text>
      )}
    </Container>
  );
};
