import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { PATH } from '#const/path';
import { LectureDetailLectureInfo, LectureDetailRoundList } from '#organisims';
import LectureChart, { useLectureChart } from '#providers/LectureChart';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { ChartHeader, Header, ListContainer } from './styles';

export const LectureDetailPage = () => {
  const [searchParams] = useSearchParams();
  const lectureId = searchParams.get('id');
  const { setLectureId, lecture } = useLectureChart();
  const location = useLocation();

  useEffect(() => {
    lectureId && setLectureId(lectureId);
  }, [lectureId]);

  return (
    <>
      <>
        <Header>
          <PrevHeaderBarWithTitleTemplate
            prevRoute={location.state?.prevLocation ?? `${PATH.HOME}?tab=3`}
            title="운동차트"
            headerbarType="relative"
          />
          <LectureChart.HasLecture>
            <ChartHeader>
              <LectureDetailLectureInfo
                title={lecture.lecture && lecture.lecture.title}
                coachName={lecture.lecture && lecture.lecture.coachName}
                coachId={lecture.lecture && lecture.lecture.coachId}
                gym={lecture.gym && lecture.gym.name}
              />
            </ChartHeader>
          </LectureChart.HasLecture>
        </Header>
        <ListContainer>
          <LectureChart.IsLoading>로딩중...</LectureChart.IsLoading>
          <LectureChart.IsError>잘못된 접근입니다!</LectureChart.IsError>
          <LectureChart.EmptyLecture>
            강의 정보가 없습니다.
          </LectureChart.EmptyLecture>
          <LectureChart.HasLecture>
            <LectureDetailRoundList
              registeredLecture={lecture.registeredLectures}
            />
          </LectureChart.HasLecture>
        </ListContainer>
      </>
    </>
  );
};
