import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 7.8rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-background);
  overflow-y: auto;
  z-index: 999;
  max-width: var(--max-width);
  margin: 0 auto;
`;

export const HeaderContainer = styled.div`
  position: fixed;
  top: 2rem;
  display: flex;
  width: 100%;
  height: 5.8rem;
  padding: 0 2rem;
  align-items: center;
  background-color: var(--color-background);
`;
