import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from '#const/main/location';
import { useUserInfo } from '#providers/UserInfo';
import { IGetAllCoachAccounts, IGetAllCoachAccountsProps } from '#types/api';
import { IResponse } from '#types/api';
import { IFileResponse } from '#types/images';
import {
  Account,
  IDeleteReason,
  IAccountMyPage,
  INickname,
  IPasswordReset
} from './../../types/account';
import { CoachAccount } from './../../types/account';

export const getMyAccount = async () => {
  const res = await request<Account>({
    method: 'get',
    url: '/accounts'
  });

  return res.data;
};

export const useGetMyAccount = (isLoggedIn: boolean) => {
  return useQuery(queryKeyFactory.GET_MY_ACCOUNT(), getMyAccount, {
    enabled: isLoggedIn,
    refetchOnWindowFocus: false
  });
};

export const getAccount = async (userId: string) => {
  const res = await request<Account>({
    method: 'get',
    url: `/accounts/${userId}`
  });

  return res.data;
};

export const useGetAccount = (userId: string) => {
  return useQuery(queryKeyFactory.GET_ACCOUNT(), () => getAccount(userId), {
    enabled: !!userId
  });
};

export const postProfileImgs = async ({
  profileImgs
}: {
  profileImgs: FormData;
}) => {
  const res = await request<IFileResponse[]>({
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' },
    method: 'patch', // 실제 로직은 post
    url: '/accounts/profile',
    data: profileImgs
  });

  return res.data;
};

export const deleteProfileImgs = async ({
  profileIds
}: {
  profileIds: string[];
}) => {
  const res = await request<string[]>({
    method: 'delete',
    url: '/accounts/profile',
    data: profileIds
  });

  return res.data;
};

export const patchAccount = async (payload: Partial<Account>) => {
  const res = await request<string>({
    method: 'patch',
    url: '/accounts',
    data: payload
  });

  return res;
};

export const getEmailDuplicateCheck = async (email: string) => {
  const res = await request<boolean>({
    method: 'get',
    url: `/accounts/email/${email}`
  });

  return res.data;
};

export const getNicknameDuplicateCheck = async (nick: string) => {
  const res = await request<boolean>({
    method: 'get',
    url: `/accounts/nick/${nick}`
  });

  return res.data;
};

export const useGetNicknameDuplicateCheck = (
  nickname: string,
  validation: boolean
) => {
  return useQuery(
    queryKeyFactory.GET_NICKNAME_DUPLICATE_CHECK(nickname),
    () => getNicknameDuplicateCheck(nickname),
    { enabled: !!nickname && validation }
  );
};

export const patchNickname = async (payload: INickname) => {
  const res = await request<string>({
    method: 'patch',
    url: '/accounts',
    data: payload
  });

  return res.data;
};

export const getPhoneNumberDuplicateCheck = async (phone: string) => {
  const res = await request<boolean>({
    method: 'get',
    url: `/accounts/phone/${phone}`
  });

  return res.data;
};

/**
 * @param {0 | 1} grade 평점순 정렬 여부, 0: false, 1: true
 * @param {0 | 1} reviewCount 리뷰순 정렬 타입, 0: 내림차순, 1: 오름차순
 * @param {0 | 1} distance 거리순 정렬 여부, 0: false, 1: true
 * @param lat 위도
 * @param lng 경도
 */

export const getAllCoachAccounts = async ({
  page,
  offset,
  tag,
  grade,
  reviewCount,
  distance,
  minPrice,
  maxPrice,
  lat,
  lng,
  districtCode
}: IGetAllCoachAccountsProps) => {
  const res = await request<IResponse<CoachAccount>>({
    method: 'get',
    url: '/accounts/coach/all',
    params: {
      page,
      offset,
      tag,
      grade,
      reviewCount,
      distance,
      minPrice,
      maxPrice,
      lat,
      lng,
      districtCode
    }
  });

  return res.data;
};

/**
 * @param {0 | 1} grade 평점순 정렬 여부, 0: false, 1: true
 * @param {0 | 1} reviewCount 리뷰순 정렬 타입, 0: 내림차순, 1: 오름차순
 * @param {0 | 1} distance 거리순 정렬 여부, 0: false, 1: true
 * @param lat 위도
 * @param lng 경도
 */

export const useGetAllCoachAccounts = ({
  page,
  offset,
  tag,
  grade,
  reviewCount,
  distance,
  minPrice,
  maxPrice,
  lat,
  lng,
  districtCode
}: IGetAllCoachAccountsProps) => {
  const { userInfo } = useUserInfo();

  return useQuery(
    queryKeyFactory.GET_ALL_COACH_ACCOUNTS({
      page,
      offset,
      tag,
      grade,
      reviewCount,
      distance,
      minPrice,
      maxPrice,
      lat,
      lng,
      districtCode
    }),
    () =>
      getAllCoachAccounts({
        page,
        offset,
        tag,
        grade,
        reviewCount,
        distance,
        minPrice,
        maxPrice,
        lat,
        lng,
        districtCode
      }),
    { enabled: !!userInfo }
  );
};

export const deleteAccount = async (payload: IDeleteReason) => {
  const res = await request({
    method: 'delete',
    url: '/accounts',
    data: payload
  });

  return res.data;
};

export const patchAccountPassword = async (payload: IPasswordReset) => {
  const res = await request({
    method: 'patch',
    url: '/accounts/password',
    data: payload
  });

  return res.data;
};

export const postMyTags = async (payload: string[]) => {
  const res = await request({
    method: 'post',
    url: '/accounts/self/tags',
    data: payload
  });

  return res.data;
};

export const deleteMyTags = async (payload: string[]) => {
  const res = await request({
    method: 'delete',
    url: '/accounts/self/tags',
    data: payload
  });

  return res.data;
};

export const getAccountMyPage = async () => {
  const res = await request<IAccountMyPage>({
    method: 'get',
    url: '/accounts/myPage'
  });

  return res.data;
};

export const useGetAccountMyPage = () => {
  return useQuery(queryKeyFactory.GET_ACCOUNT_MYPAGE(), () =>
    getAccountMyPage()
  );
};

export const getAllCoachAccountsPublic = async ({
  page,
  offset,
  tag,
  grade,
  reviewCount,
  distance,
  minPrice,
  maxPrice,
  lat = DEFAULT_LATITUDE,
  lng = DEFAULT_LONGITUDE,
  districtCode
}: IGetAllCoachAccountsProps) => {
  const res = await request<IGetAllCoachAccounts>({
    method: 'get',
    url: '/accounts/coach/all/public',
    params: {
      page,
      offset,
      tag,
      grade,
      reviewCount,
      distance,
      minPrice,
      maxPrice,
      lat,
      lng,
      districtCode
    }
  });

  return res.data;
};

export const useGetAllCoachAccountsPublic = ({
  page,
  offset,
  tag,
  grade,
  reviewCount,
  distance,
  minPrice,
  maxPrice,
  lat = DEFAULT_LATITUDE,
  lng = DEFAULT_LONGITUDE,
  districtCode
}: IGetAllCoachAccountsProps) => {
  const { userInfo } = useUserInfo();

  return useQuery(
    queryKeyFactory.GET_ALL_COACH_ACCOUNTS_PUBLIC({
      page,
      offset,
      tag,
      grade,
      reviewCount,
      distance,
      minPrice,
      maxPrice,
      lat,
      lng,
      districtCode
    }),
    () =>
      getAllCoachAccountsPublic({
        page,
        offset,
        tag,
        grade,
        reviewCount,
        distance,
        minPrice,
        maxPrice,
        lat,
        lng,
        districtCode
      }),
    { enabled: !userInfo }
  );
};
