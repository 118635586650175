import styled from 'styled-components';

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > div {
    color: var(--color-gray-text-second);
    &.alert {
      color: var(--color-red);
    }
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
  padding: 0.7rem 2rem 3rem;
  background-color: var(--color-background);
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 3rem 2rem 2rem;
  height: calc(100% - 8.3rem);
  overflow-y: auto;
`;
