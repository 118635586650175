import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '#assets/svg/logo.svg';
import { Text } from '#atoms';
import { PATH } from '#const/path';
import { useUserInfo } from '#providers/UserInfo';
import { Container, LogoArea } from './styles';

export function KakaoLogoutSuccessPage() {
  const { session, logout } = useUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    !session && navigate(PATH.HOME);
  }, [session]);

  return (
    <Container>
      <LogoArea>
        <img src={logo} />
        <Text textStyle="t3">숫자만 세주는 트레이너 말고 진짜 전문가.</Text>
        {/* <Text textStyle="t3">PT는 피벗.</Text> */}
      </LogoArea>
    </Container>
  );
}
