import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 3rem 2rem;
  background-color: var(--color-gray-third);

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

export const TextButton = styled.button`
  text-decoration: underline;
  color: var(--color-gray-text-second);
`;

export const ClauseArea = styled.div`
  text-align: center;
  color: var(--color-gray-second);
  white-space: pre-wrap;
`;
