import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useUserInfo } from '#providers/UserInfo';
import {
  ArticleContainer,
  ButtonContainer,
  Container,
  ContentContainer,
  FieldContainer
} from './styles';

export function WelcomeScheduleReserve() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const lecture = state && state.lecture ? state.lecture : '';
  const coach = state && state.coach ? state.coach : '';
  const gym = state && state.gym ? state.gym : '';
  const round: string[] =
    state && state.successeRounds.length ? state.successeRounds : [];
  const schedule: string[] =
    state && state.successeSchedules.length ? state.successeSchedules : [];
  const { fetchTodayLectures } = useUserInfo();

  return (
    <Container>
      <ContentContainer>
        <ArticleContainer>
          <FieldContainer className="title">
            <BoxIcon type="purple-check" boxColor="transparent" />
            <Text textStyle="t1" style={{ marginTop: '1.25rem' }}>
              예약이 완료되었습니다!
            </Text>
          </FieldContainer>
          <FieldContainer>
            <Text textStyle="c1" textColor="gray">
              수업
            </Text>
            <Text textStyle="t3">{lecture}</Text>
          </FieldContainer>
          <FieldContainer>
            <Text textStyle="c1" textColor="gray">
              전문가
            </Text>
            <Text textStyle="t3">{coach} 전문가</Text>
          </FieldContainer>
          <FieldContainer>
            <Text textStyle="c1" textColor="gray">
              장소
            </Text>
            <Text textStyle="t3">{gym}</Text>
          </FieldContainer>
          <FieldContainer>
            <Text textStyle="c1" textColor="gray">
              회차
            </Text>
            <Text textStyle="t3">{round.map(r => `${r}회차`).join(' / ')}</Text>
          </FieldContainer>
          <FieldContainer>
            <Text textStyle="c1" textColor="gray">
              일정
            </Text>
            {schedule.map((e, idx) => (
              <Text key={idx} textStyle="t3">
                {e}
              </Text>
            ))}
          </FieldContainer>
        </ArticleContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          onClick={() => {
            fetchTodayLectures();
            navigate(PATH.HOME);
          }}
        >
          확인
        </Button>
      </ButtonContainer>
    </Container>
  );
}
