import React from 'react';
import communityTemp from '#assets/img/community-temp.png';
import { Button, Text } from '#atoms';
import {
  CommunityBNA,
  CommunityPopularPosts,
  CommunityTodayTraining
} from '#organisims';
import { CommunityCategory } from '#organisims';
import MetaHelmet from '#utils/MetaHelmet';
import { COMMUNITY_TABS, POPULAR_POST_CONETNTS } from './const';
import {
  AdvertisementContainer,
  Container,
  PaddingContainer,
  TitleBox,
  VerticalPaddingContainer
} from './styles';

export const CommunityPage = () => {
  return (
    <>
      <MetaHelmet title="피벗 스토리" />
      <Container>
        <Container
          style={{
            filter: 'blur(10px)',
            WebkitFilter: 'blur(10px)'
          }}
        >
          <PaddingContainer>
            <CommunityCategory categories={COMMUNITY_TABS} />
          </PaddingContainer>
          <AdvertisementContainer>
            <img src={communityTemp} alt="community-temp" width="100%" />
          </AdvertisementContainer>
          <PaddingContainer>
            <CommunityPopularPosts posts={POPULAR_POST_CONETNTS} />
          </PaddingContainer>
          <hr />
          <PaddingContainer>
            <TitleBox>
              <Text textStyle="t1">오늘 운동 완료!</Text>
              <button className="color-point-fourth">더보기</button>
            </TitleBox>
            <VerticalPaddingContainer>
              <CommunityTodayTraining />
            </VerticalPaddingContainer>
            <Button>오운완 인증하기</Button>
          </PaddingContainer>
          <hr />
          <PaddingContainer>
            <TitleBox>
              <Text textStyle="t1">Before & After</Text>
              <button className="color-point-fourth">더보기</button>
            </TitleBox>
            <VerticalPaddingContainer>
              <CommunityBNA />
            </VerticalPaddingContainer>
          </PaddingContainer>
        </Container>
        <Text
          textStyle="t1"
          style={{
            position: 'fixed',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, 0)',
            filter: 'drop-shadow(0px 0px 7px black)'
          }}
        >
          Coming Soon...
        </Text>
      </Container>
    </>
  );
};
