import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type TextBlockType = 'block' | 'inline';
export type TextStyleType =
  | 'display'
  | 'login'
  | 't0'
  | 't1'
  | 't2'
  | 't3'
  | 'bb'
  | 'c1'
  | 'c2';

export type TextColorType =
  | 'point'
  | 'point4'
  | 'gray'
  | 'gray2'
  | 'black'
  | 'red'
  | 'dark'
  | 'white';

interface Props {
  textStyle?: TextStyleType;
  textColor?: TextColorType;
  style?: React.CSSProperties;
  type?: TextBlockType;
  className?: string;
  isLink?: boolean;
  route?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const Text = ({
  style,
  children,
  textStyle,
  textColor,
  type = 'block',
  className,
  isLink,
  route,
  onClick
}: PropsWithChildren<Props>) => {
  if (isLink && route) {
    return (
      <Link
        to={route}
        className={className}
        style={{
          ...style
        }}
        onClick={event => {
          if (onClick) {
            event.preventDefault();
            onClick(event);
          }
        }}
        data-text-type={textStyle}
        data-text-color={textColor}
      >
        <span
          style={{
            color: '#c6a4ff',
            borderBottom: '1px solid #c6a4ff',
            cursor: 'pointer'
          }}
        >
          {children}
        </span>
      </Link>
    );
  }

  if (type === 'inline') {
    return (
      <span
        className={className}
        style={style}
        onClick={onClick}
        data-text-type={textStyle}
        data-text-color={textColor}
      >
        {children}
      </span>
    );
  }
  return (
    <p
      className={className}
      style={style}
      onClick={onClick}
      data-text-type={textStyle}
      data-text-color={textColor}
    >
      {children}
    </p>
  );
};
