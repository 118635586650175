import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { useUserInfo } from '#providers/UserInfo';
import { IGetLecture } from '#types/api';
import { IGetConfirmationForm } from '#types/confirmationForms';
import {
  Curricula,
  Curriculum,
  IGetLectureList,
  Lecture,
  LectureIngList,
  SessionType
} from '#types/lectures';
import { IPostCoachLecture } from '#types/lectures';

interface IPostLecture {
  title: string;
  body: string;
  rounds: number;
  recommendRoundPerWeek: number;
  maximumMonth: number;
  netRevenue: number;
  price: number;
  otIntroduction: string;
  otPrice: number;
}

export const getLecture = async (lectureId: string) => {
  const res = await request<Lecture>({
    method: 'get',
    url: `/lectures/${lectureId}`
  });

  return res.data;
};

export const useGetLecture = (lectureId: string) => {
  return useQuery(
    queryKeyFactory.GET_LECTURE(lectureId),
    () => getLecture(lectureId),
    { enabled: !!lectureId }
  );
};

export const postLecture = async (payload: IPostLecture) => {
  const res = await request<Lecture>({
    method: 'post',
    url: '/lectures',
    data: payload
  });

  return res.data;
};

export const postCoachLecture = async (payload: IPostCoachLecture) => {
  const res = await request<Lecture>({
    method: 'post',
    url: '/lectures',
    data: payload
  });

  return res.data;
};

export const modifyCoachLecture = async ({
  lectureId,
  payload
}: {
  lectureId: string;
  payload: IPostCoachLecture;
}) => {
  const res = await request({
    method: 'patch',
    url: `/lectures/${lectureId}`,
    data: payload
  });

  return res.data;
};

export const modifyLecturesIndex = async ({
  payload
}: {
  payload: { array: string[] };
}) => {
  const res = await request({
    method: 'patch',
    url: `/lectures/index`,
    data: payload
  });

  return res.data;
};

export const postCurricula = async ({
  lectureId,
  payload
}: {
  lectureId: string;
  payload: Curriculum[];
}) => {
  const res = await request<Curricula[]>({
    method: 'post',
    url: `/curricula/${lectureId}`,
    data: payload
  });

  return res.data;
};

export const modifyCurricula = async ({
  lectureId,
  payload
}: {
  lectureId: string;
  payload: Curriculum[];
}) => {
  const res = await request<Curricula[]>({
    method: 'patch',
    url: `/curricula/${lectureId}`,
    data: payload
  });

  return res.data;
};

export const postLectureImgs = async ({
  lectureId,
  payload
}: {
  lectureId: string;
  payload: FormData;
}) => {
  const res = await request({
    headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' },
    method: 'post',
    url: `/lectures/${lectureId}/images`,
    data: payload
  });

  return res.data;
};

export const deleteLectureImgs = async (payload: string[]) => {
  const res = await request({
    method: 'delete',
    url: `/lectures/all/images`,
    data: payload
  });

  return res.data;
};

export const deleteLecture = async (lectureId: string) => {
  const res = await request({
    method: 'delete',
    url: `/lectures/${lectureId}`
  });

  return res.data;
};

export const getCoachLectures = async (coachId: string) => {
  const res = await request<IGetLecture>({
    method: 'get',
    url: `/lectures/coach/${coachId}`,
    params: {
      // TODO: 무한스크롤 적용
      page: 0,
      offset: 10
    }
  });

  return res.data;
};

export const useGetCoachLectures = (coachId: string) => {
  return useQuery(
    queryKeyFactory.GET_COACH_LECTURES(coachId),
    () => getCoachLectures(coachId),
    {
      enabled: !!coachId
    }
  );
};

export const getLecturesIngList = async () => {
  const res = await request<LectureIngList[]>({
    method: 'get',
    url: '/lectures/ing/list'
  });

  return res.data ?? [];
};

export const useGetLecturesIngList = () => {
  const { userInfo } = useUserInfo();

  return useQuery(queryKeyFactory.GET_LECTURES_ING_LIST(), getLecturesIngList, {
    enabled: !!userInfo && !userInfo?.isCoach
  });
};

export const getLecturesIng = async (lectureId: string) => {
  const res = await request<IGetConfirmationForm>({
    method: 'get',
    url: `/lectures/${lectureId}/ing`
  });

  return res.data;
};

export const useGetLecturesIng = (lectureId: string) => {
  return useQuery(
    queryKeyFactory.GET_LECTURES_ING(lectureId),
    () => getLecturesIng(lectureId),
    { enabled: !!lectureId }
  );
};

export const patchSessionPackage = async (
  sessionPackage: SessionType[],
  lectureId: string
) => {
  const res = await request({
    method: 'PATCH',
    url: `/lectures/${lectureId}/session-package`,
    data: sessionPackage
  });
  return res.data;
};

export const getLectureSearch = async (coachId: string, title?: string) => {
  const res = await request<IGetLectureList[]>({
    method: 'GET',
    url: '/lectures/search',
    params: title
      ? {
          coachId: coachId,
          title: title
        }
      : {
          coachId: coachId
        }
  });
  return res.data;
};

export const useGetLectureSearch = (coachId: string, title: string) => {
  return useQuery(queryKeyFactory.GET_LECTURES_SEARCH(coachId, title), () =>
    getLectureSearch(coachId, title)
  );
};
