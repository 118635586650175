import { useState } from 'react';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { BorderlessInput } from '#molecules';
import BankList from '#providers/BankList';
import { useCoachBankNumber } from '#providers/CoachBankNumber';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
// import { BankInfo } from '#types/banks';
import { BankSelectModal } from 'src/components/organisims/BankSelectModal';
import { useAccountInput, useSubmitBankNumber } from './hooks';
import {
  BankInfoContainer,
  ButtonContainer,
  Container,
  ItemBox
} from './styles';

export function BillingRegAccountPage() {
  const { regForm, setRegForm } = useCoachBankNumber();
  // const [form, setForm] = useState<BankInfo & { bankName: string }>({
  //   bankId: regForm.bankId,
  //   name: regForm.name,
  //   number: regForm.number,
  //   bankName: regForm.bankName
  // });
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const submitBankNumber = useSubmitBankNumber();

  const handleSubmitClick = async () => {
    submitBankNumber({
      bankId: regForm.bankId,
      name: regForm.name,
      number: regForm.number,
      residentNumber: regForm.residentNumber
    });
  };

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.BILLING_REGISTRATION_RRN}
      title="수입관리"
    >
      <Container>
        <div>
          <Text textStyle="t1">정산 받을</Text>
          <Text textStyle="t1">계좌를 등록해주세요</Text>
        </div>
        <BankInfoContainer>
          <li>
            <ItemBox className="bank" onClick={() => setModalOpen(true)}>
              <Text textStyle="bb">
                {regForm.bankName ? `${regForm.bankName}` : '은행'}
              </Text>
              <BoxIcon type="next-arrow" size={15} onlyIcon={true} />
            </ItemBox>
          </li>
          <li>
            <ItemBox>
              <Text textStyle="bb">예금주</Text>
              <BorderlessInput
                name="name"
                value={regForm.name}
                placeholder="계좌 예금주명을 입력해 주세요"
                onChange={e => useAccountInput({ e, setRegForm })}
              />
            </ItemBox>
          </li>
          <li>
            <ItemBox>
              <Text textStyle="bb">계좌번호</Text>
              <BorderlessInput
                name="number"
                value={regForm.number}
                placeholder="'-'를 제외한 숫자만 입력해 주세요"
                onChange={e => useAccountInput({ e, setRegForm })}
              />
            </ItemBox>
          </li>
        </BankInfoContainer>
        {modalOpen && (
          <BankList>
            <BankSelectModal
              onComplete={item => {
                setRegForm(prev => ({ ...prev, ...item }));
                setModalOpen(false);
              }}
              onClose={() => setModalOpen(false)}
            />
          </BankList>
        )}
      </Container>
      <ButtonContainer>
        <Button
          disabled={!regForm.bankId || !regForm.name || !regForm.number}
          onClick={handleSubmitClick}
        >
          완료
        </Button>
      </ButtonContainer>
    </PrevHeaderBarWithTitleTemplate>
  );
}
