import { useContext, useEffect } from 'react';
import { useGetMyAccount } from '#api/accounts';
import { UserInfoContext } from '#providers/UserInfoProvider';
import { useAuth } from './useAuth';

export function useUserInfo() {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const { isLoggedIn } = useAuth();

  userInfo.isCoach = true;

  const {
    data: myAccountData,
    isSuccess,
    isFetching
  } = useGetMyAccount(isLoggedIn);

  useEffect(() => {
    if (!isSuccess || isFetching) return;
    setUserInfo(myAccountData);
  }, [isFetching]);

  return { userInfo, isLoggedIn: true };
}
