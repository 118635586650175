import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import {
  CoachReviews,
  IAverageLectureRate,
  IGetAverageReviews,
  IGetCoachReviews,
  ILectureRate,
  IPostReviewImgResponse
} from '#types/rates';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';

interface IPostReviewProps extends ILectureRate {
  registeredLectureId: string;
  type: 'LECTURE' | 'COACH';
  grade?: number;
  desc?: string;
}

export const postReviews = async ({
  registeredLectureId,
  type,
  coachNice,
  lectureQuality,
  timeNice,
  grade,
  desc
}: IPostReviewProps) => {
  const res = await request<string>({
    // res: rateId
    method: 'post',
    url: `/rates/registered-lectures/${registeredLectureId}`,
    data: {
      type,
      coachNice,
      lectureQuality,
      timeNice,
      grade,
      desc
    }
  });

  return res.data;
};

export const usePostReviewsMutation = (
  options?: UseMutationOptions<string, unknown, IPostReviewProps>
) => {
  const queryClient = useQueryClient();
  return useMutation(postReviews, {
    ...options,
    onSuccess: (_data, _variables, _context) => {
      queryClient.invalidateQueries(['REVIEWS']);
      options?.onSuccess && options.onSuccess(_data, _variables, _context);
    }
  });
};

type GetRateByRegisteredLectureId = {
  coachNice: number;
  timeNice: number;
  lectureQuality: number;
};

export const getReviewsByRegisteredId = async (registeredLectureId: string) => {
  const res = await request<GetRateByRegisteredLectureId>({
    method: 'get',
    url: `/rates/registered-lectures/${registeredLectureId}/one`
  });

  return res.data;
};
export const useGetReviewsByRegisteredId = (registeredLectureId: string) => {
  return useQuery(
    queryKeyFactory.GET_REGISTERED_LECTURE_ID_REVIEW(registeredLectureId),
    () => getReviewsByRegisteredId(registeredLectureId),
    { enabled: !!registeredLectureId }
  );
};

interface IGetReviewsProps {
  coachId: string;
  order?: number;
}
export const getCoachReviews = async ({ coachId, order }: IGetReviewsProps) => {
  const res = await request<IGetCoachReviews>({
    method: 'get',
    url: `/rates/coach/reviews`,
    params: {
      coachId,
      order
    }
  });

  return res.data;
};

export const useGetCoachReviews = ({ coachId, order }: IGetReviewsProps) => {
  if (order) {
    return useQuery(
      queryKeyFactory.GET_REVIEWS(coachId, order),
      () => getCoachReviews({ coachId, order }),
      { enabled: !!coachId }
    );
  }
  return useQuery(
    queryKeyFactory.GET_REVIEWS(coachId),
    () => getCoachReviews({ coachId }),
    { enabled: !!coachId }
  );
};

export const getAverageReviews = async (lectureId: string) => {
  const res = await request<IGetAverageReviews>({
    method: 'get',
    url: `/rates/lecture/reviews/average/${lectureId}`
  });

  return res.data;
};

export const useGetAverageReviews = (lectureId: string) => {
  return useQuery(
    queryKeyFactory.GET_AVERAGE_REVIEWS(lectureId),
    () => getAverageReviews(lectureId),
    { enabled: !!lectureId }
  );
};

export const getCoachAverageRate = async (coachId: string) => {
  const res = await request<IAverageLectureRate>({
    method: 'get',
    url: `/rates/coach/average`,
    params: {
      coachId
    }
  });

  return res.data;
};

export const useGetCoachAverageRate = (coachId: string) => {
  return useQuery(
    queryKeyFactory.GET_COACH_AVERAGE_RATES(coachId),
    () => getCoachAverageRate(coachId),
    { enabled: !!coachId }
  );
};

export const patchReviews = async ({
  registeredLectureId,
  type,
  coachNice,
  lectureQuality,
  timeNice,
  grade,
  desc
}: Partial<IPostReviewProps>) => {
  const res = await request<string>({
    // res: rateId
    method: 'patch',
    url: `/rates/registered-lectures/${registeredLectureId}`,
    data: {
      type,
      coachNice,
      lectureQuality,
      timeNice,
      grade,
      desc
    }
  });

  return res.data;
};

export const usePatchReviews = (
  options?: UseMutationOptions<string, unknown, Partial<IPostReviewProps>>
) => {
  const queryClient = useQueryClient();
  return useMutation(patchReviews, {
    ...options,
    onSuccess: (_data, _variables, _context) => {
      queryClient.invalidateQueries(['REVIEWS']);
      options?.onSuccess && options.onSuccess(_data, _variables, _context);
    }
  });
};

export const postReviewImgs = async ({
  rateId,
  payload
}: {
  rateId: string;
  payload: FormData;
}) => {
  const res = await request<IPostReviewImgResponse[]>({
    method: 'post',
    url: `/rates/${rateId}/images`,
    data: payload
  });

  return res.data;
};

export const usePostReviewImgs = () => {
  const queryClient = useQueryClient();
  return useMutation(postReviewImgs, {
    onSuccess: () => {
      queryClient.invalidateQueries(['REVIEWS']);
    }
  });
};

export const getRecentReviews = async () => {
  const res = await request<CoachReviews[]>({
    method: 'get',
    url: `/rates/recent`,
    params: {
      page: 0,
      offset: 5
    }
  });

  return res.data;
};

export const useGetRecentReviews = () => {
  return useQuery(queryKeyFactory.GET_RECENT_REVIEWS(), getRecentReviews);
};

export interface LatestReviewResponse {
  registeredLectureId: string;
  title: string;
  coachName: string;
  taughtAt: string;
  rate: {
    id: string;
    coachNice: number;
    timeNice: number;
    lectureQuality: number;
    desc: string;
    images: [];
  };
  hasPreviousReview: boolean;
}

export const getLatestReviewByConfirmationId = async (
  confirmationId: string
) => {
  const { data } = await request<LatestReviewResponse>({
    method: 'get',
    url: `/rates/lecture/latest/${confirmationId}`
  });
  return data;
};

export const useGetLatestReviewByConfirmationIdQuery = (
  confirmationId: string
) =>
  useQuery(['review/getReviewByConfirmationId', confirmationId], () =>
    getLatestReviewByConfirmationId(confirmationId)
  );
