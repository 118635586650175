import { useState } from 'react';
import { IconType } from '#atoms';

export interface Category {
  icon: IconType;
  text: string;
}

interface Props {
  currentIntroduce?: string;
}

export const useProfileIntroduce = ({ currentIntroduce }: Props) => {
  const [introduce, setIntroduce] = useState<string>(currentIntroduce ?? '');

  const onChangeIntroduce = (text: string) => setIntroduce(text);

  return {
    introduce,
    onChangeIntroduce
  };
};
