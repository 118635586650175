import dayjs, { Dayjs } from 'dayjs';
import { IGetCoachSchedules } from '#types/api';
import { CaldenderRecipe, CalendarDay, FibudDate } from './types';

export const convertCaldendarSchedules = (
  schedules: IGetCoachSchedules
): CaldenderRecipe => {
  return schedules.schedules.reduce<CaldenderRecipe>(
    (result, { day, startAt, endAt }) => {
      const dayToken = day
        .split(',')
        .map<CalendarDay>(v => Number(v) as CalendarDay);
      const startTime = Math.floor(Date.parse(startAt) / 60000);
      const endTime = Math.floor(Date.parse(endAt) / 60000);
      dayToken.forEach(token => {
        const hourTable: Array<number> = [];
        for (let hour = startTime; hour <= endTime; hour += 60)
          hourTable.push(hour);
        result[token] = hourTable;
      });
      return result;
    },
    {}
  );
};

export const convertRegisteredSchedules = (
  schedules: Array<{ taughtAt: string }>
) => {
  return schedules.map(({ taughtAt }) => dayJsToFibudDate(dayjs(taughtAt)));
};

export const dayJsToFibudDate = (dayjs: Dayjs): FibudDate => {
  return {
    year: dayjs.year(),
    month: dayjs.month() + 1,
    day: dayjs.day(),
    date: dayjs.date(),
    hour: dayjs.hour(),
    time: dayjs.unix(),
    disabled: false,
    scheduled: false,
    dayjs,
    timeList: []
  };
};
