import styled from 'styled-components';

export const SelectBox = styled.div`
  position: relative;
  width: 100%;
  /* height: 5.3rem; */
  border-radius: 0.5rem;
`;

export const List = styled.ul`
  overflow-y: auto;
`;

export const Item = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  padding: 1.5rem 0;
  color: var(--color-gray-text);

  &.select {
    color: var(--color-white);
    background: linear-gradient(
        var(--color-background),
        var(--color-background)
      ),
      var(--color-point-gradient);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 2px solid transparent;
    border-radius: 0.5rem;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 6rem;
  padding: 1.5rem 0;
  background-color: var(--color-background);
  border: 2px solid var(--color-gray);
  border-radius: 0.5rem;
  color: var(--color-white);
  font-size: 1.6rem;
  text-align: center;

  &.select {
    background: linear-gradient(
        var(--color-background),
        var(--color-background)
      ),
      var(--color-point-gradient);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 2px solid transparent;
  }
`;
