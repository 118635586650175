import { atom } from 'recoil';
import sessionStorageManager from '#utils/sessionStorageManager';
// import {
//   DEFAULT_LATITUDE,
//   DEFAULT_LONGITUDE
// } from './../../constants/main/location';

const ATOM_KEY = 'userLocation';

const { currentLocation } = sessionStorageManager();
const [currentLatitude, currentLongitude] = currentLocation
  ?.split(',')
  .map(parseFloat) ?? [undefined, undefined];
//  [DEFAULT_LATITUDE, DEFAULT_LONGITUDE];

export interface IUserLocationState {
  latitude?: number;
  longitude?: number;
}

export const $userLocationState = atom<IUserLocationState>({
  key: ATOM_KEY,
  default: {
    latitude: currentLatitude,
    longitude: currentLongitude
  }
});
