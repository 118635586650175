import React from 'react';
import { useNavigate } from 'react-router';
import { CheckPurple } from '#assets/svg';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import {
  ArticleContainer,
  ButtonContainer,
  Container,
  ContentContainer
} from './styles';

export function MyPageScheduleCancellationSuccess() {
  const navigate = useNavigate();

  return (
    <Container>
      <ContentContainer>
        <ArticleContainer>
          <ContentContainer className="check">
            <CheckPurple />
            <Text textStyle="display">에약취소가 완료되었습니다!</Text>
          </ContentContainer>
        </ArticleContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={() => navigate(PATH.HOME)}>확인</Button>
      </ButtonContainer>
    </Container>
  );
}
