import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  height: 15rem;

  & > p:last-child {
    margin-top: 1rem;
  }
`;

export const DarkPattenButtonContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  bottom: 0;
  width: 100%;
  gap: 1rem;
`;

export const List = styled.ol`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 2rem;
  gap: 1rem;
  list-style-type: decimal;
  color: var(--color-gray-text);
`;
