import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  & > hr {
    margin: 0;
    border: 11px solid var(--color-border);
  }
`;

export const PaddingContainer = styled.div`
  width: 100%;
  padding: 2rem;
`;

export const VerticalPaddingContainer = styled.div`
  width: 100%;
  padding: 1rem 0;
`;

export const AdvertisementContainer = styled.div`
  width: 100%;
`;

export const TabList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
