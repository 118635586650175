import React, { useEffect, useState } from 'react';
import { getBankNumbers } from '#api/banks';
import { IGetBankNumber, IPostBankNumbers } from '#types/banks';

interface Props {
  children?: React.ReactNode;
}

interface CoachBankNumberContext {
  isLoading: boolean;
  isError: boolean;
  bankNumbers: IGetBankNumber | Record<string, never>;
  regForm: IPostBankNumbers & {
    bankName: string;
  };
  setRegForm: React.Dispatch<
    React.SetStateAction<
      IPostBankNumbers & {
        bankName: string;
      }
    >
  >;
}

const CoachBankNumberContext =
  React.createContext<CoachBankNumberContext | null>(null);

export const useCoachBankNumber = () => {
  const context = React.useContext(CoachBankNumberContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <CoachBankNumber> component.'
    );
  }
  return context;
};

function CoachBankNumber({ children }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [bankNumbers, setBankNumbers] = useState<
    IGetBankNumber | Record<string, never>
  >({});
  const [regForm, setRegForm] = useState<
    IPostBankNumbers & { bankName: string }
  >({
    bankId: '',
    name: '',
    number: '',
    residentNumber: '',
    bankName: ''
  });

  useEffect(() => {
    getBankNumbers()
      .then(data => setBankNumbers(data))
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const memorizedValue = React.useMemo(
    () => ({ isLoading, isError, bankNumbers, regForm, setRegForm }),
    [isLoading, isError, regForm, setRegForm]
  );

  return (
    <CoachBankNumberContext.Provider value={memorizedValue}>
      {children}
    </CoachBankNumberContext.Provider>
  );
}

function IsLoading({ children }: Props) {
  const { isLoading } = useCoachBankNumber();
  return isLoading ? <>{children}</> : null;
}

function IsError({ children }: Props) {
  const { isLoading, isError } = useCoachBankNumber();
  return !isLoading && isError ? <>{children}</> : null;
}

function IsEmpty({ children }: Props) {
  const { isLoading, isError, bankNumbers } = useCoachBankNumber();
  return !isLoading && !isError && Object.keys(bankNumbers).length === 0 ? (
    <>{children}</>
  ) : null;
}

function HasData({ children }: Props) {
  const { isLoading, isError, bankNumbers } = useCoachBankNumber();
  return !isLoading && !isError && Object.keys(bankNumbers).length > 0 ? (
    <>{children}</>
  ) : null;
}

CoachBankNumber.IsError = IsError;
CoachBankNumber.IsLoading = IsLoading;
CoachBankNumber.IsEmpty = IsEmpty;
CoachBankNumber.HasData = HasData;

export default CoachBankNumber;
