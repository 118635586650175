import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useToast } from '#providers/ToastProvider';

interface Props {
  targetState?: boolean;
  navigateTo?: string;
}

export const useCheckValidState = ({ targetState, navigateTo }: Props) => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  useEffect(() => {
    if (!targetState) {
      showToast({
        type: 'fail',
        message: '처음부터 다시 시도해주세요.'
      });
      navigateTo && navigate(navigateTo);
      // TODO: requestPay m_redirect_url 추가시 결제 이후 페이지에는 적용 불가
    }
  }, []);
};
