import { useCallback, useState } from 'react';
import { useGetDiseaseKinds } from '#api/disease_kind';
import { useGetPainPartKinds } from '#api/pain-part-kinds';
import { ArrowBigButton, Button, Text } from '#atoms';
import {
  RegisterApplicationType,
  useApplications
} from '#providers/Applications';
import { FibudContainerTemplate, useProgress } from '#templates';
import { HealthSelectPage } from './HealthSelectPage';
import { Container, SelectBoxContainer, TextContainer } from './styles';

export type HealthData = Partial<RegisterApplicationType>['healthInfo'];

export function ApplicationHealth() {
  const { next } = useProgress();
  const diseasesData = useGetDiseaseKinds().data;
  const painPartsData = useGetPainPartKinds().data;
  const { currentApplication, updateCurrentApplications } = useApplications();
  const [diseasePageOpen, setDiseasePageOpen] = useState<boolean>(false);
  const [painPageOpen, setPainPageOpen] = useState<boolean>(false);

  const updateDiseases = useCallback(
    ({
      diseases,
      diseaseEtc
    }: {
      diseases: { healthInfoId?: string; diseaseKindId: string }[];
      diseaseEtc?: string;
    }) =>
      updateCurrentApplications({
        healthInfo: {
          diseases,
          diseaseEtc
        }
      }),
    []
  );

  const updatePainParts = useCallback(
    ({
      painParts,
      painPartEtc
    }: {
      painParts: { healthInfoId?: string; kindId: string }[];
      painPartEtc?: string;
    }) => {
      updateCurrentApplications({
        healthInfo: {
          painParts,
          painPartEtc
        }
      });
    },
    []
  );

  const onDiseaseSelectComplete = (healthData: HealthData) => {
    updateDiseases({
      diseases: healthData?.diseases ?? [],
      diseaseEtc: healthData?.diseaseEtc
    });

    setDiseasePageOpen(false);
  };

  const onPainSelectComplete = (healthData: HealthData) => {
    updatePainParts({
      painParts: healthData?.painParts ?? [],
      painPartEtc: healthData?.painPartEtc
    });

    setPainPageOpen(false);
  };

  return (
    <FibudContainerTemplate type="paddingWithBottomButton">
      <FibudContainerTemplate type="bottomButtonContainer">
        <Container>
          <TextContainer>
            <Text textStyle="t1">
              회원님의 건강상태를
              <br />
              알려주세요
            </Text>
          </TextContainer>
          <SelectBoxContainer>
            <div>
              <ArrowBigButton
                label="앓고 있는 질환"
                status={
                  (
                    currentApplication?.healthInfo?.diseases?.map(
                      e =>
                        diseasesData?.data.find(d => d.id === e.diseaseKindId)
                          ?.name
                    ) || []
                  ).join(', ') +
                    (currentApplication?.healthInfo?.diseaseEtc
                      ? (currentApplication?.healthInfo?.diseases?.length
                          ? ', '
                          : '') + currentApplication?.healthInfo?.diseaseEtc
                      : '') || '-'
                }
                onClick={() => setDiseasePageOpen(true)}
              />
              <ArrowBigButton
                label="수술 또는 통증 부위"
                status={
                  (
                    currentApplication?.healthInfo?.painParts?.map(
                      e =>
                        painPartsData?.data.find(d => d.id === e.kindId)?.name
                    ) || []
                  ).join(', ') +
                    (currentApplication?.healthInfo?.painPartEtc
                      ? (currentApplication?.healthInfo?.painParts?.length
                          ? ', '
                          : '') + currentApplication?.healthInfo?.painPartEtc
                      : '') || '-'
                }
                onClick={() => setPainPageOpen(true)}
              />
            </div>
          </SelectBoxContainer>
        </Container>
        {diseasePageOpen && (
          <HealthSelectPage
            onComplete={onDiseaseSelectComplete}
            data={diseasesData}
            type="disease"
          />
        )}
        {painPageOpen && (
          <HealthSelectPage
            onComplete={onPainSelectComplete}
            data={painPartsData}
            type="pain"
          />
        )}
      </FibudContainerTemplate>
      <FibudContainerTemplate type="bottomButton">
        <Button onClick={() => next()}>다음</Button>
      </FibudContainerTemplate>
    </FibudContainerTemplate>
  );
}
