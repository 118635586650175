import styled, { keyframes } from 'styled-components';

interface Props {
  size?: number;
  color?: string;
}

const LoadingSpinner = ({ size = 20, color = '#fff' }: Props) => {
  return <Container size={size} color={color} />;
};

export default LoadingSpinner;

const spinner = keyframes`
  to {transform: rotate(360deg);}
`;

const Container = styled.div<Required<Props>>`
  display: inline-block;
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    margin-top: -${({ size }) => size / 2}px;
    margin-left: -${({ size }) => size / 2}px;
    border-radius: 50%;
    border-top: 2px solid ${({ color }) => color};
    border-right: 2px solid transparent;
    animation: ${spinner} 0.6s linear infinite;
  }
`;
