import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { SelectedLecture } from '#types/lectures';
import {
  IGetCoachRegisteredLectures,
  IGetRegisteredLectureDetail,
  IGetRegisteredLectures,
  IPatchRegisteredLecture,
  IPostRegisteredLectureCancel,
  IRegisteredLectureReviewCount
} from '#types/registeredLectures';

interface IGetRegLecturesProps {
  at?: string;
  offset?: number;
  order?: number;
}

export const getRegisteredLectures = async ({
  at,
  order
}: IGetRegLecturesProps = {}) => {
  const res = await request<IGetRegisteredLectures>({
    method: 'get',
    url: '/registered-lectures',
    params: {
      at: at,
      offset: 200, // TODO: 무한스크롤 적용
      order: order
    }
  });

  return res.data;
};

export const useGetRegisteredLectures = ({
  at,
  offset,
  order
}: IGetRegLecturesProps) => {
  return useQuery(queryKeyFactory.GET_REGISTERED_LECTURES(), () =>
    getRegisteredLectures({ at, offset, order })
  );
};

export interface GetHomeRegisteredLectures {
  id: string;
  lectureId: string;
  title: string;
  coachName: string;
  name: string;
  round: number;
  taughtAt: string;
  isFinalLectureForPayment: boolean;
  isRequestedReview: boolean;
  hasStar: boolean;
  status: 'UPCOMING' | 'ONGOING' | 'COMPLETED';
}

export interface GetHomeRegisteredLecturesResponse {
  data: GetHomeRegisteredLectures[];
}

export const getHomeRegisteredLectures = async ({
  at,
  order
}: IGetRegLecturesProps = {}) => {
  const res = await request<GetHomeRegisteredLecturesResponse>({
    method: 'get',
    url: '/registered-lectures/reviewable-lectures',
    params: {
      at: at,
      offset: 200, // TODO: 무한스크롤 적용
      order: order
    }
  });

  return res.data;
};

export const useGetHomeRegisteredLectures = ({
  at,
  offset,
  order
}: IGetRegLecturesProps) => {
  return useQuery(queryKeyFactory.GET_REGISTERED_LECTURES(), () =>
    getHomeRegisteredLectures({ at, offset, order })
  );
};

interface RegistrationResponse {
  success: SelectedLecture[];
  failure: SelectedLecture[];
}

export const postSelectedLectures = async (payload: SelectedLecture[]) => {
  const res = await request<RegistrationResponse>({
    method: 'post',
    url: '/registered-lectures/v2',
    data: payload
  });

  return res.data;
};

export const patchRegisteredLectures = async ({
  registeredLectureId,
  payload
}: {
  registeredLectureId: string;
  payload: IPatchRegisteredLecture;
}) => {
  const res = await request({
    method: 'patch',
    url: `/registered-lectures/${registeredLectureId}`,
    data: payload
  });

  return res.data;
};

export const postRegisteredLectureCancel = async ({
  registeredLectureId,
  payload
}: {
  registeredLectureId: string;
  payload: IPostRegisteredLectureCancel;
}) => {
  const res = await request({
    method: 'post',
    url: `/registered-lectures/cancel/${registeredLectureId}`,
    data: payload
  });

  return res.data;
};

export const postRegisteredLectureComplete = async (
  registeredLectureId: string
) => {
  const res = await request({
    method: 'post',
    url: `/registered-lectures/complete/${registeredLectureId}`
  });

  return res.data;
};

export interface IGetCoachRegLecturesProps {
  page?: number;
  offset?: number;
  startAt?: string;
}

export const getCoachRegisteredLectures = async ({
  page,
  offset,
  startAt
}: IGetCoachRegLecturesProps = {}) => {
  const res = await request<IGetCoachRegisteredLectures>({
    method: 'get',
    url: '/registered-lectures/coach',
    params: {
      page,
      offset,
      startAt
    }
  });

  return res.data;
};

export const useGetCoachRegisteredLectures = ({
  page,
  offset,
  startAt
}: IGetCoachRegLecturesProps = {}) => {
  return useQuery(
    queryKeyFactory.GET_COACH_REGISTERED_LECTURES({
      page,
      offset,
      startAt
    }),
    () => getCoachRegisteredLectures({ page, offset, startAt })
  );
};

export const getRegisteredLecture = async (registeredLectureId: string) => {
  // 스웨거에 명시된 타입과 다르게 calculatedRegisteredLecture 컬럼이 오지 않으므로 IGetRegisteredLectureDetail 타입 사용
  const res = await request<IGetRegisteredLectureDetail>({
    method: 'get',
    url: `/registered-lectures/${registeredLectureId}`
  });

  return res.data;
};

export const useGetRegisteredLecture = (registeredLectureId: string) => {
  return useQuery(
    queryKeyFactory.GET_REGISTERED_LECTURE(registeredLectureId),
    () => getRegisteredLecture(registeredLectureId),
    {
      enabled: !!registeredLectureId
    }
  );
};

export const getRegisteredLecturesReviewCount = async () => {
  const res = await request<IRegisteredLectureReviewCount>({
    method: 'get',
    url: `/registered-lectures/reviewCount`
  });

  return res.data;
};
