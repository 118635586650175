import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const DateSelectorArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.8rem;
`;

export const MessageArea = styled.div`
  p {
    color: var(--color-white);
    text-align: right;
  }
`;

export const SelectBoxContainer = styled.div`
  width: 100%;

  select {
    width: 100%;
    height: 5.3rem;
    background-color: var(--color-background);
    border: 1px solid var(--color-gray-text);
    border-radius: 0.5rem;
    padding: 1rem;
    color: var(--color-white);
    appearance: none;
  }
`;
