import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const DateContainer = styled.div`
  display: flex;
  width: 100%;
  height: 3rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
`;

export const DayContainer = styled.div`
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid var(--color-border-light);

  & > ul {
    display: flex;
    width: 100%;
    text-align: center;

    & > li {
      width: 100%;
    }
  }
`;

export const DateTable = styled.ul`
  width: 100%;
  height: 22.5rem;
  margin-top: 1rem;

  & > li {
    display: inline-block;
    width: calc(100% / 7);
    height: calc(100% / 5);
    text-align: center;

    & > p {
      height: 3rem;
    }

    & > div {
      position: relative;
      height: 1rem;

      & > span.scheduled {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--color-white);
      }
    }

    &[data-disabled='true'] {
      color: var(--color-gray-text-second);

      & span {
        background-color: var(--color-gray-text-second);
      }
    }
  }
`;
