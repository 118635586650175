import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMyAccount } from '#api/accounts';
import {
  postCoachGym,
  postGymSpaceImgRegister,
  postGymSpaceRegister
} from '#api/gyms';
import { Button, Text, Textarea } from '#atoms';
import { PATH } from '#const/path';
import { ImageWithUpload, LabeledInput } from '#molecules';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { imageFileType } from '#types/fileType';
import { IPostGymSpaceRegistry } from '#types/gyms';
import { createImgToFormData } from '#utils/createImgToFormData';
import { LeftTextCheckBox } from 'src/components/atoms/LeftCheckBox';
import { useUploadImages } from 'src/components/molecules/ImageWithUpload/hooks';
import {
  ButtonContainer,
  CheckBoxContainer,
  Container,
  TextInfo,
  TextareaContainer
} from './styles';

interface ListInfoType {
  description: string;
  checked: boolean;
}
interface CheckListsType {
  showerRoom: ListInfoType;
  clothRoom: ListInfoType;
  cloth: ListInfoType;
  cabinet: ListInfoType;
  towel: ListInfoType;
  parking: ListInfoType;
}

function ProfileGymRegistrationPage() {
  const [name, setName] = useState<string>('');
  const [detailAddress, setDetailAddress] = useState<string>('');
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [detailInfo, setDtailInfo] = useState<string>('');
  const [checkList, setCheckList] = useState<CheckListsType>({
    showerRoom: { description: '샤워실 제공', checked: false },
    clothRoom: { description: '탈의실 제공', checked: false },
    cloth: { description: '운동복 제공', checked: false },
    cabinet: { description: '사물함 제공', checked: false },
    towel: { description: '수건 제공', checked: false },
    parking: { description: '주차 제공', checked: false }
  });
  const { setUserInfo } = useUserInfo();
  const { state } = useLocation();
  const { showToast } = useToast();
  const curLocation = state?.location || '';
  const lat = state?.lat || 'default lat';
  const lng = state?.lng || 'default lng';
  const navigate = useNavigate();
  const {
    localImgs: localBeforeImgs,
    handleUploadImages: handleUploadBeforeImages,
    handleDeleteLocalImage: handleDeleteLocalBeforeImage,
    postedImgs: postedBeforeImgs,
    handleDeletePostedImage: handleDeletePostedBeforeImage,
    getFileInfo: getFileInfo,
    maxCount,
    handleUploadChartFiles
  } = useUploadImages({
    fileType: 'image'
  });

  const handleCheckBox = (item: keyof CheckListsType) => {
    setCheckList(prev => ({
      ...prev,
      [item]: { ...prev[item], checked: !prev[item].checked }
    }));
  };

  const submitSpaceRegApplication = async () => {
    try {
      const { showerRoom, parking, clothRoom, cloth, cabinet, towel } =
        checkList;
      const fullAddress = curLocation + ' ' + detailAddress;
      const sendingImgId = getFileInfo.map(file => file.id);
      const gymInfo: IPostGymSpaceRegistry = {
        name: name,
        introduction: detailInfo,
        phone: phoneNum,
        address: fullAddress,
        long: lng,
        lat: lat,
        showerRoom: showerRoom.checked,
        parkingSpace: parking.checked,
        locker: cabinet.checked,
        fittingRoom: clothRoom.checked,
        sportswear: cloth.checked,
        tower: towel.checked,
        images: sendingImgId
      };
      const res = await postGymSpaceRegister(gymInfo);
      await postGymSpaceImgRegister(
        res.id,
        createImgToFormData(localBeforeImgs)
      );
      await postCoachGym(res.id).then(() =>
        getMyAccount().then(account => setUserInfo(account))
      );
      showToast({
        type: 'success',
        message: '수업 공간 등록에 성공하였습니다.'
      });
      navigate(PATH.PROFILE_GYM);
    } catch {
      showToast({
        type: 'fail',
        message: '수업 공간 등록에 실패했습니다.'
      });
    }
  };

  const isFullFillRegLists = !(
    name.length &&
    detailAddress.length &&
    phoneNum.length &&
    detailInfo.length
  );

  const checkListKeys = Object.keys(checkList) as Array<keyof CheckListsType>;

  return (
    <>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.PROFILE_GYM}
        title="운동공간 추가"
      >
        <Container>
          <LabeledInput
            value={name}
            onInput={value => setName(value)}
            label={'이름'}
            placeholder="공간의 이름을 입력해 주세요"
          />
          <TextInfo>
            <Text textStyle="t3">주소</Text>
            <Text textColor="gray2">{curLocation || ''}</Text>
          </TextInfo>
          <LabeledInput
            value={detailAddress}
            onInput={value => setDetailAddress(value)}
            label={'상세 주소'}
            placeholder="공간의 상세 주소를 입력해 주세요"
          />
          <LabeledInput
            value={phoneNum}
            onInput={value => setPhoneNum(value)}
            label={'전화번호'}
            placeholder="공간의 전화번호를 입력해 주세요"
          />
          <div>
            <Text textStyle="t3">사진 및 영상</Text>
            <ImageWithUpload
              postedImages={postedBeforeImgs}
              localImages={localBeforeImgs}
              onUploadImage={handleUploadBeforeImages}
              onDeleteLocalImage={handleDeleteLocalBeforeImage}
              onDeletePostedImage={handleDeletePostedBeforeImage}
              maxCount={maxCount}
              handleUploadChartFiles={handleUploadChartFiles}
              type="chart"
              fileType={imageFileType}
            />
          </div>
          <TextareaContainer>
            <Text textStyle="t3">상세 정보</Text>
            <Textarea
              value={detailInfo}
              onChange={value => setDtailInfo(value)}
              placeholder="공간의 상세 정보를 입력해 주세요"
              height={25}
              limit={500}
            />
          </TextareaContainer>
          <CheckBoxContainer>
            {checkListKeys.map(item => (
              <LeftTextCheckBox
                id={item}
                label={checkList[item].description}
                onClick={() => handleCheckBox(item)}
                checked={checkList[item].checked}
              />
            ))}
          </CheckBoxContainer>
        </Container>
      </PrevHeaderBarWithTitleTemplate>
      <ButtonContainer>
        <Button
          disabled={isFullFillRegLists}
          onClick={submitSpaceRegApplication}
        >
          추가하기
        </Button>
      </ButtonContainer>
    </>
  );
}

export default ProfileGymRegistrationPage;
