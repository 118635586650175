import { atom } from 'recoil';

const ATOM_KEY = 'coachLectureState';

export interface ICoachLectureState {
  id: string;
  coachId: string;
  coachName?: string;
  gymName?: string;
  gymId: string;
  price: number;
  recommendRoundPerWeek: number;
  title: string;
  body: string;
  rounds: number;
  isOt: boolean;
  isOnePoint: boolean;
}

export const $coachLectureState = atom<ICoachLectureState[]>({
  key: ATOM_KEY,
  default: []
});
