import styled from 'styled-components';

export const ImageDiv = styled.div<{ source: string }>`
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  background-image: url(${({ source }) => source});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const ImageListItem = styled.li<{ src: string }>`
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
