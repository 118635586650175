import React, { useCallback, useMemo, useState } from 'react';
import { useGetLecturesIngList } from '#api/lectures';
import { LectureIngList } from '#types/lectures';

interface Props {
  children?: React.ReactNode;
}

interface AttendLectureContext {
  isLoading: boolean;
  isError: boolean;
  lectures: LectureIngList[];
  calculatedRemainingLecture: number | undefined;
  selectedLecture: LectureIngList | null;
  updateSelectedLecture: (lecture: LectureIngList | null) => void;
  hasTakenStatusLecture: boolean | undefined;
  hasTakenStatusIngLecture: boolean | undefined;
}

const AttendLectureContext = React.createContext<AttendLectureContext | null>(
  null
);

export const useAttendingLectures = () => {
  const context = React.useContext(AttendLectureContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <AttendingLecture> component.'
    );
  }
  return context;
};

function AttendingLecture({ children }: Props) {
  // @TODO: 로그인 상태에 따른 초기화 변수 필요
  const { data: ingLectures, isError, fetchStatus } = useGetLecturesIngList();

  const [selectedLecture, setSelectedLecture] = useState<LectureIngList | null>(
    null
  );
  const isLoading = fetchStatus == 'fetching';

  const updateSelectedLecture = useCallback(
    (lecture: LectureIngList | null) => {
      setSelectedLecture(lecture);
    },
    []
  );

  const calculatedRemainingLecture = ingLectures?.reduce((acc, curr) => {
    const currRemainedLecture =
      curr.calculatedRegisteredLecture.remainingRegisteredLectureCount;
    return acc + currRemainedLecture;
  }, 0);
  const hasTakenStatusIngLecture = ingLectures?.every(
    lecture => lecture.lecture.status === 'TAKEN'
  );

  const hasTakenStatusLecture = ingLectures?.every(
    lecture =>
      lecture.calculatedRegisteredLecture.totalRegisteredLectureCount !== 0
  );

  const memorizedValue = useMemo(
    () => ({
      isLoading,
      isError,
      lectures: ingLectures ?? [],
      calculatedRemainingLecture,
      selectedLecture,
      updateSelectedLecture,
      hasTakenStatusLecture,
      hasTakenStatusIngLecture
    }),
    [
      isLoading,
      isError,
      ingLectures,
      calculatedRemainingLecture,
      selectedLecture,
      updateSelectedLecture,
      hasTakenStatusLecture,
      hasTakenStatusIngLecture
    ]
  );

  return (
    <AttendLectureContext.Provider value={memorizedValue}>
      {children}
    </AttendLectureContext.Provider>
  );
}

function IsLoading({ children }: Props) {
  const { isLoading } = useAttendingLectures();
  return isLoading ? <>{children}</> : null;
}

function IsError({ children }: Props) {
  const { isLoading, isError } = useAttendingLectures();
  return !isLoading && isError ? <>{children}</> : null;
}

function EmptyLecture({ children }: Props) {
  const { isLoading, isError, lectures } = useAttendingLectures();
  return !isLoading && !isError && lectures.length === 0 ? (
    <>{children}</>
  ) : null;
}

function HasLecture({ children }: Props) {
  const { isLoading, isError, lectures } = useAttendingLectures();
  return !isLoading && !isError && lectures.length > 0 ? <>{children}</> : null;
}

AttendingLecture.IsError = IsError;
AttendingLecture.IsLoading = IsLoading;
AttendingLecture.EmptyLecture = EmptyLecture;
AttendingLecture.HasLecture = HasLecture;

export default AttendingLecture;
