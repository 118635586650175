import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 5.3rem);
  padding: 2rem 2rem 5rem;
  overflow-y: auto;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 1rem 0 2rem;
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 2rem;
  background-color: var(--color-background);
  max-width: var(--max-width);
  margin: 0 auto;
`;
