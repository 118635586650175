import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
  padding: 3rem 0;
`;

export const CheckListContainer = styled.div`
  display: flex;
  column-gap: 1.2rem;
  align-items: center;

  &.checklist {
    padding: 0 2rem 1rem;
    border-bottom: 1px solid var(--color-border-light);
  }

  > :last-child {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
`;

export const CheckBox = styled.div`
  background-color: var(--color-gray-second);
  height: 1.6rem;
  width: 1.6rem;
  align-self: center;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;

  &.checked {
    background-color: var(--color-point);
  }
`;
