import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { deleteAccount } from '#api/accounts';
import { Text, Textarea } from '#atoms';
import { PATH } from '#const/path';
import { DarkPatternButtons } from '#molecules';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarTemplate } from '#templates';
import { DarkPattenButtonContainer, TitleContainer } from './styles';

export function MyPageWithdrawalExplanation() {
  const [leavingExplanation, setLeavingExplanation] = useState('');
  const { mutateAsync } = useMutation(deleteAccount);
  const navigate = useNavigate();
  const { logout } = useUserInfo();

  const handleDeleteButtonClick = async () => {
    await mutateAsync({ reason: leavingExplanation });
    logout();
    navigate(PATH.HOME);
  };

  return (
    <PrevHeaderBarTemplate prevRoute={PATH.MYPAGE_WITHDRAWAL_PRECAUTION}>
      <TitleContainer>
        <Text textStyle="t1">
          떠나시기전에
          <br />
          이유를 알려주세요
        </Text>
      </TitleContainer>
      <Textarea
        value={leavingExplanation}
        onChange={setLeavingExplanation}
        height={12}
        limit={500}
        placeholder="소중한 의견을 반영하여 더 나은 서비스를 만들도록 노력하겠습니다"
      />
      <DarkPattenButtonContainer>
        <DarkPatternButtons
          pointButtonText="회원 유지하기"
          darkButtonText="회원 탈퇴하기"
          onPointButtonClick={() => navigate(PATH.MYPAGE_WITHDRAWAL_PRECAUTION)}
          onDarkButtonClick={handleDeleteButtonClick}
        />
      </DarkPattenButtonContainer>
    </PrevHeaderBarTemplate>
  );
}
