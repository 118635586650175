import { useState } from 'react';

const maxPrice = 1000000; // 입력 한도: 100만원

export function usePriceInput(initialValue: number | undefined) {
  const [price, setPrice] = useState<number | undefined>(initialValue);

  const handlePriceInput = (e: string) => {
    if (e.charAt(0) === '0' && e.length > 1) return; // 입력값이 0인 경우 0을 한 번만 입력이 가능하도록 처리
    if (e === '') return setPrice(undefined);
    const valueRaw = Number(e.replaceAll(',', ''));
    if (valueRaw > maxPrice) return;
    !isNaN(valueRaw) && setPrice(valueRaw);
  };

  return { price, handlePriceInput };
}
