import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { CheckPurple } from '#assets/svg';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useUserInfo } from '#providers/UserInfo';
import {
  ArticleContainer,
  ButtonContainer,
  Container,
  ContentContainer,
  FieldContainer
} from './styles';

export function ApplicationScheduleFail() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const lecture = state && state.lecture ? state.lecture : '';
  const coach = state && state.coach ? state.coach : '';
  const gym = state && state.gym ? state.gym : '';
  const successRounds: string[] =
    state && state.successRounds.length ? state.successRounds : [];
  // const failedRounds: string[] =
  //   state && state.failedRounds.length ? state.failedRounds : [];
  const successSchedules: string[] =
    state && state.successSchedules.length ? state.successSchedules : [];
  const failedSchedules: string[] =
    state && state.failedSchedules.length ? state.failedSchedules : [];
  const { fetchTodayLectures } = useUserInfo();

  return (
    <Container>
      <ContentContainer>
        <ArticleContainer>
          <ContentContainer className="check">
            <CheckPurple />
            <Text textStyle="display">결제가 완료되었습니다!</Text>
          </ContentContainer>
          <FieldContainer className="title">
            {/* <BoxIcon type="alert" boxColor="transparent" /> */}
            <Text textStyle="t1">일정 예약 중 문제가 발생하였습니다</Text>
            <Text textColor="gray2">
              일정관리 페이지에서 다시 시도해주세요.
            </Text>
          </FieldContainer>
          <FieldContainer>
            <Text textStyle="c1" textColor="gray">
              수업
            </Text>
            <Text textStyle="t3">{lecture}</Text>
          </FieldContainer>
          <FieldContainer>
            <Text textStyle="c1" textColor="gray">
              전문가
            </Text>
            <Text textStyle="t3">{coach} 전문가</Text>
          </FieldContainer>
          <FieldContainer>
            <Text textStyle="c1" textColor="gray">
              장소
            </Text>
            <Text textStyle="t3">{gym}</Text>
          </FieldContainer>
          {successSchedules.length > 0 && (
            <>
              <FieldContainer>
                <Text textStyle="c1" textColor="gray">
                  예약된 회차
                </Text>
                <Text textStyle="t3">
                  {successRounds.map(r => `${r}회차`).join(' / ')}
                </Text>
              </FieldContainer>
              <FieldContainer>
                <Text textStyle="c1" textColor="gray">
                  예약된 일정
                </Text>
                {successSchedules.map((e, idx) => (
                  <Text key={idx} textStyle="t3">
                    {e}
                  </Text>
                ))}
              </FieldContainer>
            </>
          )}
          <FieldContainer>
            <Text textStyle="c1" textColor="gray">
              실패한 일정
            </Text>
            {failedSchedules.map((e, idx) => (
              <Text key={idx} textStyle="t3">
                {e}
              </Text>
            ))}
          </FieldContainer>
        </ArticleContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={() => navigate(PATH.MYPAGE_SCHEDULES)}>
          수업 예약하기
        </Button>
        <Button
          background="transparent"
          onClick={() => {
            fetchTodayLectures();
            navigate(PATH.HOME);
          }}
        >
          홈으로 돌아가기
        </Button>
      </ButtonContainer>
    </Container>
  );
}
