import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { BoxIcon, Text, VideoRenderer } from '#atoms';
import { Images } from '#types/images';
import { Image } from './hooks';
import { ImageIconBox, ImageInput, ImageItem, ImageList } from './styles';
interface ImageUploadProps {
  localImages?: Image[];
  postedImages?: Images[];
  onUploadImage?: React.ChangeEventHandler<HTMLInputElement>;
  onDeleteLocalImage?: (fileUrl: string) => void;
  onDeletePostedImage?: (fileId: string) => void;
  isLoading?: boolean;
  maxCount?: number;
  handleUploadChartFiles?: React.ChangeEventHandler<HTMLInputElement>;
  type?: string;
  fileType: string;
}

// export const fileType =
//   '.webp, .jpg, .jpeg, .png, .mp4, .mov, .avi, .wmv, .gif' as const;
export const defaultMaxCount = 5;

export function ImageWithUpload({
  localImages,
  postedImages,
  onUploadImage,
  onDeleteLocalImage,
  onDeletePostedImage,
  handleUploadChartFiles,
  maxCount = defaultMaxCount,
  fileType = '.webp, .jpg, .jpeg, .png, .mp4, .mov, .avi, .wmv, .gif',
  type
}: ImageUploadProps) {
  return (
    <ImageList>
      <ImageIconBox>
        <ImageInput
          type="file"
          accept={fileType}
          onChange={type === 'chart' ? handleUploadChartFiles : onUploadImage}
          multiple={true}
        />
        <BoxIcon type="camera-white" onlyIcon={true} size={25} />
        <div>
          <Text textStyle="c1" type="inline">
            {(postedImages?.length ?? 0) + (localImages?.length ?? 0)}
          </Text>
          <Text textStyle="c1" textColor="gray2" type="inline">
            /{maxCount}
          </Text>
        </div>
      </ImageIconBox>
      {postedImages?.map((item: Images, index) => (
        <ImageItem key={index}>
          {item.loading ? (
            <ClipLoader color="#36d7b7" />
          ) : (
            <>
              {item.type !== 'video' ? (
                <img src={item.resizedLink} />
              ) : (
                <VideoRenderer url={item.resizedLink} />
              )}
              {onDeletePostedImage && (
                <BoxIcon
                  key={index}
                  type="x-circle"
                  onlyIcon={true}
                  size={20}
                  onClick={() => onDeletePostedImage(item.id)}
                />
              )}
            </>
          )}
        </ImageItem>
      ))}
      {localImages?.map((item: Image, index) => {
        return (
          <ImageItem key={index}>
            {item.loading ? (
              <>
                <ClipLoader
                  cssOverride={{
                    position: 'absolute',
                    top: '25%',
                    right: '25%'
                  }}
                  color="#36d7b7"
                />
                {onDeleteLocalImage && (
                  <BoxIcon
                    type="x-circle"
                    onlyIcon={true}
                    size={20}
                    onClick={() => onDeleteLocalImage(item.url)}
                  />
                )}
              </>
            ) : (
              <>
                {!item.file.type.includes('video') ? (
                  <img src={item.url} width={74} height={74} />
                ) : (
                  <VideoRenderer url={item.url} />
                )}
                {onDeleteLocalImage && (
                  <BoxIcon
                    type="x-circle"
                    onlyIcon={true}
                    size={20}
                    onClick={() => onDeleteLocalImage(item.url)}
                  />
                )}
              </>
            )}
          </ImageItem>
        );
      })}
    </ImageList>
  );
}
