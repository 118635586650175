import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  height: 15rem;

  & > p:last-child {
    margin-top: 1rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  row-gap: 0.8rem;
  flex-wrap: wrap;

  > div {
    height: 3.6rem;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;
