import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 3rem 1.65rem 11.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
`;

export const HeaderContainer = styled.div`
  padding: 3rem 0;
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
`;

export const RevenueArea = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    > div > input {
      text-align: center;
    }
  }

  &.revenue {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 5.3rem;
    background-color: var(--color-gray);
    border-radius: 0.5rem;
    padding: 1rem;
    align-items: center;

    p:first-child {
      text-align: center;
      flex: 1;
    }
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  left: 1rem;
  right: 1rem;
  bottom: 3rem;
`;
