import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  padding: 0 2rem;
  background: var(--color-point-dark-gradient);
`;

export const ButtonArea = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  align-items: center;
  gap: 1.25rem;
`;
