import styled from 'styled-components';

export const Container = styled.ul`
  display: grid;
  width: 100%;
  gap: 0.8rem;
`;

export const CategoryContainer = styled.li`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: var(--color-gray);
`;

export const IconContainer = styled.div`
  position: relative;
  width: 8rem;
  height: 8rem;

  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const TextContainer = styled.div`
  display: flex;
  height: 100%;
  padding-left: 0.5rem;
  flex-direction: column;
  justify-content: space-evenly;
`;
