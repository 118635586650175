import { useNavigate } from 'react-router';
import {
  deleteLectureImgs,
  modifyCoachLecture,
  modifyCurricula,
  postLectureImgs
} from '#api/lectures';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { Images } from '#types/images';
import { Curriculum, IPostCoachLecture, SessionType } from '#types/lectures';
import { createImgToFormData } from '#utils/createImgToFormData';
import { Image } from 'src/components/molecules/ImageWithUpload/hooks';

interface DeleteImgProps {
  images: Images[];
  postedImgs: Images[];
}

async function deleteImages({ images, postedImgs }: DeleteImgProps) {
  const deletedImages = images
    .filter(value => !postedImgs.includes(value))
    .map(value => value.id);

  if (deletedImages.length) {
    await deleteLectureImgs(deletedImages);
  }
}

export interface modifyLectureProps {
  lectureId?: string;
  form: IPostCoachLecture;
  curricula: Curriculum[];
  localImgs: Image[];
  images: Images[];
  postedImgs: Images[];
  multiCountPrice?: SessionType[];
}

export const useModifyCoachLecture = () => {
  const { showToast } = useToast();

  const navigate = useNavigate();
  const modifyLecture = ({
    lectureId,
    form,
    curricula,
    localImgs,
    images,
    postedImgs,
    multiCountPrice
  }: modifyLectureProps) => {
    const newForm = { ...form, lectureSessionPackage: multiCountPrice };
    Promise.all([
      lectureId &&
        modifyCoachLecture({
          lectureId: lectureId,
          payload: newForm
        }),
      lectureId &&
        curricula.length &&
        modifyCurricula({ lectureId: lectureId, payload: curricula }),
      images.length && deleteImages({ images, postedImgs }),
      lectureId &&
        localImgs.length &&
        postLectureImgs({
          lectureId: lectureId,
          payload: createImgToFormData(localImgs)
        })
    ])
      .then(() => {
        showToast({
          message: '수업 수정에 성공하였습니다.'
        });
        navigate(PATH.PROFILE_CLASS);
      })
      .catch(() => {
        showToast({
          type: 'fail',
          message: '수업 수정에 실패하였습니다.'
        });
      });
  };
  return modifyLecture;
};
