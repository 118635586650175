import { refreshAxiosInstance } from '#api/axios';

export interface IGetTokenResponseData {
  accessToken: string;
  refreshToken: string;
}

export const postRefreshAuthToken = async () => {
  const res = await refreshAxiosInstance
    .post<IGetTokenResponseData>('/auth/refresh')
    .catch(err => {
      throw new Error(err);
    });

  return res.data;
};
