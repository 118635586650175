import request from '#api/axios';

export interface ISingin {
  email: string;
  password: string;
}

export interface ISignInResponse {
  accessToken: string;
  refreshToken: string;
}

export interface IPasswordRequest {
  redirectUrl: string;
  email: string;
}

export const postSignin = async (payload: ISingin) => {
  const res = await request<ISignInResponse>({
    method: 'post',
    url: '/auth/login',
    data: payload
  });

  return res.data;
};

export const postPasswordRequest = async (payload: IPasswordRequest) => {
  const res = await request({
    method: 'post',
    url: '/mails/reset/password',
    data: payload
  });

  return res.data;
};
