import { usePostReviewsMutation } from '#api/reviews';
import { useQueryString } from '#hooks/useQueryString';
import { Modal } from '#molecules';
import { useToast } from '#providers/ToastProvider';
import { useEffect, useState } from 'react';
import { useGetRecordsV2 } from '../api';
import { StarRating } from './RatingSection/StarRating';
import { useRatingState } from './RatingSection/ratingState';

export const LectureRatingModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [registeredLectureId] = useQueryString(['id']);

  const { data, isFetched } = useGetRecordsV2(registeredLectureId);

  // 운동차트에 기록된 평점
  // const record = {
  //   coachNice: data?.lectureRate?.coachNice,
  //   timeNice: data?.lectureRate?.timeNice,
  //   lectureQuality: data?.lectureRate?.lectureQuality
  // };

  // 사용자가 입력한 평점
  // StarRating 컴포넌트에서 관리
  const { ratingState, setRatingState } = useRatingState();

  const { mutate } = usePostReviewsMutation({
    onSuccess: () => {
      setIsModalOpen(false);
      showToast({
        type: 'success',
        message: '평가가 완료되었습니다.'
      });
      setRatingState({
        coachNice: 0,
        timeNice: 0,
        lectureQuality: 0
      });
    },
    onError: () => {
      setIsModalOpen(false);
      showToast({
        type: 'fail',
        message: '평가에 실패했습니다.'
      });
    }
  });

  const { showToast } = useToast();

  const getRatingValidate = () => {
    const { coachNice, timeNice, lectureQuality } = ratingState;

    return coachNice && timeNice && lectureQuality;
  };

  const onClick = () => {
    if (!getRatingValidate()) {
      showToast({
        type: 'fail',
        message: '평가를 완료해주세요.'
      });
      return;
    }

    const { coachNice, timeNice, lectureQuality } = ratingState;

    mutate({
      coachNice,
      timeNice,
      lectureQuality,
      registeredLectureId,
      type: 'LECTURE'
    });
  };

  useEffect(() => {
    if (isFetched && data) {
      const { lectureRate } = data;
      const { coachNice, lectureQuality, timeNice } = lectureRate;

      if (!coachNice || !lectureQuality || !timeNice) {
        setIsModalOpen(true);
      }
    }
  }, [data, isFetched]);

  return (
    <>
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <div className="p-4 w-full">
            <div className="text-center text-[#d7d2de] text-xl font-semibold font-['Pretendard Variable'] leading-normal tracking-tight">
              오늘 수업은 어떠셨나요?
            </div>

            <div className="w-full h-8"></div>

            <div className="text-center text-[#938f9e] text-base font-normal font-['Pretendard Variable'] leading-[21px]">
              수업에 대한 간단한 평가 부탁드릴게요 :)
            </div>

            <div className="w-full h-4"></div>

            <div className="text-center text-[#585362] text-xs font-normal font-['Pretendard Variable'] leading-none">
              (익명으로 저장되며, 트레이너에게 전달되지 않습니다)
            </div>

            <div className="w-full h-24"></div>

            <StarRating />

            <div className="w-full h-32"></div>

            <div className="flex gap-12 w-full">
              <div
                onClick={() => {
                  setIsModalOpen(false);
                }}
                className="flex-1 h-[45px] px-10 py-3 bg-[#2e2a3a] rounded-lg justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-[#bcb8c3] text-base font-medium font-['Pretendard Variable'] leading-[21px]">
                  취소
                </div>
              </div>
              <div
                onClick={onClick}
                className="flex-1 h-[45px] px-10 py-3 bg-[#7750e9] rounded-lg justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-[#ebe9f4] text-base font-medium font-['Pretendard Variable'] leading-[21px]">
                  확인
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
