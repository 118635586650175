import { useEffect, useState } from 'react';
import Today1 from '#assets/img/samples/TodayT1.png';
import Today2 from '#assets/img/samples/TodayT2.png';
import Today3 from '#assets/img/samples/TodayT3.png';
import Today4 from '#assets/img/samples/TodayT4.png';
import Today5 from '#assets/img/samples/TodayT5.png';

export const useCommunityTodayTraining = () => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    setImages(
      [Today1, Today2, Today3, Today4, Today5]
        .sort(() => Math.random() - Math.random())
        .slice(0, 4)
    );
  }, []);

  return images;
};
