import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  color: var(--color-white);
  padding-bottom: 1rem;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 9rem;
  padding: 1rem 0 1.5rem;
`;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const MenuItems = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.5rem;
`;
