import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { getCoachSchedules } from '#api/schedules';
import { useUserInfo } from '#providers/UserInfo';
import { IGetCoachSchedules } from '#types/api';
import { CalendarDateHour } from './Schedules';

interface Props {
  children?: React.ReactNode;
}

interface CoachScheduleContext extends IGetCoachSchedules {
  updateScheduleList: Array<CalendarDateHour>;
  updateImpossibleSchedules: (d: CalendarDateHour) => void;
}

const CoachScheduleContext = React.createContext<CoachScheduleContext | null>(
  null
);

export const useCoachSchedules = () => {
  const context = React.useContext(CoachScheduleContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <CoachSchedules> component.'
    );
  }
  return context;
};

function CoachSchedules({ children }: Props) {
  const { userInfo } = useUserInfo();
  const [coachSchedules, setCoachSchedueles] = useState<IGetCoachSchedules>({
    schedules: [],
    registeredLectures: [],
    impossibleSchedules: [],
    vacations: []
  });
  const [updateScheduleList, setUpdateScheduleList] = useState<
    Array<CalendarDateHour>
  >([]);

  const updateImpossibleSchedules = (d: CalendarDateHour) => {
    if (updateScheduleList.includes(d)) {
      setUpdateScheduleList(updateScheduleList.filter(x => x !== d));
    } else {
      setUpdateScheduleList([...updateScheduleList, d]);
    }
  };

  // useEffect(() => {
  // }, [updateScheduleList]);

  useEffect(() => {
    if (userInfo && userInfo.isCoach) {
      const { id } = userInfo;
      getCoachSchedules(id, dayjs().format('YYYY-MM-DD')).then(res =>
        setCoachSchedueles({ ...res })
      );
    } else {
      setCoachSchedueles({
        schedules: [],
        registeredLectures: [],
        impossibleSchedules: [],
        vacations: []
      });
    }
  }, [userInfo]);

  const memorizedValue = useMemo(
    () => ({
      ...coachSchedules,
      updateScheduleList,
      updateImpossibleSchedules
    }),
    [coachSchedules, updateScheduleList, updateImpossibleSchedules]
  );

  return (
    <CoachScheduleContext.Provider value={memorizedValue}>
      {children}
    </CoachScheduleContext.Provider>
  );
}

function EmptySchedules({ children }: Props) {
  const context = useCoachSchedules();
  return context.schedules.length ? null : <>{children}</>;
}

function HasSchedules({ children }: Props) {
  const context = useCoachSchedules();
  return context.schedules.length ? <>{children}</> : null;
}

CoachSchedules.EmptySchedules = EmptySchedules;
CoachSchedules.HasSchedules = HasSchedules;

export default CoachSchedules;
