import styled from 'styled-components';

export const Container = styled.div`
  max-width: var(--max-width);
  width: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: var(--color-gray-second);
`;

export const LeftHandler = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5%;
  z-index: 1;
`;

export const RightHandler = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 5%;
  z-index: 1;
`;

export const ImageViewport = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: left 0.1s ease-in-out;
`;

export const ImageItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const SliderContainer = styled.ul`
  display: flex;
  position: relative;
  width: 100%;
  height: 3rem;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const Slider = styled.li<{ isOn: boolean; isOnColor: string }>`
  width: ${({ isOn }) => `${isOn ? 1.7 : 0.5}rem`};
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ isOn, isOnColor }) =>
    `${isOn ? isOnColor : 'var(--color-gray-second)'}`};
`;
