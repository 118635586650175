import React, { useState } from 'react';
import { Text } from '#atoms';
import { Lecture } from '#types/lectures';
import { InfoBox } from './InfoBox';
import { LectureInfoItem } from './LectureInfoItem';
import {
  IndexIcon,
  IndexIconArea,
  ItemList,
  ItemWrapper,
  LectureInfoBox,
  LectureInfoWrap
} from './styles';

interface Props {
  lectureData: Lecture[];
  ot: Lecture | null;
  onePoint: Lecture | null;
}

export const LectureInfoPage = ({ lectureData, ot, onePoint }: Props) => {
  const lectureList = [ot, onePoint].filter(Boolean);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleScroll: React.UIEventHandler<HTMLUListElement> = e => {
    const newIndex = Math.round(
      e.currentTarget.scrollLeft / e.currentTarget.clientWidth
    );
    setCurrentIndex(newIndex);
  };

  return (
    <LectureInfoWrap>
      {lectureList.length > 0 && (
        <ItemWrapper>
          <ItemList onScroll={handleScroll}>
            {lectureList.map(lecture =>
              lecture ? <InfoBox key={lecture?.id} data={lecture} /> : null
            )}
          </ItemList>
          {lectureList.length > 1 && (
            <IndexIconArea>
              {new Array(lectureList.length).fill(null).map((_, index) => (
                <IndexIcon
                  key={index}
                  className={index === currentIndex ? 'point' : ''}
                />
              ))}
            </IndexIconArea>
          )}
        </ItemWrapper>
      )}
      {/* {ot && <OtInfoBox data={ot} />} */}
      {lectureData.length > 0 ? (
        lectureData.map(
          lecture =>
            !lecture.isOt &&
            !lecture.isOnePoint && (
              <LectureInfoItem key={lecture?.id} data={lecture} />
            )
        )
      ) : (
        <LectureInfoBox>
          <Text>수업 정보 없음</Text>
        </LectureInfoBox>
      )}
    </LectureInfoWrap>
  );
};
