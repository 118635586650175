import React, { useEffect, useState } from 'react';
import { Modal } from '#molecules';
import { ClauseArea, Container, TextButton } from './styles';

export const BottomPolicy = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>('');

  useEffect(() => {
    if (modalOpen) {
      fetch(`/policy_payment.txt`)
        .then(response => response.text())
        .then(text => setText(text));
    }
  }, [modalOpen]);

  return (
    <Container>
      <div data-text-type="c1">
        {/* <TextButton type="button">개인정보 제3자 제공</TextButton> */}
        <TextButton type="button" onClick={() => setModalOpen(!modalOpen)}>
          취소 및 환불 규정
        </TextButton>
      </div>
      <ClauseArea data-text-type="c2">
        <p>
          피벗은 통신판매중개자로서 오픈마켓 피벗의 거래당사자가 아니며,
          <br />
          전문가가 등록한 수업정보 및 거래에 대해 피벗은 일체의 책임을 지지
          않습니다.
        </p>
      </ClauseArea>
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          {text &&
            text.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
        </Modal>
      )}
    </Container>
  );
};
