import classNames from 'classnames';
import { useState } from 'react';
import { BoxIcon, Text } from '#atoms';
import { FilterTabContainer, ListContainer, ItemText } from './styles';

interface Props {
  data: { id: number; name: string }[];
  filter: number;
  onFilterChange: (filter: number) => void;
}

export const FilterTab = ({ data, filter, onFilterChange }: Props) => {
  const [toggle, setToggle] = useState(false);

  const handleFilter = (id: number) => {
    setToggle(!toggle);
    onFilterChange(id);
  };
  return (
    <FilterTabContainer>
      <ItemText
        onClick={() => setToggle(!toggle)}
        className={classNames({ open: toggle })}
      >
        <Text>{data.find(f => f.id === filter)?.name}</Text>
        <BoxIcon type="thin-arrow" onlyIcon size={12} />
      </ItemText>
      {toggle && (
        <ListContainer>
          {data.map(filter => (
            <li
              data-text-type="c1"
              key={filter.id}
              onClick={() => handleFilter(filter.id)}
            >
              {filter.name}
            </li>
          ))}
        </ListContainer>
      )}
    </FilterTabContainer>
  );
};
