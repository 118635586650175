/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
const openDaumPostCode = (onComplete, target) => {
  new daum.Postcode({
    oncomplete: onComplete,
    width: '100%',
    height: '100%',
    pleaseReadGuide: 5
  }).embed(target);
};

export default openDaumPostCode;
