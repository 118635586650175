import React from 'react';
// import { useNavigate } from 'react-router';
import { /* ArrowButton, */ Text } from '#atoms';
import { PATH } from '#const/path';
import { KakaoButton } from '#molecules';
import { PrevHeaderBarTemplate } from '#templates';
import { ButtonContainer, TitleContainer } from './styles';

export function MyPageHelpHome() {
  // const navigate = useNavigate();

  return (
    <PrevHeaderBarTemplate prevRoute={PATH.MYPAGE}>
      <TitleContainer>
        <Text textStyle="t1">무엇을 도와드릴까요?</Text>
      </TitleContainer>
      <ButtonContainer>
        <KakaoButton
          text="카카오톡으로 문의하기"
          annotation="운영시간 : 평일 10:00~17:00"
          onClick={() => window.open('http://pf.kakao.com/_cxeCPG')}
        />
        {/* <ArrowButton
          text="개선사항 제안하기"
          onClick={() => navigate(PATH.MYPAGE_HELP_CS)}
          hasBigfontSize={false}
          arrowColor="white"
        /> */}
      </ButtonContainer>
    </PrevHeaderBarTemplate>
  );
}
