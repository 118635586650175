import styled from 'styled-components';

export const Container = styled.div<{ isButtonFixed: boolean }>`
  position: relative;
  width: 100%;
  height: ${props => (props.isButtonFixed ? 'calc(100% - 9.5rem)' : '100%')};
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ButtonContainer = styled.div<{ isFixed: boolean }>`
  position: ${props => (props.isFixed ? 'absolute' : 'relative')};
  bottom: 0;
  width: 100%;
  padding: 1.2rem 2rem 3rem;
  background-color: var(--color-background);
`;

export const TextContainer = styled.div`
  width: 100%;
  padding: 3.6rem 2rem 2rem;
`;

export const ItemContainer = styled.div`
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--color-gray-third);
  box-sizing: border-box;
  white-space: normal;
`;

export const SelectedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TimeSliderContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  overflow-x: hidden;
`;

export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;
