import classNames from 'classnames';
import React, { useCallback } from 'react';
import { BoxColorType, Container, Icon, IconType } from './styles';

interface Props {
  // 직사각형으로 렌더링 하는 경우
  width?: number;
  height?: number;
  // 정사각형인 경우
  size?: number;
  type: IconType;
  boxColor?: BoxColorType;
  focus?: boolean;
  onlyIcon?: boolean;
  hide?: boolean;
  onClick?: () => void;
}

export const BoxIcon = ({
  type,
  onlyIcon = false,
  boxColor = 'background',
  focus,
  hide,
  onClick,
  size = 40,
  width,
  height
}: Props) => {
  const onClickEventListener = useCallback(
    () => onClick && onClick(),
    [onClick]
  );

  if (onlyIcon)
    return (
      <Icon
        style={{ display: 'block' }}
        width={width ?? size}
        height={height ?? size}
        className={classNames(type, { hide })}
        onClick={onClickEventListener}
      />
    );

  return (
    <Container
      className={classNames({ focus, hide })}
      boxColor={boxColor}
      onClick={onClickEventListener}
    >
      <Icon width={width ?? size} height={height ?? size} className={type} />
    </Container>
  );
};
