import classNames from 'classnames';
import { useState } from 'react';
import { BoxIcon, Text } from '#atoms';
import { IExerciseRecord, IGetCoachRecord } from '#types/records';
import { dateDayFormatter } from '#utils/dateFormatter';
import {
  CheckBox,
  ExerciseRecordWrapper,
  ExerciseSetBox,
  ExerciseSetWrapper,
  LectureInfoContainer,
  LectureInfoWrapper,
  LectureUserNameWrapper,
  PerSetWeight,
  RecordsContainer,
  RowStretchBox,
  ToggleBox
} from './styles';

interface Props {
  data: IGetCoachRecord;
  values: IExerciseRecord[];
  onChange?: (values: IExerciseRecord[]) => void;
}

export const ExerciseRecordSelector = ({ data, values, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleCheckBoxClick = (exerciseRecord: IExerciseRecord) => {
    onChange &&
      onChange(
        values.includes(exerciseRecord)
          ? values.filter(record => record !== exerciseRecord)
          : [...values, exerciseRecord]
      );
  };

  return (
    <>
      <LectureInfoContainer>
        <LectureUserNameWrapper>
          <Text textStyle="t2">{data.registeredLectureRecord.name}</Text>
          <Text textStyle="t2">회원님 운동기록</Text>
          <ToggleBox onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
            <Text textStyle="t3" textColor="gray2">
              {values.length}개 선택
            </Text>
            <BoxIcon type="thin-arrow" onlyIcon size={16} />
          </ToggleBox>
        </LectureUserNameWrapper>
        <LectureInfoWrapper>
          <RowStretchBox>
            <p data-text-type="t3">
              {dateDayFormatter(data.registeredLectureRecord.taughtAt)}
            </p>
            <Text textStyle="t3">{data.registeredLectureRecord.round}회차</Text>
          </RowStretchBox>
          <Text textStyle="t3">{data.registeredLectureRecord.gym}</Text>
        </LectureInfoWrapper>
      </LectureInfoContainer>
      {isOpen && (
        <RecordsContainer>
          {data.exerciseRecord?.map((item, index) => (
            <ExerciseRecordWrapper key={index}>
              <RowStretchBox>
                <Text textStyle="t3">{item.exerciseKindName}</Text>
                <CheckBox
                  className={classNames({
                    checked: values.includes(item)
                  })}
                  onClick={() => handleCheckBoxClick(item)}
                >
                  {values.includes(item) && (
                    <BoxIcon
                      type="check-white"
                      size={10}
                      boxColor="transparent"
                    />
                  )}
                </CheckBox>
              </RowStretchBox>
              <ExerciseSetWrapper>
                {item.sets.map((data, index) => (
                  <ExerciseSetBox key={index}>
                    <PerSetWeight>
                      <Text>{data.round}set</Text>
                      <Text>{data.set.weight}kg</Text>
                    </PerSetWeight>
                    <Text>{data.set.reps}회</Text>
                  </ExerciseSetBox>
                ))}
              </ExerciseSetWrapper>
            </ExerciseRecordWrapper>
          ))}
        </RecordsContainer>
      )}
    </>
  );
};
