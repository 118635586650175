import React, { PropsWithChildren, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { BoxIcon } from '#atoms';
import { ChildrenContainer, Container, HeaderConatiner } from './styles';

interface Props {
  prevRoute: string;
}

export function PrevHeaderBarTemplate({
  prevRoute,
  children
}: PropsWithChildren<Props>) {
  const navigate = useNavigate();

  const onClickArrow = useCallback(() => navigate(prevRoute), [prevRoute]);

  return (
    <Container>
      <HeaderConatiner>
        <BoxIcon
          type="prev-arrow"
          size={18}
          onlyIcon={true}
          onClick={onClickArrow}
        />
      </HeaderConatiner>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
}
