import styled from 'styled-components';

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
`;

export const Circle = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;

  &.on {
    outline: 1px solid var(--color-white);
    background-color: var(--color-point);
  }

  &.off {
    background-color: var(--color-white);
  }
`;
