import { useGetRecentClinicalData } from '#api/clinical-data';
import { useGetRecentReviews } from '#api/reviews';
import { MainHomeBanner } from '#organisims';
import { useAttendingLectures } from '#providers/AttendLecture';
import { useUserInfo } from '#providers/UserInfo';
import { MainConsumerCard } from 'src/components/organisims/MainConsumerCard';
import { MainConsumerTitle } from 'src/components/organisims/MainConsumerTitle';
import { ClinicalDataList } from 'src/components/organisims/MainHomeBanner/ClinicalDataList';
import { ReviewList } from 'src/components/organisims/MainHomeBanner/ReviewList';
import { SlideSelectorArea } from './styles';

export const MainConsumerTemplate = () => {
  const { userInfo } = useUserInfo();
  const { lectures, calculatedRemainingLecture, hasTakenStatusIngLecture } =
    useAttendingLectures();
  const { data: recentClinicalDataList } = useGetRecentClinicalData();
  const { data: recentReviews } = useGetRecentReviews();

  const hasActiveLectures =
    userInfo &&
    !userInfo.isCoach &&
    lectures.length !== 0 &&
    calculatedRemainingLecture !== 0 &&
    !hasTakenStatusIngLecture;

  return (
    <SlideSelectorArea>
      {/* <MainHomeBanner type="intro" /> */}
      <div style={{ minHeight: '162px' }}>
        {hasActiveLectures ? (
          <MainHomeBanner type="schedule" />
        ) : (
          // <MainCategorySelector />
          <MainConsumerTitle />
        )}
      </div>
      <MainConsumerCard />
      {/* <MainAdvCard /> */}
      <div>
        {!!recentClinicalDataList?.length && (
          <ClinicalDataList data={recentClinicalDataList} />
        )}
      </div>
      <div>
        {!!recentReviews?.length && <ReviewList data={recentReviews} />}
      </div>
      {/* <div>
        <CertificationExpertList />
      </div> */}
    </SlideSelectorArea>
  );
};
