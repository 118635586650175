import styled from 'styled-components';

export const FilterTabContainer = styled.div`
  position: relative;
`;

export const ItemText = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  background-color: transparent;
  color: var(--color-gray-text-second);

  &.open {
    > span.thin-arrow {
      transform: rotate(180deg);
    }
  }
`;

export const ListContainer = styled.ul`
  position: absolute;
  top: 3rem;
  background-color: var(--color-gray);
  max-height: 18rem;
  overflow-y: auto;
  width: 15rem;
  right: 0;
  border-radius: 0.8rem;
  z-index: 2;

  > li {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    border-bottom: 1px solid var(--color-border-light);

    &:last-child {
      border-bottom: none;
    }
  }
`;
