import styled from 'styled-components';
import ProfileIcon from '#assets/svg/profile.svg';

export const CustomerReviewItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-bottom: 1px solid var(--color-border);
  padding: 2rem;
`;

export const ProfileContainer = styled.div`
  display: flex;
  gap: 0.9rem;
`;

export const ProfileImage = styled.img`
  width: 3.8rem;
  height: 3.8rem;
  object-fit: fill;
  border-radius: 50%;
  background-color: ${props =>
    props.src === ProfileIcon ? 'white' : 'transparent'};
  align-self: center;
`;

export const ReviewContent = styled.p`
  color: var(--color-gray-text-second);
  white-space: pre-wrap;
`;

export const ImageList = styled.ul`
  display: flex;
  gap: 0.75rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const ImageItem = styled.li`
  width: 9.25rem;
  height: 9.25rem;

  img {
    width: 9.25rem;
    height: 9.25rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  > div {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    > div.rate {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
`;
