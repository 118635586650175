import React from 'react';
import { useNavigate } from 'react-router';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { ButtonContainer, Container, TextContainer } from './styles';

export function RegisterScheduleTemplate() {
  const navigate = useNavigate();

  return (
    <Container>
      <TextContainer>
        <Text textStyle="t3">
          수업 가능 시간을 등록한 후
          <br />
          일정 등록이 가능합니다.
        </Text>
      </TextContainer>
      <ButtonContainer>
        <Button onClick={() => navigate(PATH.PROFILE_UPDATE)}>
          수업 가능 시간 등록하러 가기
        </Button>
      </ButtonContainer>
    </Container>
  );
}
