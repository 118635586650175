import { useEffect, useState } from 'react';
import SampleBA1 from '#assets/img/samples/BA 1.png';
import SampleBA2 from '#assets/img/samples/BA 2.png';
import SampleBA3 from '#assets/img/samples/BA 3.png';

interface BNAImage {
  type: 'before' | 'after';
  src: string;
}

interface BNA {
  images: BNAImage[];
  title: string;
  description: string;
}

export const useCommunityBNFPreview = () => {
  const [bna, setBNA] = useState<BNA[]>([]);

  useEffect(() => {
    setBNA([
      {
        images: [
          { type: 'before', src: SampleBA1 },
          { type: 'after', src: SampleBA2 },
          { type: 'after', src: SampleBA3 }
        ],
        title: '3개월차 헬린이 평가 부탁드립니다.',
        description:
          '헬스 시작한지 3개월 되어가는 헬린이입니다.\n 훈수받고싶어 올려보아요!'
      }
    ]);
  }, []);

  return bna;
};
