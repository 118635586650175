import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; //
import utc from 'dayjs/plugin/utc'; // UTC 처리 플러그인
import { useState } from 'react';
import { useNavigate } from 'react-router';
// import { useGetAccount } from '#api/accounts';
// import { useGetCoachIdFromMyUrl } from '#api/my-url';
import { Button, Text, Textarea } from '#atoms';
import { PATH } from '#const/path';
import { useChat } from '#providers/Chat';
// import { useDashboard } from '#providers/Dashboard';
import { useScheduleManage } from '#providers/SchedulesManage';
// import { ReservedMembersType } from '#types/schedule';
import { dateDayKoFormatter } from '#utils/dateFormatter';
import {
  Container,
  TimeBox,
  TimeHeader,
  LabeledInfo,
  TextAreaBox,
  ButtonBox
} from './styles';
dayjs.extend(timezone);
dayjs.extend(utc);

export function WeeklyCalenderDetail() {
  const { openChat } = useChat();
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const navigate = useNavigate();
  const { selectedLecture: bookedMember } = useScheduleManage();

  const todayDate = bookedMember && dateDayKoFormatter(bookedMember.taughtAt);
  const startTime = dayjs(bookedMember?.taughtAt)
    .subtract(9, 'hour')
    .format('HH:mm');
  const endTime = dayjs(bookedMember?.endedAt)
    .subtract(9, 'hour')
    .format('HH:mm');

  const handleChatButtonClick = (customerId: string | undefined) => {
    if (customerId) {
      const onSuccess = () => {
        navigate(PATH.CHAT_ROOM);
      };
      const onFail = () => '';
      openChat(customerId, onSuccess, onFail);
    }
  };
  return (
    <Container>
      <TimeHeader>
        <Text textColor="gray2">{todayDate}</Text>
        <TimeBox>
          <Text textStyle="t2">{startTime}</Text>
          <Text textStyle="t2" textColor="gray">
            -
          </Text>
          <Text textStyle="t2">{endTime}</Text>
        </TimeBox>
      </TimeHeader>

      <div>
        <LabeledInfo>
          <Text>회원</Text>
          <Text>{bookedMember?.personName}</Text>
        </LabeledInfo>

        <LabeledInfo>
          <Text>수업</Text>
          <Text>{bookedMember?.title}</Text>
        </LabeledInfo>

        <LabeledInfo>
          <Text>회차</Text>
          <Text>
            {bookedMember?.round}/{bookedMember?.totalRound}회차
          </Text>
        </LabeledInfo>
      </div>
      <TextAreaBox>
        <Text>메모</Text>
        <Textarea
          value={textAreaValue}
          placeholder="회원과 관련된 메모를 적어주세요!"
          onChange={e => setTextAreaValue(e)}
          height={40}
          limit={500}
        />
      </TextAreaBox>

      <ButtonBox>
        <Button onClick={() => navigate(PATH.APPLICATION)}>
          운동차트 작성
        </Button>
        <Button onClick={() => handleChatButtonClick(bookedMember?.personId)}>
          채팅
        </Button>
      </ButtonBox>
    </Container>
  );
}
