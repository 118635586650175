import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const HeaderContainer = styled.div<{
  headerbarType: 'absolute' | 'relative';
  defaultBackground?: boolean;
}>`
  position: ${({ headerbarType }) => headerbarType};
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 5.8rem;
  padding: 0 2rem;
  z-index: 1;
  color: var(--color-white);
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-gray);
  align-items: center;
  background-color: ${({ defaultBackground }) =>
    defaultBackground ? 'var(--color-background)' : 'none'};
`;

export const ChildrenContainer = styled.article<{
  headerbarType: 'absolute' | 'relative';
}>`
  position: relative;
  width: 100%;
  height: ${({ headerbarType }) =>
    headerbarType === 'absolute' ? '100%' : 'calc(100% - 5.8rem)'};
  padding-top: ${({ headerbarType }) =>
    headerbarType === 'absolute' ? '5.8rem' : '0'};
  overflow-x: hidden;
  overflow-y: auto;
`;
export const IconDropdownContainer = styled.div`
  position: relative;
`;
export const DropBox = styled.div`
  width: 16rem;
  height: 10.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #373245;
  border-radius: 12px;
  text-align: center;
`;

export const DropDownButton = styled.button`
  width: 90%;
  height: 100%;
  padding: 0 2rem;
`;

export const DropDownBorderButton = styled.button`
  width: 90%;
  height: 100%;
  padding: 0 2rem;
  border-top: 1px solid var(--color-gray-fourth);
  color: #e13a3a;
`;

export const ModalButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  button {
    width: 45%;
  }
`;
