import React, { useEffect } from 'react';
import { ScheduleCalendar, ScheduleHourTable } from '#organisims';
import CoachSchedules from '#providers/CoachSchedules';
import { useSchedules } from '#providers/Schedules/Schedules';
import { useUserInfo } from '#providers/UserInfo';
import {
  PrevHeaderBarWithTitleTemplate,
  RegisterScheduleTemplate
} from '#templates';
import { ItemConatiner } from './styles';

export function MyPageScheduleCoach() {
  const { userInfo } = useUserInfo();
  const { setCoachId } = useSchedules();

  useEffect(() => {
    setCoachId(userInfo?.id ?? null);
  }, [userInfo]);

  return (
    <PrevHeaderBarWithTitleTemplate title="수업 일정 관리" defaultBackground>
      <CoachSchedules.EmptySchedules>
        <RegisterScheduleTemplate />
      </CoachSchedules.EmptySchedules>
      <CoachSchedules.HasSchedules>
        <ItemConatiner>
          <ScheduleCalendar />
        </ItemConatiner>
        <ItemConatiner>
          <ScheduleHourTable />
        </ItemConatiner>
      </CoachSchedules.HasSchedules>
    </PrevHeaderBarWithTitleTemplate>
  );
}
