import styled from 'styled-components';
export const Container = styled.div`
  padding: 2rem 0rem;

  & > .rate {
    display: flex;
    padding: 0 2rem 3rem;
    border-bottom: 1rem solid var(--color-gray-third);
  }

  & > .review {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem 0;
  }

  & > div:last-child {
    border-bottom: none;
  }
`;

export const RateContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  position: relative;

  &:hover .label {
    display: block;
  }
`;

export const ReviewListContainer = styled.div`
  & > :last-child {
    border-bottom: none;
  }
`;

export const Label = styled.p`
  display: none;
  position: absolute;
  top: -1rem;
  left: 6.2rem;
  padding: 0.4rem 0.6rem;
  width: max-content;
  border-radius: 5px;
  background-color: var(--color-border);
  z-index: 1;
`;
