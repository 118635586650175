import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const CarouselContainer = styled.article`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CarouselViewport = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`;

export const CarouselSlide = styled.div<{ count: number }>`
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${({ count }) => `${count * 100}%`};
  transition: left 0.15s ease-in-out;
`;

export const CarouselItem = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const AlignContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const FirstTabConatiner = styled.div`
  width: 100%;
  padding-left: 21px;
  text-align: left;
`;

export const SecondTabConatiner = styled.div`
  width: 100%;
  padding-right: 21px;
  text-align: right;
`;

export const FirstCenterLine = styled.hr`
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #b1b1b1 0%, #ffffff 0.01%, #863cff 100%);
  border: none;
`;

export const SecondCenterLine = styled.hr`
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #863cff 0%, #ffffff 100%);
  border: none;
`;

export const BottomProgressContainer = styled.article`
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
`;

export const ProgressDotContainer = styled.ul`
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const ProgresDot = styled.li<{ activate: boolean }>`
  width: ${({ activate }) => (activate ? '52px' : '5px')};
  height: 5px;
  border-radius: 5px;
  background-color: ${({ activate }) =>
    activate ? 'var(--color-point)' : 'var(--color-white) '};
`;

export const BackgroundContiner = styled.div<{ src: string }>`
  width: 100%;
  height: 100%;
  background: url(${props => props.src}) no-repeat;
`;
