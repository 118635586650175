import logo from '#assets/svg/logo.svg';
import { BoxIcon, Text } from '#atoms';
import { PATH } from '#const/path';
import { KakaoButton } from '#molecules';
import sessionStorageManager from '#utils/sessionStorageManager';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  ButtonArea,
  Container,
  EmailAuthButtonArea,
  LogoArea,
  LogoWrapper,
  OAuthButtonArea
} from './styles';

export function SignHomePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setBlockedPagePath } = sessionStorageManager();

  const emailSignInCallback = useCallback(
    () =>
      navigate(PATH.SIGNIN, {
        state: { blockedPagePath: location.state?.blockedPagePath }
      }),
    []
  );

  const emailSignUpCallback = useCallback(() => {
    setBlockedPagePath(location.state?.blockedPagePath ?? '');
    navigate(PATH.SIGNUP);
  }, []);

  const onTrainerLogin = () => {
    window.location.href = 'https://trainer.fibud.kr';
  };

  return (
    <Container>
      <LogoWrapper>
        <BoxIcon
          type="x-white"
          onlyIcon
          size={18}
          onClick={() => navigate(-1)}
        />
        <LogoArea>
          <img src={logo} />
          <Text
            textStyle="t3"
            style={{ textAlign: 'center', fontWeight: '600' }}
          >
            숫자만 세주는 트레이너 말고,<br></br> 진짜 전문가를 만나는 곳.
          </Text>
        </LogoArea>
      </LogoWrapper>
      <ButtonArea>
        <OAuthButtonArea>
          <KakaoButton
            text="카카오로 1초만에 시작하기"
            icon
            onClick={() => {
              setBlockedPagePath(location.state?.blockedPagePath ?? '');
              window.location.href = `${process.env.REACT_APP_API_SERVER_URL}/auth/login/kakao?state=fibud`;
            }}
          />
        </OAuthButtonArea>
        <div>
          <EmailAuthButtonArea>
            <Text textStyle="c1" onClick={emailSignInCallback}>
              이메일로 로그인
            </Text>
            <div style={{ opacity: 0.5 }}></div>
            <Text textStyle="c1" onClick={emailSignUpCallback}>
              이메일로 회원가입
            </Text>
          </EmailAuthButtonArea>
          <div style={{ width: '100%', height: '10px' }}></div>
          <EmailAuthButtonArea>
            <Text textStyle="c1" onClick={onTrainerLogin}>
              트레이너로 회원가입
            </Text>
          </EmailAuthButtonArea>
        </div>
      </ButtonArea>
    </Container>
  );
}
