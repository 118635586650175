import { TabIntroduce } from 'src/components/molecules/TabIntroduce';
import { IEducation } from '../types';

interface EduIntroduceProps {
  educationDetail: IEducation;
}
export const EduIntroduce = ({ educationDetail }: EduIntroduceProps) => {
  const introduceContents = Object.entries(educationDetail.introductions).map(
    ([key, value]) => ({
      title: key,
      body: value
    })
  );

  return (
    <TabIntroduce
      admin={{
        name: educationDetail.adminName || '',
        accountId: educationDetail.accountId
      }}
      introduceContents={introduceContents}
    />
  );
};
