import React, { useEffect, useMemo, useState } from 'react';
import { getCustomerDetail } from '#api/confirmation_forms';
import { getRecords } from '#api/records';
import {
  IGetConfirmationForm,
  IGetCustomerDetailProps
} from '#types/confirmationForms';
import { IRecord } from '#types/records';

interface Props {
  children?: React.ReactNode;
}

interface DashboardContext {
  isLoading: boolean;
  isError: boolean;
  confirmationFormIdProps: IGetCustomerDetailProps | Record<string, never>;
  setConfirmationFormIdProps: React.Dispatch<
    React.SetStateAction<Record<string, never> | IGetCustomerDetailProps>
  >;
  customerDetail: Record<string, never> | IGetConfirmationForm;
  setCustomerDetail: React.Dispatch<
    React.SetStateAction<Record<string, never> | IGetConfirmationForm>
  >;
  registeredLectureId: string;
  setRegisteredLectureId: React.Dispatch<React.SetStateAction<string>>;
  lectureRecord: Partial<IRecord>;
  setLectureRecord: React.Dispatch<React.SetStateAction<Partial<IRecord>>>;
}

const DashboardContext = React.createContext<DashboardContext | null>(null);

export const useDashboard = () => {
  const context = React.useContext(DashboardContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <Dashboard> component.'
    );
  }
  return context;
};

function Dashboard({ children }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [confirmationFormIdProps, setConfirmationFormIdProps] = useState<
    IGetCustomerDetailProps | Record<string, never>
  >({});
  const [customerDetail, setCustomerDetail] = useState<
    IGetConfirmationForm | Record<string, never>
  >({});
  const [registeredLectureId, setRegisteredLectureId] = useState<string>('');
  const [lectureRecord, setLectureRecord] = useState<Partial<IRecord>>({});

  useEffect(() => {
    if (Object.keys(confirmationFormIdProps).length) {
      setIsLoading(true);
      getCustomerDetail({
        personId: confirmationFormIdProps.personId,
        id: confirmationFormIdProps.id
      })
        .then(data => {
          setCustomerDetail(data);
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setIsError(false);
      setCustomerDetail({});
    }
  }, [confirmationFormIdProps]);

  useEffect(() => {
    if (registeredLectureId) {
      setIsLoading(true);
      getRecords(registeredLectureId)
        .then(data => {
          setLectureRecord(data);
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setIsError(false);
      setLectureRecord({});
    }
  }, [registeredLectureId]);

  const memorizedValue = useMemo<DashboardContext>(
    () => ({
      isLoading,
      isError,
      confirmationFormIdProps,
      setConfirmationFormIdProps,
      customerDetail,
      setCustomerDetail,
      registeredLectureId,
      setRegisteredLectureId,
      lectureRecord,
      setLectureRecord
    }),
    [
      isLoading,
      isError,
      confirmationFormIdProps,
      customerDetail,
      registeredLectureId,
      lectureRecord
    ]
  );

  return (
    <DashboardContext.Provider value={memorizedValue}>
      {children}
    </DashboardContext.Provider>
  );
}

function IsLoading({ children }: Props) {
  const { isLoading } = useDashboard();
  return isLoading ? <>{children}</> : null;
}

function IsError({ children }: Props) {
  const { isLoading, isError } = useDashboard();
  return !isLoading && isError ? <>{children}</> : null;
}

function HasCustomerDetail({ children }: Props) {
  const { isLoading, isError, customerDetail } = useDashboard();
  return !isLoading && !isError && Object.keys(customerDetail).length > 0 ? (
    <>{children}</>
  ) : null;
}

function EmptyCustomerDetail({ children }: Props) {
  const { isLoading, isError, customerDetail } = useDashboard();
  return !isLoading && !isError && Object.keys(customerDetail).length === 0 ? (
    <>{children}</>
  ) : null;
}

function HasRecord({ children }: Props) {
  const { isLoading, isError, lectureRecord } = useDashboard();
  return !isLoading && !isError && Object.keys(lectureRecord).length > 0 ? (
    <>{children}</>
  ) : null;
}

function EmptyRecord({ children }: Props) {
  const { isLoading, isError, lectureRecord } = useDashboard();
  return !isLoading && !isError && Object.keys(lectureRecord).length === 0 ? (
    <>{children}</>
  ) : null;
}

Dashboard.IsLoading = IsLoading;
Dashboard.IsError = IsError;
Dashboard.HasCustomerDetail = HasCustomerDetail;
Dashboard.EmptyCustomerDetail = EmptyCustomerDetail;
Dashboard.HasRecord = HasRecord;
Dashboard.EmptyRecord = EmptyRecord;

export default Dashboard;
