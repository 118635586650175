import { useGeolocation } from '#hooks/useGeolocation';
import { useIntersectionObserver } from '#hooks/useIntersectionObserver';
import useScrollRestoration from '#hooks/useScrollRestoration';
import { useParams } from 'react-router';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner';
import { LocationItem } from 'src/components/molecules/LocationItem';
import { CoacheList } from 'src/components/organisims/CoacheList/CoacheList';
import { useGetEducationCoach, useGetEducationIssuerId } from '../api';

export const EduCoachesList = () => {
  // 위치 정보 가져오기
  const { position } = useGeolocation();

  // 쿼리 파라미터 가져오기
  const { educationUrl } = useParams();

  const { data: educationIssuerId } = useGetEducationIssuerId(educationUrl, {
    enabled: !!educationUrl
  });
  const {
    data: coachesList,
    hasNextPage,
    fetchNextPage
  } = useGetEducationCoach(
    {
      educationIssuerId: educationIssuerId as string,
      lat: position.lat,
      lng: position.lng,
      offset: 10
    },
    {
      enabled: !!educationIssuerId && !!position.lat && !!position.lng
    }
  );

  const { setTarget } = useIntersectionObserver({
    hasNextPage,
    fetchNextPage
  });

  const COACH_LIST_SCROLL_TOP_KEY = 'coach-list-scroll-top';
  const { setScrollRestoration } = useScrollRestoration(
    COACH_LIST_SCROLL_TOP_KEY
  );

  if (!coachesList)
    return (
      <div
        style={{
          width: '100%',
          paddingTop: '30px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <LoadingSpinner />
      </div>
    );
  return (
    <div style={{ paddingBottom: '20px' }}>
      <div style={{ padding: '28px 20px 0' }}>
        <LocationItem />
      </div>
      <CoacheList
        coachesList={coachesList}
        setScrollRestoration={setScrollRestoration}
        setTarget={setTarget}
      />
    </div>
  );
};
