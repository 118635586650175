import styled from 'styled-components';

export const BottomSheetWrap = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
  max-height: 50rem;
  overflow-y: auto;
  background-color: var(--color-gray);
  border-radius: 2rem 2rem 0 0;
  transition: all 0.3s linear;
`;

export const BottomSheetArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;

  > :first-child {
    right: 2rem;
    margin-left: auto;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  border-bottom: 1px solid var(--color-border);

  li {
    padding-left: 1rem;
  }

  :last-child {
    border-bottom: none;
  }
`;
