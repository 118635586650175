import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const StatusArea = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    color: var(--color-gray-text);
  }
`;
