import React from 'react';
import { ImageDiv, ImageListItem } from './styles';

/**
 * @todo 언젠간 없어질 컴포넌트
 * 일단은 빠른 개발을 위해 만든다
 */

interface Props {
  type?: 'div' | 'li';
  src: string;
}

export const SquareImage = ({ type = 'div', src }: Props) => {
  if (type === 'li') return <ImageListItem src={src} />;
  return <ImageDiv source={src} />;
};
