import body_profile from '#assets/svg/body_profile.svg';
import body_shape_correction from '#assets/svg/body_shape_correction.svg';
import competition_preparation from '#assets/svg/competition_preparation.svg';
import diet from '#assets/svg/diet.svg';
import muscle_strengthening from '#assets/svg/muscle_strengthening.svg';
import pain_treatement from '#assets/svg/pain_treatement.svg';
import physical_strength_increase from '#assets/svg/physical_strength_increase.svg';
import rehabilitation from '#assets/svg/rehabilitation.svg';

/**
 * @description
 *
 * 백엔드에서 특정 enum 값을 요구하는 경우가 있습니다.
 * 이때 백엔드에서 요구하는 enum 값을 id로,
 * 이에 해당하는 프론트에서 사용할 이름 값을 name으로 지정해주시기 바랍니다.
 */

export const STARTDATE_LIST = [
  { id: 0, name: '경력없음' },
  { id: 1, name: '1년' },
  { id: 2, name: '2년' },
  { id: 3, name: '3년' },
  { id: 4, name: '4년' },
  { id: 5, name: '5년 이상' }
];

export const PURPOSE_LIST = [
  { id: 'DIET', name: '다이어트', imgSrc: diet },
  {
    id: 'MUSCLE_STRENGTHENING',
    name: '근력 강화',
    imgSrc: muscle_strengthening
  },
  { id: 'BODY_PROFILE', name: '바디프로필', imgSrc: body_profile },
  {
    id: 'PHYSICAL_STRENGTH_INCREASE',
    name: '체력 증진',
    imgSrc: physical_strength_increase
  },
  {
    id: 'BODY_SHAPE_CORRECTION',
    name: '체형 교정',
    imgSrc: body_shape_correction
  },
  {
    id: 'COMPETITION_PREPARATION',
    name: '대회 준비',
    imgSrc: competition_preparation
  },
  { id: 'PAIN_TREATMENT', name: '통증 관리', imgSrc: pain_treatement },
  { id: 'REHABILITATION', name: '재활 케어', imgSrc: rehabilitation }
];

export const QUALITY_OF_SLEEP_LIST = [
  { id: 0, name: '나쁨' },
  { id: 1, name: '보통' },
  { id: 2, name: '좋음' }
];

export const SLEEPING_HOUR_AVG_LIST = [
  { id: 2, name: '3시간 미만' },
  { id: 3, name: '3시간' },
  { id: 4, name: '4시간' },
  { id: 5, name: '5시간' },
  { id: 6, name: '6시간' },
  { id: 7, name: '7시간' },
  { id: 8, name: '8시간' },
  { id: 9, name: '9시간' },
  { id: 10, name: '10시간 이상' }
];

export const JOB_LIST = [
  { id: '학생', name: '학생' },
  { id: '군인', name: '군인' },
  { id: '주부', name: '주부' },
  { id: '사무직', name: '사무직' },
  { id: '영업직', name: '영업직' },
  { id: '서비스직', name: '서비스직' },
  { id: '전문직', name: '전문직' },
  { id: '자영업자', name: '자영업자' },
  { id: '운동선수', name: '운동선수' }
];

export const JOB_QUALITY_LIST = [
  { id: 'repetitiveTasks', name: '반복적인 동작을 요구하는 직업인가요?' },
  { id: 'deskJobLongTime', name: '장시간 앉아있는 직업인가요?' },
  { id: 'overtime', name: '8시간을 초과하여 근무 하시나요?' },
  { id: 'stress', name: '근무 시 정신적인 스트레스가 있으신가요?' }
];

export const DISEASE_LIST = [
  { id: 'HEART', name: '심장 질환' },
  { id: 'MUSCLE_OR_JOINT_INJURY', name: '근육/관절 부상' },
  { id: 'ETC1', name: '당뇨' },
  { id: 'ETC2', name: '고혈압' },
  { id: 'ETC3', name: '저혈압' },
  { id: 'ETC4', name: '소화기 질환' }
];

export const PAINPART_LIST = [
  { id: 'NECK', name: '목' },
  { id: 'SHOULDER', name: '어깨' },
  { id: 'WAIST', name: '허리' },
  { id: 'ETC1', name: '골반' },
  { id: 'ETC2', name: '팔꿈치' },
  { id: 'ETC3', name: '손목' },
  { id: 'ETC4', name: '발목' },
  { id: 'ETC5', name: '무릎' }
];
