import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { getSettlements } from '#api/settlements';
import { IGetSettlements } from '#types/settlements';
import { yearMonthDayFormatter } from '#utils/dateFormatter';

interface Props {
  children?: React.ReactNode;
}

interface CoachSettlementsContext {
  getCurrentSettlements: ({
    startDate,
    endDate
  }: {
    startDate: Date;
    endDate: Date;
  }) => void;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  settlements: IGetSettlements[];
  setSettlements: React.Dispatch<React.SetStateAction<IGetSettlements[]>>;
  settlementDetail: IGetSettlements | Record<string, never>;
  setSettlementDetail: React.Dispatch<
    React.SetStateAction<IGetSettlements | Record<string, never>>
  >;
}

const CoachSettlementsContext =
  React.createContext<CoachSettlementsContext | null>(null);

export const useCoachSettlements = () => {
  const context = React.useContext(CoachSettlementsContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <CoachSettlements> component.'
    );
  }
  return context;
};

function CoachSettlements({ children }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [settlements, setSettlements] = useState<IGetSettlements[]>([]);
  const [settlementDetail, setSettlementDetail] = useState<
    IGetSettlements | Record<string, never>
  >({});

  const getCurrentSettlements = ({
    startDate,
    endDate
  }: {
    startDate: Date;
    endDate: Date;
  }) => {
    getSettlements({
      findStartDate: yearMonthDayFormatter(startDate),
      findEndDate: yearMonthDayFormatter(endDate),
      sort: 'desc'
    })
      .then(data => {
        setIsError(false);
        setErrorMessage('');
        setSettlements(data);
      })
      .catch((error: AxiosError) => {
        setIsLoading(false);
        setIsError(true);
        if (error.response?.status === 400) {
          setErrorMessage('조회된 정산 내역이 없습니다.');
        }
        setSettlements([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const memorizedValue = React.useMemo(
    () => ({
      getCurrentSettlements,
      isLoading,
      isError,
      errorMessage,
      settlements,
      setSettlements,
      settlementDetail,
      setSettlementDetail
    }),
    [isLoading, isError, errorMessage, settlements, settlementDetail]
  );

  return (
    <CoachSettlementsContext.Provider value={memorizedValue}>
      {children}
    </CoachSettlementsContext.Provider>
  );
}

function IsLoading({ children }: Props) {
  const { isLoading } = useCoachSettlements();
  return isLoading ? <>{children}</> : null;
}

function IsError({ children }: Props) {
  const { isLoading, isError, errorMessage } = useCoachSettlements();
  return !isLoading && isError && !errorMessage ? <>{children}</> : null;
}

function IsEmpty() {
  const { isLoading, isError, errorMessage } = useCoachSettlements();
  return !isLoading && isError && errorMessage ? <>{errorMessage}</> : null;
}

function HasData({ children }: Props) {
  const { isLoading, isError, settlements } = useCoachSettlements();
  return !isLoading && !isError && settlements.length > 0 ? (
    <>{children}</>
  ) : null;
}

function IsDeposit({ children }: Props) {
  const { settlementDetail } = useCoachSettlements();
  return settlementDetail.type === 'DEPOSIT' ? <>{children}</> : null;
}

function IsWithdrawal({ children }: Props) {
  const { settlementDetail } = useCoachSettlements();
  return settlementDetail.type === 'WITHDRAWAL' ? <>{children}</> : null;
}

CoachSettlements.IsError = IsError;
CoachSettlements.IsLoading = IsLoading;
CoachSettlements.IsEmpty = IsEmpty;
CoachSettlements.HasData = HasData;
CoachSettlements.IsDeposit = IsDeposit;
CoachSettlements.IsWithdrawal = IsWithdrawal;

export default CoachSettlements;
