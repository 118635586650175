import { useNavigate } from 'react-router';
import {
  patchSessionPackage,
  postCoachLecture,
  postCurricula,
  postLectureImgs
} from '#api/lectures';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { Curriculum, IPostCoachLecture, SessionType } from '#types/lectures';
import { createImgToFormData } from '#utils/createImgToFormData';
import { Image } from 'src/components/molecules/ImageWithUpload/hooks';

export interface SubmitLectureProps {
  form: IPostCoachLecture;
  curricula: Curriculum[];
  localImgs: Image[];
  multiCountPrice: SessionType[];
}

export const useSubmitCoachLecture = () => {
  const { showToast } = useToast();

  const navigate = useNavigate();
  const submitLecture = ({
    form,
    curricula,
    localImgs,
    multiCountPrice
  }: SubmitLectureProps) => {
    postCoachLecture(form)
      .then(response => {
        const lectureId = response.id;
        Promise.all([
          curricula && postCurricula({ lectureId, payload: curricula }),
          localImgs.length &&
            postLectureImgs({
              lectureId,
              payload: createImgToFormData(localImgs)
            }),
          multiCountPrice.length > 0 &&
            patchSessionPackage(multiCountPrice, lectureId)
        ]);
      })
      .then(() => {
        showToast({
          message: '수업 등록에 성공하였습니다.'
        });
        navigate(PATH.PROFILE_CLASS);
      })
      .catch(() => {
        showToast({
          type: 'fail',
          message: '수업 등록에 실패하였습니다.'
        });
      });
  };
  return submitLecture;
};
