import ChannelService from '#utils/channelService';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

interface ChannelDisplayProps {}

const ChannelDisplay = ({}: ChannelDisplayProps) => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (pathname === '/chat/room' && process.env.NODE_ENV === 'production') {
      ChannelService.hideChannelButton();
    } else {
      ChannelService.showChannelButton();
    }
  }, [location]);

  return <div></div>;
};

export default ChannelDisplay;
