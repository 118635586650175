import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100svh;
  padding-top: 2rem;
`;

export const LogoWrapper = styled.div`
  height: 100%;
`;

export const LogoArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 37%; // height 반응형처리

  img {
    margin-bottom: 3.3rem;
    height: 4.8rem;
  }
`;

interface LineHeightWrapper {
  lineHeight?: number;
}

export const LineHeightWrapper = styled.div<LineHeightWrapper>`
  line-height: ${({ lineHeight }) =>
    `${lineHeight}px`}; /* 단위를 포함해야 합니다 */
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  padding: 0.75rem 0 5rem;
  background-color: var(--color-background);
`;

export const OAuthButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const EmailAuthButtonArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.8rem;

  > div::after {
    display: block;
    position: relative;
    top: 0.45rem;
    height: 1.2rem;
    content: '';
    border-right: 0.05rem solid #fff;
  }

  & > p {
    border-bottom: 0.1rem solid #fff;
    height: 2.1rem;
  }

  /* & > p:first-of-type::after {
    content: '|';
    margin: 0 1.8rem;
    color: var(--color-border-light);
  } */
  /* 
  & > p {
    border-bottom: 1px solid #fff;
  } */
`;
