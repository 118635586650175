import React from 'react';
import { Text, FibudNumericInput } from '#atoms';
import { Container, InputContainer } from './styles';

interface Props {
  value?: number;
  onInput?: (str?: number) => void;
  label?: string;
  suffix?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  pureNumber?: boolean;
}

export function FibudLabeledNumericInput({
  label,
  value,
  suffix,
  placeholder,
  onInput,
  min,
  max,
  pureNumber
}: Props) {
  return (
    <Container>
      {label && <Text textStyle="c1">{label}</Text>}
      <InputContainer>
        <FibudNumericInput
          value={value}
          onInput={onInput}
          placeholder={placeholder}
          min={min}
          max={max}
          pureNumber={pureNumber}
        />
        {suffix && (
          <Text type="inline" textStyle="t3">
            {suffix}
          </Text>
        )}
      </InputContainer>
    </Container>
  );
}
