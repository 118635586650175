import { useQuery } from '@tanstack/react-query';
import { Association } from '#types/associations';
import { fetcher } from '../libs';
import queryKeys from '../queryKeys';

interface Params {
  /** 협회 id useGetAssociationQuery 로 id 받아옴 */
  associationUrl: string;
}

/**
 * @abstract /associations/{associationId}
 * @abstract 협회 정보 상세 조회
 * @link https://api-dev.fibud.kr/api#/%ED%8A%B8%EB%A0%88%EC%9D%B4%EB%8B%9D%20%ED%98%91%ED%9A%8C/AssociationsController_findAssociation
 * @param associationId - 협회 id
 * @returns
 */
export const useGetAssociationDetailWithUrlQuery = ({
  associationUrl
}: Params) => {
  return useQuery<Association>(
    [queryKeys.ASSOCIATION, associationUrl],
    async () => {
      const associationId = await fetcher(`/associations/associationId`, {
        params: { associationUrl }
      });
      return fetcher(`/associations/${associationId}`);
    },
    {
      enabled: !!associationUrl
    }
  );
};
