import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 9.3rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem 0 0;

    > p:first-child {
      color: var(--color-gray-text-second);
    }

    > p:nth-child(2) {
      text-align: center;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 1rem 1rem 3rem;
  background-color: var(--color-background);
`;
