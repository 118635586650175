import { useEffect, useState } from 'react';
import { useGetExerciseTypes } from '#api/exercise-types';

interface Props {
  keyword?: string;
}

export function useGetExerciseNames({ keyword }: Props) {
  const { data: exerciseTypes } = useGetExerciseTypes();
  const [filteredNames, setFilteredNames] = useState<string[]>([]);

  useEffect(() => {
    if (!exerciseTypes?.data) return;
    if (keyword) {
      const filtered = exerciseTypes.data
        .filter(item => item.name.includes(keyword))
        .map(item => item.name);
      setFilteredNames(filtered);
    } else {
      // 키워드가 없는 경우 모든 이름 반환
      const names = exerciseTypes.data.map(item => item.name);
      setFilteredNames(names);
    }
  }, [keyword, exerciseTypes]);

  return filteredNames;
}

// export function useFormatingFiles(localImgs: Image[]) {
//   useEffect(() => {}, []);
// }
