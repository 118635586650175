import request from '#api/axios';
import { ILicense } from '#types/account';

export const postLicenses = async (payload: ILicense) => {
  const res = await request({
    method: 'post',
    url: '/licenses',
    data: payload
  });

  return res.data;
};

export const deleteLicenses = async (id: string) => {
  const res = await request({
    method: 'delete',
    url: `/licenses/${id}`
  });

  return res.data;
};
