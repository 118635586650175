import { atom } from 'recoil';

interface IApplicationData {
  gymId: string;
  lectureId: string;
  myUrl: string;
  price: number;
}

const ATOM_KEY = 'applicationData';

export const $applicationData = atom<IApplicationData>({
  key: ATOM_KEY,
  default: { gymId: '', lectureId: '', myUrl: '', price: 0 }
});
