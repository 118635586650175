import styled from 'styled-components';

export const LineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const InputArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.5rem;
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
  padding: 3rem 0;
`;
