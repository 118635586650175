import { Lecture } from '#types/lectures';

// quantity에 따른 가격 계산
export const calculatePriceBySession = (
  currentLecture: Lecture,
  quantity: number,
  unitPrice: number
) => {
  const sessionPackage = currentLecture?.lectureSessionPackage || [];

  // sessionPackage가 비어있는 경우 unitPrice 반환
  if (sessionPackage.length === 0 || quantity < sessionPackage[0].session) {
    return unitPrice;
  }

  // 10회 이상일 때 세션 패키지에 맞는 가격 계산
  let left = 0;
  let right = sessionPackage.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const session = sessionPackage[mid];
    const sessionCount = session.session;

    if (quantity < sessionCount) {
      right = mid - 1;
    } else if (
      quantity >= sessionCount &&
      (mid === sessionPackage.length - 1 ||
        quantity < sessionPackage[mid + 1].session)
    ) {
      return session.price || unitPrice;
    } else {
      left = mid + 1;
    }
  }

  // 모든 세션을 초과한 경우 마지막 세션 패키지 가격 반환
  return sessionPackage[right].price || unitPrice;
};
