import { useEffect, useRef } from 'react';
import openDaumPostCode from '#api/address';
import X from '#assets/svg/x_header_black.svg';
import { ModalPortal } from '#atoms';
import { HeaderBar } from '../HeaderBar';
import { Background, AddressModalContainer } from './styles';

interface Props {
  onComplete: (data: { address: string }) => void;
  onCancel: () => void;
}

export const AddressModal = ({ onComplete, onCancel }: Props) => {
  const modal = useRef<HTMLDivElement>(null);

  const loadDaumPostcodeScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src =
        'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadDaumPostcodeScript()
      .then(() => {
        openDaumPostCode(onComplete, modal.current);
      })
      .catch(error => {
        console.error('주소 검색 API 스크립트 로딩 중 오류 발생:', error);
      });
  }, []);

  return (
    <ModalPortal>
      <Background>
        <HeaderBar
          leftButtonImgSrc={X}
          onLeftButtonClick={onCancel}
          transparentBackground
        />
        <AddressModalContainer ref={modal} />
      </Background>
    </ModalPortal>
  );
};
