import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { patchMyUrl } from '#api/my-url';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { BorderlessInput } from '#molecules';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarTemplate } from '#templates';
import { isAxiosErrorWithResponse } from '#utils/typeguard';
import { useMyUrl } from './hooks';
import { ButtonContainer, InputContainer, TitleContainer } from './styles';

export function MyPageMyUrl() {
  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useUserInfo();
  const { myUrl, updateMyUrl } = useMyUrl({
    currentMyUrl: userInfo?.myUrl ?? ''
  });
  const { mutateAsync } = useMutation(patchMyUrl);
  const { showToast } = useToast();

  useEffect(() => {
    // 페이지 새로고침 시 userInfo를 불러오면 myUrl을 화면에 update
    userInfo && userInfo.myUrl && updateMyUrl(userInfo.myUrl);
  }, [userInfo]);

  const saveMyUrl = () => {
    if (!userInfo) return;
    mutateAsync(myUrl.value, {
      onSuccess: () => {
        setUserInfo({ ...userInfo, myUrl: myUrl.value }); // refetch없이 바로 userInfo에 업데이트
        navigate(PATH.MYPAGE);
      },
      onError: (error: unknown) => {
        if (isAxiosErrorWithResponse(error)) {
          showToast({
            type: 'fail',
            message: error.response?.data.message
          });
        } else {
          showToast({ type: 'fail', message: 'MyUrl 수정에 실패하였습니다.' });
        }
      }
    });
  };

  return (
    <PrevHeaderBarTemplate prevRoute={PATH.MYPAGE}>
      <TitleContainer>
        <Text textStyle="t1">
          등록할 MyURL을
          <br />
          입력해주세요
        </Text>
        <Text textStyle="c1">전문가의 링크를 쉽게 공유할 수 있습니다.</Text>
      </TitleContainer>
      <InputContainer>
        <Text>https://fibud.kr/expert/</Text>
        <BorderlessInput
          type="text"
          placeholder="영문, 숫자, -._~/로만 입력해주세요."
          value={myUrl.value}
          onChange={e => updateMyUrl(e.target.value)}
        />
        {!myUrl.isValid && (
          <Text textColor="red" textStyle="c2">
            url 형식에 맞는 문자열을 입력해주세요
          </Text>
        )}
      </InputContainer>
      <ButtonContainer>
        <Button
          onClick={saveMyUrl}
          disabled={!myUrl.isValid || userInfo?.myUrl === myUrl.value}
        >
          저장하기
        </Button>
      </ButtonContainer>
    </PrevHeaderBarTemplate>
  );
}
