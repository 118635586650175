import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2rem 2rem 9.3rem;
  row-gap: 4rem;
  justify-content: center;
  background: var(--color-point-dark-gradient);
`;

export const MessageContainer = styled.div`
  display: grid;
  grid-template-rows: 2.2fr 1fr 1fr;
  justify-content: center;
  row-gap: 0.75rem;
  > span {
    justify-self: center;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  max-width: var(--max-width);
  padding: 1rem 1rem 3rem;
`;

export const SettlementInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  > div > p:first-child {
    color: var(--color-gray-text-second);
  }
`;
