import React, { useState, useEffect } from 'react';
import { IconType } from 'react-icons';

interface Props {
  iconName: string;
}

export const ReactIcon = ({ iconName }: Props) => {
  const [IconComponent, setIconComponent] = useState<IconType | null>(null);

  useEffect(() => {
    const importIcon = async () => {
      try {
        const iconModule = await import(`react-icons/bi`);
        const Icon =
          iconModule[
            (iconName || 'BiSolidImageAlt') as keyof typeof iconModule
          ];
        setIconComponent((Icon as unknown as IconType) || null);
      } catch (error) {
        error instanceof Error &&
          console.error(`Error importing icon: ${error.message}`);
      }
    };

    importIcon();
  }, [iconName]);

  return <>{IconComponent}</>;
};
