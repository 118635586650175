import { postPaymentsFree } from '#api/payments';
import requestPay from '#api/payments/requestPay';
import { useToast } from '#providers/ToastProvider';
import { $freeLectureGymId } from '#states/atoms/freeLectureGymId';
import { IPaymentsResponse, IRequestPay } from '#types/payments';
import sessionStorageManager from '#utils/sessionStorageManager';
import { useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSetRecoilState } from 'recoil';

interface Props {
  data: IRequestPay;
}

function usePayment({ data }: Props) {
  const { showToast } = useToast();
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [isConfirmationFormRequired, setIsConfirmationFormRequired] =
    useState(false);
  const setFreeLectureGymId = useSetRecoilState($freeLectureGymId);

  const { setConfirmationFormId } = sessionStorageManager();

  const loadPortOneScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://cdn.iamport.kr/v1/iamport.js';
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const initializePayment = (merchant_uid: string) => {
    if (data.amount === 0) {
      postPaymentsFree(data.gymId, data.lectureId)
        .then(res => {
          setFreeLectureGymId(data.gymId);
          const tagManagerArgs = {
            dataLayer: {
              event: 'click_purchase',
              transaction_id: 'free',
              coach_id: data.coachId,
              purchase_price: data.amount * data.count
            }
          };
          TagManager.dataLayer(tagManagerArgs);
          setConfirmationFormId(res.confirmationFormId);
          setPaymentComplete(true);
          setIsConfirmationFormRequired(res.isConfirmationFormRequired);
        })
        .catch(err =>
          showToast({
            type: 'fail',
            message: err.response.data.message
          })
        );
      return;
    }

    loadPortOneScript()
      .then(() => {
        if (data.lectureId) {
          requestPay({
            data,
            merchant_uid,
            onSuccess: (res: IPaymentsResponse) => {
              setPaymentComplete(true);
              setIsConfirmationFormRequired(res.isConfirmationFormRequired);
            }
          });
        }
      })
      .catch(error => {
        console.error('포트원 API 스크립트 로딩 중 오류 발생:', error);
      });
  };

  return {
    paymentComplete,
    setPaymentComplete,
    initializePayment,
    isConfirmationFormRequired
  };
}

export default usePayment;
