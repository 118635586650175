import { useMutation } from '@tanstack/react-query';
import { postSelectedLectures } from '#api/registered-lectures';
import { SelectedLecture } from '#types/lectures';

export default function usePostLectures() {
  return useMutation({
    mutationFn: async (selectedLectures: SelectedLecture[]) => {
      return postSelectedLectures(selectedLectures);
    }
  });
}
