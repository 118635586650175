import styled from 'styled-components';

export const EmptyContentWrap = styled.div`
  padding: 1.65rem;
  background-color: var(--color-gray-third);
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 7.7rem;
  padding: 1rem;
  border-radius: 1.25rem;
`;
