import React from 'react';
import { useRecoilState } from 'recoil';
import { $signupState } from '#states/atoms/recoilSignupState';
import {
  SignUpCoachFinal as SignUpCoachFinalTemplate,
  SignUpFinal as SignUpFinalTemplate
} from '#templates';

export function SignUpFinal() {
  const [signupState] = useRecoilState($signupState);

  return signupState.isCoach ? (
    <SignUpCoachFinalTemplate />
  ) : (
    <SignUpFinalTemplate />
  );
}
