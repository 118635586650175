import { PropsWithChildren, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { BoxIcon, Text } from '#atoms';
import { ChildrenContainer, Container, HeaderConatiner } from './styles';

interface Props {
  prevRoute?: string;
  title: string;
  hide?: boolean;
  headerbarType?: 'absolute' | 'relative';
  defaultBackground?: boolean;
  willBeScroll?: boolean;
  onPrevCallback?: () => void;
}

export function PrevHeaderBarWithTitleTemplate({
  prevRoute,
  title,
  hide,
  headerbarType = 'absolute',
  defaultBackground,
  onPrevCallback,
  children
}: PropsWithChildren<Props>) {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickArrow = useCallback(() => {
    // type 문제로 인한 nullish 처리가 불가
    if (prevRoute) return navigate(prevRoute);
    // cd one level 과 비슷하게 동작함.

    if (location.state) {
      navigate(location.state);
    } else return navigate('../');

    onPrevCallback && onPrevCallback();
  }, [prevRoute]);

  return (
    <Container>
      <HeaderConatiner
        headerbarType={headerbarType}
        defaultBackground={defaultBackground}
      >
        <BoxIcon
          hide={hide}
          type="prev-arrow"
          boxColor="transparent"
          size={18}
          onlyIcon={true}
          onClick={onClickArrow}
        />
        <Text textStyle="t3">{title}</Text>
      </HeaderConatiner>
      <ChildrenContainer headerbarType={headerbarType}>
        {children}
      </ChildrenContainer>
    </Container>
  );
}
