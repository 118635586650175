import _wni, { WNInterface as _WNInterface } from './lib/WNInterface';

export class WNInterface extends _WNInterface {
  isReady: boolean | undefined;
  appVersion: string | undefined;
}

export function useWNInterface(): _WNInterface {
  return window.WNInterface;
}
