import { useEffect, useState } from 'react';
import { Input, Text } from '#atoms';
import { Container, InputArea, MessageArea, PasswordInputArea } from './styles';

interface Props {
  value?: string | null;
  setUserPassword: (password: string | null) => void;
}

const passwordValidation = (password: string) =>
  /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/.test(password);

export const SignUpPasswordInput = ({ value, setUserPassword }: Props) => {
  const [password, setPassword] = useState<string | null>(value ?? null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(
    value ?? null
  );
  const [passwordMatchStatus, setPasswordMatchStatus] = useState(
    value ? true : false
  );
  const [isValidPassword, setIsValidPassword] = useState(value ? true : false);

  useEffect(() => {
    if (password && password === confirmPassword) {
      setPasswordMatchStatus(true);

      if (passwordValidation(password)) {
        setIsValidPassword(true);
        setUserPassword(password);
      } else {
        setIsValidPassword(false);
        setUserPassword(null);
      }
    } else {
      setPasswordMatchStatus(false);
      setUserPassword(null);
    }
  }, [password, confirmPassword]);

  return (
    <Container>
      <PasswordInputArea>
        <InputArea>
          <Input
            type="password"
            placeholder="영문, 숫자를 포함한 8자리 이상의 비밀번호"
            labelText="비밀번호"
            value={password}
            onChange={password => setPassword(String(password))}
            showPlainTextButton={true}
          />
          <Input
            type="password"
            placeholder="비밀번호를 다시 입력해주세요"
            labelText="비밀번호 확인"
            value={confirmPassword}
            onChange={confirm => setConfirmPassword(String(confirm))}
            showPlainTextButton={true}
          />
        </InputArea>
        <MessageArea>
          <Text>
            {password && password?.length >= 8
              ? passwordMatchStatus
                ? isValidPassword
                  ? '비밀번호가 일치합니다'
                  : '비밀번호는 숫자와 영문을 포함해야 합니다'
                : '비밀번호가 일치하지 않습니다'
              : '비밀번호는 8자리 이상이어야 합니다'}
          </Text>
        </MessageArea>
      </PasswordInputArea>
    </Container>
  );
};
