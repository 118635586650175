import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  & > hr {
    border: 4px solid var(--color-border);
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 3rem;
`;

export const CoachInformationContainer = styled.ul`
  width: 100%;
`;

export const CoachInformationItem = styled.li`
  display: flex;
  width: 100%;
  padding: 2rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
  }

  & > img {
    width: 6.8rem;
    height: 6.8rem;
    margin-right: 1rem;
    border-radius: 0.8rem;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  &.gym {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const GymLocationButton = styled.span`
  padding: 0.3rem 0.5rem;
  width: 6.8rem;
  height: 2.4rem;
  background: var(--color-point);
  border-radius: 1.25rem;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AltImageBox = styled.div`
  width: 6.8rem;
  height: 6.8rem;
  margin-right: 1rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-second);
`;

export const UserInformationConatiner = styled.ul`
  width: 100%;
`;

export const UserInformationItem = styled.li`
  width: 100%;
  padding: 2rem 0;
  border-bottom: 1px solid var(--color-border);

  & > p:last-child {
    margin-top: 0.5rem;
  }

  &:last-child {
    margin-bottom: 2rem;
    border-bottom: none;
  }
`;

export const FragmentContainer = styled.ul`
  width: 100%;
  padding: 3rem 2rem;

  &.class-info {
    padding-bottom: 0;
  }
`;

export const PaymentItem = styled.li`
  width: 100%;
  padding: 1rem 0;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    gap: 1rem;
  }

  &:last-child {
    border-top: 1px solid var(--color-border);
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 1.2rem 2rem 3rem;
`;
