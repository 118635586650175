import { useMutation } from '@tanstack/react-query';
import React from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { postSignin } from '#api/auth/signin';
import { postSignup } from '#api/auth/signup';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useCheckValidState } from '#hooks/useCheckValidState';
import { DateSelector } from '#molecules';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { $signupState } from '#states/atoms/recoilSignupState';
import { PrevWithTitleTextTemplate } from '#templates';
import { ButtonContainer } from './styles';

export function SignUpBirthday() {
  const { showToast } = useToast();
  const { createSession } = useUserInfo();

  const [signupState, setSignupState] = useRecoilState($signupState);
  const navigate = useNavigate();

  useCheckValidState({
    targetState: typeof signupState.isCoach === 'boolean',
    navigateTo: PATH.SIGN_UP_CATEGORY
  });

  const handleBirthdayChange = (birthday: string | null) => {
    setSignupState(prev => ({ ...prev, birthday }));
  };

  const { mutateAsync: signUpMutation, isLoading: isSignUpLoading } =
    useMutation(postSignup);
  const { mutateAsync: signinMutation } = useMutation(postSignin);

  const handleButtonClick = () => {
    if (
      signupState.name &&
      signupState.nickname &&
      signupState.phone &&
      signupState.email &&
      signupState.password &&
      signupState.agreeNotification !== null &&
      signupState.sex &&
      signupState.birthday &&
      signupState.isCoach !== null
    ) {
      signUpMutation(
        {
          name: signupState.name,
          nickname: signupState.nickname,
          phone: signupState.phone,
          email: signupState.email,
          password: signupState.password,
          agreeNotification: signupState.agreeNotification,
          sex: signupState.sex,
          birthday: signupState.birthday,
          isCoach: signupState.isCoach,
          isAdmin: false
        },
        {
          onSuccess: res => {
            const tagManagerArgs = {
              dataLayer: {
                event: 'signup',
                method: 'LOCAL',
                type: signupState.isCoach ? '전문가' : '고객',
                terms_marketing: signupState.agreeNotification,
                user_phone: signupState.phone,
                user_email: signupState.email,
                user_name: signupState.name,
                user_nickname: signupState.nickname,
                user_gender: signupState.sex,
                user_birthdate: signupState.birthday,
                user_id: res
              }
            };

            TagManager.dataLayer(tagManagerArgs);
            signupState.email &&
              signupState.password &&
              signinMutation(
                { email: signupState.email, password: signupState.password },
                {
                  onSuccess: ({ accessToken, refreshToken }) => {
                    createSession(accessToken, refreshToken);
                    navigate(PATH.SIGN_UP_FINAL);
                  }
                }
              );
          }
        }
      );
    } else
      showToast({
        type: 'fail',
        message: '회원가입에 실패하였습니다.'
      });
  };

  return (
    <PrevWithTitleTextTemplate
      prevRoute={PATH.SIGN_UP_GENDER}
      title={
        <Text textStyle="display">
          회원님의 생년월일을
          <br />
          선택해주세요
        </Text>
      }
    >
      <DateSelector
        value={signupState.birthday}
        onDateChange={handleBirthdayChange}
      />
      <ButtonContainer>
        <Button
          disabled={signupState.birthday === null}
          onClick={handleButtonClick}
          isLoading={isSignUpLoading}
        >
          완료
        </Button>
      </ButtonContainer>
    </PrevWithTitleTextTemplate>
  );
}
