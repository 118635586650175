import React from 'react';
import { Text } from '#atoms';
import { Lecture } from '#types/lectures';
import { Container, Header, Body } from './styles';

interface Props {
  data: Lecture;
}

export const InfoBox = ({ data }: Props) => {
  return (
    <Container>
      <Header isOT={data.isOt}>
        <Text textStyle="t2">{data.title}</Text>
        <div>
          <Text textStyle="t3">{data.rounds}회</Text>
          <Text textStyle="t2">
            {(data.price * data.rounds).toLocaleString('ko-KR')}
          </Text>
          <Text textStyle="t3"> 원</Text>
        </div>
      </Header>
      <Body>
        <Text textStyle="c1" textColor="dark">
          {data.body}
        </Text>
      </Body>
    </Container>
  );
};
