import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { IGetIExerciseTypes } from '#types/exerciseTypes';

export const getExerciseTypes = async () => {
  const res = await request<IGetIExerciseTypes>({
    method: 'get',
    url: '/exercise-types',
    params: {
      page: 0,
      offset: 300 // TODO: 무한스크롤 적용
    }
  });

  return res.data;
};

export const useGetExerciseTypes = () => {
  return useQuery(queryKeyFactory.GET_EXERCISE_TYPES(), getExerciseTypes);
};
