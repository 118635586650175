import React, { useRef } from 'react';
import { Text } from '#atoms';
import { TextareaWrap, TextareaArea, TextareaTag } from './styles';

interface Props {
  value: string;
  onChange: (changeValue: string) => void;
  height: number;
  limit: number;
  placeholder?: string;
}

/**
 * @param limit 글자수 제한
 * @param height textarea의 높이 (단위: rem)
 */
export const Textarea = ({
  height,
  limit,
  value,
  onChange,
  placeholder
}: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (limit && event.target.value.length > limit) {
      event.target.value = event.target.value.slice(0, limit);
    }
    onChange(event.target.value);
  };

  return (
    <TextareaWrap>
      <TextareaArea height={height}>
        <TextareaTag
          ref={textareaRef}
          value={value}
          onChange={handleChange}
          maxLength={limit}
          placeholder={placeholder}
        />
      </TextareaArea>
      <div className="flex-end">
        <Text textStyle="c1" textColor="gray2">
          {value.length} / {limit}
        </Text>
      </div>
    </TextareaWrap>
  );
};
