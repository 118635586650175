import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { postPasswordRequest } from '#api/auth/signin';
import { Button, Input, Text } from '#atoms';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { PrevWithTitleTextTemplate } from '#templates';
import { ButtonContainer, EmailInputArea } from './styles';

const redirectUrl = process.env.REACT_APP_URL + PATH.SIGNIN_PASSWORD_RESET;

const isValidEmail = (email: string) =>
  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);

export function SignInPasswordRequest() {
  const { showToast } = useToast();

  const [email, setEmail] = useState<string>('');
  const [isValidEmailType, setIsValidEmailType] = useState(false);
  const [message, setMessage] = useState('');

  const { mutateAsync: passwordRequestMutation } =
    useMutation(postPasswordRequest);

  useEffect(() => {
    if (isValidEmail(email)) {
      setIsValidEmailType(true);
      setMessage('올바른 형식의 이메일입니다');
    } else {
      setIsValidEmailType(false);
      setMessage('이메일 형식이 올바르지 않습니다');
    }
  }, [email]);

  const handleButtonClick = () => {
    redirectUrl &&
      email &&
      passwordRequestMutation(
        { redirectUrl, email },
        {
          onSuccess: () => {
            showToast({
              message: '비밀번호 재설정 링크를 전송하였습니다.'
            });
          },
          onError: () =>
            showToast({
              type: 'fail',
              message: '비밀번호 재설정 요청에 실패하였습니다.'
            })
        }
      );
  };

  return (
    <PrevWithTitleTextTemplate
      prevRoute={PATH.SIGNIN}
      title={
        <>
          <Text textStyle="display">비밀번호 찾기</Text>
          <Text textColor="gray2">비밀번호 재설정 링크를 보내드릴게요</Text>
        </>
      }
    >
      <EmailInputArea>
        <Input
          type="text"
          placeholder="가입한 이메일 주소를 입력해주세요"
          labelText="이메일"
          value={email}
          onChange={v => setEmail(String(v))}
        />
        <Text textColor="gray2">{message}</Text>
      </EmailInputArea>
      <ButtonContainer>
        <Button disabled={!isValidEmailType} onClick={handleButtonClick}>
          비밀번호 재설정 링크 보내기
        </Button>
      </ButtonContainer>
    </PrevWithTitleTextTemplate>
  );
}
