import Cookies from 'js-cookie';

class TokenController {
  private static ACCESS_TOKEN_KEY =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? 'access_token'
      : 'dev_access_token';
  private static REFRESH_TOKEN_KEY = 'refresh_token';
  private static TOKEN_TIME_KEY = 'token_time';

  static get accessToken() {
    return Cookies.get(this.ACCESS_TOKEN_KEY);
  }

  static get refreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

  static get tokenTime() {
    return localStorage.getItem(this.TOKEN_TIME_KEY);
  }

  static setAccessToken(accessToken: string, expires?: number) {
    Cookies.set(this.ACCESS_TOKEN_KEY, accessToken, {
      domain: process.env.REACT_APP_NODE_ENV !== 'local' ? '.fibud.kr' : '',
      expires: expires ?? 7
    });
  }

  static setRefreshToken(refreshToken: string) {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
  }

  static setTokenTime() {
    const currentPlusHalfHour = new Date()
      .setMinutes(new Date().getMinutes() + 30)
      .toString();

    localStorage.setItem(this.TOKEN_TIME_KEY, currentPlusHalfHour);
  }

  static isTokenExpired() {
    return !this.tokenTime || this.tokenTime < new Date().getTime().toString();
  }

  static clear() {
    Cookies.remove(this.ACCESS_TOKEN_KEY, {
      domain: process.env.REACT_APP_NODE_ENV !== 'local' ? '.fibud.kr' : ''
    });
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
    localStorage.removeItem(this.TOKEN_TIME_KEY);
  }
}

export default TokenController;
