import { atom, atomFamily } from 'recoil';

const ATOM_KEY = 'modalState';

const FAMILY_KEY = 'modalFamily';

export const MODAL_TYPE = {
  APPLY: 'apply',
  LOGIN: 'login',
  QR: 'qr',
  RATING: 'rating',
  FINAL_RATING: 'finalrating',
  CONFIRMATION_FORM: 'confirmationform'
};

export const $modalState = atom({
  key: ATOM_KEY,
  default: false
});

export const $modalFamily = atomFamily({
  key: FAMILY_KEY,
  default: false
});
