import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
`;

export const PhoneAuthArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const RequestArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    height: 1rem;
    text-align: right;
    color: var(--color-white);
  }
`;

export const InputArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.25rem;

  div {
    flex-basis: 60%;
  }

  button {
    flex-basis: 40%;
    height: 3.25rem;
  }
`;
