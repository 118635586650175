import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Item = styled.div<{ selected: boolean }>`
  color: ${props =>
    props.selected ? 'var(--color-point-fourth)' : 'var(--color-gray-text)'};
`;
