import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-black-alpha75);
  z-index: 999;
  max-width: var(--max-width);
  margin: 0 auto;
`;

export const ClassEvaluationModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 30rem;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  background-color: var(--color-white);
  min-height: 36.8rem;
  border-radius: 1.25rem;

  & > button {
    margin-top: auto;
  }
`;

export const EvaluationBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  padding: 1.5rem 0 1rem;

  & > div {
    display: flex;
    justify-content: space-between;
    color: var(--color-gray-second);
  }
`;

export const FinalRateBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
`;
