import styled from 'styled-components';

export const ItemButton = styled.button`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 1rem;
  width: 100%;
  border-bottom: 1px solid var(--color-gray-text);
`;

export const InformationContainer = styled.div`
  width: 100%;
  padding: 0 2rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const HR = styled.hr`
  border: 0.5rem solid var(--color-gray-third);
`;

export const UpdateItem = styled.div`
  display: flex;
  width: 100%;
  height: 12rem;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
  }
`;

// ProfileImgUploader
export const ProfileViewerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const ImageList = styled.ul`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
`;

export const ImageItem = styled.li`
  flex-shrink: 0;
  scroll-snap-align: start;
  width: 100%;
  aspect-ratio: 1;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  > span {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
`;

export const ImageLabel = styled.label`
  flex-shrink: 0;
  scroll-snap-align: start;
  width: 100%;
  aspect-ratio: 1;
  background-color: var(--color-gray-second);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const ImageInput = styled.input`
  display: none;
`;

export const IndexIconArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.25rem;
`;

export const IndexIcon = styled.div`
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background-color: var(--color-gray-second);

  &.point {
    width: 1rem;
    border-radius: 0.5rem;
    background-color: var(--color-white);
  }
`;
