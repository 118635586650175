import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 3rem 2rem 0;
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;

  > :last-child {
    border: 1px solid var(--color-white);
    border-radius: 1.4rem;
    padding: 0.4rem 1.8rem;
    align-self: center;
  }
`;

export const DetailArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 0.6rem;

  > p {
    padding: 1.5rem 0;
    text-align: center;

    :first-child {
      border-right: 1px solid var(--color-white);
    }
  }
`;
