import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const HeaderConatiner = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  padding: 2rem 2rem 0;
  justify-content: space-between;
  z-index: 1;
`;

export const ChildrenContainer = styled.article`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
