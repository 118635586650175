import { Text } from '#atoms';
import { MessageInputBox } from '#molecules';
import classNames from 'classnames';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
// import { ChatFloatingButton, Floating } from '#organisims';
import { useChat } from '#providers/Chat';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { dateDayKoFormatter } from '#utils/dateFormatter';
import { dayPeriodTimeFormatter } from '#utils/timeFormatter';
import {
  ChatInfo,
  ChatInputContainer,
  ChatListContainer,
  Container,
  DateBox,
  MessageBox,
  TemplateContainer
} from './styles';
import { useIntersectionObserver } from '#hooks/useIntersectionObserver';

const isNewDate = (prev: string, cur: string) => {
  const prevDate = new Date(prev);
  const curDate = new Date(cur);

  return prevDate.getDate() !== curDate.getDate();
};

export function ChatRoom() {
  const { userInfo } = useUserInfo();
  const { setTarget, isIntersected } = useIntersectionObserver({});
  const { curRoom, currentRoomChat, sendChat, exitCurrentRoom, loadMore } =
    useChat();

  const chatContainerRef = useRef<HTMLDivElement>(null);
  const endOfContentRef = useRef<HTMLDivElement>(null);

  const [mounted, setMounted] = useState(false);
  const [prevHeight, setPrevHeight] = useState(0);

  // 개같은거 리팩해야함
  useEffect(() => {
    if (isIntersected) {
      setPrevHeight(chatContainerRef?.current?.scrollHeight || 0);
      loadMore(currentRoomChat[0]?._id);
      return;
    }
  }, [isIntersected]);

  useLayoutEffect(() => {
    if (prevHeight > 0 && chatContainerRef?.current && currentRoomChat.length) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight - prevHeight;
    }
  }, [prevHeight, currentRoomChat.length]);

  useLayoutEffect(() => {
    if (!isIntersected) {
      endOfContentRef.current?.scrollIntoView();
    }
  }, [currentRoomChat.length]);

  useEffect(() => {
    if (chatContainerRef.current && !mounted && currentRoomChat.length) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      setMounted(true);
    }
  }, [mounted, currentRoomChat.length]);

  const opponent = curRoom?.users.find(u => u._id !== userInfo?.id);
  const opponentName = `${opponent?.name ?? ''} ${
    opponent ? (opponent.isCoach ? '전문가' : '회원님') : ''
  }`;

  return (
    <TemplateContainer>
      <PrevHeaderBarWithTitleTemplate
        title={opponentName}
        // prevRoute={PATH.CHAT} // default: -1
        headerbarType="relative"
        onPrevCallback={exitCurrentRoom}
      >
        <Container>
          <ChatListContainer ref={chatContainerRef}>
            <div ref={currentRoomChat.length ? setTarget : null} />
            {opponent?.isCoach && (
              <ChatInfo>
                <Text textStyle="c1">
                  {opponent?.name} 전문가님에게 궁금하신 것들을 무엇이든
                  물어보세요.
                  <br /> 답변이 도착하면, 알림톡으로 알려드립니다.
                </Text>
              </ChatInfo>
            )}
            {currentRoomChat.map(
              ({ _id, message, sender, createdAt, isRead }, idx) => (
                <>
                  {(idx === 0 ||
                    isNewDate(
                      currentRoomChat[idx - 1].createdAt,
                      createdAt
                    )) && (
                    <DateBox>
                      <Text textStyle="c1">
                        {dateDayKoFormatter(createdAt)}
                      </Text>
                    </DateBox>
                  )}
                  <MessageBox
                    key={_id}
                    className={classNames({ mine: sender === userInfo?.id })}
                  >
                    {sender === userInfo?.id ? (
                      <>
                        <div>
                          <Text textStyle="c2">{isRead ? '' : '1'}</Text>
                          <Text textColor="gray2" textStyle="c2">
                            {dayPeriodTimeFormatter(createdAt)}
                          </Text>
                        </div>
                        <Text>{message}</Text>
                      </>
                    ) : (
                      <>
                        <Text>{message}</Text>
                        <div>
                          {/* <Text textStyle="c2">{isRead ? '' : '1'}</Text> */}
                          <Text textColor="gray2" textStyle="c2">
                            {dayPeriodTimeFormatter(createdAt)}
                          </Text>
                        </div>
                      </>
                    )}
                  </MessageBox>
                </>
              )
            )}
            {/* <Floating>
              <ChatFloatingButton />
            </Floating> */}
            <div ref={endOfContentRef} />
          </ChatListContainer>
          <ChatInputContainer>
            <MessageInputBox onSubmit={message => sendChat(message)} />
          </ChatInputContainer>
        </Container>
      </PrevHeaderBarWithTitleTemplate>
    </TemplateContainer>
  );
}
