import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 6.3rem);
  padding: 2rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > :first-child {
    padding-bottom: 1rem;
  }
`;

export const DegreeList = styled.ul`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const DegreeItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AppendButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 6rem;

  & > span {
    display: inline-block;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  padding: 1rem 2rem 3rem;
  background-color: var(--color-background);
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px; // 고정값으로 진행
`;
