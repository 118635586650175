import React, { useState } from 'react';
import { X } from '#assets/svg';
import { BoxIcon, Text } from '#atoms';
import {
  InputWrap,
  InputArea,
  InputTagArea,
  InputTag,
  DateInput
} from './styles';

interface Props {
  type: string;
  placeholder?: string;
  value?: number | string | null;
  onChange?: (v: number | string, type?: string) => void;
  onClick?: (e: React.MouseEvent) => void;
  labelText?: string;
  sideLabelText?: string;
  showDeleteButton?: true;
  showPlainTextButton?: true;
  maxLength?: number;
  min?: string;
  max?: string;
  checked?: boolean;
}

export const Input = ({
  type,
  placeholder,
  labelText,
  sideLabelText,
  onChange,
  onClick,
  value,
  showDeleteButton,
  showPlainTextButton,
  maxLength,
  min,
  max,
  checked,
  ...props
}: Props) => {
  const [isPlainTextMode, setIsPlainTextMode] = useState(false);

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    // TODO: 타입에러로 예외처리 추가
    if (typeof onChange === 'undefined') return;
    if (maxLength && target.value.length > maxLength) {
      target.value = target.value.slice(0, maxLength);
    }
    onChange(type === 'number' ? Number(target.value) : target.value);
  };

  const handleErase = () => {
    // TODO: 타입에러로 예외처리 추가
    if (typeof onChange === 'undefined') return;
    onChange('');
  };
  const handleShowPlainText = () => setIsPlainTextMode(!isPlainTextMode);

  return (
    <InputWrap>
      <InputArea>
        {labelText && <label htmlFor={labelText}>{labelText}</label>}
        <InputTagArea>
          {type === 'date' ? (
            <>
              <DateInput
                required
                onChange={handleInput}
                onClick={onClick}
                className={checked ? 'checked' : ''}
                min={min}
                max={max}
                value={value || ''}
                {...props}
              />
              <BoxIcon type="down-arrow-gray" onlyIcon size={15} />
            </>
          ) : (
            <InputTag
              id={labelText}
              type={isPlainTextMode ? 'text' : type}
              placeholder={placeholder}
              onInput={handleInput}
              value={value || ''}
              maxLength={maxLength}
              {...props}
            />
          )}
          {sideLabelText && <Text textStyle="t3">{sideLabelText}</Text>}
          {showDeleteButton && value && (
            <X className="icon" width={10} height={10} onClick={handleErase} />
          )}
          {showPlainTextButton && (
            <BoxIcon
              type="eye"
              size={24}
              onlyIcon={true}
              onClick={handleShowPlainText}
            />
          )}
        </InputTagArea>
      </InputArea>
    </InputWrap>
  );
};
