import React from 'react';
import { useNavigate } from 'react-router';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { SignUpMemberTypeSelector } from '#organisims';
import { $signupState } from '#states/atoms/recoilSignupState';
import { PrevWithTitleTextTemplate } from '#templates';
import { ButtonContainer } from './styles';

export function SignUpCategory() {
  const [signupState, setSignupState] = useRecoilState($signupState);
  const navigate = useNavigate();

  const handleTypeChange = (isCoach: boolean) =>
    setSignupState(prev => ({ ...prev, isCoach }));

  const resetSignupState = useRecoilCallback(({ reset }) => () => {
    reset($signupState);
  });

  return (
    <PrevWithTitleTextTemplate
      prevRoute={PATH.HOME}
      onPrevClick={resetSignupState}
      title={
        <Text textStyle="display">
          회원유형을
          <br />
          선택해주세요
        </Text>
      }
    >
      <SignUpMemberTypeSelector
        isCoach={signupState.isCoach}
        onChange={handleTypeChange}
      />
      <ButtonContainer>
        <Button
          disabled={signupState.isCoach === null}
          onClick={() => navigate(PATH.SIGN_UP_POLICY)}
        >
          다음
        </Button>
      </ButtonContainer>
    </PrevWithTitleTextTemplate>
  );
}
