import styled from 'styled-components';
import { Container100vh } from '#styles/commonStyles';

export const Container = styled(Container100vh)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5.8rem;
  padding: 0 1.25rem;
`;

export const TitleContainer = styled.div`
  width: 100%;
`;

export const ChildrenContainer = styled.article`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
