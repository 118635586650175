import { useNavigate } from 'react-router';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import UserInfo, { useUserInfo } from '#providers/UserInfo';
import { CONSUMER_MENUS, TRAINER_MENUS } from './const';
import { Container, MenuContainer, MenuItem } from './styles';

/**
 * @description
 * 해당 버전의 디자인에서는 menu의 4번째 버튼, 즉 index 3의 요소 위 아래에
 * 회색 선이 그어져있습니다.
 * 이를 구현하기 위해 index가 3일 경우, 선이 나타나도록 하였습니다.
 */

export const MyPageButtonList = () => {
  const { userInfo, logout } = useUserInfo();
  const navigate = useNavigate();

  const handleSignoutButtonClick = () => {
    if (userInfo?.lType === 'KAKAO') {
      window.location.href = `https://kauth.kakao.com/oauth/logout?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&logout_redirect_uri=${process.env.REACT_APP_URL}/auth/leave`;
    } else {
      logout();
      navigate(PATH.HOME);
    }
  };

  return (
    <Container>
      <MenuContainer>
        <UserInfo.IsConsumer>
          {CONSUMER_MENUS.map((menu, idx) => {
            if (menu === 'hr')
              return (
                <MenuItem key={idx}>
                  <hr />
                </MenuItem>
              );
            else if (typeof menu === 'string')
              return (
                <MenuItem key={idx}>
                  <Text textStyle="bb">{menu}</Text>
                </MenuItem>
              );
            return (
              <MenuItem key={idx} onClick={() => navigate(menu.path)}>
                <BoxIcon type={menu.type} onlyIcon={true} size={18} />
                <Text textStyle="bb">{menu.desc}</Text>
              </MenuItem>
            );
          })}
        </UserInfo.IsConsumer>
        <UserInfo.IsCoach>
          {TRAINER_MENUS.map((menu, idx) => {
            if (menu === 'hr')
              return (
                <MenuItem key={idx}>
                  <hr />
                </MenuItem>
              );
            else if (typeof menu === 'string')
              return (
                <MenuItem key={idx}>
                  <Text textStyle="bb">{menu}</Text>
                </MenuItem>
              );
            return (
              <MenuItem key={idx} onClick={() => navigate(menu.path)}>
                <BoxIcon type={menu.type} onlyIcon={true} size={18} />
                <Text textStyle="bb">{menu.desc}</Text>
              </MenuItem>
            );
          })}
        </UserInfo.IsCoach>
      </MenuContainer>
      <Button background="anti" onClick={handleSignoutButtonClick}>
        로그아웃
      </Button>
    </Container>
  );
};
