import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const MemberTypeSelectorButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10.6rem;
  padding: 1rem;
  background-color: var(--color-gray);
  border-radius: 0.5rem;

  &.active {
    background-color: var(--color-point);
  }
`;

export const IconArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  background-color: var(--color-gray-second);
  border-radius: 50%;
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-white);

  & > p {
    align-self: flex-end;
  }
`;
