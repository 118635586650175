import { useGetCoachLectures } from '#api/lectures';
import { useUserInfo } from '#providers/UserInfo';

function useCoachGymLecture() {
  const { userInfo } = useUserInfo();
  const { data, isSuccess } = useGetCoachLectures(userInfo ? userInfo.id : '');
  const gymRentalFee =
    userInfo && userInfo.recommendGyms.length
      ? Math.floor(userInfo.recommendGyms[0].pricePerSession)
      : -1;

  const otData = isSuccess ? data.data.filter(item => item.isOt) : [];
  const otId = otData.length ? otData[0].id : undefined;

  const LectureData = isSuccess ? data.data.filter(item => !item.isOt) : [];
  const LectureSummary = LectureData.map(item => ({
    lectureId: item.id,
    title: item.title,
    body: item.body,
    isTitle: item.isTitle
  }));

  return { userInfo, gymRentalFee, LectureSummary, otId };
}

export default useCoachGymLecture;
