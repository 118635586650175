import { createContext, useState } from 'react';
import TokenController from '#utils/tokenController';

type LoginProviderProps = {
  children: React.ReactNode;
};

type ContextType = {
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LoginContext = createContext<ContextType>({
  isLoggedIn: false,
  setIsLoggedIn: () => undefined
});

export function LoginProvider({ children }: LoginProviderProps) {
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!TokenController.accessToken && !TokenController.isTokenExpired()
  );

  return (
    <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </LoginContext.Provider>
  );
}
