import { Text } from '#atoms';
import { ItemBox } from './styles';

type TextStyleType = 'display' | 't1' | 't2' | 't3' | 'bb' | 'c1' | 'c2';

interface Props {
  text: string;
  value: string;
  textStyle?: TextStyleType;
  valueStyle?: TextStyleType;
}

export const TextValueBox = ({ text, value, textStyle, valueStyle }: Props) => (
  <ItemBox>
    <Text textStyle={textStyle}>{text}</Text>
    <Text textStyle={valueStyle}>{value}</Text>
  </ItemBox>
);
