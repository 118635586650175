import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  button {
    background-color: #373245;
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    color: var(--color-point) !important;
  }
`;

export const ButtonBox = styled.div`
  height: 100%;
  display: flex;
  gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    height: 100%;
  }
`;

export const CountBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  button {
    height: 100%;
    padding: 1rem;
  }
`;

export const DropdownBox = styled.div`
  div {
    width: 8rem;
    background-color: #373245;
    color: #ccc;
    border: none;
    border-radius: 0.8rem;
  }
`;
