/** 알림톡을 통해 들어온 유저 또는 코치가 로그인 이후 가야할 곳을 만들어내는 함수
 *
 *
 * @param flag 알림톡을 통해 들어온 유저 또는 코치를 구분하기 위한 플래그
 * @param searchParams URLSearchParams 객체
 * @param pathname 알림톡유입 경로
 *
 * @return finalDestination
 */
export const makeFinalDestination = (
  flag: string,
  searchParams: URLSearchParams,
  pathname: string
) => {
  searchParams.delete(flag);
  const deletedFlagSearch = searchParams.toString();
  const finalDestination = deletedFlagSearch
    ? `${pathname}?${deletedFlagSearch}`
    : pathname;

  return finalDestination;
};
