import styled, { CSSObject } from 'styled-components';

export const StyledButton = styled.button<{
  isLoading?: boolean;
  customStyle?: CSSObject;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5.3rem;
  border-radius: 0.8rem;
  background-color: var(--color-point);
  color: var(--color-white);

  &:disabled {
    background-color: var(--color-gray) !important;
    color: var(--color-gray-text);
  }

  &.anti {
    background-color: var(--color-gray);
  }

  &.transparent {
    background-color: transparent;
    color: var(--color-gray-text-second);
  }

  ${({ isLoading }) => isLoading && 'pointer-events: none; opacity: 0.5;'}

  ${({ customStyle }) => customStyle}
`;
