import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useGetAverageReviews,
  useGetReviewsByRegisteredId
} from '#api/reviews';
import { Button, Text, Textarea } from '#atoms';
import { REVIEW_LIST } from '#const/review';
import { ImageWithUpload, TitleItemBox } from '#molecules';
import { HeaderBarTemplate } from '#templates';
import { imageFileType } from '#types/fileType';
import { dottedDateFormatter } from '#utils/dateFormatter';
import { useUploadImages } from 'src/components/molecules/ImageWithUpload/hooks';
import { RatingStars } from 'src/components/molecules/RatingStar';
import { useSubmitFinalReview } from './hooks';
import {
  Container,
  TextArea,
  InfoWrapper,
  BottomArea,
  ReviewCategory,
  ReviewListWrapper,
  StarArea,
  PageContainer
} from './styles';
// import { useGetRegisteredLecture } from '#api/registered-lectures';

export const FinalRatingPage = () => {
  //patch + type coach
  const [rates, setRates] = useState({
    coachNice: 0,
    lectureQuality: 0,
    timeNice: 0
  });
  const location = useLocation();
  const submitReview = useSubmitFinalReview();
  const { lectureId, registeredLectureId } = location.state as {
    lectureId: string;
    registeredLectureId: string;
  };

  const { data } = useGetAverageReviews(lectureId ?? '');
  const { data: rateData } = useGetReviewsByRegisteredId(
    registeredLectureId ?? ''
  );

  const [reviewText, setReviewText] = useState<string>('');
  const { localImgs, handleUploadImages, handleDeleteLocalImage } =
    useUploadImages({
      fileType: 'image'
    });

  if (!data || !rateData) return null;

  return (
    <PageContainer>
      <HeaderBarTemplate TitleCenter={<Text textStyle="t3">리뷰 등록</Text>}>
        <Container>
          <TextArea>
            <Text children="마지막 리뷰를 등록해주세요" textStyle="t1" />
            <div>
              <Text textColor="gray2" textStyle="t3">
                {dottedDateFormatter(data.startAt)} ~{' '}
                {dottedDateFormatter(data.endAt)}
              </Text>
              <InfoWrapper>
                <Text textColor="gray2" textStyle="t3">
                  {data.lecture.title}
                </Text>
                <Text textColor="gray2" textStyle="t3">
                  {data.lecture.coachName} 전문가
                </Text>
              </InfoWrapper>
            </div>
          </TextArea>
          <BottomArea>
            <ReviewListWrapper>
              {REVIEW_LIST.map(({ id, title }) => (
                <ReviewCategory key={id}>
                  <Text textStyle="bb">{title}</Text>
                  <StarArea>
                    <RatingStars
                      mode="register"
                      rate={rates[id] ? rates[id] : rateData[id]}
                      onClick={value => {
                        setRates(prev => ({ ...prev, [id]: value }));
                        return;
                      }}
                    />
                  </StarArea>
                </ReviewCategory>
              ))}
            </ReviewListWrapper>
            <Textarea
              height={19}
              limit={500}
              value={reviewText}
              onChange={setReviewText}
              placeholder="수업 리뷰를 적어주세요"
            />
            <TitleItemBox title="사진 첨부" type="images" isOptional>
              <ImageWithUpload
                localImages={localImgs}
                onUploadImage={handleUploadImages}
                onDeleteLocalImage={handleDeleteLocalImage}
                fileType={imageFileType}
              />
            </TitleItemBox>
            <Button
              children="리뷰 등록하기"
              onClick={() =>
                submitReview({
                  registeredLectureId,
                  rates,
                  description: reviewText,
                  localImgs
                })
              }
              disabled={!reviewText}
            />
          </BottomArea>
        </Container>
      </HeaderBarTemplate>
    </PageContainer>
  );
};
