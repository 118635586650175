import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import WelcomeFirst from '#assets/img/WelCome1.png';
import WelcomeSecond from '#assets/img/WelCome2.png';
import { PATH } from '#const/path';
import { WelcomeTemplate } from '#templates';
import localStoreageManager from '#utils/localStoreageManager';

export function WelcomeCoach() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStoreageManager.isAlreadyReadWelcome())
      navigate(PATH.HOME, { replace: true });
  }, []);

  return (
    <WelcomeTemplate
      firstTexts={[
        '내가 정하는 수업 일정, 수업 공간',
        '수업 가능한 요일, 시간을 자유롭게 설정하고,\n원하는 운동공간을 직접 비교해서 선택해보세요.'
      ]}
      firstBackground={WelcomeFirst}
      secondTexts={[
        '더 많은 회원, 더 많은 수익',
        '기존 60%의 수수료를 30%로 줄였습니다.\n수익은 동일하게, 고객에겐 저렴하게 다가가세요.'
      ]}
      secondBackground={WelcomeSecond}
      nextRoute={PATH.HOME}
    />
  );
}
