import styled from 'styled-components';

export const StyledInput = styled.input`
  width: 100%;
  height: 5.3rem;
  padding: 1rem;
  background-color: transparent;
  border: 1px solid var(--color-gray-second);
  border-radius: 0.5rem;
  font-size: 1.6rem;
  color: var(--color-white);
`;
