import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  > li {
    :last-child > button {
      border-bottom: none;
    }
  }
`;

export const RoundButton = styled.button`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: 2rem 0;
  border-bottom: 0.1rem solid var(--color-border);
`;

export const RoundBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
