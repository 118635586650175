import {
  deleteClinicalDataAfterImg,
  deleteClinicalDataBeforeImg,
  modifyClinicalData,
  postClinicalData,
  postClinicalDataImgs
} from '#api/clinical-data';
import { Image } from 'src/components/molecules/ImageWithUpload/hooks';

interface deleteImgProps {
  clinicalDataId: string;
  deletedImgIds: string[];
}

export interface modifyClinicalDataProps {
  clinicalDataId: string;
  rounds: number;
  body: string;
  deletedBeforeImgIds: string[];
  deletedAfterImgIds: string[];
  localBeforeImgs: Image[];
  localAfterImgs: Image[];
}

export interface postClinicalDataProps {
  lectureId: string;
  rounds: number;
  body: string;
  beforeImages: Image[];
  afterImages: Image[];
}

function convertToFormData(payload: postClinicalDataProps): FormData {
  const formData = new FormData();
  formData.append('lectureId', payload.lectureId);
  formData.append('rounds', payload.rounds.toString());
  formData.append('body', payload.body);

  payload.beforeImages.forEach(file => {
    formData.append(`beforeImages`, file.file);
  });

  payload.afterImages.forEach(file => {
    formData.append(`afterImages`, file.file);
  });

  return formData;
}

function convertModifiedToFormData(payload: {
  beforeImages: Image[];
  afterImages: Image[];
}): FormData {
  const formData = new FormData();
  payload.beforeImages.forEach(file => {
    formData.append(`beforeImages`, file.file);
  });

  payload.afterImages.forEach(file => {
    formData.append(`afterImages`, file.file);
  });

  return formData;
}

async function deletePostedBeforeImages({
  clinicalDataId,
  deletedImgIds
}: deleteImgProps) {
  if (deletedImgIds.length) {
    Promise.allSettled(
      deletedImgIds.map(imgId => {
        return deleteClinicalDataBeforeImg({
          id: clinicalDataId,
          beforeImageId: imgId
        });
      })
    );
  }
}

async function deletePostedAfterImages({
  clinicalDataId,
  deletedImgIds
}: deleteImgProps) {
  if (deletedImgIds.length) {
    Promise.allSettled(
      deletedImgIds.map(imgId => {
        return deleteClinicalDataAfterImg({
          id: clinicalDataId,
          afterImageId: imgId
        });
      })
    );
  }
}

export const useSubmitClinicalData = () => {
  const submitLecture = ({
    payload,
    onSuccess,
    onFail
  }: {
    payload: postClinicalDataProps;
    onSuccess: () => void;
    onFail?: () => void;
  }) => {
    postClinicalData(convertToFormData(payload))
      .then(() => {
        onSuccess();
      })
      .catch(() => onFail && onFail());
  };
  return submitLecture;
};

export const useModifyClinicalData = () => {
  const modifyLecture = ({
    payload,
    onSuccess,
    onFail
  }: {
    payload: modifyClinicalDataProps;
    onSuccess: () => void;
    onFail?: () => void;
  }) => {
    Promise.all([
      modifyClinicalData({
        id: payload.clinicalDataId,
        payload: { rounds: payload.rounds, body: payload.body }
      }),
      payload.deletedBeforeImgIds.length &&
        deletePostedBeforeImages({
          clinicalDataId: payload.clinicalDataId,
          deletedImgIds: payload.deletedBeforeImgIds
        }),
      payload.deletedAfterImgIds.length &&
        deletePostedAfterImages({
          clinicalDataId: payload.clinicalDataId,
          deletedImgIds: payload.deletedAfterImgIds
        }),
      (payload.localBeforeImgs.length > 0 ||
        payload.localAfterImgs.length > 0) &&
        postClinicalDataImgs({
          id: payload.clinicalDataId,
          payload: convertModifiedToFormData({
            beforeImages: payload.localBeforeImgs,
            afterImages: payload.localAfterImgs
          })
        })
    ])
      .then(() => onSuccess())
      .catch(() => onFail && onFail());
  };
  return modifyLecture;
};
