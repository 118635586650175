import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  color: var(--color-white);
`;

export const LectureTextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const LectureList = styled.ul`
  width: 100%;
  height: 100%;
`;
