import { useState } from 'react';
import { useNavigate } from 'react-router';
import { KakaoMap, Text } from '#atoms';
import { PATH } from '#const/path';
import useScrollRestoration from '#hooks/useScrollRestoration';
import { IGym } from '#types/gyms';
import { GymItems } from './GymItems';
import {
  ImageItem,
  ImageList,
  LecturePlacePageArea,
  LecturePlacePageWrap,
  MainInfoArea,
  MapContainer,
  PlaceInfoArea
} from './styles';
import { GymLocationPage } from '#pages';
import { Modal } from '#molecules';
import { MapModal } from './modal';

interface Props {
  recommendGyms: IGym[];
}

const lecturePlaceKey = 'lecturePlace';

export const LecturePlacePage = ({ recommendGyms }: Props) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [mapModal, setMapModal] = useState(false);
  const [detailImage, setDetailImage] = useState<string | null>(null);

  const handleDetailClick = (image: string | null) => {
    setDetailImage(image);
    setModalOpen(true);
  };

  const onClose = () => {
    setMapModal(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setDetailImage(null);
  };

  const handleLocationButtonClick = (gymId: string) => {
    gymId && navigate(PATH.GYM_LOCATION, { state: { gymId } });
  };

  const { setScrollRestoration } = useScrollRestoration(lecturePlaceKey);

  if (!recommendGyms.length)
    return (
      <LecturePlacePageWrap>
        <LecturePlacePageArea>
          <Text>수업공간 정보 없음</Text>
        </LecturePlacePageArea>
      </LecturePlacePageWrap>
    );

  return (
    <LecturePlacePageWrap>
      {recommendGyms.map(gym => (
        <LecturePlacePageArea key={gym.id}>
          {recommendGyms[0].images.length > 0 && (
            <ImageList>
              {recommendGyms[0].images.map(img => (
                <ImageItem key={img.id}>
                  <img
                    src={img.resizedLink}
                    onClick={() => handleDetailClick(img.uploadedLink)}
                  />
                </ImageItem>
              ))}
            </ImageList>
          )}
          <MainInfoArea>
            <div>
              <Text textStyle="t2">{gym?.name}</Text>
              <Text textStyle="c1" textColor="gray2">
                {gym?.address}
              </Text>
            </div>
            <MapContainer
              onClick={() => {
                setScrollRestoration();
                setMapModal(true);
                // handleLocationButtonClick(gym.id);
              }}
            >
              {!mapModal && (
                <KakaoMap
                  latitude={Number(recommendGyms[0].lat)}
                  longitude={Number(recommendGyms[0].long)}
                  onMarkerClick={() => handleLocationButtonClick(gym.id)}
                  // omMarkerClick시 resotraion이 돼야한다.
                />
              )}
            </MapContainer>
            {mapModal && (
              <MapModal onClose={onClose}>
                <GymLocationPage setMapModal={setMapModal} gymId={gym.id} />
              </MapModal>
            )}
            <PlaceInfoArea>
              <Text textStyle="t3">공간소개</Text>
              <Text textColor="gray2" style={{ whiteSpace: 'pre-wrap' }}>
                {gym?.introduction}
              </Text>
            </PlaceInfoArea>
            <PlaceInfoArea>
              <Text textStyle="t3">추가정보</Text>
              <GymItems data={recommendGyms[0]} />
            </PlaceInfoArea>
            <PlaceInfoArea>
              <Text textStyle="t3">전화번호</Text>
              <Text textColor="gray2" style={{ whiteSpace: 'pre-wrap' }}>
                {gym?.phone}
              </Text>
            </PlaceInfoArea>
          </MainInfoArea>
        </LecturePlacePageArea>
      ))}
      {modalOpen && detailImage && (
        <Modal onClose={handleModalClose} transparent>
          <img className="modal-img" src={detailImage} />
        </Modal>
      )}
    </LecturePlacePageWrap>
  );
};
