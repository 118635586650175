import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  background: var(--color-point-dark-gradient);
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 5rem 2rem;
  flex: 1;
`;

export const ArticleContainer = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  gap: 1rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 0 2rem 6.2rem;
`;

export const FieldContainer = styled.div`
  width: 100%;
  text-align: center;

  &.title {
    padding: 3rem 0;
  }
`;
