/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import { KakaoMapContainer } from './styles';

declare global {
  interface Window {
    kakao: {
      maps: any;
    };
  }
}

const loadKakaoMapScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}&libraries=services`;
    script.defer = true;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

interface Props {
  latitude: number;
  longitude: number;
  setIsBottomSheetExpand?: React.Dispatch<React.SetStateAction<boolean>>;
  onMarkerClick?: () => void;
}

export const KakaoMap = ({
  latitude,
  longitude,
  setIsBottomSheetExpand,
  onMarkerClick
}: Props) => {
  // 추후에 map 객체를 사용하는 함수가 작성될 것을 대비하여 useRef로 따로 빼두었음.
  const map = useRef<unknown>(null);

  useEffect(() => {
    loadKakaoMapScript()
      .then(() => {
        const container = document.getElementById('map');
        const options = {
          center: new window.kakao.maps.LatLng(latitude, longitude), // 지도의 중심 좌표
          level: 4 // 지도의 확대 레벨
        };

        map.current = new window.kakao.maps.Map(container, options);

        const markerPosition = new window.kakao.maps.LatLng(
          latitude,
          longitude
        );
        const marker = new window.kakao.maps.Marker({
          position: markerPosition
        });
        marker.setMap(map.current);

        window.kakao.maps.event.addListener(map.current, 'dragend', () => {
          setIsBottomSheetExpand?.(false);
        });

        window.kakao.maps.event.addListener(marker, 'click', () => {
          onMarkerClick?.();
        });
      })
      .catch(error => {
        console.error('카카오맵 API 스크립트 로딩 중 오류 발생:', error);
      });
  }, [latitude, longitude, setIsBottomSheetExpand]);

  return (
    <KakaoMapContainer>
      <div id="map" />
    </KakaoMapContainer>
  );
};
