import { useCallback, useEffect, useState } from 'react';

interface HookProps {
  onChange?: (checkItems: string[]) => void;
}

export const useCheckList = ({ onChange }: HookProps) => {
  const [checked, setCheked] = useState<Array<string>>([]);

  const toggleChecked = useCallback(
    (item: string) => {
      if (checked.includes(item)) {
        setCheked(checked.filter(i => i !== item));
      } else {
        setCheked([...checked, item]);
      }
    },
    [checked]
  );

  useEffect(() => {
    onChange && onChange(checked);
  }, [onChange, checked]);

  return {
    checked,
    toggleChecked
  };
};
