import styled from 'styled-components';

export const PageContainer = styled.div`
  height: 100%;
  padding-bottom: 9.3rem;

  &:first-child > div > :nth-child(2) > :first-child {
    // RecordDetailLectureInfo
    border-bottom: none;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  &.records {
    padding-top: 0;
    border-bottom: 1rem solid var(--color-gray);
  }

  .textArea {
    > div > div:first-child {
      border: none;
      background-color: var(--color-gray);
      > div {
        height: 100%;
        > p {
          // placeholder
          text-align: center;
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`;

export const ChartContainer = styled.div`
  > :first-child {
    padding-top: 0;
  }

  > :last-child {
    padding-bottom: 0.8rem;
    border: none;
  }
`;

export const ExerciseInfoContainer = styled.div`
  padding: 2rem 0;
  border-bottom: 1px solid var(--color-border);
`;

export const SetInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding-top: 2rem;

  > div {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 1.5fr 4fr 4fr 0.5fr;
    align-items: center;

    > div {
      display: flex;
      column-gap: 0.6rem;

      > div {
        height: 3rem;
        > input {
          text-align: center;
        }
      }
      > p {
        box-sizing: border-box;
        flex: 0 0 2.1rem; //text width 고정시켜 두 input width 동일하게 설정
      }
    }

    > :last-child {
      justify-self: flex-end;
    }
  }
`;

export const SetInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  & > :last-child {
    display: flex;
    align-self: flex-end;
    gap: 0.8rem;
    padding-top: 1rem;
  }
`;

export const AddBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 1rem 3rem;
  background-color: var(--color-background);
  display: flex;
  gap: 1.8rem;
  max-width: var(--max-width);
  margin: 0 auto;
`;

export const ExerciseDropdown = styled.ul`
  position: relative;
  background-color: var(--color-gray);
  max-height: 21rem;
  overflow-y: auto;
  width: 80%;
  top: -1rem;
  left: 5%;
  border-radius: 0.8rem;

  > li {
    display: flex;
    justify-content: center;
    padding: 1.4rem 0;
    border-bottom: 1px solid var(--color-border-light);

    &:last-child {
      border-bottom: none;
    }
  }
`;

export const CameraBox = styled.div`
  border: 1px solid white;
`;
