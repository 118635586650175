import { Button, Text } from '#atoms';
import { SelectBox } from '#atoms';
import { PATH } from '#const/path';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { DISEASE_LIST, PAINPART_LIST } from './const';
import {
  ButtonContainer,
  Container,
  PaddingContainer,
  SelectBoxContainer,
  TextContainer
} from './styles';

export function MyPageApplicationsHealth() {
  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.MYPAGE_APPLICATIONS}
      title="나의 신청서"
    >
      <PaddingContainer>
        <Container>
          <TextContainer>
            <Text textStyle="t1">
              회원님의 운동경력을
              <br />
              입력해주세요
            </Text>
          </TextContainer>
          <SelectBoxContainer>
            <SelectBox
              placeholder="앓고 계신 질환을 선택해주세요"
              data={DISEASE_LIST}
            />
            <Text textStyle="t3">수술 또는 통증 부위</Text>
            <SelectBox
              placeholder="앓고 계신 질환을 선택해주세요"
              data={PAINPART_LIST}
            />
          </SelectBoxContainer>
          <ButtonContainer>
            <Button>저장</Button>
          </ButtonContainer>
        </Container>
      </PaddingContainer>
    </PrevHeaderBarWithTitleTemplate>
  );
}
