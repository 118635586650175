import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100svh - 8.8rem);
  text-align: center;
`;

export const ButtonContainer = styled.div`
  padding: 0 2rem 3rem;
`;
