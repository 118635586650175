import React from 'react';
import { Switch } from '#atoms';
import { PATH } from '#const/path';
import { PrevHeaderBarTemplate, TitleMenuTemplate } from '#templates';

export function MyPageSettingsAlert() {
  return (
    <PrevHeaderBarTemplate prevRoute={PATH.MYPAGE_SETTING}>
      <TitleMenuTemplate
        title="알림 설정"
        titleCaption="피벗의 다양한 소식과 혜택을 알려드려요"
        menus={[
          {
            text: '혜택 정보 수신',
            menuContoller: <Switch initState={true} />
          },
          {
            text: '앱 푸시 알림',
            menuContoller: <Switch />
          }
        ]}
      />
    </PrevHeaderBarTemplate>
  );
}
