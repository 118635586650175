import { useState } from 'react';
import UserDefaultProfile from '#assets/svg/user_default_profile.svg';
import { BoxIcon, Text } from '#atoms';
import { Modal } from '#molecules';
import { CoachReviews } from '#types/rates';
import {
  CustomerReviewItemContainer,
  ProfileContainer,
  ProfileImage,
  ReviewContent,
  ImageList,
  ImageItem,
  TitleContainer
} from './styles';

export const CustomerReviewItem = ({
  userProfile,
  userNickName: userNickname,
  createdAt,
  description,
  lectureName,
  averageRate,
  images,
  totalRegisteredLectureCount
}: CoachReviews) => {
  const reviewDate = createdAt.slice(0, 10).replaceAll('-', '.');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [detailImage, setDetailImage] = useState<string | null>(null);

  const handleDetailClick = (image: string | null) => {
    setDetailImage(image);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setDetailImage(null);
  };

  return (
    <CustomerReviewItemContainer>
      <ProfileContainer>
        <ProfileImage
          src={userProfile.length ? userProfile[0] : UserDefaultProfile}
        />
        <TitleContainer>
          <div>
            <Text textStyle="bb">{userNickname}</Text>
            <div className="rate">
              <BoxIcon type="star_purple" onlyIcon={true} size={16} />
              <Text textStyle="c1" type="inline">
                {averageRate}
              </Text>
            </div>
          </div>
          <div data-text-type="c2" data-text-color="gray2">
            <Text>
              {lectureName} {totalRegisteredLectureCount}회
            </Text>
            <Text>{reviewDate}</Text>
          </div>
        </TitleContainer>
      </ProfileContainer>
      {images.length > 0 && (
        <ImageList>
          {images.map(img => (
            <ImageItem key={img.id}>
              <img
                src={img.resizedLink}
                onClick={() => handleDetailClick(img.uploadedLink)}
              />
            </ImageItem>
          ))}
        </ImageList>
      )}
      <ReviewContent>{description}</ReviewContent>
      {modalOpen && detailImage && (
        <Modal onClose={handleModalClose} transparent>
          <img className="modal-img" src={detailImage} />
        </Modal>
      )}
    </CustomerReviewItemContainer>
  );
};
