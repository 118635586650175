import styled from 'styled-components';

export const Conatiner = styled.div`
  width: 100%;
  height: 100%;
  color: var(--color-white);
  padding: 1rem;

  & > p {
    height: 3rem;
  }
`;

export const CategoryContainer = styled.div`
  width: 100%;
  height: calc(100% - 6.25rem);
  padding-bottom: 1rem;
  overflow: auto;
`;

export const CategoryList = styled.ul`
  width: 100%;
  word-break: break-all;

  & > li > div {
    aspect-ratio: 1;
  }
`;

export const CategoryItem = styled.li`
  display: inline-block;
  width: 30%;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--color-gray-text);

  &:not(:nth-child(3n + 1)) {
    margin-left: 5%;
  }

  & > p {
    line-height: 1.5;
  }

  &.on {
    color: var(--color-white);

    & > div {
      border: 2px solid var(--color-point);
    }
  }
`;
