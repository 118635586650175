import { useCallback } from 'react';
import { BoxIcon, IconType } from '#atoms';
import { StarWrapper } from './styles';

interface Props {
  mode: 'register' | 'show';
  rate: number;
  onClick?: (value: number) => void;
}

const getStarType = (mode: string, rate: number, idx: number): IconType => {
  if (rate <= idx) return 'star_gray';
  return mode === 'register' ? 'star_orange' : 'star_purple';
};

export const RatingStars = ({ mode, rate, onClick }: Props) => {
  const onClickStar = useCallback(
    (idx: number) => {
      onClick && onClick(idx);
    },
    [onClick]
  );

  return (
    <StarWrapper>
      {Array.from({ length: 5 }).map((_, idx) => (
        <BoxIcon
          key={idx}
          type={getStarType(mode, rate, idx)}
          size={24}
          onlyIcon
          onClick={() => onClickStar(idx + 1)}
        />
      ))}
    </StarWrapper>
  );
};
