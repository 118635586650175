import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getNicknameDuplicateCheck } from '#api/accounts';

interface Nickname {
  isValid: boolean;
  value: string;
}

interface Props {
  currentNickname: string;
}

const debouncedCheck = debounce(getNicknameDuplicateCheck, 400);

export const useNickname = ({ currentNickname }: Props) => {
  const [nickname, setNickname] = useState<Nickname>({
    isValid: true,
    value: currentNickname
  });

  const updateNickname = (nextNickName: string) => {
    setNickname(prev => ({ ...prev, value: nextNickName }));
  };

  useEffect(() => {
    const { value } = nickname;
    const trimedValue = value.trim();
    if (trimedValue.length < 2 || trimedValue.length > 10) {
      setNickname(prev => ({ ...prev, isValid: false }));
    } else if (value === currentNickname)
      setNickname(prev => ({ ...prev, isValid: true }));
    else {
      debouncedCheck(value)?.then(isDuplicate =>
        setNickname(prev => ({ ...prev, isValid: !isDuplicate }))
      );
    }
  }, [nickname.value]);

  const memolizedValue = useMemo(
    () => ({ nickname, updateNickname }),
    [nickname, updateNickname]
  );

  return memolizedValue;
};
