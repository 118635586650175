import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div > :first-child {
    // TopArticle of HeaderBarTemplate
    border-bottom: 1px solid var(--color-border);
    > div {
      box-sizing: content-box;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TextArea = styled.div`
  padding: 3rem 2rem 2rem;
  border-bottom: 1rem solid var(--color-gray);

  > div {
    padding-top: 3rem;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BottomArea = styled.div`
  padding: 2rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
`;

export const ReviewCategory = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  color: var(--color-gray-text-second);
`;

export const ReviewListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const StarArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 14.6rem;
`;
