export const CALENDAR_DAY = ['일', '월', '화', '수', '목', '금', '토'];

export const DATE = [
  {
    key: 1,
    text: '월'
  },
  {
    key: 2,
    text: '화'
  },
  {
    key: 3,
    text: '수'
  },
  {
    key: 4,
    text: '목'
  },
  {
    key: 5,
    text: '금'
  },
  {
    key: 6,
    text: '토'
  },
  {
    key: 0,
    text: '일'
  }
];

export const TIME = [
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00'
];
