import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding-bottom: 3rem;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-third);
  padding: 4rem 0 2rem;
`;
