import styled from 'styled-components';

export const DateTimePickerWrap = styled.div`
  width: 100%;
  padding: 1.65rem;
  background-color: var(--color-gray-third);
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

export const PickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const SelectorBox = styled.div`
  display: flex;
  padding: 0 2.5rem;
  justify-content: space-between;
`;
