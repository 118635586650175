import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 3rem 1.65rem 11.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
`;

export const InputArea = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;

  > div {
    width: 5.8rem;
    height: 3rem;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  padding: 1rem 2rem 3rem;
  background-color: var(--color-background);
`;
