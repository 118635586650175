import { useGetLecturesIng } from '#api/lectures';
import {
  postRegisteredLectureCancel,
  useGetRegisteredLecture
} from '#api/registered-lectures';
import { Button, Text, Textarea } from '#atoms';
import { PATH } from '#const/path';
import { useAttendingLectures } from '#providers/AttendLecture';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { LectureIngList } from '#types/lectures';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  ButtonContainer,
  Container,
  LoadingContainer,
  TextContainer
} from './styles';

export function MyPageScheduleCancellation() {
  const queryClient = useQueryClient();
  const today = dayjs();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { lectureId } = useParams();
  const [searchParams] = useSearchParams();
  const registeredLectureId = searchParams.get('id');
  const { fetchTodayLectures } = useUserInfo();

  const { data: registeredLecture } = useGetRegisteredLecture(
    registeredLectureId ?? ''
  );
  const { data, isLoading } = useGetLecturesIng(lectureId ?? '');
  const lecture: LectureIngList | null = data
    ? {
        lecture: data.lecture,
        gymName: data.gym.name,
        calculatedRegisteredLecture: data.calculatedRegisteredLecture,
        status: 'NORMAL'
      }
    : null;
  const { selectedLecture, updateSelectedLecture } = useAttendingLectures();
  const [reasonText, setReasonText] = useState('');
  const { mutate: cancelLecture } = useMutation(postRegisteredLectureCancel);
  const lectureDate = registeredLecture
    ? dayjs(registeredLecture?.taughtAt)
    : null;

  const handleButtonClick = () => {
    if (registeredLectureId) {
      if (
        today.isSame(lectureDate, 'day') &&
        today.isAfter(lectureDate, 'hour')
      ) {
        showToast({
          type: 'fail',
          message: '과거의 수업은 취소할 수 없습니다.'
        });
        return;
      } else
        cancelLecture(
          {
            registeredLectureId,
            payload: {
              status: today.isBefore(lectureDate, 'day')
                ? 'WITHDRAWAL'
                : 'CANCELLED',
              withdraw: reasonText
            }
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(['LECTURESINGLIST']);
              fetchTodayLectures();
              navigate('success');
            },
            onError: () =>
              showToast({
                type: 'fail',
                message: '예약 취소에 실패하였습니다.'
              })
          }
        );
    }
  };

  useEffect(() => {
    if (lecture && !selectedLecture) {
      updateSelectedLecture(lecture);
      return;
    }
  }, [selectedLecture, lecture]);

  if (isLoading) {
    return <LoadingContainer>loading...</LoadingContainer>;
  }

  return (
    <>
      <>
        <PrevHeaderBarWithTitleTemplate
          prevRoute={`${PATH.MYPAGE_SCHEDULES}/${selectedLecture?.lecture.id}`}
          title="예약 취소"
          defaultBackground
        >
          <Container>
            <TextContainer>
              <Text textStyle="t1">
                {registeredLecture?.round}회차 수업 예약을 취소하시겠어요?
              </Text>
              <div>
                <Text textStyle="c1">
                  예약 취소시 해당 전문가에게 알림이 전송됩니다.
                  <br />
                  전문가 별도의 예약 취소 규정을 확인해주세요.
                </Text>
              </div>
              {lectureDate && today.isSame(lectureDate, 'day') && (
                <div className="alert">
                  <Text textStyle="c1">
                    {lectureDate.format('MM')}월 {lectureDate.format('DD')}일
                    예약은 전문가 별도 예약 취소 규정에 의해
                    <br />
                    PT 횟수가 차감됩니다.
                  </Text>
                </div>
              )}
              <Textarea
                placeholder="예약 취소 이유를 적어주세요."
                value={reasonText}
                onChange={e => setReasonText(e)}
                height={20}
                limit={500}
              />
            </TextContainer>
          </Container>
        </PrevHeaderBarWithTitleTemplate>
        <ButtonContainer>
          <Button disabled={!reasonText} onClick={handleButtonClick}>
            예약 취소하기
          </Button>
        </ButtonContainer>
      </>
    </>
  );
}
