import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  button {
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    color: var(--color-point) !important;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  gap: 1rem;
`;
export const List = styled.div``;

export const User = styled.div<{ isSelected: boolean }>`
  height: 5.5rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  background-color: ${({ isSelected }) =>
    isSelected ? '#7750E9' : 'var(--color-black-500)'};
  padding: 2rem;
  border-radius: 8px;
`;

export const ModalContainer = styled.div`
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0;
`;
export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 48%;
    height: 4.8rem;
  }
`;
export const SearchFailBox = styled.div`
  width: 100%;
  height: 5.5rem;
  text-align: center;
  color: var(--color-gray-700);
`;
export const IconArea = styled.div`
  border: 1px solid #ccc;
  width: 5.5rem;
  height: 5.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;
