import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  border-bottom: 1rem solid var(--color-gray);
`;

export const ExerciseRecordWrapper = styled.li`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
  border-bottom: 0.1rem solid var(--color-border);

  &:last-child {
    border-bottom: none;
  }
`;

export const ExerciseSetWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem 15%;
`;

export const ExerciseSetBox = styled.li`
  display: grid;
  grid-template-columns: 2.2fr 1fr;
  width: 100%;
  gap: 1rem;
  color: var(--color-gray-text-second);

  > p {
    text-align: end;
  }
`;

export const PerSetWeight = styled.div`
  display: flex;
  justify-content: space-between;
`;
