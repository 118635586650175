import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 5.8rem;
  padding: 0 2rem;
  z-index: 1;
  color: var(--color-white);
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-gray);
  align-items: center;
  background-color: var(--color-background);
  & > p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`;

export const ChildrenContainer = styled.article`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 5.8rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const PageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 0 0 12.7rem;
  max-width: var(--max-width);
  margin: 0 auto;
  z-index: 11; // 헤더를 덮는 페이지이므로 쌓임맥락 가장 높게 설정
  background-color: var(--color-background);
`;

export const ButtonContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 2rem 3rem;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

// ExerciseRecordSelector

export const LectureInfoContainer = styled.div`
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-bottom: 1rem solid var(--color-gray);
`;

export const LectureUserNameWrapper = styled.div`
  display: flex;
  gap: 0.5rem;

  > :first-child {
    color: var(--color-point);
  }
`;

export const LectureInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-gray-text-second);
`;

export const RowStretchBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RecordsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  border-bottom: 1rem solid var(--color-gray);
`;

export const ExerciseRecordWrapper = styled.li`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
  border-bottom: 0.1rem solid var(--color-border);

  &:last-child {
    border-bottom: none;
  }
`;

export const ExerciseSetWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem 15%;
`;

export const ExerciseSetBox = styled.li`
  display: grid;
  grid-template-columns: 2.2fr 1fr;
  width: 100%;
  gap: 1rem;
  color: var(--color-gray-text-second);

  > p {
    text-align: end;
  }
`;

export const PerSetWeight = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ToggleBox = styled.div<{ isOpen: boolean }>`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  > :last-child {
    transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  }
`;

export const CheckBox = styled.div`
  height: 1.6rem;
  width: 1.6rem;
  align-self: center;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  border: 1px solid var(--color-gray-text-second);

  &.checked {
    background-color: var(--color-point);
    border: none;
  }
`;
