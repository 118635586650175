import { useState } from 'react';
import { BoxIcon, Text } from '#atoms';
import { Profile } from '#types/account';
import { imageFileType } from '#types/fileType';
import useUploadProfile from './hooks';
import {
  ProfileViewerWrap,
  ImageList,
  ImageItem,
  IndexIconArea,
  IndexIcon,
  ImageInput,
  ImageLabel
} from './styles';

interface Props {
  profileImgs: Profile[];
}

const maxCount = 10;

export const ProfileImgUploader = ({ profileImgs }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const { handlePostImages, handleDeleteImage } = useUploadProfile({
    images: profileImgs,
    maxCount
  });

  const handleScroll: React.UIEventHandler<HTMLUListElement> = e => {
    const newIndex = Math.round(
      e.currentTarget.scrollLeft / e.currentTarget.clientWidth
    );
    setCurrentIndex(newIndex);
  };

  return (
    <ProfileViewerWrap>
      <ImageList onScroll={handleScroll}>
        <ImageLabel>
          <ImageInput
            type="file"
            accept={imageFileType}
            onChange={handlePostImages}
            multiple={true}
          />
          <BoxIcon type="plus-white" onlyIcon size={24} />
          <Text textColor="gray2">프로필 사진 등록</Text>
        </ImageLabel>
        {profileImgs.length > 0 &&
          profileImgs.map(img => (
            <ImageItem key={img.id}>
              <img src={img.resizedLink} />
              <BoxIcon
                type="x-gray"
                onlyIcon
                size={16}
                onClick={() => handleDeleteImage(img.id)}
              />
            </ImageItem>
          ))}
      </ImageList>
      <IndexIconArea>
        {new Array(profileImgs.length + 1).fill(null).map((_, index) => (
          <IndexIcon
            key={index}
            className={index === currentIndex ? 'point' : ''}
          />
        ))}
      </IndexIconArea>
    </ProfileViewerWrap>
  );
};
