import { useCallback, useEffect, useState } from 'react';
import { StyledInput } from './styles';

interface Props {
  value?: number;
  onInput?: (str?: number) => void;
  placeholder?: string;
  min?: number;
  max?: number;
  pureNumber?: boolean;
}

const nonDigitRegex = /[^0-9]/;

export function FibudNumericInput({
  value,
  onInput,
  placeholder,
  min,
  max,
  pureNumber
}: Props) {
  const [num, setNum] = useState<number | undefined>(value);

  useEffect(() => {
    if (value !== undefined && value !== num) {
      setNum(value);
    }
  }, [value]);

  const onNumInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // 숫자가 아닌 문자가 들어온 경우 입력을 무시
      if (nonDigitRegex.test(e.target.value)) return;

      if (pureNumber) {
        // 0으로 시작하는 경우 이후 입력을 무시
        if (e.target.value.charAt(0) === '0' && e.target.value.length > 1)
          return;
      }
      const nextNum =
        e.target.value === Number(e.target.value).toString()
          ? max && Number(e.target.value) > max
            ? max
            : Number(e.target.value)
          : undefined;

      setNum(nextNum);
      if (onInput && num !== nextNum) onInput(nextNum);
    },
    [num, onInput]
  );

  return (
    <StyledInput
      type="text"
      value={num === undefined ? '' : num}
      min={min}
      max={max}
      placeholder={placeholder}
      pattern="[0-9]*"
      onInput={onNumInput}
    />
  );
}
