import { useState } from 'react';
import { Button, SelectBox, Text } from '#atoms';
import { PATH } from '#const/path';
import { SleepQualitySelector } from '#molecules';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { QUALITY_OF_SLEEP_LIST } from '../ApplicationSleep/const';
import {
  ButtonContainer,
  Container,
  PaddingContainer,
  TextContainer
} from './styles';

export const SLEEP_TIME_LIST = [
  '3시간 미만',
  '3시간',
  '4시간',
  '5시간',
  '6시간',
  '7시간',
  '8시간',
  '9시간',
  '10시간 이상'
];

export function MyPageApplicationsSleep() {
  const [_, setSleepQuality] = useState<string>(QUALITY_OF_SLEEP_LIST[0]);

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.MYPAGE_APPLICATIONS}
      title="나의 신청서"
    >
      <PaddingContainer>
        <Container>
          <TextContainer>
            <Text textStyle="t1">
              하루 평균 수면시간을
              <br />
              알려주세요
            </Text>
          </TextContainer>
          <SelectBox
            placeholder="수면시간을 선택해주세요"
            data={SLEEP_TIME_LIST}
          />
          <SleepQualitySelector
            data={QUALITY_OF_SLEEP_LIST}
            onChange={nsq => setSleepQuality(nsq)}
          />
          <ButtonContainer>
            <Button>저장</Button>
          </ButtonContainer>
        </Container>
      </PaddingContainer>
    </PrevHeaderBarWithTitleTemplate>
  );
}
