import { BoxIcon } from '#atoms';
import { Text } from '#atoms';
import { CheckBox, CheckBoxWrapper } from './styles';

interface CheckBoxAtomProps {
  id: string;
  label?: string;
  checked: boolean;
  labelLocation?: 'left' | 'right';
  onClick?: () => void;
}

export function LeftTextCheckBox({
  id,
  label,
  checked,
  onClick,
  labelLocation,
  ...props
}: CheckBoxAtomProps) {
  return (
    <CheckBoxWrapper onClick={onClick}>
      {!labelLocation ||
        (labelLocation === 'left' && label && (
          <Text textStyle="t3">{label}</Text>
        ))}
      <CheckBox id={id} className={checked ? 'checked' : ''} {...props}>
        {checked && (
          <BoxIcon type="check-white" size={10} boxColor="transparent" />
        )}
      </CheckBox>
      {labelLocation && labelLocation === 'right' && label && (
        <Text textStyle="c1" textColor="gray2">
          {label}
        </Text>
      )}
    </CheckBoxWrapper>
  );
}
