import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 3rem 1.65rem 11.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
`;

export const ImageList = styled.ul`
  display: flex;
  gap: 1.5rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const CurriculaContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const ImageItem = styled.li`
  position: relative;
  display: flex;
  width: 7.4rem;
  height: 7.4rem;

  img {
    width: 7.4rem;
    height: 7.4rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

export const CurriculaArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem 1rem 0.4rem;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 1rem 3rem;
  background-color: var(--color-background);
  display: flex;
  gap: 1.8rem;
  max-width: var(--max-width);
  margin: 0 auto;
`;
