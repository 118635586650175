import styled from 'styled-components';

export const Header = styled.div`
  position: sticky;
  top: 0;
  background: var(--color-point-gradient);
  z-index: 1;

  > :first-child {
    div {
      z-index: 1;
      border-bottom: none;
    }
    article {
      display: none;
    }
  }
`;

export const ChartHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
`;

export const ListContainer = styled.div`
  background-color: var(--color-background);
  padding: 1rem 0 3rem;
`;
