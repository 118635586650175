import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Text } from '#atoms';
import { LocationItem } from '#molecules';
import { $coachConditionState } from '#states/atoms/coachCondition';
import { $selectedCoachTagState } from '#states/atoms/tagState';
import { Container, TextArea } from './styles';

export const LocationTitleBox = () => {
  const setSelectedCoachTag = useSetRecoilState($selectedCoachTagState);
  const [, setSearchParams] = useSearchParams();
  const setCoachConditionState = useSetRecoilState($coachConditionState);

  const handleLocationBannerClick = (id: string, name: string) => {
    setSelectedCoachTag({ id, name });
    setSearchParams({ tab: '2' });
    setCoachConditionState(prev => {
      return {
        ...prev,
        grade: false,
        reviewCount: false,
        distance: true,
        price: false,
        minPrice: undefined,
        maxPrice: undefined
      };
    });
  };

  return (
    <Container onClick={() => handleLocationBannerClick('all', '전체')}>
      <TextArea>
        <Text>운동은 가까운게 최고!</Text>
        <Text textStyle="display">내 주변 전문가 둘러보기</Text>
      </TextArea>
      <LocationItem />
    </Container>
  );
};
