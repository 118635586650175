import { useNavigate } from 'react-router';
import { Button, Input, Text } from '#atoms';
import { PATH } from '#const/path';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarTemplate } from '#templates';
import { useNickname } from './hooks';
import { InputContainer, TitleContainer } from './styles';

export function MyPageProfileNickname() {
  const navigate = useNavigate();
  const { userInfo, patchProfile } = useUserInfo();
  const { nickname, updateNickname } = useNickname({
    currentNickname: userInfo?.nickname ?? ''
  });

  const saveNickName = () => {
    patchProfile(
      { nickname: nickname.value },
      {
        onSuccess: () => navigate(PATH.MYPAGE)
      }
    );
  };

  return (
    <PrevHeaderBarTemplate prevRoute={PATH.MYPAGE_PROFILE}>
      <TitleContainer>
        <Text textStyle="t1">
          변경할 닉네임을
          <br />
          입력해주세요
        </Text>
        <Text textStyle="c1">닉네임은 언제든지 변경할 수 있어요</Text>
      </TitleContainer>
      <InputContainer>
        <Input
          type="text"
          labelText="닉네임"
          placeholder="2 ~ 10자 이내로 입력해주세요"
          value={nickname.value}
          onChange={v => updateNickname(v.toString())}
        />
        <Button onClick={saveNickName} disabled={!nickname.isValid}>
          저장하기
        </Button>
      </InputContainer>
    </PrevHeaderBarTemplate>
  );
}
