import React, { PropsWithChildren } from 'react';
import {
  Container,
  TitleConatiner,
  TopArticle,
  ContentArticle,
  IconContainer
} from './style';

interface Props {
  TitleLeft?: React.ReactNode;
  TitleCenter?: React.ReactNode;
  TitleRight?: React.ReactNode;
  minWidthSetter?: string;
}

export const HeaderBarTemplate = ({
  TitleLeft,
  TitleCenter,
  TitleRight,
  minWidthSetter,
  children
}: PropsWithChildren<Props>) => {
  return (
    <Container>
      <TopArticle>
        <TitleConatiner>
          <div
            className={`flex justify-center items-center min-w-[18px] ${minWidthSetter}`}
          >
            {TitleLeft}
          </div>
          <IconContainer>
            <div className="w-full flex justify-center items-center">
              {TitleCenter}
            </div>
            <div
              className={`flex justify-center items-center min-w-[18px] ${minWidthSetter}`}
            >
              {TitleRight}
            </div>
          </IconContainer>
        </TitleConatiner>
      </TopArticle>
      <ContentArticle>{children}</ContentArticle>
    </Container>
  );
};
