import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { Text } from '#atoms';
import { SEX } from '#const/confirmationForms/enum';
import { PATH } from '#const/path';
import { useChat } from '#providers/Chat';
import { IGetConfirmationForm } from '#types/confirmationForms';
import { exerciseCareerFormatter } from '#utils/dateFormatter';
import { BottomArea, Container, DetailArea, TitleArea } from './styles';

interface Props {
  customerDetail: IGetConfirmationForm | Record<string, never>;
  type: 'customer-detail' | 'confirmation-form';
}

export const DashboardCustomerInfo = ({ customerDetail, type }: Props) => {
  const navigate = useNavigate();
  const { openChat } = useChat();
  const handleChatButtonClick = (customerId: string) => {
    const onSuccess = () => {
      navigate(PATH.CHAT_ROOM);
    };
    const onFail = () => '';
    openChat(customerId, onSuccess, onFail);
  };

  return (
    <>
      <Container>
        <TitleArea>
          <Text textStyle="t1">{customerDetail.lecture.title}</Text>
          <Text textStyle="c1">
            {
              customerDetail.calculatedRegisteredLecture
                .totalRegisteredLectureCount
            }
            회
          </Text>
        </TitleArea>
        <Text textStyle="t3">{customerDetail.person.name}</Text>
        <DetailArea>
          <Text>
            {dayjs().diff(dayjs(customerDetail.person.birthday), 'year')}세
          </Text>
          <Text>{SEX[customerDetail.person.sex]}</Text>
          <Text>{customerDetail.healthInfo.height}cm</Text>
          <Text>{customerDetail.healthInfo.weight}kg</Text>
          <Text>
            운동경력{' '}
            {exerciseCareerFormatter(customerDetail.person.startAthletic)}
          </Text>
        </DetailArea>
      </Container>
      <BottomArea>
        {type === 'customer-detail' ? (
          <Text onClick={() => navigate(PATH.DASHBOARD_CONFIRMATION)}>
            신청서 보기
          </Text>
        ) : (
          <Text onClick={() => navigate(PATH.DASHBOARD_CUSTOMER)}>확인</Text>
        )}
        <Text onClick={() => handleChatButtonClick(customerDetail.person.id)}>
          채팅하기
        </Text>
      </BottomArea>
    </>
  );
};
