import { Text } from '#atoms';
import { OnePointCard } from './styles';
import Icon from '#assets/svg/3dicons.svg';
import { useNavigate } from 'react-router';
export const ReservationCard = () => {
  const navigate = useNavigate();
  return (
    <OnePointCard onClick={() => navigate('/expert?tab=원포인트 레슨')}>
      {/* <Card>
        <Text textStyle="bb">PT견적</Text>
        <Text
          textColor="gray2"
          style={{ lineHeight: '3.2rem', fontSize: '1.4rem' }}
        >
          내게 꼭 맞는 PT 찾기
        </Text>
      </Card> */}
      {/* <Card> */}
      <Text textStyle="bb">원포인트 레슨</Text>
      <Text
        textColor="gray2"
        style={{ lineHeight: '3.2rem', fontSize: '1.4rem' }}
      >
        막히는 부분이 있다면?
      </Text>
      <img src={Icon} width={82} height={82} />
      {/* </Card> */}
    </OnePointCard>
  );
};
