import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { IGetTags } from '#types/tags';

export const getTags = async () => {
  const res = await request<IGetTags>({
    method: 'get',
    url: '/tags',
    params: {
      page: 0,
      offset: 20
    }
  });

  return res.data;
};

export const getAllTags = async () => {
  const res = await request<IGetTags>({
    method: 'get',
    url: '/tags/all',
    params: {
      page: 0,
      offset: 20
    }
  });

  return res.data;
};

export const useGetTags = () => {
  return useQuery(queryKeyFactory.GET_TAGS(), getTags);
};

export const useGetAllTags = () => {
  return useQuery(queryKeyFactory.GET_ALL_TAGS(), getAllTags);
};
