import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  justify-content: space-between;
  overflow: scroll;

  & > li {
    width: 24%;
    border-radius: 0.5rem;
  }
`;
