import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  border: 1px var(--color-border) solid;
  height: 5rem;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;

  & input {
    // width: 100%;
    width: 90%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--color-white);
    padding: 0 1rem;
  }

  & p {
    padding-right: 1rem;
  }
`;

export const LabeledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
