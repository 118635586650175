import React from 'react';
import { Route, Routes } from 'react-router';
import {
  DashboardChartPage,
  DashboardChartFormPage,
  DashboardPage,
  DashboardCustomerPage,
  DashboardConfirmationPage
} from '#pages';

export function DashboardView() {
  return (
    <Routes>
      <Route path="" element={<DashboardPage />} />
      <Route
        path="customer/*"
        element={
          <Routes>
            <Route path="" element={<DashboardCustomerPage />} />
            <Route
              path="confirmation"
              element={<DashboardConfirmationPage />}
            />
          </Routes>
        }
      />
      <Route
        path="chart/*"
        element={
          <Routes>
            <Route path="" element={<DashboardChartPage />} />
            <Route path="form" element={<DashboardChartFormPage />} />
          </Routes>
        }
      />
    </Routes>
  );
}
