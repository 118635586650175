import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { PATH } from '#const/path';
import useCoachGymLecture from '#hooks/useCoachGymLecture';
import { CoachClassSummary } from '#organisims';
import { useCoachLecture } from '#providers/CoachLecture';
import { useToast } from '#providers/ToastProvider';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { Container } from './styles';

export function ProfileClassPage() {
  const { showToast } = useToast();

  const { userInfo, gymRentalFee, LectureSummary } = useCoachGymLecture();
  const { resetLectureData } = useCoachLecture();

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo && gymRentalFee === -1) {
      showToast({
        type: 'fail',
        message: '운동공간 등록 후 수업을 개설할 수 있습니다.'
      });
      navigate(PATH.PROFILE_UPDATE);
    }
  }, [userInfo, gymRentalFee]);

  useEffect(() => {
    resetLectureData();
  }, []);

  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.PROFILE_UPDATE}
      title="정규수업 등록"
      defaultBackground
    >
      <Container>
        <CoachClassSummary items={LectureSummary} />
      </Container>
    </PrevHeaderBarWithTitleTemplate>
  );
}
