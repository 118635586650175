import { useQueryString } from '#hooks/useQueryString';
import styled from 'styled-components';
import { useGetRecordsV2 } from '../api';

export const InbodyIndicator = () => {
  const [registedLectureId] = useQueryString(['id']);

  const { data } = useGetRecordsV2(registedLectureId);

  if (!data) return <div></div>;

  const { physicalRecordDto } = data;
  const { inbody } = physicalRecordDto;

  const list = ['bodyFatMass', 'skeletalMuscleMass', 'weight'] as const;

  return (
    <div className="flex w-full py-2 pb-10">
      {list.map(item => (
        <div
          className="flex-1 flex flex-col gap-12 justify-center items-center border-r-1 border-[#262231] border-solid last:border-r-0"
          key={item}
        >
          <Key>{LIST_KEY[item]}</Key>
          <Value>
            {inbody ? inbody[item] : '-'} <Unit>{LIST_KEY_METERS[item]}</Unit>
          </Value>
        </div>
      ))}
    </div>
  );
};

const LIST_KEY: { [key: string]: string } = {
  bodyFatMass: '체중',
  skeletalMuscleMass: '체지방률',
  weight: '골격근량'
};

const LIST_KEY_METERS: { [key: string]: string } = {
  bodyFatMass: 'kg',
  skeletalMuscleMass: '%',
  weight: 'kg'
};

const Key = styled.div`
  color: var(--Gray-Scale-600, #756f7e);

  /* NEW/caption_01_regular */
  font-family: 'Pretendard Variable';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
`;

const Value = styled.p`
  color: var(--Gray-Scale-400, #bcb8c3);

  /* NEW/body_02_medium */
  font-family: 'Pretendard Variable';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

const Unit = styled.span`
  color: var(--Gray-Scale-500, #948f9e);

  /* NEW/caption_01_regular */
  font-family: 'Pretendard Variable';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
