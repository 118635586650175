import request from '#api/axios';
import { IDegree } from '#types/account';

export const postDegrees = async (payload: IDegree) => {
  const res = await request({
    method: 'post',
    url: '/degrees',
    data: payload
  });

  return res.data;
};

export const deleteDegrees = async (id: string) => {
  const res = await request({
    method: 'delete',
    url: `/degrees/${id}/delete`
  });

  return res.data;
};
