import styled from 'styled-components';

export const Container = styled.header`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  height: 5.8rem;
  padding: 0 1.5rem;
  background-color: var(--color-background);
  box-shadow: 0 0.25rem 0.5rem var(--color-black-alpha30);
  z-index: 999;
`;
