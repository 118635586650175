import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 7.8rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-background);
  overflow-y: auto;
  z-index: 999;
  max-width: var(--max-width);
  margin: 0 auto;
`;

export const HeaderContainer = styled.div`
  position: fixed;
  top: 2rem;
  display: flex;
  width: 100%;
  padding: 0 2rem;
  align-items: center;
  background-color: var(--color-background);
`;

export const Container = styled.div`
  position: relative;
  padding: 2rem 2rem 8rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  overflow-y: auto;
`;

export const BankSelectorWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
`;

export const BankItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 10.4rem;
  height: 8.4rem;
  padding: 1.4rem;
  border: 2px solid transparent;
  border-radius: 1.8rem;
  background-color: var(--color-black);

  :active {
    border: 2px solid var(--color-point);
    outline: none;
  }

  > img {
    width: 3rem;
    height: 3rem;
  }
`;
