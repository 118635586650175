import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

interface Props {
  date?: Date;
}

export function useMonthDate({ date = new Date() }: Props) {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );

  useEffect(() => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    if (
      currentMonth !== startDate.getMonth() ||
      currentYear !== startDate.getFullYear()
    ) {
      navigate(`?date=${dayjs(startDate).format('YYYY-MM-DD')}`);
    } else {
      navigate(``);
    }
  }, [startDate]);

  const handleNextMonth = () => {
    const newStartDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      1
    );
    setStartDate(newStartDate);
    setEndDate(
      new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 1, 0)
    );
  };

  const handlePrevMonth = () => {
    const newStartDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() - 1,
      1
    );
    setStartDate(newStartDate);
    setEndDate(
      new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 1, 0)
    );
  };

  return {
    startDate,
    endDate,
    handleNextMonth,
    handlePrevMonth
  };
}
