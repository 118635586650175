import { BoxIcon } from '#atoms';
import { Text } from '#atoms';
import { AddBox } from './styles';

interface Props {
  onClickFuc: () => void;
}

export function SessionAdderBox({ onClickFuc }: Props) {
  return (
    <AddBox>
      <BoxIcon
        type="plus-gray"
        onlyIcon={true}
        size={26}
        onClick={onClickFuc}
      />
      <Text textStyle="c1">회차 추가</Text>
    </AddBox>
  );
}
