import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getMyAccount } from '#api/accounts';
import { deleteDegrees, postDegrees } from '#api/degrees';
import { BoxIcon, Button, Input, Text } from '#atoms';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { dottedDateFormatter } from '#utils/dateFormatter';
import CheckBoxAtom from 'src/components/atoms/Checkbox';
import { Degree, useDegrees } from './hooks';
import {
  AppendButtonContainer,
  ButtonContainer,
  DegreeItem,
  DegreeList,
  Container,
  FlexBox,
  CheckBoxContainer
} from './styles';

export function ProfileDegreePage() {
  const now = dayjs().format('YYYY-MM-DD');
  const navigate = useNavigate();
  const { showToast } = useToast();

  const { userInfo, setUserInfo } = useUserInfo();
  const currentDegrees: Degree[] =
    userInfo?.degrees
      .filter(degree => degree.status === 'NORMAL')
      .map(({ id, name, at }) => ({
        id,
        name,
        at
      })) ?? [];
  const [inputText, setInputText] = useState<string>('');
  const [acquiredDate, setAcquiredDate] = useState<string | undefined>('');
  const [checked, setChecked] = useState<boolean>(false);

  const { degrees, setDegrees, appendDegree, removeDegree } = useDegrees({
    currentDegrees
  });

  // 새로고침 등의 이슈로 userInfo를 못받아와질 때, 다시 set 해줌
  useEffect(() => {
    if (!userInfo) return;
    setDegrees(currentDegrees);
  }, [userInfo]);

  const appendItem = () => {
    if (!inputText.trim().length || acquiredDate === '') return;
    appendDegree({
      id: Date.now().toString(),
      name: inputText,
      at: acquiredDate
    });
    setInputText('');
    setAcquiredDate('');
    setChecked(false);
  };

  const handleCheckBox = () => {
    setChecked(!checked);
    setAcquiredDate(undefined);
  };

  const handleChangeSendDateFormat = (day: string | number) => {
    if (typeof day === 'number') return;
    setAcquiredDate(day);
  };

  const onButtonClick = () => {
    Promise.all([
      degrees
        .filter(degree => !currentDegrees.some(e => e.id === degree.id))
        .map(c => postDegrees({ name: c.name, at: c.at || undefined })),
      currentDegrees
        .filter(currentDegree => !degrees.some(e => e.id === currentDegree.id))
        .map(c => deleteDegrees(c.id))
    ]).then(res => {
      Promise.all(res.map(p => Promise.allSettled(p))).then(results => {
        const hasErrors = results.some(result =>
          result.some(r => r.status === 'rejected')
        );
        if (!hasErrors) {
          showToast({
            message: '학력사항 등록에 성공하였습니다.'
          });
          getMyAccount().then(account => setUserInfo(account));
          navigate(PATH.PROFILE_UPDATE);
        } else {
          showToast({
            type: 'fail',
            message: '학력사항 등록에 실패하였습니다.'
          });
        }
      });
    });
  };

  useEffect(() => {
    if (checked && acquiredDate?.length) {
      setAcquiredDate(undefined);
    }
  }, [checked]);

  return (
    <>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={PATH.PROFILE_UPDATE}
        title="학력사항 등록"
      >
        <Container>
          <Text textStyle="display">학력사항을 작성해주세요</Text>
          <Input
            type="text"
            placeholder="학력사항을 입력해주세요"
            value={inputText}
            onChange={text => setInputText(String(text))}
          />
          <FlexBox>
            <Input
              type="date"
              data-placeholder="학력 취득일을 선택해주세요"
              value={acquiredDate}
              checked={checked}
              onChange={day => handleChangeSendDateFormat(day)}
              min="1900-01-01"
              max={now}
            />
            <CheckBoxContainer>
              <CheckBoxAtom
                id="working"
                label="재학중"
                onClick={handleCheckBox}
                checked={checked}
              />
            </CheckBoxContainer>
          </FlexBox>
          <DegreeList>
            {degrees.map(({ id, name, at }) => (
              <DegreeItem key={id}>
                {/* 학력사항을 자격사항에서 옮길 때 날짜가 없는 경우 1900-01-01로 임시 설정 */}
                <Text>
                  {`${name}${
                    at
                      ? dottedDateFormatter(at) === '1900.01.01'
                        ? ''
                        : ` / ${dottedDateFormatter(at)}`
                      : ' / 재학중'
                  }`}
                </Text>
                <BoxIcon
                  type="x-gray"
                  onlyIcon={true}
                  size={12}
                  onClick={() => removeDegree(id)}
                />
              </DegreeItem>
            ))}
          </DegreeList>
          <AppendButtonContainer onClick={() => appendItem()}>
            <BoxIcon type="plus-gray" size={24} onlyIcon={true} />
            <Text textStyle="c1" textColor="gray2">
              학력 추가
            </Text>
          </AppendButtonContainer>
        </Container>
      </PrevHeaderBarWithTitleTemplate>
      <ButtonContainer>
        <Button
          disabled={JSON.stringify(degrees) === JSON.stringify(currentDegrees)}
          onClick={onButtonClick}
        >
          프로필 등록
        </Button>
      </ButtonContainer>
    </>
  );
}
