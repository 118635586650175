import { useEffect, useState } from 'react';
import { MultiSelectBar, InlineSelect, Text } from '#atoms';
import { DATE, TIME } from '#const/enrollment/calendar';
import { DateTime } from '#types/schedule';
import { SelectItem } from 'src/components/atoms/MultiSelectBar';
import { DateTimePickerWrap, PickerContainer, SelectorBox } from './styles';

interface Props {
  onComplete: (data: DateTime) => void;
  isCompleted: boolean;
}

export const DateTimePicker = ({ onComplete, isCompleted }: Props) => {
  const defaultValues: SelectItem['key'][] = []; //TODO: api 연동
  const [dates, setDates] = useState<SelectItem['key'][]>(defaultValues ?? []);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const handleDatesClick = (selectedItems: number[]) => {
    setDates(selectedItems);
  };

  const handleStartTimeClick = (time: string) => {
    setStartTime(time);
  };

  const handleEndTimeClick = (time: string) => {
    setEndTime(time);
  };

  const getFilteredTimes = (startTime: string) => {
    if (!startTime) return TIME;
    return TIME.filter(time => time > startTime);
  };

  useEffect(() => {
    if (dates.length && startTime && endTime) {
      onComplete({ dates, startTime, endTime });
      setDates([]);
      setStartTime('');
      setEndTime('');
    }
  }, [isCompleted]);

  return (
    <DateTimePickerWrap>
      <PickerContainer>
        <Text textStyle="t3" className="color-white">
          요일 선택
        </Text>
        <SelectorBox>
          <MultiSelectBar
            items={DATE}
            values={dates}
            onChange={handleDatesClick}
          />
        </SelectorBox>
      </PickerContainer>
      <PickerContainer>
        <Text textStyle="t3" className="color-white">
          시간 선택
        </Text>
        <SelectorBox>
          <InlineSelect
            sideLabel="부터"
            data={TIME}
            value={startTime}
            onClick={handleStartTimeClick}
          />
          <InlineSelect
            sideLabel="까지"
            data={getFilteredTimes(startTime)}
            value={endTime}
            onClick={handleEndTimeClick}
            disabled={startTime === ''} // 시작 시간 선택 후에 선택할 수 있도록 설정
          />
        </SelectorBox>
      </PickerContainer>
    </DateTimePickerWrap>
  );
};
