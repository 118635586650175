import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 9rem;
  padding: 0.7rem 2rem 3rem;
  background-color: var(--color-background);
`;

export const TextContainer = styled.div`
  width: 100%;
  padding: 3.6rem 2rem 2rem;
`;

export const ItemContainer = styled.div`
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: var(--color-gray-third);

  > div.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SelectedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TimeSliderContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
`;

export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
