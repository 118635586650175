import styled from 'styled-components';

export const SlideSelectorArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const GradientBox = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 22rem;
  padding: 2rem;
  background: var(--color-point-gradient-r180);
  border-radius: 1rem;
  color: var(--color-white);
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const NewRequestArea = styled.div`
  text-align: right;
`;

export const InClassConatiner = styled.div`
  display: flex;
  width: 100%;
  color: var(--color-gray-text-second);
  justify-content: space-between;

  svg {
    transform: translate(0, 50%);
  }
`;

export const InClassConsumerContainer = styled.article`
  width: 100%;
  /* max-height: 28rem; */
  background-color: var(--color-gray-second);
  border-radius: 1rem;
  padding: 8px 13px;
  overflow-y: auto;
`;

export const InClassItem = styled.div`
  width: 100%;
  background-color: var(--color-gray);
  border-radius: 1rem;
  padding: 8px 15px 8px 16px;

  hr {
    border-color: var(--color-point-fourth);
  }

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const ClassInfoContainer = styled.div`
  & > div:first-child {
    margin-bottom: 5px;
  }

  & > div:last-child {
    margin-top: 3px;
  }
`;

export const ClassInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  &.time {
    gap: 1rem;
    > :last-child {
      flex-shrink: 0;
    }
  }
`;
