import { useRef, useState } from 'react';

const useDrag = () => {
  const dragItem = useRef<number | null>(null); // 드래그할 아이템의 인덱스
  const dragOverItem = useRef<number | null>(null); // 드랍할 위치의 아이템의 인덱스
  const [list, setList] = useState<string[]>([]);

  // 드래그 시작될 때 실행
  const dragStart = (position: number) => {
    dragItem.current = position;
  };

  // 드래그중인 대상이 위로 포개졌을 때
  const dragEnter = (position: number) => {
    dragOverItem.current = position;
  };

  // 드랍 (커서 뗐을 때)
  const drop = () => {
    if (
      list.length > 0 &&
      dragItem.current != null &&
      dragOverItem.current != null
    ) {
      const newList = [...list];

      const dragItemValue = newList[dragItem.current];
      newList.splice(dragItem.current, 1);
      newList.splice(dragOverItem.current, 0, dragItemValue);

      dragItem.current = null;
      dragOverItem.current = null;
      setList(newList);
    }
  };

  return {
    list,
    setList,
    dragStart,
    dragEnter,
    drop
  };
};

export default useDrag;
