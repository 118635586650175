import styled from 'styled-components';

export const ImageIconBox = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--color-gray-second);
  min-width: 7.4rem;
  max-width: var(--max-width);
  height: 7.4rem;
  padding: 1.5rem 2rem 1.1rem;
`;

export const ImageList = styled.ul`
  display: flex;
  gap: 1.5rem;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 1.5rem;
`;

export const ImageInput = styled.input`
  display: none;
`;

export const ImageItem = styled.li`
  position: relative;
  display: flex;
  width: 7.4rem;
  height: 7.4rem;

  img,
  video {
    width: 7.4rem;
    height: 7.4rem;
    object-fit: contain;
    border-radius: 0.5rem;
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
`;
