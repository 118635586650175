import React from 'react';
import { Text } from '#atoms';
import { Container, HeaderContainer, MenuItems, MenuList } from './styles';

interface Menu {
  text: string;
  textCaption?: string;
  menuContoller: JSX.Element;
}

interface Props {
  title: string;
  titleCaption?: string;
  menus: Array<Menu>;
}

export function TitleMenuTemplate({ title, titleCaption, menus }: Props) {
  return (
    <Container>
      <HeaderContainer>
        <Text textStyle="t1">{title}</Text>
        {titleCaption && <Text>{titleCaption}</Text>}
      </HeaderContainer>
      <MenuList>
        {menus.map(menu => (
          <MenuItems key={menu.text}>
            {!menu.textCaption && <Text textStyle="t3">{menu.text}</Text>}
            {menu.textCaption && (
              <div>
                <Text textStyle="t3">{menu.text}</Text>
                <Text textStyle="c1" textColor="gray">
                  {menu.textCaption}
                </Text>
              </div>
            )}
            {menu.menuContoller}
          </MenuItems>
        ))}
      </MenuList>
    </Container>
  );
}
