import React, { useEffect, useState } from 'react';
import { getBanks } from '#api/banks';
import { IGetBanks } from '#types/banks';

interface Props {
  children?: React.ReactNode;
}

interface BankListContext {
  isLoading: boolean;
  isError: boolean;
  banks: IGetBanks | null;
}

const BankListContext = React.createContext<BankListContext | null>(null);

export const useBankList = () => {
  const context = React.useContext(BankListContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <BankList> component.'
    );
  }
  return context;
};

function BankList({ children }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [banks, setBanks] = useState<IGetBanks | null>(null);

  useEffect(() => {
    getBanks()
      .then(data => setBanks(data))
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const memorizedValue = React.useMemo(
    () => ({ isLoading, isError, banks }),
    [isLoading, isError]
  );

  return (
    <BankListContext.Provider value={memorizedValue}>
      {children}
    </BankListContext.Provider>
  );
}

function IsLoading({ children }: Props) {
  const { isLoading } = useBankList();
  return isLoading ? <>{children}</> : null;
}

function IsError({ children }: Props) {
  const { isLoading, isError } = useBankList();
  return !isLoading && isError ? <>{children}</> : null;
}

function EmptyList({ children }: Props) {
  const { isLoading, isError, banks } = useBankList();
  return !isLoading && !isError && !banks ? <>{children}</> : null;
}

function HasList({ children }: Props) {
  const { isLoading, isError, banks } = useBankList();
  return !isLoading && !isError && banks !== null ? <>{children}</> : null;
}

BankList.IsError = IsError;
BankList.IsLoading = IsLoading;
BankList.EmptyList = EmptyList;
BankList.HasList = HasList;

export default BankList;
