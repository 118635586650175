import styled from 'styled-components';

export const GymContentWrap = styled.div<{ isClicked: boolean }>`
  padding: 1.65rem;
  background-color: ${props =>
    props.isClicked ? `var(--color-point)` : `var(--color-gray-third)`};
`;

export const ImageArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  width: 7.7rem;
  height: 100%;
  background-color: var(--color-gray-second);
  position: relative;

  svg {
    width: 3rem;
    height: 3rem;
  }
`;

export const GymItemsContainer = styled.div<{ isClicked: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1.5rem;

  :last-child > span {
    padding: 0.3rem 1rem;
    background: ${props =>
      props.isClicked ? `var(--color-white)` : `var(--color-point)`};
    color: ${props =>
      props.isClicked ? `var(--color-point)` : `var(--color-white)`};
    border-radius: 1.25rem;
    height: fit-content;
  }

  p {
    color: ${props => props.isClicked && `var(--color-white)`};
  }
`;

export const InfoArea = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  padding: 0.25rem 0;
  row-gap: 0.15rem;

  p:last-child {
    align-self: flex-end;
  }
`;

export const GymThumbnailImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  object-fit: contain;
`;
